<template>
  <section class="time-issue-table" v-if="tableData.length">
    <div class="table-title">
      <div>{{$t('issue_times')}}</div>
    </div>
    <div :class="['table-header', 'table-row']">
      <div class="start">{{$t('start')}}</div>
      <div class="solve-sla-container">
        <div class="solve-hour">{{$t('solved')}}</div>
        <div class="SLA" :style="{ visibility: 'hidden' }">
          10 min
          <img
            :style="{ visibility: 'hidden' }"
            :src="require('@/assets/performance/warning.svg')"
          />
        </div>
      </div>
    </div>
    <div class="table-row" v-for="(item, index) in tableData" :key="index">
      <div class="start-icon-container">
        <div class="start-hour">
          {{item.complaints[0].time | ddmmyy}} -
          <span
            class="start-hour-time"
          >{{_getOrigDateTime(item.complaints[0].time).toTimeString().slice(0,5)}}</span>

        </div>
        <div class="icon-list">
          <Tooltip
            v-for="(complaint, index) in reduceItems(item.complaints)"
            :key="index"
            :boldText="isRTL ? complaint.name_he : complaint.name"
            :text="ISOToHHMM(_getOrigDateTime(complaint.time))"
          >
            <img class="icon-img" :src="getIssueIcon(complaint, complaint.isSuggestion, complaint.isOffline)" :alt="complaint.name" />
          </Tooltip>
        </div>
      </div>
      <div v-if="item.closeTime" class="solve-sla-container">
        <div class="solve-hour">
          {{item.closeTime | ddmmyy}} -
          <span
            class="solve-hour-time"
          >{{_getOrigDateTime(item.closeTime).toTimeString().slice(0,5)}}</span>
        </div>
        <div class="SLA" :style="{ color: `${item.isSlaExpired ? colors.red : ''}` }">
          {{(item.responseTime / 1000 / 60).toFixed(0)}} {{$t('min')}}
          <img
            :style="{ visibility: `${item.isSlaExpired ? 'visible' : 'hidden'}` }"
            :src="require('@/assets/performance/warning.svg')"
            alt="warning icon"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Tooltip from "../../common/components/Tooltip";
import scssVars from "@/styles/vars.scss";
import {mapGetters} from 'vuex';

export default {
  props: {
    tableData: { type: Array, required: true }
  },
  data() {
    return {
      colors: scssVars,
    };
  },
  computed: {
    ...mapGetters(['isRTL'])
  },
  methods: {
    /**@param {{name: String; url: String;}} */
    getIssueIcon({ name, url }, isSuggestion = false, isOffline = false) {
      const key = name
        .toLowerCase()
        .split(" ")
        .join("_");
      let icon;
      try {
        if(url) {
          var untilIdx = url.indexOf('/v')
          const firstHalfUrl = url.substr(0, untilIdx)
          const secondHalfUrl = url.substr(untilIdx, url.length)
          let servikColor = this.$route.name === 'maintenanceLog' ? '389CEB' : 'fe5060'
          if (isSuggestion) servikColor = 'DD7ADD'
          if (isOffline) servikColor = '808080'
          if (name === 'call cleaner') servikColor = 'AE7EA3'
          icon = firstHalfUrl + `/c_scale,co_rgb:${servikColor},e_colorize:100,f_png` + secondHalfUrl
        } else {
          icon = require(`@/assets/servik_icons/${key}.svg`)
        }
      } catch (err) {
        icon = require("@/assets/servik_icons/missing_toilet_paper.svg")
      }
      return icon;
    },
    ISOToHHMM(isoFormat) {
      return new Date(isoFormat).toTimeString().slice(0,5)
    },
    reduceItems(arr) {
      return arr.slice(0, 12);
    },
    _getOrigDateTime(timeStr) {
      if (!timeStr) return
      const date = new Date(timeStr)
      return new Date(date.toISOString().slice(0, -1))
    }
  },
  components: {
    Tooltip
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";
@import "@/styles/mixins.scss";
$header-text-color: #a9aab2;
$body-text-color: #7a7a7a;

.table-title {
  display: flex;
  padding-bottom: 1.4vh;
  padding-top: 1.4vh;
  text-align: start;
  color: #7f7f7f;
  border-bottom: 0px solid #f5f5f5;
  -webkit-padding-start: 25px;
  padding-inline-start: 25px;
}
h3 {
  @include small-titles;
}

.start,
.start-hour,
.solve-hour {
  text-align: end;
  width: 125px;
  margin: auto;
}
.start-hour-time {
  font-weight: bold;
}
.solve-hour-time {
  font-weight: bold;
}

.table-row {
  font-weight: $font-weight-regular;
  border-bottom: 1px solid $grey-border-color;
  color: $body-text-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;

  &.table-header {
    color: $black-blue;
  }
  .start,
  .start-hour {
    margin-inline-start: 0.125vw;
  }

  .solve-sla-container {
    display: flex;
    div {
      align-items: center;
      width: 125px;
      justify-content: flex-end;
    }
  }
  .SLA {
    margin-inline-start: 0.125vw;
    margin-inline-end: 10px;
    display: flex;
    img {
      height: 20px;
      margin-inline-start: 10px;
    }
  }

  .start-icon-container {
    display: flex;
    .start-hour {
      margin-inline-end: 0.125vw;
    }
    .icon-list {
      display: flex;
      gap: 20px;
      margin: auto;
      padding-left: 25px;
      .icon-img {
        height: 30px;
        padding: 2px;
      }
    }
  }
}
</style>