import utilService from '../../common/services/utilService'

export const taskMultiLangsInputFields = [
	{
		label: 'title',
		keyInTask: 'titles'
	}
]

export const taskInputs = [
	{
		label: 'description',
		keyInTask: 'description'
	}
]

export const taskSchedules = [
	{
		name: 'daily',
		options: utilService.getWeekDaysNames()
	},
	{
		name: 'weekly',
		options: utilService.getWeeksRange(1, 5)
	},
	{
		name: 'monthly',
		options: utilService.getNumRange(1, 31)
	}
]

export const taskOtherSchedules = [
	{
		name: 'once',
		options: []
	}
]

export const taskTypes = ['call_to_action', 'schedule', 'sequential']
export const hours = utilService.getDayHours()
export const bufferTimeMins = Array.from({ length: 12 }, (_, i) => (i + 1) * 15)
