<template>
  <div id="survey-app">
    <Loading
      height="100vh"
      :style="{ backgroundColor: 'white' }"
      v-if="loading"
    />
    <template v-else>
      <Setup
        v-if="!survey"
        @restartTimeout="switchSurvey"
        @surveySet="setSurvey"
        :survey="survey"
        :identifier="identifier"
      />
      <Survey
        v-else
        @switchSurvey="switchSurvey"
        :survey="survey"
        :isTestSurvey="isTestSurvey"
        :identifier="identifier"
      />
    </template>
  </div>
</template>

<script>
import Setup from './views/Setup';
import Survey from './views/Survey';
import Loading from '../modules/common/components/Loading';
import installService from './services/installationService';
import { sessionLoad } from '@/modules/common/services/storageService'
import signalService from './services/signalService';

export default {
  data() {
    return {
      survey: null,
      loading: false,
      isTestSurvey: false,
      surveyTimeout: null,

    }
  },
  created() {
    signalService.handleFailedRequests()
    this.setSurvey();
  },
  methods: {
    async setSurvey() {
      this.loading = true;
      try {
        if (this.identifier){
           this.survey = await installService.getSurveyByDeviceCode(this.identifier);
        }
        else if (sessionLoad('surveyId')) {
          this.isTestSurvey = true;
          this.survey = await installService.getSurveyById(sessionLoad('surveyId'));
        }
      } catch (err) { console.log(err) }
      this.loading = false;
    },
    switchSurvey() {
      this.survey = null
      clearTimeout(this.surveyTimeout)
      this.surveyTimeout = setTimeout(this.setSurvey, 1000 * 60)
    }
  },
  computed: {
    identifier() {
      let id = '';
      let urlParams = window.location.href.split('survey-app/?');
        if (urlParams.length == 2 && urlParams[1].toLowerCase().includes('qr')) {
        id = urlParams[1]
      }
     else if (window.Android ) {
        id = window.Android.getUniqueIdentifier();
      }
      return id
    }
  },
  components: { Setup, Survey, Loading }
};
</script>

<style lang="scss">
@import "@/styles/main.scss";
@import "@/styles/mixins";

#survey-app {
  font-family: $font-family-main;
  height: 100vh;
}

.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  max-width: 320px;
}

.md-menu-content-container.md-scrollbar.md-theme-default {
  @include scroll;
}
</style>
