<template>
  <section class="device-activity-chart-container">
    <Select
      v-model="currDeviceType"
      class="company-select"
      :placeholder="$t('device')"
      :disabled="deviceTypes.length === 1"
    >
      <Option
        v-for="(deviceType, idx) in deviceTypes"
        :key="idx"
        :label="deviceType"
        :value="deviceType"
      />
    </Select>
    <span class="chart"></span>
    <h4>1000 {{$t('online')}} | 99 {{$t('offline')}}</h4>
  </section>
</template>

<script>
import Select from "@/modules/common/components/Select";
import Option from "@/modules/common/components/Option";
import {mapGetters} from 'vuex';
export default {
  data() {
    return {
      currDeviceType:''
    }
  },
  created(){
    this.currDeviceType = this.deviceTypes[0]
  },
  computed: {
    ...mapGetters(['devicesStatus']),
    deviceTypes(){
      return this.devicesStatus.reduce((acc, device) => {
        if(!acc.some(type => type.toLowerCase() === device.device_type.toLowerCase())) acc.push(device.device_type[0].toUpperCase() + device.device_type.slice(1));
        return acc
      },[])
    }
  },
  components: {
    Select,
    Option
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
@import "@/styles/vars.scss";

* {
  box-sizing: border-box;
}

.device-activity-chart-container {
  width: 13.6vw;
  height: 31vh;
  padding-bottom: 15px;
  box-shadow: 0 0 1px #bdbdbd;
  background-color: $white;
  @include align-center;
  flex-direction: column;
  justify-content: space-between;
  .header {
    border-bottom: 1px solid #eaeaea;
    width: 100%;
    padding: 15px 30px;
    @include align-center;
    justify-content: space-between;
    img {
      max-width: 0.5vw;
    }
  }
  h4 {
    // text-transform: capitalize;
  }
  .chart {
    width: 9.1vw;
    height: 9.1vw;
    background-color: $red;
    border-radius: 50%;
  }
  .company-select {
    border-bottom: 1px solid $grey-border-color;
    width: 100% !important;
    padding: 7px 30px;
    margin: 0 3px;
    font-size: 16px !important;
  }
}
</style>