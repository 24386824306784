<template>
	<section class="map-view">
		<CleanerModal
			:cleanerProp="cleaner"
			:languages="languages"
			:workingAreas="workingAreas"
			:supervisors="supervisors"
			v-if="isopenCleanerModal"
			@closeModal="closeCleanerModal"
			@save="saveCleaner"
		/>
		<UserModal
			:userProp="user"
			:languages="languages"
			:workingAreas="workingAreas"
			v-if="isOpenUserModal"
			@closeModal="closeUserModal"
			@save="saveUser"
		/>
		<div class="custom-button-container">
			<TableSearch :tableTitles="tableTitles" @input="handleInput" />
			<template v-if="(isAdmin || isSupervisor) && routeName === 'cleaners'">
				<Button @click="openCleanerModal" skin="primary">{{ $t('new_cleaner') }}</Button>
			</template>
			<template v-if="isAdmin && routeName === 'users'">
				<Button @click="openUserModal" skin="primary">{{ $t('new_user') }}</Button>
			</template>
		</div>
		<Tabs :isRTL="isRTL" syncRoute>
			<Tab :label="$t('users')" :to="'/users'" exact>
				<section class="map-details">
					<Loading v-if="isLoading" class="loader" style="height: 75vh" />
					<Table
						v-if="filteredUsers.length"
						:tableData="filteredUsers"
						idField="id"
						:editOptions="{ remove: isAdmin, edit: isAdmin }"
						@editRow="editUser"
						@removeRow="removeUser"
					></Table>
					<div v-else>
						<h3>{{ $t('no_users_found') }}</h3>
					</div>
				</section>
			</Tab>
			<Tab :label="$t('cleaners')" :to="'/users/cleaners'" exact>
				<section class="map-details">
					<Loading v-if="isLoading" class="loader" style="height: 75vh" />
					<Table
						v-if="filteredCleaners.length"
						idField="id"
						:tableData="filteredCleaners"
						:editOptions="{ remove: isSupervisor || isAdmin, edit: isSupervisor || isAdmin }"
						@editRow="editCleaner"
						@removeRow="removeCleaner"
					></Table>
					<div v-else>
						<h3>{{ $t('no_cleaners_found') }}</h3>
					</div>
				</section>
			</Tab>
		</Tabs>
	</section>
</template>

<script>
import Tabs from '@/modules/common/components/Tabs/Tabs'
import Tab from '@/modules/common/components/Tabs/Tab'
import Loading from '@/modules/common/components/Loading'
import Table from '@/modules/common/components/customTable/Table'
import Button from '@/modules/common/components/Button'
import TableSearch from '@/modules/common/components/customTable/TableSearch'
import CleanerModal from '@/modules/users/components/CleanerModal'
import UserModal from '@/modules/users/components/UserModal'
import utilService from '@/modules/common/services/utilService.js'
import workingAreaService from '../../working-area/services/workingAreaService'
import swalService from '@/modules/common/services/swalService'
import { getUsers, getLanguages, getDefaultUser, saveUser, removeUser } from '../../control-panel/services/userService'
import {
	getFilterdContacts,
	removeContactById,
	saveContact,
	getDefaultContact
} from '../../control-panel/services/contactService'
import { mapGetters } from 'vuex'

export default {
	name: 'Users',
	components: { Tabs, Tab, Loading, Table, Button, TableSearch, CleanerModal, UserModal },
	data() {
		return {
			users: [],
			cleaners: [],
			workingAreas: [],
			languages: [],
			tableTitles: [],
			isLoading: false,
			searchTxt: '',
			searchBy: '',
			isOpenUserModal: false,
			isopenCleanerModal: false,
			cleaner: getDefaultContact(),
			user: getDefaultUser()
		}
	},
	methods: {
		async updatePageData() {
			try {
				this.startLoading()
				const { site: siteId } = this.filterSelected
				const [users, languages, workingAreas] = await Promise.all([
					getUsers({ siteId }),
					getLanguages(),
					workingAreaService.getWorkingAreas(this.filterSelected)
				])
				this.users = users.filter((user) => (user.type !== 'admin' && user.level !== 'buzzz') || user.level === 'supervisor') || []
				this.languages = languages
				this.workingAreas = workingAreas
				this.cleaners = await getFilterdContacts({
					siteId,
					userIds: this.users.map((user) => user.id) || []
				})
				this.setTableTitles()
				this.stopLoading()
			} catch (err) {
				this.errorMsg()
			}
		},
		async saveCleaner(cleaner) {
			try {
				await saveContact(cleaner)
				swalService.savedMsg(this.$t('Your work has been saved'))
				this.closeCleanerModal()
				this.updatePageData()
			} catch (err) {
				this.errorMsg()
			}
		},
		async editCleaner({ id }) {
			try {
				const cleanerToEdit = this.findCleanerById(id)
				this.cleaner = cleanerToEdit
				this.openCleanerModal()
			} catch (err) {
				this.errorMsg()
			}
		},
		async removeCleaner(id) {
			try {
				const isRemoveConfirm = await swalService.confirmMsg()
				if (!isRemoveConfirm.value) return
				await removeContactById(id)
				swalService.deletedMsg()
				this.updatePageData()
			} catch (err) {
				this.errorMsg()
			}
		},
		async saveUser(user) {
			try {
				user.sites = [this.filterSelected.site]
				await saveUser(user)
				swalService.savedMsg(this.$t('Your work has been saved'))
				this.closeUserModal()
				this.updatePageData()
			} catch (err) {
				this.errorMsg()
			}
		},
		async editUser({ id }) {
			try {
				if (!this.isAdmin) {
					this.errorMsg(`${this.$t('you_are_not_allowed_to_edit')}`)
					return
				}
				const userToEdit = this.findUserById(id)
				this.user = userToEdit
				this.openUserModal()
			} catch (err) {
				this.errorMsg()
			}
		},
		async removeUser(id) {
			try {
				if (!this.isAdmin) {
					this.errorMsg(`${this.$t('you_are_not_allowed_to_remove')}`)
					return
				}
				const isRemoveConfirm = await swalService.confirmMsg()
				if (!isRemoveConfirm.value) return
				await removeUser(id)
				swalService.deletedMsg()
				this.updatePageData()
			} catch (err) {
				this.errorMsg()
			}
		},
		findCleanerById(id) {
			return this.cleaners.find((cleaner) => cleaner.id === id)
		},
		findUserById(id) {
			return this.users.find((user) => user.id === id)
		},
		setTableTitles() {
			const excludedKeys = ['status', 'isWorkingInSite', 'id']
			const cleanersToShow = this.cleanersToShow
			if (this.routeName === 'cleaners') {
				if (!cleanersToShow.length) return []
				this.tableTitles = Object.keys(cleanersToShow[0]).filter((key) => !excludedKeys.includes(key))
			} else if (this.routeName === 'users') {
				const usersToShow = this.usersToShow
				if (!usersToShow.length) return []
				this.tableTitles = Object.keys(usersToShow[0]).filter((key) => !excludedKeys.includes(key))
			}
		},
		handleInput(value, searchBy) {
			if (searchBy) this.searchBy = searchBy
			this.searchTxt = value
		},
		startLoading() {
			this.isLoading = true
		},
		stopLoading() {
			this.isLoading = false
		},
		openCleanerModal() {
			this.isopenCleanerModal = true
		},
		openUserModal() {
			this.isOpenUserModal = true
		},
		closeCleanerModal() {
			this.isopenCleanerModal = false
			this.cleaner = getDefaultContact()
		},
		closeUserModal() {
			this.isOpenUserModal = false
			this.user = getDefaultUser()
		},
		errorMsg(msg) {
			swalService.errorMsg(msg)
		}
	},
	computed: {
		...mapGetters(['isRTL', 'loggedUser', 'filterSelected']),
		filteredUsers() {
			return utilService.filterArrayOfObjectsWithString(this.usersToShow, this.searchTxt, this.searchBy)
		},
		filteredCleaners() {
			return utilService.filterArrayOfObjectsWithString(this.cleanersToShow, this.searchTxt, this.searchBy)
		},
		supervisors() {
			return this.usersToShow.filter((user) => user.level === 'supervisor')
		},
		cleanersToShow() {
			return this.cleaners.map((cleaner) => {
				const { id, phone, device, isActive, supervisorFirstName, supervisorLastName, isWorkingInSite, workingAreaNames, username } = cleaner
				return {
					id,
					phone,
					supervisor: `${supervisorFirstName} ${supervisorLastName}`,
					device: device ? device : 'N/A',
					isWorkingInSite: isWorkingInSite ? 'yes' : 'no',
					status: isActive ? 'active' : 'inactive',
					workingAreaNames,
					username
				}
			})
		},
		usersToShow() {
			return this.users.map((user) => {
				const { id, email, phone, firstName, lastName, username, type, level } = user
				return {
					id,
					email,
					phone,
					firstName,
					lastName,
					username,
					type,
					level
				}
			})
		},
		isAdmin() {
			return this.loggedUser.type === 'admin'
		},
		isSupervisor() {
			return this.loggedUser.level === 'supervisor'
		},
		routeName() {
			return this.$route.name
		}
	},
	watch: {
		'$store.getters.filterSelected.site': {
			handler() {
				this.updatePageData()
			},
			immediate: true
		},
		'$route.name': {
			handler() {
				this.setTableTitles()
			},
			immediate: true
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.map-view {
	position: relative;
	background-color: white;
	min-height: 86vh;
}
.custom-button-container {
	height: 80px;
	display: flex;
	align-items: center;
	margin-inline-end: 20px;
	text-transform: uppercase;
	position: absolute;
	z-index: 3;
	right: 0;
	@include rtl {
		left: 0;
		right: unset;
	}
	span {
		@include modal-input-label;
		margin-bottom: 0px;
		font-size: 13px;
	}
	input {
		@include modal-input;
		margin-inline-start: 5px;
		margin-bottom: 0px;
		padding: 0 5px;
		width: 150px;
	}
	button {
		font-size: 12px;
		font-weight: bold;
	}
}
</style>
