var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"hour-table-conatiner"},[_c('div',{staticClass:"table-title"},[_c('div',[_vm._v(_vm._s(_vm.$t('data_by_hour')))])]),_c('table',{staticClass:"hour-table"},[_c('thead',[_c('tr',[_c('td',{staticClass:"table-head-cell"},[_c('img',{attrs:{"src":require('@/assets/performance/clock-light.svg'),"alt":""}})]),_vm._l((_vm.dataByHour),function(item){return _c('td',{key:item.time,staticClass:"table-head-cell",class:_vm.currentlyFilteringHour === item.time ? 'highlight' : '',on:{"click":function($event){return _vm.$emit('hourClick', item.time)}}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm._f("numberToHour")(item.time))+"\n\t\t\t\t")])})],2)]),_c('tbody',[(_vm.isShown.visits)?_c('tr',[_c('td',[_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/performance/icons-visits.svg'),"alt":"visits","title":_vm.$t('visits')}})]),_vm._l((_vm.dataByHour),function(item){return _c('td',{key:item.time,style:({ backgroundColor: ("rgba(224, 214, 225, " + (item.visits / _vm.dataByHourEachMax.visits) + ")") })},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.kFormatter(item.visits))+"\n\t\t\t\t\t"),(item.isAmended && _vm.isAmendedPermitted)?_c('span',{staticClass:"amended-room"},[_vm._v("*")]):_vm._e()])})],2):_vm._e(),(_vm.isShown.cleanComplaint)?[_c('tr',[_c('td',[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.inSurveyOverviewPage ? _vm.$t('clicks') : '')+"\n\t\t\t\t\t\t"),(!_vm.inSurveyOverviewPage)?_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/performance/icons-complaint.svg'),"alt":"complaints","title":_vm.$t('C.complaints')}}):_vm._e()]),_vm._l((_vm.dataByHour),function(item){return _c('td',{key:item.time,style:({
							backgroundColor: ("rgba(213, 139, 157, " + (item.complaints / _vm.dataByHourEachMax.complaints) + ")")
						})},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.kFormatter(item.complaints))+"\n\t\t\t\t\t")])})],2)]:_vm._e(),(_vm.isShown.arrivals)?[_c('tr',[_c('td',[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.inSurveyOverviewPage ? _vm.$t('arrivals') : '')+"\n\t\t\t\t\t\t"),(!_vm.inSurveyOverviewPage)?_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/performance/cleaner_arrival-icon.svg'),"alt":"cleaning arrivals","title":_vm.$t('C.arrivals')}}):_vm._e()]),_vm._l((_vm.dataByHour),function(item){return _c('td',{key:item.time,style:({ backgroundColor: ("rgba(163, 194, 155, " + (item.arrivals / _vm.dataByHourEachMax.arrivals) + ")") })},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.kFormatter(item.arrivals))+"\n\t\t\t\t\t")])})],2)]:_vm._e(),(_vm.isShown.maintenanceComplaint)?[_c('tr',[_c('td',[_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/performance/icons-complaint_blue.svg'),"alt":"maintenance complaints","title":_vm.$t('M.complaints')}})]),_vm._l((_vm.dataByHour),function(item){return _c('td',{key:item.time,style:({
							backgroundColor: ("rgba(215, 169, 146, " + (item.maintenanceComplaint /
								_vm.dataByHourEachMax.maintenanceComplaint) + ")")
						})},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.kFormatter(item[_vm.dataKey('maintenanceComplaint')]))+"\n\t\t\t\t\t")])})],2)]:_vm._e(),(_vm.isShown.maintenanceArrivals)?[_c('tr',[_c('td',[_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/performance/maintenance-arrivals_purple.svg'),"alt":"maintenance arrivals","title":_vm.$t('M.Arrivals')}})]),_vm._l((_vm.dataByHour),function(item){return _c('td',{key:item.time,style:({
							backgroundColor: ("rgba(96, 149, 209, " + (item.maintenanceArrivals /
								_vm.dataByHourEachMax.maintenanceArrivals) + ")")
						})},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.kFormatter(item[_vm.dataKey('maintenanceArrivals')]))+"\n\t\t\t\t\t")])})],2)]:_vm._e()],2)]),_c('section',{staticClass:"icon-legend print-mode"},[(_vm.isShown.visits)?_c('div',[_c('img',{attrs:{"src":require('@/assets/performance/icons-visits.svg'),"alt":"visits"}}),_c('span',{staticClass:"icon-label"},[_vm._v(_vm._s(_vm.$t('visits')))])]):_vm._e(),(_vm.isShown.cleanComplaint)?_c('div',[_c('img',{attrs:{"src":require('@/assets/performance/icons-complaint.svg'),"alt":"complaints"}}),_c('span',{staticClass:"icon-label"},[_vm._v(_vm._s(_vm.$t('C.complaints')))])]):_vm._e(),(_vm.isShown.arrivals)?_c('div',[_c('img',{attrs:{"src":require('@/assets/performance/cleaner_arrival-icon.svg'),"alt":"cleaning arrivals"}}),_c('span',{staticClass:"icon-label"},[_vm._v(_vm._s(_vm.$t('C.arrivals')))])]):_vm._e(),(_vm.isShown.maintenanceComplaint)?_c('div',[_c('img',{attrs:{"src":require('@/assets/performance/icons-complaint_blue.svg'),"alt":"maintenance complaints"}}),_c('span',{staticClass:"icon-label"},[_vm._v(_vm._s(_vm.$t('M.complaints')))])]):_vm._e(),(_vm.isShown.maintenanceArrivals)?_c('div',[_c('img',{attrs:{"src":require('@/assets/performance/maintenance-arrivals_purple.svg'),"alt":"maintenance arrivals"}}),_c('span',{staticClass:"icon-label"},[_vm._v(_vm._s(_vm.$t('M.Arrivals')))])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }