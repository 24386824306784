<template>
  <section class="service-log">
    <Loading v-if="isLoading" />
    <template v-else>
      <section class="info-panel" v-if="isShowBarItems">
        <BarItem
          v-for="(item, index) in serviceLogBarItems"
          :isAmended="item.isAmended"
          :key="item.label"
          componentType="info"
          :text="item.text"
          :label="$t(item.label)"
          :iconUrl="item.iconUrl"
          :spaceIconText="true"
          :class="{
            last: index === serviceLogBarItems.length - 1,
            first: index === 0,
          }"
        />
      </section>
      <template v-if="currOpenTab.isRoomAnalyticsLog">
        <AnalyticsChartTable
          @chartClick="handleChartClick"
          v-if="data"
          :data="chartData"
          class="chart-table"
        />
        <TableByHour
          v-if="data.hourData"
          :dataByHour="dataForHourTable"
          class="table-by-hour"
        />
      </template>
      <template v-if="currOpenTab.isCleanLog || currOpenTab.isMaintenanceLog">
        <NoData v-if="tableData.length === 0" />
        <IssueTimesTable v-else :tableData="tableData" />
      </template>
      <CleanerArrival v-if="currOpenTab.isCleanerArrival && cleanerArrivals" :cleanerArrivals="cleanerArrivals" />
      <TimelineExposures v-if="currOpenTab.isRoomExposures && roomExposures" :roomExposures="roomExposures" />
    </template>
  </section>
</template>

<script>
import AnalyticsChartTable from "../components/AnalyticsChartTable";
import CleanerArrival from './CleanerArrival';
import TimelineExposures from "./TimelineExposures";
import TableByHour from './TableByHour';
import BarItem from "../components/BarItem";
import IssueTimesTable from './IssueTimesTable';
import Loading from "@/modules/common/components/Loading";
import NoData from "@/modules/common/components/NoData";
import { infoBarItems } from "../consts/consts";
import { getRoomDataByHour, getOverviewSums, getAnalyticsSums, getRoomsCounterCallsSum, getRoomsCleandexCallsSum, getRoomsManualCallsSum } from "../services/performanceService";
import utilService from "@/modules/common/services/utilService";
import { mapGetters } from 'vuex';

export default {
  name: 'ServiceLog',
  props: {
    cleanerArrivals: { type: Array, required: false },
    roomExposures: { type: Array, required: false }
  },
  data() {
    return {
      data: null,
      isLoading: false,
      serviceLogBarItems: [],
      tableData: []
    };
  },
  created() {
    this.updatePageData();
  },
  mounted() {
    this.serviceLogBarItems = this.serviceLogBarItemsByRoute;
  },
  methods: {
    async handleChartClick(servikFilter) {
      this.$store.commit({ type: 'updateFilter', field: 'servikName', value: servikFilter.label });
      this.$store.commit({ type: 'updateFilter', field: 'rooms', value: [this.roomId] });
      this.isLoading = true
      const prms = []
      prms.push(this.$store.dispatch({ type: 'getAnalyticsData', filter: this.filterSelected }))
      prms.push(this.$store.dispatch({ type: 'getOverviewData', filter: this.filterSelected }))
      await Promise.all(prms)
      await this.updatePageData()
      this.isLoading = false
    },
    async updatePageData() {
      this.isLoading = true;
      this.data = this.$store.getters.analyticsData;
      const { currOpenTab } = this
      const currRoomId = this.roomId
      const logType = (currOpenTab.isCleanLog) ? 'clean' : (currOpenTab.isMaintenanceLog) ? 'maintenance' : '';
      let tableData = await getRoomDataByHour(currRoomId, { ...this.filterSelected, 'type': logType });
      tableData = tableData.sort((a, b) => new Date(b.openTime) - new Date(a.openTime));
      this.tableData = tableData;
      this.isLoading = false;
      this.updateBarItems();
      if (!this.data) this.$router.push({ name: "analytics" });
    },
    updateBarItems() {
      if (!this.data) return
      const { data } = this
      const overviewSums = getOverviewSums(this.overviewData);
      const analyticsSums = getAnalyticsSums(data.hourData);
      analyticsSums.counterCalls = getRoomsCounterCallsSum(data.roomsData)
      analyticsSums.cleandexCalls = getRoomsCleandexCallsSum(data.roomsData)
      analyticsSums.manualCalls = getRoomsManualCallsSum(data.roomsData)

      this.serviceLogBarItems = this.serviceLogBarItems.reduce((acc, item) => {
        const { name } = item;
        const { kFormatter } = utilService

        if (analyticsSums[name] || overviewSums[name]) {
          const itemsIncludesTime = ['response_time_SLA']
          const isTypeTime = itemsIncludesTime.includes(name)
          if (name === "visits") item.isAmended = analyticsSums.isAmended || overviewSums.isAmended
          if (name === 'avg_score' || name === 'response_time_SLA') {
            item.text = kFormatter(overviewSums[name])
          } else if (name === 'complaints') {
            item.text = kFormatter(analyticsSums[name])
          } else if (name === 'exposures') {
            item.text = this.addPercentageSign(overviewSums[name])
          } else {
            item.text = kFormatter(analyticsSums[name] || overviewSums[name])
          }
          if (isTypeTime) {
            item.text = this.addMinutesText(item.text)
          }
          acc.push(item);
        }
        return acc;
      }, []);
    },
    getTotalCleaningComplaints() {
      const { charts: { cleaning : { data: cleaningComplaints = [] } } } = this.data
      const totalCleaningComplaints = cleaningComplaints.reduce((acc, item) => acc + item, 0)
      return totalCleaningComplaints;
    },
    addPercentageSign(value) {
      return value + '%';
    },
    addMinutesText(value) {
      return `${value} ${this.$t("min")}`;
    }
  },
  watch: {
    '$route.name': function () {
      const { currOpenTab } = this
      if (currOpenTab.isCleanLog || currOpenTab.isMaintenanceLog) this.updatePageData();
      this.serviceLogBarItems = this.serviceLogBarItemsByRoute;
      this.updateBarItems();
    }
  },
  computed: {
    ...mapGetters(['filterSelected', 'overviewData']),
    serviceLogBarItemsByRoute() {
      const { currOpenTab } = this
      if (!currOpenTab.isCleanLog && !currOpenTab.isMaintenanceLog) return infoBarItems
      return []
    },
    dataForHourTable() {
      const { data: { hourData }, currOpenTab } = this
      const isServikSelected = this.$store.getters.filterSelected.servikName
      if (isServikSelected || currOpenTab.isRoomAnalyticsLog) return hourData
      return []
    },
    chartData() {
      const { data: { charts: { cleaning, totalLikes, maintenance } }, currOpenTab } = this
      if (currOpenTab.isRoomAnalyticsLog) {
        return { cleaning, totalLikes, maintenance }
      }
      return {}
    },
    roomId() {
      return this.$route.params.roomId;
    },
    isShowBarItems() {
      const { routeName } = this
      const notShowBarItemsIn = ['cleanLog', 'maintenanceLog']
      return !notShowBarItemsIn.includes(routeName)
    },
    currOpenTab() {
      const { routeName } = this
      const routeNameCapitalized = routeName.charAt(0).toUpperCase() + routeName.slice(1)
      const key = `is${routeNameCapitalized}`
      return { [key]: true }
    },
    routeName() {
      return this.$route.name;
    }
  },
  components: {
    BarItem,
    AnalyticsChartTable,
    Loading,
    IssueTimesTable,
    TableByHour,
    CleanerArrival,
    TimelineExposures,
    NoData
  }
};
</script>

<style lang="scss" scoped>
.info-panel {
  display: flex;
  margin-bottom: 30px;
}

.chart-table {
  margin-bottom: 80px;
}

.table-by-hour {
  margin-bottom: 35px;
}
</style>