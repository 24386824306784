<template>
  <!-- <div> -->
    <!-- <div v-if="isShowArrivalInfo && arrivalInfo" class="arrival-info-container">
      <div class="arrival-info">
        <span>{{arrivalInfo.arrivalTime ? new Date(arrivalInfo.arrivalTime).toTimeString().slice(0, 5) : ''}}</span>
        <span v-show="survey['preview_arrival'].isArrivalAndStatus" class="arrival-status" :class="arrivalInfo.status ? 'dirty' : 'clean' " >{{arrivalInfo.cleanerArrivalCount}}</span>
      </div>
    </div> -->
    <section class="survey-container" :class="isEditClass">
      <div class="icons">
        <span @click="franchiseClick" class="img-container">
          <img :src="currLeftLogoImg" alt />
          <label for="left-logo-upload">
            <img
            class="control upload"
            :src="require('@/assets/upload_icon.svg')"
          />
          <input
            v-if="isEdit"
            type="file"
            id="left-logo-upload"
            @change="showLeftLogoImage"
          />
          </label>
        </span>
        <span @click="goToLogoRoute" class="img-container">
          <img :src="currCompanyLogo" alt />
          <label for="logo-upload">
            <img
              class="control upload"
              :src="require('@/assets/upload_icon.svg')"
            />
            <input
              v-if="isEdit"
              type="file"
              id="logo-upload"
              @change="showAddedImage"
            />
          </label>
          <img
            @click="editLogoRoute(true)"
            class="control settings"
            :src="require('@/assets/admin_icon.svg')"
          />
          <div
            v-click-outside="editLogoRoute"
            v-if="isEditingLogoRoute"
            class="field"
          >
            <h1>{{ $t("go_to_stage") }}</h1>
            <Select v-model="survey.logo_route" class="select-container">
              <Option
                v-for="page in ['', ...pages]"
                :value="page"
                :key="page"
                :label="!page ? 'None' : ++page"
              />
            </Select>
          </div>
        </span>
      </div>
      <SurveyPage
        :currPage="survey.pages[activePage]"
        :previewImgInfoProp="pagesPreviewImagesInfo[activePage]"
        :isEdit="isEdit"
        :pages="pages"
        :attachStageNoToSignal="attachStageNoToSignal"
        :activePageNum="activePage"
        @changePage="handlePageChange"
        @submitContent="handleContentSubmit"
        @saveRating="saveRating"
        @click="reportIssue"
        @updatePage="handlePageUpdate"
        @updatePagePreviewImage="updatePagePreviewImage"
        @deletePagePreviewImage="deletePagePreviewImage"
        @deletePageImage="deletePageImage"
        @changePageImgSize="changePageImgSize"
        @handlePageBackgroundImage="handlePageBackgroundImage"
      />
    </section>
  <!-- </div> -->
</template>

<script>
import SurveyPage from "../components/SurveyPage";
import Select from '@/modules/common/components/Select'
import Option from '@/modules/common/components/Option'
import signalService from "../services/signalService";
import surveyService from "@/modules/surveys/services/surveyService";
// import realTimeService from "../../modules/real-time/services/realTimeService";

import { getCompanyById } from '../../modules/control-panel/services/companyService';

export default {
  props: {
    survey: { type: Object, required: true },
    isEdit: { type: Boolean, default: false },
    currPageNum: { type: Number, default: 0 },
    identifier: { type: String, required: false },
    isTestSurvey: { type: Boolean, required: true },
    pagesPreviewImagesInfo: { type: Object, required: false },
  },
  data() {
    return {
      activePage: this.currPageNum,
      delayTimeOut: null,
      currentRoomId: null,
      companyId: null,
      isEditingLogoRoute: false,
      franchiseClickCount: 0,
      franchiseClickTimeout: null,
      company: null,
      imgUrl: null,
      leftLogoImgUrl: null,
      attachStageNoToSignal:Boolean(this.survey.attach_stage_number),
      // isShowArrivalInfo:false,
      // arrivalInfo:null,
    };
  },
  async created() {
    this.companyId = this.survey.company_id || this.$store.getters.filterSelected.company;
    this.company = await getCompanyById(this.companyId);
    // const {isLastTimeOfArrival} = this.survey['preview_arrival']
    // if(isLastTimeOfArrival) this.isShowArrivalInfo = true
    // unComment if going to use this app in production
    // this.getArrivalInfo()
  },
  destroyed() {
    if (this.franchiseClickTimeout) clearTimeout(this.franchiseClickTimeout)
  },
  watch: {
    pageDiff(to, from) {
      const pageCount = Object.values(this.survey.pages).length;
      const nextPage = this.activePage + this.pageDiff;
      if (nextPage > pageCount || (this.activePage === 1 && this.pageDiff < 0)) { return }
      this.activePage += this.pageDiff;
      this.$emit("setPage", this.activePage);
    },
    currPageNum(to) {
      this.activePage = to
    },
    'survey.logo_route'(val) {
      this.$emit('setLogoRoute', +val)
    }
  },
  computed: {
    isEditClass() {
      return { edit: this.isEdit };
    },
    pages() {
      return Object.keys(this.survey.pages);
    },
    currCompanyLogo() {
      let icon;
      try {
        if (this.imgUrl) icon = this.imgUrl;
        else if (this.survey.logo_url) icon = this.survey.logo_url;
        else if (this.company.logo) icon = this.company.logo;
      } catch (e) {
        icon = require(`@/assets/buzzztv_logo.svg`);
      }
      return icon;
    },
    currLeftLogoImg() {
      let icon
      try {
        if (this.leftLogoImgUrl) icon = this.leftLogoImgUrl
        else if (this.survey.left_logo_url) icon = this.survey.left_logo_url
        else icon = require(`@/assets/buzzztv_logo.svg`)
      } catch (e) {
        icon = require(`@/assets/buzzztv_logo.svg`);
      }
      return icon
    },
  },
  methods: {
    showAddedImage(ev) {
      const file = ev.target.files[0];
      this.imgUrl = window.URL.createObjectURL(file);
      this.$emit('logoChange', file);
    },
    showLeftLogoImage(ev) {
      const file = ev.target.files[0]
      this.leftLogoImgUrl = window.URL.createObjectURL(file)
      this.$emit('leftLogoChange', file)
    },
    handlePageChange(pageNumber) {

      pageNumber = parseInt(pageNumber)

      if (!!this.survey.pages[pageNumber].delay_time) {
        clearTimeout(this.delayTimeOut);
        this.delayTimeOut = setTimeout(() => {
         if (this.survey.pages[pageNumber].redirect_to)return window.location = this.survey.pages[pageNumber].redirect_to;
          this.activePage = 0;
          this.currentRoomId = null;
        }, this.survey.pages[pageNumber].delay_time * 1000);

        this.activePage = pageNumber;
      }
      else  this.activePage = pageNumber;
    },
    handlePageUpdate(page) {
      this.$emit("updatePage", page);
    },
    reportIssue({ servikId, connectedRoomId, isLinkServik,stageNo }) {
      // if(servikId){
      //   if(servikId === 48) {
      //     this.arrivalInfo.status = false
      //     this.arrivalInfo.cleanerArrivalCount += 1
      //     this.arrivalInfo.arrivalTime = new Date()
      //   }else{
      //       this.arrivalInfo.status = true
      //   }
      // }
      if (connectedRoomId) this.currentRoomId = connectedRoomId
      if (this.isTestSurvey) return;
      if (isLinkServik) return;
      const isTablet = (this.survey.device_type && this.survey.device_type.toLowerCase() === 'tablet') || false;
      signalService.sendSignal(servikId, this.identifier, isTablet, this.currentRoomId, this.survey.device_type,stageNo);

    },
    editLogoRoute(val) {
      if (typeof val !== 'boolean') {
        this.isEditingLogoRoute = false
        return
      }
      setTimeout(() => this.isEditingLogoRoute = !this.isEditingLogoRoute, 0)
    },
    goToLogoRoute() {
      if (this.isEdit || this.survey.logo_route === null) return;
      const dest = this.activePage === this.survey.logo_route ? 0 : this.survey.logo_route
      this.handlePageChange(dest)
    },
    franchiseClick() {
      if (this.isTestSurvey) return
      if (this.franchiseClickCount === 2) {
        this.franchiseClickCount = 0
        clearTimeout(this.franchiseClickTimeout)
        this.$emit('switchSurvey')
        return
      } else if (!this.franchiseClickCount) {
        if (this.franchiseClickTimeout) clearTimeout(this.franchiseClickTimeout)
        this.franchiseClickTimeout = setTimeout(() => {
          this.franchiseClickCount = 0
        }, 3000)
      }
      this.franchiseClickCount++;
    },
    handleContentSubmit(content, smsList, emailList) {
      content.forEach((responseInfo) => {
        responseInfo.device_code = this.identifier
        responseInfo.smsList = smsList
        responseInfo.emailList = emailList
        // surveyService.saveSurveyResponse(responseInfo)
      })
      surveyService.saveSurveyResponseContent(content)
    },
    saveRating(rating) {
      rating.device_code = this.identifier
      surveyService.saveSurveyResponse(rating)
    },
    updatePagePreviewImage(previewImageInfo) {
      this.$emit("updatePagesPreviewImagesInfo", previewImageInfo)
    },
    deletePagePreviewImage(pageNumber) {
      this.$emit("deletePagesPreviewImagesInfo", pageNumber)
    },
    deletePageImage(pageNumber) {
      this.$emit("deletePageImage", pageNumber)
    },
    changePageImgSize({ width, height }) {
      this.$emit("changePageImgSize", { width, height })
    },
    handlePageBackgroundImage(pageNumber) {
      this.$emit("handlePageBackgroundImage", pageNumber)
    }
    // async getArrivalInfo(){
    //   try {
    //     const info =  await realTimeService.getRoomArrivalPreview(this.identifier)
    //     this.arrivalInfo = info
    //   } catch (err) {
    //     console.log('Getting arrival preview info failed', err);
    //   }
    // },
  },
  components: { SurveyPage, Option, Select }
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars";
@import "@/styles/mixins";

.survey-container {
  position: relative;
}

.edit {
  .icons {
    padding-top: 40px;
    .img-container {
      width: 12.5vw;
      padding: 20px 15px;
      @include border($grey-border-color);
      border-radius: 16px;
      position: relative;
      input[type="file"] {
        display: none;
      }
      img {
        &:first-child {
          width: unset;
        }
      }
      .control {
        display: block;
      }
    }
  }
}

.field {
  padding: 10px;
  @include border($grey-border-color);
  @include flex-center;
  justify-content: space-between;
  position: absolute;
  bottom: -5.1vh;
  border-radius: 5px;
  width: 12.5vw;
  height: 5.1vh;
  .select-container {
    @include border($grey-border-color);
    background-color: $white;
    height: 46px;
    max-width: 3.3vw !important;
    border-radius: 4px;
    margin: 0;
    padding: 0 5px;
  }
  .input-container {
    background-color: $white;
    @include border($grey-border-color);
    width: 4.2vw;
    height: 3.3vh;
    border-radius: 4px;
  }
}

.icons {
  position: absolute;
  width: 100%;
  height: 15vh;
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  .img-container {
    @include flex-center;
    width: 14.6vw;
    height: 11.2vh;
    position: relative;
    img {
      width: 8.5vw;
      &:first-child {
        width: 100%;
      }
    }
    .control {
      width: 1vw;
      height: 1vw;
      position: absolute;
      display: none;
      &:hover {
        cursor: pointer;
      }
    }
    .settings {
      bottom: 13.5px;
      left: 12px;
    }
    .upload {
      bottom: 13.5px;
      right: 12px;
    }
  }
}

// .arrival-info-container{
//   @include flex-center;
//   .arrival-info{
//     @include flex-center;
//     height: 50px;
//     width: 120px;
//     gap:5px;
//     padding:20px;
//     background-color: rgb(235, 223, 223);
//     .arrival-status {
//       @include flex-center;
//       color:#fff;
//       font-size: 1.3rem;
//       font-weight: bold;
//       border-radius: 50%;
//       width: 20px;
//       height: 20px;
//       padding: 10px;
//       &.clean{
//         border: 3px solid #5f9b46;
//         background-color: #5f9b46;
//       }
//       &.dirty{
//         border: 3px solid #fe5060;
//         background-color: #fe5060;
//       }
//     }
//   }
// }

</style>