<template>
  <div class="shift-info-container">
    <div class="shift-info">
      <span>{{currShift.shift_name}}</span>
      <span class="seperator"></span>
      <span>{{now.getDate() + ' ' + $t(`monthMap[${now.getMonth()+1}]`) + ' ' + now.getFullYear()}}</span>
      <span class="seperator"></span>
      <span>{{clock}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * @typedef {import('../../control-panel/services/shiftService').Shift} Shift
     * @type {{new():Shift}
     */
    currShift: { type: Object, required: true }
  },
  data() {
    return {
      clock: null
    }
  },
  created() {
    this.clock = this.currTime();
    this.clockInterval = setInterval(() => {
      this.clock = this.currTime();
    }, 1000 * 30);
  },
  methods: {
    currTime() {
      const hour = new String(new Date().getHours());
      const minutes = new String(new Date().getMinutes());
      return `${hour.length === 1 ? 0 + hour : hour}:${minutes.length === 1 ? 0 + minutes : minutes}`;
    },
  },
  computed: {
    now() {
      return new Date()
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/vars";

.shift-info-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  height: 30px;
  margin-inline-end: 30px;
  .shift-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: $font-weight-bold;
    color: #707070;
    .seperator {
      margin: 0 20px;
      height: 100%;
      border-inline-start: 1px solid #ececec;
    }
  }
}
</style>