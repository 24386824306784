<template>
  <section
    :class="['real-time-table-cell', { arrival: columnName === 'arrival' }]"
    :style="{ paddingInlineStart: isSingleIconCell ? '15px' : '' }"
  >
    <template v-if="isStringCell">{{ $t(strToShow) }}</template>
    <template v-if="columnName === 'date'">{{ ddmmyyhhmm(slaData.date) }}</template>
    <template v-else-if="columnName === 'arrival' && slaData.arrival && !isMaintenance">
      {{ new Date(slaData.arrival.time).toTimeString().slice(0, 5) }}
      <div class="arrival-count">{{ slaData.arrival.count }}</div>
    </template>
    <template v-else-if="columnName === 'last_arrival'">
      {{
        new Date(slaData.last_arrival).toLocaleString('en-gb', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })
      }}
    </template>
    <template v-else-if="isSingleIconCell">
      <Tooltip
        v-if="columnName === 'cleandex'"
        :text="
          `Visits: Past - ${slaData.debug.pastVisits} Future - ${slaData.debug.futureVisits}
          Complaints Rate: ${slaData.debug.complaintsVisitsRate.toFixed(5)}
          Weighted Cleandex: ${slaData.cleandex} ${slaData.debug.complaints ? `
          Open Complaints Count: ${slaData.debug.complaints}` : ``}`
        "
        style="display:flex"
      >
        <img :src="cleandexIcon(slaData.cleandex)" />
      </Tooltip>
      <!-- <img v-if="columnName === 'cleandex'" :src="cleandexIcon(slaData.cleandex)" /> -->
      <img v-else :src="iconToShow" />
    </template>
    <div v-else-if="columnName === 'title'">
      <div class="title-container">
        <div v-if="slaData.title && slaData.categoryColor">
          <div :title="slaData.category" :style="getSideLabelStyle(slaData.categoryColor)">
            <div class="title">{{ slaData.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="call-cell-container" v-else-if="columnName === 'call'">
      <div v-for="(complaint, index) in slaData.complaints" :key="index" class="call-icon-container">
        <Tooltip :boldText="complaint.name" :text="ISOToHHMM(complaint.time)" style="display:flex">
          <span :style="getComplaintCountStyle(complaint)" v-if="complaint.count > 1">{{ complaint.count }}</span>
          <img :style="getComplaintStyle(complaint)" :src="getServikIcon(complaint)" />
        </Tooltip>
      </div>
    </div>
  </section>
</template>

<script>
import Tooltip from '../../common/components/Tooltip'
import realTimeService from '../services/realTimeService'
import { ddmmyyhhmm } from '../../../filters'

/**@typedef {{
 * name: String;
 * iconUrl: String;
 * time: String | Number;
 * category: String;
 * count: Number;
 * isSuggestion: boolean;
 * }} Complaint*/

export default {
  props: {
    columnName: {
      type: String,
      required: true,
      validator: (prop) =>
        [
          'name',
          'building',
          'zone',
          'floor',
          'gender',
          'arrival',
          'time_start',
          'sla_status',
          'duration',
          'call',
          'date',
          'title',
          'cleandex',
          'last_arrival',
        ].includes(prop),
    },
    /** @type {new() {
     * name: String;
     * building: String;
     * zone: String;
     * floor: String;
     * gender: String;
     * isOpen: Boolean;
     * openTime: String | Number;
     * complaints: Complaint[]
     * }} */
    slaData: { type: Object, required: true },
    //max sla in minutes of current shift to calculate countdown(duration)
    maxSla: { type: Number, required: false },
    isMaintenance: { type: Boolean, default: false, required: false },
  },
  components: {
    Tooltip,
  },
  data() {
    return {
      durationStr: '',
      durationInterval: '',
    }
  },
  destroyed() {
    window.clearInterval(this.durationInterval)
  },
  methods: {
    getServikIcon({ name, category, id, iconUrl }) {
      let icon
      try {
        if (iconUrl) icon = iconUrl
        else icon = require(`@/assets/survey/servik_icons_white/${category}/${id}.${name}.svg`)
      } catch (err) {
        icon = null
      }
      return icon
    },
    getComplaintCountStyle() {
      return {
        borderColor: this.isMaintenance ? '#389CEB' : '#fe5060',
        color: this.isMaintenance ? '#389CEB' : '#fe5060',
      }
    },
    getComplaintStyle({ id: servikId, isSuggestion, isOffline, backgroundColor }) {
      if (servikId === 216 || servikId === 1008) return { backgroundColor: '#AE7EA3', borderColor: '#AE7EA3' }
      else if (isSuggestion) return { backgroundColor: '#DD7ADD', borderColor: '#DD7ADD' }
      else if (isOffline) return { backgroundColor: '#808080', borderColor: '#808080' }
      else if (backgroundColor) {
        return {
          backgroundColor,
          borderColor: backgroundColor
        }
      } else {
        return {
          backgroundColor: this.isMaintenance ? '#389CEB' : '#fe5060',
          borderColor: this.isMaintenance ? '#389CEB' : '#fe5060',
        }
      }
    },
    ISOToHHMM(isoFormat) {
      return new Date(isoFormat).toTimeString().slice(0, 5)
    },
    ddmmyyhhmm(date) {
      return ddmmyyhhmm(date)
    },
    msTohhmmss(ms) {
      const hours = Math.floor(ms / 3600000)
      const minutes = Math.floor((ms % 3600000) / 60000)
      const seconds = Math.floor(((ms % 3600000) % 60000) / 1000)
      const hoursStr = hours < 10 ? '0' + hours : hours
      const minutesStr = minutes < 10 ? '0' + minutes : minutes
      const secondsStr = seconds < 10 ? '0' + seconds : seconds

      return `${hoursStr}:${minutesStr}:${secondsStr}`
    },
    getSideLabelStyle(color) {
      return `border-left: 7px solid ${color}; float:left; width: initial; padding-left: 5px;`
    },
  },
  computed: {
    strToShow() {
      if (this.columnName === 'duration') {
        if (this.slaData.responseTime) {
          return this.msTohhmmss(this.slaData.responseTime)
        }
        if (this.slaData.isOpen) {
          const info = realTimeService.getDurationInfo(this.slaData.openTime, this.maxSla)
          this.durationStr = info.durationStr
          window.clearInterval(this.durationInterval)
          this.durationInterval = setInterval(() => {
            const info = realTimeService.getDurationInfo(this.slaData.openTime, this.maxSla)
            this.durationStr = info.durationStr
          }, 1000)
          return this.durationStr
        }
      } else if (this.columnName === 'time_start' && this.slaData.openTime && this.slaData.isOpen)
        return ddmmyyhhmm(this.slaData.openTime)
      return this.slaData[this.columnName]
    },
    isStringCell() {
      return ['name', 'building', 'zone', 'floor', 'time_start', 'duration'].includes(this.columnName)
    },
    isSingleIconCell() {
      return ['gender', 'sla_status', 'cleandex'].includes(this.columnName)
    },
    cleandexIcon() {
      return (cleandex) => {
        let icon
        if (cleandex > 85) icon = require('@/assets/performance/warning.svg')
        else if (cleandex > 50) icon = require('@/assets/real_time_icons/warning_orange.svg')
        else icon = require('@/assets/real_time_icons/OK.svg')
        return icon
      }
    },
    iconToShow() {
      let icon
      let genderIconName
      const currIcon = this.genderIconName
      if (currIcon === 'shared toilet' || currIcon === 'men/women') genderIconName = 'men-women'
      else if (currIcon === 'meeting room' || currIcon === 'meetingroom') genderIconName = 'meeting_room'
      else if (currIcon === 'clean room' || currIcon === 'cleanroom') genderIconName = 'clean_room'
      else if (currIcon === 'men toilet') genderIconName = 'men'
      else if (currIcon === 'women toilet') genderIconName = 'women'
      else if (currIcon === 'handicapped toilet') genderIconName = 'handicap'
      else genderIconName = currIcon
      try {
        const { sensorState, sensorType, openTime, isOpen, date } = this.slaData
        const isPad = sensorType === 'pad9' || sensorType === 'pad9+'
        if (this.columnName === 'gender') icon = require(`@/assets/gender_icons/${genderIconName}.svg`)
        else if (this.columnName === 'sla_status') {
          let minPassed
          const currTime = new Date().getTime()
          if (date) {
            if (new Date(date).getTime() > new Date().getTime()) return null
            if (openTime) {
              minPassed = Math.floor((currTime - new Date(openTime).getTime()) / (1000 * 60))
            }
          }
          if (!isOpen) icon = require('@/assets/real_time_icons/OK.svg')
          else if (this.maxSla && minPassed > this.maxSla) icon = require('@/assets/performance/warning.svg')
          else icon = require('@/assets/real_time_icons/warning_orange.svg')
          if (isPad) {
            if (!sensorState || sensorState === 'offline') icon = require('@/assets/offline.svg')
          }
        }
      } catch (err) {
        icon = null
      }
      return icon
    },
    genderIconName() {
      if (this.slaData.gender === 'shared toilet') return 'men-women'
      else return this.slaData.gender
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars';
@import '@/styles/mixins';

$real-time-red: #fe5060;

.arrival {
  display: flex;
  align-items: center;
  justify-content: space-around;

  .arrival-count {
    border: 1px solid #5f9b46;
    border-radius: 50%;
    @include flex-center;
    width: 20px;
    padding: 10px;
    height: 20px;
    @media (width: 1280px) { 
        height: 45px;
        width: 45px;
    }
  }
}

.call-cell-container {
  display: flex;

  .call-icon-container {
    display: flex;
    span {
      align-self: flex-end;
      position: relative;
      right: -8px;
      @include rtl {
        right: unset;
        left: -8px;
      }
      bottom: -5px;
      border: 1px solid $real-time-red;
      color: $red;
      font-weight: $font-weight-bold;
      border-radius: 50%;
      height: 15px;
      width: 15px;
      @include flex-center;
      font-size: 9px;
      background-color: $white;
    }

    img {
      height: 25px;
      width: 25px;
      @media (width: 1280px) { 
        height: 45px;
        width: 45px;
      }
      border-radius: 25%;
      background-color: $real-time-red;
      border: 1px solid $real-time-red;
      padding: 3px;
      margin-inline-end: 5px;
    }
  }
}
</style>
