<template>
  <section class="api-modal-container">
    <Modal
      v-if="selectedSurvey"
      @submit="saveApi"
      @close="closeApiModal"
      :texts="modalTexts"
      width="medium"
    >
      <template v-slot:default>
        <div class="modal-container">
          <h4>{{ $t("please_edit_the_api_information") }}</h4>
          <div class="fields-container">
            <h4>{{ $t("method") }}</h4>
            <div class="select1">
              <Select v-model="apiSettings.method" class="filter-select">
                <Option
                  v-for="method in methods"
                  :key="method"
                  :label="method"
                  :value="method"
                />
              </Select>
            </div>
            <h4>{{ $t("api_url") }}</h4>
            <input v-model.trim="apiSettings.url" placeholder="https://" />
            <h4>{{ $t("authorization") }}</h4>
            <Select v-model="apiSettings.auth" class="filter-select">
              <Option
                v-for="auth in auths"
                :key="auth"
                :label="auth"
                :value="auth"
              />
            </Select>
            <h4>{{ $t("token") }}</h4>
            <input
              v-model.trim="apiSettings.token"
              placeholder="Enter Token Here..."
            />
            <h4>{{ $t("token name ") }}</h4>
            <input
              v-model="apiSettings.tokenDynamicName"
              placeholder="Token Name In Response Token Object..."
            />
            <h4>{{ $t("post header options") }}</h4>
            <input
              v-model="apiSettings.headerOptions.key"
              placeholder="Enter Key Here..."
            />
            <h4>{{ $t("value") }}</h4>
            <input
              v-model="apiSettings.headerOptions.value"
              placeholder="Enter Value Here..."
            />
          </div>

          <div class="api-info">
            <h4>{{ $t("api_request_info") }}</h4>
            <span v-for="(val, infoName, idx) in apiSettings.info" :key="idx">
              <h4>{{ $t(infoName) }}</h4>
              <CheckBox v-model="apiSettings.info[infoName]" />
            </span>
          </div>
            <div class="api-info">
            <h4>{{ $t("additional_info") }}</h4>
            <span v-for="(val, infoName, idx) in apiSettings.additionalInfo" :key="idx">
              <h4>{{ $t(infoName) }}</h4>
              <CheckBox v-model="apiSettings.additionalInfo[infoName]" />
            </span>
          </div>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import Modal from "@/modules/common/components/Modal";
import Select from "@/modules/common/components/Select";
import Option from "@/modules/common/components/Option";
import CheckBox from "../components/CheckBox";

export default {
  props: {
    selectedSurvey: { type: Object, default: null }
  },
  data() {
    return {
      methods: ["POST"],
      auths: ["API KEY", "DYNAMIC KEY"],
      // isEditingApi: this.selectedSurvey !== null,
      survey: this.selectedSurvey,
      apiSettings: {
        method: "",
        url: "",
        headerOptions: {
          key: "",
          value: ""
        },
        auth: "",
        token: "",
        tokenDynamicName: "",
        info: {
          siteName: false,
          roomName: false,
          servikName: false,
          time: false
        },
        additionalInfo:{
          gender:false,
          floor:false,
          zone:false,
          servikCategory:false
        }
      },
      modalTexts: {
        headerPrimary: "api_configuration"
        // footerCancel: "LEAVE",
        // footerConfirm: "SAVE"
      }
    };
  },
  created() {

    if (this.selectedSurvey.api) {
      this.apiSettings = this.selectedSurvey.api;
      if (!this.apiSettings.headerOptions) {
        this.apiSettings.headerOptions = {
          key: "",
          value: ""
        }
      } else if (!this.apiSettings.tokenDynamicName) this.apiSettings.tokenDynamicName = "";
    }
  },
  methods: {
    infoNameSpaced(txt) {
      const spaced = txt
        .replace(/([A-Z])([A-Z])/g, "$1 $2")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/[\s_]+/g, " ");
      return spaced;
    },
    saveApi() {
      this.$emit("close", this.apiSettings);
    },
    closeApiModal() {
      this.isEditingApi = false;
      this.$emit("close");
    }
  },
  components: {
    Modal,
    Select,
    Option,
    CheckBox
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";
@import "@/styles/mixins.scss";

.modal-container {
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }

  .fields-container {
    max-width: 42vw;
    display: grid;
    grid-template: repeat(4, 4.2vh) / 6.25vw 7.6vw 3.5vw 20vw;
    grid-template-areas:
      "txt1 select1 txt2 input1 "
      "txt3 select2 txt4 input2 "
      "txt5 input3 input3 input3 "
      "txt6 select3 input4 input4 ";
    grid-gap: 2.5vh;
    h4 {
      // line end?
      text-align: right;
      @include align-center;
      justify-content: flex-end;
      // &:nth-child(3) {
      //   grid-area: txt2;
      // }
      // &:nth-child(5) {
      //   grid-area: txt3;
      // }
      // &:nth-child(7) {
      //   grid-area: txt4;
      // }
    }
    input {
      background-color: $white;
      @include border($grey-border-color);
      border-radius: 4px;
      width: 100%;
      height: 4.2vh;
      padding: 0 14px;
      font-size: 14px;
      margin-inline-start: 10px;

      &:nth-child(4) {
        grid-area: input1;
      }

      &:nth-child(8) {
        grid-area: input2;
      }
      &:nth-child(10) {
        grid-area: input3;
      }
    }
    .filter-select {
      background-color: $white;
      @include border($grey-border-color);
      width: unset;
      height: 46px;
      border-radius: 4px;
      padding-top: 0;
      margin: 0 7px;
      &:nth-child(2) {
        grid-area: select1;
      }
      &:nth-child(6) {
        grid-area: select2;
      }
    }
  }

  .api-info {
    width: 100%;
    @include align-center;
    justify-content: space-between;
    & > *:first-child {
      margin-inline-end: 50px;
    }
    span {
      min-width: 6.1vw;
      @include align-center;
      justify-content: flex-end;
    }
  }
  
}
</style>