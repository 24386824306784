<template>
  <section v-if="servikMap" class="option-service-container">
    <div class="servik-select">
      <div ref="elServikCategories" class="servik-categories-container">
        <span
          v-for="(servik, category, idx) in servikMap"
          :key="idx"
          @click="setCategory(category)"
        >
          <h4>{{category}}</h4>
          <img class="arrow-down" :src="require('@/assets/survey/icon_shapes/arrow_down.svg')" alt />
        </span>
        <div v-if="servikList" :style="listStyle" ref="elServikList" class="servik-list-container">
          <div
            v-for="(servik, idx) in servikList"
            :key="idx"
            @click="setServik(servik)"
            class="servik-list-item"
          >
            <img v-if="servik.path" :src="servik.path" />
            <h4>{{servik.name}}</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Select from "@/modules/common/components/Select";
import Option from "@/modules/common/components/Option";
import servikService from '../services/servikService'

import { servikMap } from "../consts/consts";

export default {
  props: {
    servikId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      servik: this.servikId,
      currCategory: "",
      servikMap: null
    };
  },
  async created() {
    this.servikMap = await servikService.getServikCtgMap()
  },
  computed: {
    servikList() {
      if (!this.currCategory) return null;
      setTimeout(() => {
        const x = window.scrollX - this.$refs.elServikList.getBoundingClientRect().left
        const direction = x < -1500 ? 'left' : 'right';
        this.$refs.elServikList.style[direction] = '-10vw';
      }, 1)
      return this.servikMap[this.currCategory].map(
        ({ name: servikName, id, url }) => {
          let path;
          try {
            if (url && url.grey) path = url.grey;
            else path = require(`@/assets/survey/servik_icons/${this.currCategory}/${id}.${servikName}.svg`);
          } catch (e) {
            icon = null
          }
          const name = servikName.replace(/_/g, " ");
          return { name, id, path };
        }
      );
    },
    listStyle() {
      const categories = Object.keys(this.servikMap);
      const idx = categories.indexOf(this.currCategory);
      const top = idx * 20 + "px";
      return { top };
    }
  },
  methods: {
    setCategory(category) {
      this.currCategory = category;
    },
    setServik({ id, name }) {
      name = name.replace(/ /g, "_").toLowerCase();
      this.$emit("changeServik", { id, name, category: this.currCategory });
    }
  },
  watch: {
    servik(newServik) {
      this.$emit("changeServik", newServik);
    }
  },
  components: {
    Select,
    Option
  }
};
</script>

<style lang="scss">
@import "@/styles/vars";
@import "@/styles/mixins";

.option-service-container {
  @include flex-center;
  position: absolute;
  width: 8.5vw;
  height: 5.5vh;
  bottom: -5.5vh;
  box-shadow: 0px 2px 6px #bdbdbd;
  background-color: #fff;
  .servik-select {
    @include border($grey-border-color);
    height: 33px;
    width: 130px !important;
    border-radius: 4px;
    padding-top: 0;
    margin: 0 7px;
  }
}

.servik-list-container {
  width: 10vw;
  position: absolute;
  right: -10vw;
  max-height: 30vh;
  overflow-y: scroll;

  background-color: $white;
  @include border($grey-border-color);
  border-radius: 4px;
  @include align-center;
  flex-direction: column;
  .servik-list-item {
    width: 100%;
    padding: 4px 7px;
    // text-transform: capitalize;
    @include align-center;
    justify-content: space-between;
    h4 {
      text-align: center;
    }
    img {
      max-width: 1.8vw;
    }
    &:hover {
      background-color: rgb(247, 247, 247);
    }
  }
}

.servik-categories-container {
  position: absolute;
  bottom: -30vh;
  z-index: 10;
  width: 130px;
  height: 35vh;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  @include align-center;
  flex-direction: column;
  justify-content: space-between;
  @include border($grey-border-color);
  border-radius: 4px;
  background-color: $white;
  & > span {
    // text-transform: capitalize;
    &:last-child {
      margin-bottom: 4px;
    }
    &:first-child {
      margin-top: 4px;
    }
    padding: 4px 10px;
    @include align-center;
    justify-content: space-between;
    width: 100%;
    img {
      max-width: 0.3vw;
      transform: rotate(-90deg);
    }
    &:hover {
      background-color: $grey;
    }
  }
}
</style>