<template>
  <label class="switch">
    <input type="checkbox" @click="emitInput" :checked="isChecked"/>
    <span class="slider"></span>
  </label>
</template>

<script>
export default {
  props: {
    isChecked: { type: Boolean, required: false }
  },
  methods: {
    emitInput(event) {
      this.$emit('input', event.target.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";

.switch {
  /* The switch - the box around the slider */
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F0F0F0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  border:2px solid $blue;
  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 2px;
    bottom: 2px;
    background-color:#A5A4BF;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
}
</style>