/**
 * @param {{
 * data: Number[];
 * labels: String[];
 * }} chartData 
 * @param {Number} total
 */
function addPercentToLabels(chartData, total) {
    chartData.labels = chartData.labels.map((label, index) => {
        label = `${Math.floor((chartData.data[index] / total) * 100)}% ${label}`
        return label
    })
    return chartData;
}

export default { addPercentToLabels };