<template>
	<section class="hour-table-conatiner">
		<div class="table-title">
			<div>{{ $t('data_by_hour') }}</div>
		</div>
		<table class="hour-table">
			<thead>
				<tr>
					<td class="table-head-cell">
						<img :src="require('@/assets/performance/clock-light.svg')" alt />
					</td>
					<td
						class="table-head-cell"
						:class="currentlyFilteringHour === item.time ? 'highlight' : ''"
						v-for="item in dataByHour"
						:key="item.time"
						@click="$emit('hourClick', item.time)"
					>
						{{ item.time | numberToHour }}
					</td>
				</tr>
			</thead>
			<tbody>
				<tr v-if="isShown.visits">
					<td>
						<img
							class="icon"
							:src="require('@/assets/performance/icons-visits.svg')"
							alt="visits"
							:title="$t('visits')"
						/>
					</td>
					<td
						v-for="item in dataByHour"
						:style="{ backgroundColor: `rgba(224, 214, 225, ${item.visits / dataByHourEachMax.visits})` }"
						:key="item.time"
					>
						{{ kFormatter(item.visits) }}
						<span v-if="item.isAmended && isAmendedPermitted" class="amended-room">*</span>
					</td>
				</tr>
				<template v-if="isShown.cleanComplaint">
					<tr>
						<td>
							{{ inSurveyOverviewPage ? $t('clicks') : '' }}
							<img
								v-if="!inSurveyOverviewPage"
								class="icon"
								:src="require('@/assets/performance/icons-complaint.svg')"
								alt="complaints"
								:title="$t('C.complaints')"
							/>
						</td>
						<td
							v-for="item in dataByHour"
							:key="item.time"
							:style="{
								backgroundColor: `rgba(213, 139, 157, ${item.complaints / dataByHourEachMax.complaints})`
							}"
						>
							{{ kFormatter(item.complaints) }}
						</td>
					</tr>
				</template>
				<template v-if="isShown.arrivals">
					<tr>
						<td>
							{{ inSurveyOverviewPage ? $t('arrivals') : '' }}
							<img
								v-if="!inSurveyOverviewPage"
								class="icon"
								:src="require('@/assets/performance/cleaner_arrival-icon.svg')"
								alt="cleaning arrivals"
								:title="$t('C.arrivals')"
							/>
						</td>
						<td
							v-for="item in dataByHour"
							:style="{ backgroundColor: `rgba(163, 194, 155, ${item.arrivals / dataByHourEachMax.arrivals})` }"
							:key="item.time"
						>
							{{ kFormatter(item.arrivals) }}
						</td>
					</tr>
				</template>
				<template v-if="isShown.maintenanceComplaint">
					<tr>
						<td>
							<img
								class="icon"
								:src="require('@/assets/performance/icons-complaint_blue.svg')"
								alt="maintenance complaints"
								:title="$t('M.complaints')"
							/>
						</td>
						<td
							v-for="item in dataByHour"
							:style="{
								backgroundColor: `rgba(215, 169, 146, ${item.maintenanceComplaint /
									dataByHourEachMax.maintenanceComplaint})`
							}"
							:key="item.time"
						>
							{{ kFormatter(item[dataKey('maintenanceComplaint')]) }}
						</td>
					</tr>
				</template>
				<template v-if="isShown.maintenanceArrivals">
					<tr>
						<td>
							<img
								class="icon"
								:src="require('@/assets/performance/maintenance-arrivals_purple.svg')"
								alt="maintenance arrivals"
								:title="$t('M.Arrivals')"
							/>
						</td>
						<td
							v-for="item in dataByHour"
							:style="{
								backgroundColor: `rgba(96, 149, 209, ${item.maintenanceArrivals /
									dataByHourEachMax.maintenanceArrivals})`
							}"
							:key="item.time"
						>
							{{ kFormatter(item[dataKey('maintenanceArrivals')]) }}
						</td>
					</tr>
				</template>
			</tbody>
		</table>
		<section class="icon-legend print-mode">
			<div v-if="isShown.visits">
				<img :src="require('@/assets/performance/icons-visits.svg')" alt="visits" />
				<span class="icon-label">{{ $t('visits') }}</span>
			</div>
			<div v-if="isShown.cleanComplaint">
				<img :src="require('@/assets/performance/icons-complaint.svg')" alt="complaints" />
				<span class="icon-label">{{ $t('C.complaints') }}</span>
			</div>
			<div v-if="isShown.arrivals">
				<img :src="require('@/assets/performance/cleaner_arrival-icon.svg')" alt="cleaning arrivals" />
				<span class="icon-label">{{ $t('C.arrivals') }}</span>
			</div>
			<div v-if="isShown.maintenanceComplaint">
				<img :src="require('@/assets/performance/icons-complaint_blue.svg')" alt="maintenance complaints" />
				<span class="icon-label">{{ $t('M.complaints') }}</span>
			</div>
			<div v-if="isShown.maintenanceArrivals">
				<img :src="require('@/assets/performance/maintenance-arrivals_purple.svg')" alt="maintenance arrivals" />
				<span class="icon-label">{{ $t('M.Arrivals') }}</span>
			</div>
		</section>
	</section>
</template>

<script>
import util from '@/modules/common/services/utilService'
import { byHourTableConst } from '../consts/consts'
import { mapGetters } from 'vuex'

export default {
	props: {
		dataByHour: { type: Array, required: true },
		hideRows: { type: Array, required: false, default: () => [] },
		inSurveyOverviewPage: { type: Boolean, required: false, default: false }
	},
	data() {
		return {
			descriptionConst: byHourTableConst
		}
	},
	mounted() {
		this.$emit('mounted')
	},
	computed: {
		...mapGetters(['loggedUser']),
		currentlyFilteringHour() {
			const filter = this.$store.getters.filterSelected
			const dateToCalculateLocalHour = new Date()
			dateToCalculateLocalHour.setHours(filter.hour)
			const currFilteringHour = dateToCalculateLocalHour.getHours()
			return currFilteringHour
		},
		isShown() {
			return {
				visits: this.isRowData('visits'),
				cleanComplaint: this.isRowData('complaints') || !!(this.dataByHour[0].cleaningProblemsByServik + 1),
				arrivals: this.isRowData('arrivals'),
				maintenanceComplaint:
					this.isRowData('maintenanceComplaint') || !!(this.dataByHour[0].maintenanceProblemsByServik + 1),
				maintenanceArrivals: this.isRowData('maintenanceArrivals')
			}
		},
		dataByHourEachMax() {
			return this.dataByHour.reduce((maxMap, item) => {
				for (const key in item) {
					if (!maxMap[key] || maxMap[key] < item[key]) maxMap[key] = item[key]
				}
				return maxMap
			}, {})
		},
		isCleanDrillDown() {
			return this.dataByHour.some((a) => a.cleaningProblemsByServik)
		},
		isMaintenanceDrillDown() {
			return this.dataByHour.some((a) => a.maintenanceProblemsByServik)
		},
		isAmendedPermitted() {
			const { level } = this.loggedUser
			return level === 'buzzz'
		}
	},
	methods: {
		kFormatter(number) {
			if (number === 0) return ''
			return util.kFormatter(number)
		},
		isRowData(field) {
			if (this.hideRows.length && this.hideRows.includes(field)) return false
			const isDataForRow = this.dataByHour.some((item) => item[field] > 0)
			return isDataForRow
		},
		dataKey(name) {
			if (!this.isMaintenanceDrillDown && !this.isCleanDrillDown) return name
			else if (name === 'maintenanceComplaint' && this.isMaintenanceDrillDown) return 'maintenanceProblemsByServik'
			else if (name === 'cleanComplaint' && this.isCleanDrillDown) return 'cleaningProblemsByServik'
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
@import '@/styles/vars.scss';

.amended-room {
	color: blue;
	font-size: large;
	display: flex;
	padding-block-start: 10px;
	padding-inline-start: 2px;
}

tr {
	td:first-child {
		width: 80px;
		font-size: $font-size-small;
		text-align: center;
	}
	img {
		width: 23px;
		height: 23px;
		cursor: default;
	}
}

.table-title {
	display: flex;
	padding-bottom: 1.4vh;
	padding-top: 1.4vh;
	text-align: start;
	color: #7f7f7f;
	border-bottom: 0px solid #f5f5f5;
	-webkit-padding-start: 25px;
	padding-inline-start: 25px;

	h3 {
		@include small-titles;
	}

	.table-description {
		display: flex;
		align-items: center;
		font-size: $font-size-small;
		margin-inline-start: 40px;

		.color-description {
			margin-inline-end: 10px;
			width: 13px;
			height: 13px;
		}
	}
}

table {
	width: 100%;
}

.hour-table {
	font-size: $font-size-medium;
}

thead {
	color: $black-blue;
	font-weight: $font-weight-semi-bold;
}

tr {
	min-height: 4.1vh;
	max-height: 4.1vh;
	border-bottom: 1px solid #f5f5f5;
	display: flex;

	td {
		@include flex-center;
		border-inline-start: 2px solid #00000013;
		flex-grow: 1;

		&:first-child,
		&:nth-child(2),
		&.table-head-cell {
			border: unset;
			cursor: pointer;

			&.highlight {
				font-weight: $font-weight-bold;
			}
		}

		width: 2.5vw;
		font-size: $font-size-smaller;
	}
}

.print-mode {
	display: none;
}

.print-mode .icon-legend {
  @include flex-center;
  gap: 10px;
  border-bottom: 1px solid #f5f5f5;
  padding: 25px;

  .icon-label {
    display: inline-block;
    margin-left: 10px;
    font-size: $font-size-small;
  }
}


</style>
