<template>
  <section class="blank-container">
    <div class="logo-header-container">
      <img class="logo-header" src="@/assets/buzzztv_logo.png" alt />
    </div>
    <Login />
  </section>
</template>

<script>
import Login from "@/modules/auth/components/Login";

export default {
  created() {
    this.$store.commit({ type: "toggleBlankMode", value: true });
  },
  destroyed() {
    this.$store.commit({ type: "toggleBlankMode", value: false });
  },
  data() {
    return {};
  },
  components: {
    Login
  }
  };
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.blank-container {
  margin: 5% 40% 0 22%;
  @include screen-size(xs) {
    margin: 0;
  }

  .logo-header-container {
    width: 25%;
    margin: auto;
  }

  .logo-header {
    max-height: 100%;
  }
}
</style>
