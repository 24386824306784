<template>
	<section class="user-page">
		<AdminHeader
			@add="openModal"
			:texts="{ title: $t('users'), add: $t('add_user') }"
			@input="handleInput"
			:isSearchShown="false">
			<TableSearch :tableTitles="tableTitles" @input="handleInput" />
		</AdminHeader>
		<Loading class="loader" style="height:75vh;" v-if="isLoading" />
		<Table
			class="table"
			idField="id"
			v-if="usersToShow.length"
			:tableData="usersToShow"
			:editOptions="{ remove: true, edit: true }"
			@editRow="openModal"
			@removeRow="removeUser"
		/>
		<div v-else>
			<h3>{{ $t('no_user_found') }}</h3>
		</div>
		<Modal
			class="modal"
			v-if="currUser"
			@submit="saveUser"
			@close="closeModal"
			width="medium"
			:texts="{ headerPrimary: currUser.id ? 'edit_user' : 'new_user' }"
			:isConfirmDisabled="isConfirmDisabled">
			<template v-slot:default>
				<div class="modal-body">
					<div v-for="(field, idx) in fields" :key="idx" class="short-field">
						<label>
							{{ $t(field.label) }}
							<Input
								v-model="currUser[field.model]" 
								:placeholder="`${$t(field.label)}...`" 
								:type="field.type === 'password' && isPasswordShown ? 'text' : field.type"
								:flash="errors.includes(field.model)"
							/>
						</label>
					</div>
					<label class="short-field password-container">
						{{ $t('password') }}
						<div>
							<Input
								v-model="currUser.password"
								:type="isPasswordShown ? 'text' : 'password'"
								:placeholder="`${$t('password')}...`"
								:style="{ marginBottom: '0px' }"
								:flash="errors.includes('password')"
							/>
							<img
								:src="require('@/assets/survey/eye.svg')"
								alt="eye"
								@mousedown="isPasswordShown = true"
								@mouseup="isPasswordShown = false"
							/>
						</div>
					</label>
					<label class="short-field">
						{{ $t('email') }}
						<Input
							v-model="currUser.email"
							:placeholder="`${$t('email')}...`"
							:style="{ marginBottom: '0px' }"
							@blur="checkEmail"
							:flash="errors.includes('email')"
						/>
					</label>
					<div class="select-container">
						{{ $t('type') }}
						<Select v-model="currUser.type" class="modal-select">
							<Option value="admin" label="admin" />
							<Option value="viewer" label="viewer" />
							<Option value="installer" label="installer" />
						</Select>
						<ErrorMsg :isShown="errors.includes('type')" :text="`${$t('must_choose')}`" />
					</div>
					<div class="select-container">
						{{ $t('level') }}
						<Select v-model="currUser.level" class="modal-select" :placeholder="$t('level')">
							<Option
								v-for="(level, idx) in loggedUser.permitedLevels"
								:key="level + idx"
								:value="level"
								:label="$t(level)"
							/>
						</Select>
					</div>
					<div class="select-container">
						{{ $t('language') }}
						<Select v-model="currUser.language" class="modal-select" :placeholder="$t('language')">
							<Option
								v-for="(lang, idx) in selectOptions.languages"
								:key="lang.value + idx"
								:value="lang.value"
								:label="lang.label"
							/>
						</Select>
						<ErrorMsg :isShown="errors.includes('language')" :text="`${$t('must_choose')}`" />
					</div>
					<div class="select-container">
						{{ $t('service_provider') }}
						<Select 
							v-model="currUser.serviceProviderId"
							@input="handleServiceProvider(currUser.serviceProviderId)"
							class="modal-select"
							:placeholder="$t('service_provider')"
							>
							<Option
								v-for="(sp, idx) in selectOptions.serviceProviders"
								:key="sp.id + '' + idx"
								:value="sp.id"
								:label="sp.name"
							/>
						</Select>
					</div>
					<transition name="fade" v-for="(field, idx) in multiSelectFields" :key="field + idx">
						<div class="select-container">
							{{ $t(field) }}
							<Select
								:multiple="true"
								:placeholder="$t('select')"
								:value="getUserSelectedValues(field)"
								@input="handleSelect($event, field)"
								class="modal-select"
								>
								<SearchBar v-model="modalSearchBy[field]" />
								<!-- Add the "all" option -->
								<Option value="all" :label="$t('all')" />
								<Option
									v-for="(option, idx) in getFilteredItems(selectOptions[`${field}_options`], field)"
									:key="option.id + '' + idx"
									:value="option.id"
									:label="$t(option.name)"
								/>
							</Select>
						</div> 
					</transition>
				</div>
			</template>
		</Modal>
	</section>
</template>

<script>
import Input from '@/modules/common/components/Input'
import Loading from '@/modules/common/components/Loading'
import Select from '@/modules/common/components/Select'
import ErrorMsg from '@/modules/common/components/ErrorMsg'
import Option from '@/modules/common/components/Option'
import swalService from '@/modules/common/services/swalService'
import Modal from '@/modules/common/components/Modal'
import Table from '@/modules/common/components/customTable/Table'
import TableSearch from '@/modules/common/components/customTable/TableSearch'
import AdminHeader from '../components/AdminHeader'
import siteService from '../services/siteService'
import { getUsers, getUser, getPermitedLevels, saveUser, removeUser, getLanguages } from '../services/userService'
import { getServiceProviders } from '../services/serviceProviderService'
import authService from '../../auth/services/authService'
import userService from '../services/userService'
import util from '../../common/services/utilService'
import SearchBar from '@/modules/common/components/SearchBar'

export default {
	async created() {
		await this.loadUsers()
		this.setTableTitles()
	},
	data() {
		return {
			currUser: null,
			isLoading: false,
			users: [],
			isPasswordShown: false,
			selectOptions: this.defaultSelectOptions(),
			loggedUser: null,
			openSelectMenus: {
				permitted_franchise: false,
				permitted_companies: false,
				permitted_sites: false,
				panel_permitted: false
			},
			errors: [],
			tableTitles: null,
			searchTxt: '',
			searchBy: '',
			modalSearchBy: this.defaultModalSearchBy(),
			isConfirmDisabled: false,
			permittedSitesIds: [],
			permittedCompaniesIds: [],
			permittedFranchiseIds: [],
			permittedPanelViews: [],
			fields: [
				{ label: 'first_name', model: 'firstName', type: 'text' },
				{ label: 'last_name', model: 'lastName', type: 'text' },
				{ label: 'username', model: 'username', type: 'text' },
				{ label: 'phone', model: 'phone', type: 'tel' },
			],
			originalFields :{
				email: '',
				username: '',
				phone: '',
			}
		}
	},
	computed: {
		usersToShow() {
			const { searchTxt, searchBy } = this
			const users = this.users.map(({ id, email, username, firstName, lastName, level, type, phone }) => ({
				id,
				email,
				username,
				firstName,
				lastName,
				level,
				type,
				phone
			}))
			return util.filterArrayOfObjectsWithString(users, searchTxt, searchBy)
		},
		gridRowNumber() {
			const { level } = this.loggedUser
			if (level === 'buzzz') return 5
			else if (level === 'franchise') return 4
			else return 3
		},
		multiSelectFields() {
			const fields = ['sites', 'companies', 'franchise']
			let fieldsToShow = ['panel_permitted']
			for (let i = 0; i < this.gridRowNumber - 2; i++) {
				fieldsToShow.unshift(`permitted_${fields[i]}`)
			}
			return fieldsToShow
		},
	},
	methods: {
		async checkEmail() {
			this.errors = []
			const { email, id } = this.currUser
			if (id) return
			const isValid = await authService.isEmailValid(email)
			if (!isValid) this.errors.push('email')
		},
		closeModal() {
			this.errors = []
			this.currUser = null
			this.resetPermittedSelectedValues()
			this.selectOptions = this.defaultSelectOptions()
		},
		async openModal(user) {
			const { options, field } = await userService.getFirstOptions(this.loggedUser.level)
			this.selectOptions[field] = options
			const [languages, serviceProviders] = await Promise.all([getLanguages(), getServiceProviders()])
			this.selectOptions.languages = languages
			this.selectOptions.serviceProviders = serviceProviders
			this.selectOptions.serviceProviders.unshift({ id: '', name: 'none' })

			if (user) {
				user = await getUser(user.id)
				this.currUser = user
				this.originalFields = {
					email: user.email,
					username: user.username,
					phone: user.phone,
				}
				this.handleMultiSelectInput(this.currUser.panelViews.map((a) => ({ id: a, name: a })), 'panel_permitted')
				this.handleMultiSelectInput(this.currUser.franchises, 'permitted_franchise')
				this.handleMultiSelectInput(this.currUser.companies, 'permitted_companies')
				this.handleMultiSelectInput(this.currUser.sites, 'permitted_sites')
			} else {
				this.currUser = {
					email: '',
					password: '',
					firstName: '',
					lastName: '',
					username: '',
					address: '',
					permitted_franchise: [],
					permitted_companies: [],
					permitted_sites: [],
					//select all when open
					panel_permitted: JSON.parse(JSON.stringify(this.selectOptions.panel_permitted_options)),
					phone: '',
					level: 'site',
					type: '',
					sites: [],
					serviceProviderId: '',
				}
			}
			this.updatePermittedSelectedValues()
		},
		async removeUser(id) {
			const result = await swalService.confirmMsg()

			if (!result.value) return
			try {
				await removeUser(id)
				swalService.deletedMsg()
			} catch (err) {
				swalService.errorMsg()
			}

			this.loadUsers()
		},
		async checkForm() {
			const {
				email,
				firstName,
				lastName,
				username,
				password,
				permitted_sites,
				id,
				type,
				phone,
				language,
				level
			} = this.currUser
			const { email: originalEmail, username: originalUsername, phone: originalPhone } = this.originalFields
			this.errors = []
			const validations = {
				isEmailValidPrm: (!id || email !== originalEmail) && email ? authService.isEmailValid(email) : true,
				isUsernameValidPrm: (!id || username !== originalUsername) && username ? authService.isUsernameValid(username) : true,
				isPhoneValidPrm: (!id || phone !== originalPhone) && phone ? authService.isPhoneValid(phone) : true
			}
			// Field presence validation
			!firstName && this.errors.push('firstName');
			!lastName && this.errors.push('lastName');
			!username && this.errors.push('username');
			(!password && !id) && this.errors.push('password');
			(level !== 'franchise' && !permitted_sites.length) && this.errors.push('sites');
			!type && this.errors.push('type');
			!phone && this.errors.push('phone');
			!language && this.errors.push('language');
			
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
			!emailRegex.test(email) && this.errors.push('email')

			// Regex validation for username
			const usernameRegex = /^[^\s@]+$/g;
			!usernameRegex.test(username) && this.errors.push('username');
			
			// Await asynchronous validations
			const [isEmailValid, isUsernameValid, isPhoneValid] = await Promise.all(Object.values(validations))

			// Push errors based on validation results
			!isEmailValid && this.errors.push('email');
			!isUsernameValid && this.errors.push('username');
			!isPhoneValid && this.errors.push('phone');
		},
		async saveUser() {
			const { currUser } = this
			const user = { ...currUser }
			await this.checkForm()
			if (this.errors.length) return
			this.isConfirmDisabled = true
			if (user.panel_permitted.length) {
				user.panelViews = user.panel_permitted.map((a) => a.name)
			} else {
				user.panelViews = []
			}
			if (user.permitted_sites.length) {
				user.sites = user.permitted_sites.map((s) => s.id)
			} else {
				user.sites = []
			}
			try {
				await saveUser(user)
				this.isConfirmDisabled = false
				swalService.savedMsg(this.$t('Your work has been saved'))
			} catch (err) {
				swalService.errorMsg()
			}
			this.resetModalSearchBy()
			this.closeModal()
			this.loadUsers()
		},
		updatePermittedSelectedValues() {
			const { currUser } = this
			if (!currUser) return
			const { permitted_sites, permitted_companies, permitted_franchise, panel_permitted } = currUser
			if (permitted_sites.length > 0) {
				this.permittedSitesIds = [...new Set(permitted_sites.map(s => s.id))]
			}
			if (permitted_companies.length > 0) {
				this.permittedCompaniesIds = [...new Set(permitted_companies.map(c => c.id))]
			}
			if (permitted_franchise.length > 0) {
				this.permittedFranchiseIds = [...new Set(permitted_franchise.map(f => f.id))]
			}
			if (panel_permitted.length > 0) {
				this.permittedPanelViews = [...new Set(panel_permitted.map(p => p.name))]
			}
		},
		resetPermittedSelectedValues() {
			this.permittedSitesIds = []
			this.permittedCompaniesIds = []
			this.permittedFranchiseIds = []
		},
		async handleMultiSelectInput(values, field) {
			if (!values || !values.length) {
				this.currUser[field] = []
				return
			}
			this.currUser[field] = JSON.parse(JSON.stringify(values))
			if (field !== 'panel_permitted') {
				const { nextField, options } = await userService.getNextOptions(field, values.map((a) => a.id))
				this.selectOptions[nextField] = options
			}
		},
		handleServiceProvider(id) {
			if (!id) {
				this.resetCurrUserPermittedOptions()
				return
			}
			const { selectOptions: { serviceProviders } } = this
			const selectedServiceProvider = serviceProviders.find((sp) => sp.id === id)
			const { franchiseId, franchiseName, associated_companies: associatedCompanies, sites } = selectedServiceProvider
			this.handleMultiSelectInput([{ id: franchiseId, name: franchiseName }], 'permitted_franchise')
			this.handleMultiSelectInput(associatedCompanies, 'permitted_companies')
			this.handleMultiSelectInput(sites, 'permitted_sites')
			this.updatePermittedSelectedValues()
		},
		defaultSelectOptions() {
			return {
				permitted_franchise_options: [],
				permitted_companies_options: [],
				permitted_sites_options: [],
				languages: [],
				panel_permitted_options: [
					{ name: 'dashboard', id: 'dashboard' },
					{ name: 'staff', id: 'staff' },
					{ name: 'real-time', id: 'real-time' },
					{ name: 'survey', id: 'survey' },
					{ name: 'room', id: 'room' },
					{ name: 'task', id: 'task' },
					{ name: 'users', id: 'users' },
					{ name: 'shift', id: 'shift' },
					{ name: 'devices', id: 'devices' },
					{ name: 'surveyApp', id: 'surveyApp' },
					{ name: 'installation', id: 'installation' },
					{ name: 'working_area', id: 'working_area' },
				],
				serviceProviders: [],
			}
		},
		resetCurrUserPermittedOptions() {
			this.currUser.permitted_franchise = []
			this.currUser.permitted_companies = []
			this.currUser.permitted_sites = []
			this.resetPermittedSelectedValues()
		},
		handleSelect(selectedValues, field) {
			const fieldItems = this.selectOptions[field + '_options']
			const isAllSelected = selectedValues.includes('all')
			let selectedItems = []
			if (isAllSelected) {
				selectedItems = fieldItems
			} else {
				const isPanelPermitted = field === 'panel_permitted'
				if (isPanelPermitted) {
					selectedItems = fieldItems.filter((item) => selectedValues.includes(item.name))
				} else {
					selectedItems = fieldItems.filter((item) => selectedValues.includes(item.id))
				}
			}
			this.handleMultiSelectInput(selectedItems, field)
			if (isAllSelected) {
				this.updatePermittedSelectedValues()
			}
			this.openSelectMenus[field] = !this.openSelectMenus[field]
		},
		getUserSelectedValues(field) {
			if (field === 'panel_permitted') {
				return this.permittedPanelViews
			}
			if (field === 'permitted_sites') {
				return this.permittedSitesIds
			}
			if (field === 'permitted_companies') {
				return this.permittedCompaniesIds
			}
			if (field === 'permitted_franchise') {
				return this.permittedFranchiseIds
			}
		},
		async loadUsers() {
			try {
				this.isLoading = true
				const users = await getUsers()
				if (!users.length) {
					this.isLoading = false
					return
				}
				this.users = users
				const loggedUser = { ...this.$store.getters.loggedUser }
				const permitedSites = await siteService.getSites(loggedUser.siteIdsToShow)
				loggedUser.permitedSites = permitedSites
				loggedUser.permitedLevels = getPermitedLevels(loggedUser.level)
				this.loggedUser = loggedUser
				this.isLoading = false
				return loggedUser
			} catch (err) {
				swalService.errorMsg()
			}
		},
		setTableTitles() {
			if (!this.users.length) return
			this.tableTitles = Object.keys(this.users[0]).filter((key) =>
				['email', 'username', 'phone', 'firstName', 'lastName', 'level', 'type'].includes(key)
			)
		},
		handleInput(value, searchBy) {
			if (searchBy) this.searchBy = searchBy
			this.searchTxt = value
		},
		getFilteredItems(items, field) {
			const searchTxt = this.modalSearchBy[field]
			if (!searchTxt) return items
			return util.filterArrayOfObjectsWithString(items, searchTxt, 'name')
		},
		resetModalSearchBy() {
			this.modalSearchBy = this.defaultModalSearchBy()
		},
		defaultModalSearchBy() {
			return {
				permitted_franchise: '',
				permitted_companies: '',
				permitted_sites: '',
				panel_permitted: ''
			}
		},
	},
	components: {
		Modal,
		Input,
		Table,
		AdminHeader,
		Select,
		Option,
		ErrorMsg,
		TableSearch,
		Loading,
		SearchBar,
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';

.user-page {
  .modal {
    .modal-body {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;
      label, .select-container {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: #333;
        
        &.long-field {
          grid-column: span 2; /* Spans across both columns */
        }

        &.short-field {
          grid-column: span 1; /* Takes only one column */
        }

        &.full-row {
          grid-column: 1 / -1; /* Spans across the full row */
        }

        .modal-select {
          margin: 4px 0 0;
          border: 1px solid #ccc;
          border-radius: 4px;
          padding: 8px;
        }

        & > * {
			border: 1px solid $grey-border-color;
			border-radius: 4px;
			padding: 8px;
			margin: 0;
        }
      }
      .password-container {
        & > div {
          display: flex;
          align-items: center;
          img {
            height: 50%;
            width: 7%;
            cursor: pointer;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

</style>
