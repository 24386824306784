<template>
  <section class="room-details">
    <section class="basic-room-info">
      <div class="info-item-container" v-for="(value, name) in roomDetails" :key="value">
        <template v-if="value && name !== 'id'">
          <div class="property">{{$t(name)}}</div>
          <div class="value">{{value}}</div>
        </template>
      </div>
    </section>
    <ShiftList class="shift-table" shiftParentType="room" :roomId="+roomId" :siteId="+siteId" :companyId="+companyId" />
  </section>
</template>

<script>
import roomService from "../services/roomService";
import ShiftList from "../components/ShiftList";

export default {
  data() {
    return {
      roomDetails: {}
    };
  },
  async created() {
    const room = await roomService.getRoomById(this.roomId);
    delete room.site_id;
    delete room.survey_id;
    this.roomDetails = room;
  },
  computed: {
    roomId() {
      return this.$route.params.id;
    },
    companyId() {
      return this.$store.getters.filterSelected.company
    },
    siteId() {
      return this.$store.getters.filterSelected.site
    }
  },
  components: {
    ShiftList
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars";
$table-grey: #7f7f7f;

.room-details {
  // text-transform: capitalize;
  display: flex;
  justify-content: space-between;
}

.shift-table {
  margin-top: 20px;
}

.info-item-container {
  display: flex;
  margin-bottom: 40px;
  margin-inline-start: 40px;

  .property {
    width: 9.375vw;
    color: $table-grey;
  }

  .value {
    font-size: $font-size-small;
  }
}
</style>