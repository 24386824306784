<template>
  <Tabs :isRTL="isRTL" syncRoute>
    <Tab :label="$t('shifts')" :to="'/shift'" exact class="shift-holiday">
      <Loading class="loader" style="width:85%" v-show="!siteDetails || !mountedCmps.shift || !mountedCmps.holiday" />
      <ShiftList
        shiftParentType="shift"
        :siteId="+siteId"
        :companyId="+companyId"
        :companyName="siteDetails.company_name"
        @loaded="mountedCmps.shift = true"
        v-show="siteDetails && mountedCmps.shift && mountedCmps.holiday"
        v-if="siteDetails"
      />
      <HolidayList
        shiftParentType="shift"
        :siteId="+siteId"
        :companyId="+companyId"
        :companyName="siteDetails.company_name"
        :companyCountry="siteDetails.country"
        :shifts="siteDetails.shifts"
        @loaded="mountedCmps.holiday = true"
        v-show="siteDetails && mountedCmps.shift && mountedCmps.holiday"
        v-if="siteDetails"
      />
    </Tab>
  </Tabs>
</template>

<script>
import ShiftList from '@/modules/control-panel/components/ShiftList'
import HolidayList from '@/modules/control-panel/components/HolidayList'
import Tabs from '../../common/components/Tabs/Tabs.vue'
import Tab from '../../common/components/Tabs/Tab.vue'
import Loading from '../../common/components/Loading.vue'
import { mapGetters } from 'vuex'
import siteService from '../../control-panel/services/siteService'

export default {
  name: 'shift',
  data() {
    return {
      siteDetails: null,
      mountedCmps: {
        shift: false,
        holiday: false,
      },
    }
  },
  components: {
    ShiftList,
    HolidayList,
    Loading,
    Tabs,
    Tab,
  },
  async created() {
    await this.updateSiteDetails()
  },
  methods: {
    async updateSiteDetails() {
      const site = await siteService.getSiteById(this.siteId)
      this.siteDetails = site
    },
  },
  computed: {
    ...mapGetters(['isRTL', 'loggedUser', 'filterSelected']),
    siteId() {
      return this.filterSelected.site
    },
    companyId() {
      return this.filterSelected.company
    },
  },
  watch: {
    siteId: {
      async handler() {
        this.mountedCmps = {
          shift: false,
          holiday: false,
        }
        await this.updateSiteDetails()
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
.view-nav-container {
  height: 89vh;
  max-height: 89vh;
}

.shift-holiday {
  display: flex;
  flex-direction: row;
  height: 100%;
}

#app:has(.shift-holiday) .md-tabs {
  height: 100% !important;

  > * {
    display: flex;
    height: 100% !important;
  }

  > :first-child {
    height: fit-content !important;
  }

  > :nth-child(2) > * {
    height: 100%;
    width: 100%;
  }
}
</style>
