<template>
  <section class="setup-body">
    <section class="body-start">
      <div class="logo-container">
        <img
          :src="require('@/assets/buzzztv_logo.svg')"
          alt="logo"
          class="buzz-logo"
        />
      </div>
      <section class="room-info">
        <template v-for="(value, propertyName) in room">
          <transition
            v-if="propertyName !== 'survey' && propertyName !== 'survey_id'"
            name="fade"
            :key="propertyName + 100"
          >
            <div class="room-item" :key="propertyName" v-if="value">
              <div class="property-name">{{ propertyName }}:</div>
              <div class="value" v-if="propertyName === 'site'">
                {{ siteName }}
              </div>
              <div class="value" v-else>
                {{ value === "not_specified" ? "Not Spcified" : value }}
              </div>
            </div>
          </transition>
        </template>
        <div v-if="selectedSurvey" class="edit-btns-container">
          <Button skin="primary" class="login" @click="edit('location')"
            >EDIT LOCATION</Button
          >
          <Button skin="primary" class="login" @click="edit('survey')"
            >EDIT SURVEY</Button
          >
        </div>
      </section>
    </section>
    <section class="body-end">
      <LoginForm
        @restartTimeout="restartTimeout"
        @login="emitCred"
        v-if="!user"
        :isBackButton="isBackButton"
        @backToSurvey="backToSurvey"
      />
      <template v-if="user && !selectedSurvey">
        <Loading height="55vh" width="50vw" v-if="isLoading" />
        <SetupForm
          v-else
          :room="room"
          :options="roomOptions"
          :selectedSurvey="selectedSurvey"
          @selectModeChange="changeSelectMode"
          @input="handleInput"
          :selectMode="selectMode"
          @restartTimeout="restartTimeout"
        />
      </template>
      <template v-if="selectedSurvey">
        <div class="form-header">
          <img
            :src="require(`@/assets/finger_clicking.svg`)"
            alt="click icon"
          />
          {{ selectedSurvey.name }}
        </div>
        <section class="survey-info">
          <div class="survey-item">
            <div class="property-name">By:</div>
            <div class="value">{{ selectedSurvey.updated_by }}</div>
          </div>
          <div class="survey-item">
            <div class="property-name">Update:</div>
            <div class="value">{{ selectedSurvey.updated.slice(0, 10) }}</div>
          </div>
          <div class="survey-item">
            <div class="property-name">Created:</div>
            <div class="value">{{ selectedSurvey.created.slice(0, 10) }}</div>
          </div>
          <div class="survey-item">
            <div class="property-name">Stage #:</div>
            <div class="value">
              {{ Object.keys(selectedSurvey.pages).length }}
            </div>
          </div>
        </section>
        <Button skin="primary" class="preview-btn" @click="approveSurvey">
          <img :src="require('@/assets/eye_white.svg')" alt />
          approve
        </Button>
      </template>
    </section>
  </section>
</template>

<script>
import LoginForm from "../components/LoginForm";
import SetupForm from "../components/SetupForm";
import roomService from "../../modules/control-panel/services/roomService";
import siteService from "../../modules/control-panel/services/siteService";
import installService from "../services/installationService";
import Button from "../../modules/common/components/Button";
import Loading from "../../modules/common/components/Loading";
import {
  sessionLoad,
  sessionStore,
} from "@/modules/common/services/storageService";

export default {
  props: {
    user: { type: Object, required: false },
    identifier: { type: String, required: true },
    survey: { type: Object, required: false },
  },
  data() {
    return {
      room: {
        site: "",
        building: "",
        floor: "",
        zone: "",
        gender: "",
        name: "",
        id: "",
      },
      roomOptions: [],
      siteName: "",
      selectedSurvey: null,
      /**@type {'list' | 'steps'} */
      selectMode: "steps",
      isLoading: false,
      isBackButton: false,
    };
  },
  methods: {
    emitCred(userCred) {
      this.$emit("login", userCred);
    },
    backToSurvey() {
      this.$emit("surveySet");
    },
    restartTimeout() {
      this.$emit("restartTimeout");
    },
    async handleInput(key, value, isPrev = false) {
      if (this.selectMode === "list") {
        this.room = await installService.getRoomById(value);
        key = "name";
        value = this.room.name;
        this.selectMode = "steps";
        this.goBack();
        return;
      }
      if (key === "room") key = "id";
      if (!isPrev) this.room[key] = value;
      this.isLoading = true;

      if (key !== "survey")
        this.roomOptions = await installService.getNextOptions(this.room, key);
      else this.selectedSurvey = await installService.getSurveyById(value);
      this.isLoading = false;
    },
    async approveSurvey() {
      delete this.room.survey;
      await installService.assignTabletAsSensor(
        this.room,
        this.identifier,
        this.selectedSurvey.id
      );
      this.$emit("surveySet");
    },
    /**@param {'list' | 'steps'} value*/
    async changeSelectMode(value) {
      this.selectMode = value;
      if (value === "list") {
        this.isLoading = true;
        const rooms = (
          await roomService.getRooms({ site: this.room.site })
        ).map(({ display_name: name, id: value }) => ({ name, value }));
        this.roomOptions = rooms;
        this.isLoading = false;
      } else {
        this.handleInput("site", this.room.site);
      }
    },
    goBack() {
      this.selectedSurvey = null;
      const prevkey = installService.getPrevRoomKey(this.room);
      if (prevkey && this.selectMode === "steps") {
        const prevPropertyValue = this.room[prevkey];
        this.room[prevkey] = "";
        if (this.selectedSurvey) this.selectedSurvey = null;
        if (prevkey === "site") this.assignPermittedSites();
        else
          this.handleInput(
            installService.getPrevRoomKey(this.room),
            prevPropertyValue,
            true
          );
      } else if (this.selectMode === "list") {
        if (!this.room.id) {
          this.room.site = "";
          this.selectMode = "steps";
          this.assignPermittedSites();
        } else if (this.room.survey) {
          this.room.survey = "";
          this.selectedSurvey = null;
        } else this.room.id = "";
      } else this.$emit("logout");
    },
    edit(fieldName) {
      if (fieldName === "location") {
        const site = this.room.site;
        this.room = {
          site,
          building: "",
          floor: "",
          zone: "",
          gender: "",
          name: "",
        };
      }
      this.goBack();
    },
    async assignPermittedSites() {
      const sites = await installService.getPermittedSites();
      this.roomOptions = sites.map((a) => ({
        name: a.name + " " + a.company_name,
        value: a.id,
      }));
    },
  },
  async created() {
    if (!this.survey) {
      const currentRoom = await installService.getRoomByDeviceCode(
        this.identifier
      );

      if (currentRoom) {
        this.isBackButton = true;
        const {
          site,
          building,
          floor,
          zone,
          gender,
          name,
          id,
          survey_id,
        } = currentRoom;
        this.room = {
          site,
          building,
          floor,
          zone,
          gender,
          name,
          id,
          survey_id,
        };
      }
    }
    const { name, company_name } = await siteService.getSiteById(
      this.room.site
    );
    this.siteName = `${company_name} / ${name}`;
  },
  watch: {
    async user(value) {
      if (!value) return;
      else if (this.room.name) this.handleInput("survey", this.room.survey_id);
      else this.assignPermittedSites();
    },
    room: {
      deep: true,
      handler(value) {
        this.$emit("selected", this.room);
      },
    },
  },
  components: { LoginForm, SetupForm, Button, Loading },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins";
@import "@/styles/vars";

.setup-body {
  display: flex;
  justify-content: space-between;
  height: 89vh;
  background-color: white;
}

.preview-btn {
  position: relative;
  top: 130px;
  left: 220px;
  img {
    margin-inline-end: 5px;
  }
  @include rtl {
    right: 220px;
  }
}

.room-info {
  width: 80%;
  .room-item {
    margin-bottom: 10px;
  }
}

.room-info,
.survey-info {
  margin: 0 auto;
  font-size: $font-size-medium-large;
  color: #707070;

  .room-item,
  .survey-item {
    height: 40px;
    display: flex;
    .property-name {
      text-align: end;
      width: 90px;
    }
    .value {
      font-weight: $font-weight-bold;
      margin-inline-start: 15px;
      width: 50%;
    }
  }
}

.body-start {
  display: flex;
  width: 50vw;
  flex-direction: column;
  justify-content: center;
}

.body-end {
  padding-top: 9vh;
  line-height: 25px;
  margin: 0 auto;
}

.logo-container {
  @include flex-center;
  margin-bottom: 11vh;
  .buzz-logo {
    height: 14vh;
    width: 23vw;
  }
}

.form-header {
  @include survey-form-header;
  color: $black;
  img {
    margin-inline-end: 15px;
  }
}

.edit-btns-container {
  width: 300px;
  display: flex;
  justify-content: space-between;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>