<template>
  <section class="real-time-container">
    <transition name="fade" v-if="currShift">
      <div class="info-container">
        <TopInfo :currShift="currShift" />
        <button class="export-button-container" @click="openFullScreen" v-if="$route.name === 'realTime' || $route.name === 'maintenanceRealTime' || $route.name === 'taskRealTime'">
          <img :src="require('@/assets/full_screen.svg')" alt="full screen" />
        </button>
      </div>
    </transition>
    <Tabs :isRTL="isRTL" syncRoute class="tabs-container" ref="tabs">
      <Tab :label="$t('cleaning')" to="/real-time" exact>
        <template v-if="currShift">
          <Loading height="50vh" v-if="!realTimeData" />
          <RealTimeTable v-else :isMaintenance="false" :realTimeData="realTimeData" :maxSla="currShift.max_sla" @closeClick="closeRoomSla" />
        </template>
        <section v-else>No Shift Please Check If Site Is Selected</section>
      </Tab>
      <Tab :label="$t('maintenance')" to="/real-time/maintenance">
        <template v-if="currShift">
          <Loading height="50vh" v-if="!maintenanceRealTimeData" />
          <RealTimeTable
            v-else
            :isMaintenance="true"
            :realTimeData="maintenanceRealTimeData"
            :maxSla="currShift.max_sla"
            @closeClick="
              roomId => {
                closeRoomSla(roomId, true)
              }
            "
          />
        </template>
        <section v-else>No Shift Please Check If Site Is Selected</section>
      </Tab>
      <Tab :label="$t('cleandex')" to="/real-time/cleandex">
        <template v-if="currShift">
          <Loading height="50vh" v-if="!cleandexData" />
          <RealTimeTable v-else-if="cleandexData.length > 0" :realTimeData="cleandexData" :isCleandex="true" sortBy="cleandex" :maxSla="currShift.max_sla" />
          <section v-else>{{ $t('no_data_available') }}</section>
        </template>
      </Tab>
      <Tab v-if="isShowTaskTab" :label="$t('task')" to="/real-time/task">
        <template v-if="currShift">
          <Loading height="50vh" v-if="!taskRealTimeData" />
          <RealTimeTable v-else-if="taskRealTimeData.length > 0" :isTask="true" :realTimeData="taskRealTimeData" @startTask="startTask" @completeTask="completeTask" />
          <section v-else>{{ $t('no_data_available') }}</section>
        </template>
        <section v-else>No Shift Please Check If Site Is Selected</section>
      </Tab>
    </Tabs>
  </section>
</template>

<script>
import Tabs from '../../common/components/Tabs/Tabs'
import Tab from '../../common/components/Tabs/Tab'
import Loading from '../../common/components/Loading'
import TopInfo from '../components/TopInfo'
import RealTimeTable from './RealTimeTable'
import realTimeService from '../services/realTimeService'
import taskService from '@/modules/task/services/taskService.js'
import shiftService from '../../control-panel/services/shiftService'
import sensorService from '../../control-panel/services/sensorService'
import signalService from '../../../survey/services/signalService'
import swalService from '@/modules/common/services/swalService'
import siteService from '../../control-panel/services/siteService'
import { localeData } from '@/modules/common/services/localeService'
import { mapGetters } from 'vuex'

import io from 'socket.io-client/dist/socket.io'

const SOCKET_URL = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:3000'

export default {
  data() {
    return {
      realTimeData: null,
      maintenanceRealTimeData: null,
      taskRealTimeData: null,
      cleandexData: null,
      currShift: null,
      socket: null,
      siteType: null
    }
  },
  methods: {
    openFullScreen() {
      const tabsEl = this.$refs.tabs.$el
      //browser compatibility
      if (tabsEl.msRequestFullscreen) tabsEl.msRequestFullscreen()
      else if (tabsEl.mozRequestFullScreen) tabsEl.mozRequestFullScreen()
      else if (tabsEl.webkitRequestFullscreen) tabsEl.webkitRequestFullscreen()
      else tabsEl.requestFullscreen()
    },
    async closeRoomSla(roomId, isMaintenance = false) {
      const { device_code, type } = await sensorService.getSensorByRoomId(roomId)
      if (!device_code) {
        swalService.errorMsg('This room has no device code attached to it')
        return
      }
      try {
        await signalService.sendSignal(isMaintenance ? 43 : 48, device_code, false, false, type)
        const currRealtimeData = isMaintenance ? 'maintenanceRealTimeData' : 'realTimeData'
        const foundIndex = this[currRealtimeData].findIndex(room => room.id === roomId)
        this[currRealtimeData][foundIndex].isOpen = false
        this[currRealtimeData][foundIndex].complaints = []
      } catch (err) {
        console.error('failed sending signal!')
      }
    },
    async startTask(slaId) {
      try {
        const foundIndex = this.taskRealTimeData.findIndex(task => task.slaId === slaId)
        const task = this.taskRealTimeData[foundIndex]
        if (new Date(task.date) > new Date()) {
          swalService.errorMsg('You cannot start a task before its open time')
          return
        }
        if (task.isStarted) {
          swalService.errorMsg('You cannot start a task that is already started')
          return
        }
        const approval = await taskService.startTask(slaId)
        if (approval) {
          this.taskRealTimeData[foundIndex].openTime = new Date()
          this.taskRealTimeData[foundIndex].isStarted = true
        }
      } catch (err) {
        console.error(err)
      }
    },
    async completeTask(slaId) {
      try {
        const foundIndex = this.taskRealTimeData.findIndex(task => task.slaId === slaId)
        const task = this.taskRealTimeData[foundIndex]
        const { isStarted, closeTime, startTime } = task
        if (!isStarted) {
          swalService.errorMsg('You cannot complete a task before starting it')
          return
        }
        const approval = await taskService.completeTask(slaId)
        if (approval) {
          this.taskRealTimeData[foundIndex].closeTime = new Date()
          this.taskRealTimeData[foundIndex].isOpen = false
          this.taskRealTimeData[foundIndex].responseTime = new Date(closeTime) - new Date(startTime)
        }
      } catch (err) {
        console.error(err)
      }
    }
  },
  destroyed() {
    if (this.socket) this.socket.disconnect()
  },
  watch: {
    '$store.getters.filterSelected.site': {
      async handler(siteId) {
        if (!siteId) return
        const shiftPrm = shiftService.getCurrentShift(siteId)
        const sitePrm = siteService.getSiteById(siteId)
        const [shift, site] = await Promise.all([shiftPrm, sitePrm])
        this.siteType = site.type
        if (!shift) this.currShift = null
        if (shift) {
          if (this.socket) this.socket.disconnect()
          this.socket = io.connect(SOCKET_URL, {
            // withCredentials: true,
            // transports: ['websocket', 'polling']
          })
          const timeFrom = realTimeService.filterFromByShiftStartHour(shift.start_hour)
          this.socket.emit('site-id', { siteId: siteId, timeFrom: timeFrom.toUTCString() })
          this.socket.on('site-data', ({ cleanRealTimeData, maintenanceRealTimeData, cleandexData }) => {
            this.cleandexData = null
            this.cleandexData = cleandexData
            this.realTimeData = null
            this.realTimeData = cleanRealTimeData.sort((a, b) => b.isOpen - a.isOpen)
            this.maintenanceRealTimeData = maintenanceRealTimeData.sort((a, b) => b.isOpen - a.isOpen)
          })

          const prms = ['realTimeData', 'maintenanceRealTimeData', 'taskRealTimeData', 'cleandexData'].map(async data => {
            let res
            this[data] = null

            if (data === 'realTimeData') {
              res = await realTimeService.getRealTimeData(siteId, timeFrom)
              this.realTimeData = res.sort((a, b) => b.isOpen - a.isOpen)
            } else if (data === 'maintenanceRealTimeData') {
              res = await realTimeService.getRealTimeData(siteId, timeFrom, 'maintenance')
              this.maintenanceRealTimeData = res.sort((a, b) => b.isOpen - a.isOpen)
            } else if (data === 'taskRealTimeData') {
              res = await taskService.getTaskRealTimeData({ siteId, timeFrom, language: localeData.language, shiftId: shift.id })
              this.taskRealTimeData = res.sort((a, b) => b.isOpen - a.isOpen)
            } else {
              res = await realTimeService.getCleandexData(siteId)
              this.cleandexData = res
            }
          })

          this.currShift = shift
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['isRTL', 'loggedUser']),
    routeName() {
      return this.$route.name
    },
    isShowTaskTab() {
      return this.siteType === 'task' || this.siteType === 'cleandex'
    }
  },
  components: { Tabs, Tab, RealTimeTable, Loading, TopInfo }
}
</script>

<style lang="scss">
@import '@/styles/vars';
@import '@/styles/mixins';

.real-time-container {
  background-color: white;
  min-height: 100%;
  font-family: $font-family-main;

  .tabs-container:fullscreen {
    .md-content.md-theme-default {
      height: 100vh !important;
      overflow-y: scroll;
    }
  }
  .info-container {
    height: $tabs-height;
    width: 40%;
    @include flex-center;
    position: absolute;
    right: 20px;
    z-index: 11;
    @include rtl {
      left: 20px;
      right: unset;
    }
    .export-button-container {
      @include rtl {
        left: 20px;
        right: unset;
      }
    }
  }
}
</style>
