<template>
	<Modal
		width="large"
		@close="emitCloseModal"
		@submit="saveTask"
		:texts="{ headerPrimary: modalTitle }"
		:isConfirmDisabled="isConfirmDisabled"
	>
		<section class="form-container">
			<div class="task-main-info">
				<template v-for="(input, idx) in taskMultiLangsInputFields">
					<div class="input-container" :class="input.keyInTask + '-container'" :key="input.keyInTask + idx">
						<div class="input-img-wrapper">
							<img class="input-img" v-if="selectedLangFlag" :src="selectedLangFlag" />
							{{ $t(input.label) }}
						</div>
						<input
							class="modal-input"
							dir="auto"
							@keypress="isCharValid"
							@click.right.prevent
							@paste.prevent
							:class="input.keyInTask + '-input'"
							:placeholder="$t('insert_text')"
							type="text"
							v-model.trim="task[input.keyInTask][selectedLanguage]"
						/>
						<ErrorMsg :isShown="isErrorShown(input.label)" :text="`${$t('please_enter')} ${$t(input.label)}`" />
						<ErrorMsg
							:isShown="isErrorShown('english_title')"
							:text="`${$t('please_enter')} ${$t('english_title')}`"
						/>
					</div>
				</template>
				<template v-for="(input, idx) in taskInputs">
					<div class="input-container" :class="input.keyInTask + '-container'" :key="input.keyInTask + idx">
						<div class="input-img-wrapper">
							<img class="input-img" v-if="selectedLangFlag" :src="selectedLangFlag" />
							{{ $t(input.label) }}
						</div>
						<input
							class="modal-input"
							:class="input.keyInTask + '-input'"
							dir="auto"
							@keypress="isCharValid"
							@click.right.prevent
							@paste.prevent
							:placeholder="$t('insert_text')"
							type="text"
							v-model.trim="task[input.keyInTask]"
						/>
					</div>
				</template>
			</div>
			<div class="task-secondary-info">
				<div class="input-container">
					<div>{{ $t('language') }}</div>
					<Select class="select-container" v-model="selectedLanguage" :src="selectedLangFlag">
						<Option
							v-for="(lang, idx) in languages"
							:key="lang.label + idx"
							:label="$t(lang.label)"
							:value="lang.label"
							:src="lang.logo_url"
						/>
					</Select>
					<ErrorMsg :isShown="isErrorShown('invalid_language')" :text="`${$t('invalid_language')}`" />
				</div>
				<div class="input-container">
					<div>{{ $t('task_type') }}</div>
					<Select class="select-container" :placeholder="$t('select')" v-model="task.type">
						<Option v-for="(type, idx) in taskTypes" :key="type + idx" :label="$t(type)" :value="type" />
					</Select>
					<ErrorMsg :isShown="isErrorShown('task_type')" :text="`${$t('please_enter')} ${$t('task_type')}`" />
				</div>
				<div class="input-container">
					<div>{{ $t('task_category') }}</div>
					<Select
						class="select-container"
						:placeholder="$t('select')"
						v-model="task.category"
						:sideLabelStyle="sideLabelStyle"
						:disabled="!task.type"
					>
						<Option
							v-for="(category, idx) in taskCategories"
							:key="category + idx"
							:label="$t(category.name)"
							:value="category.name"
							:isSideLabel="true"
							:sideLabelStyle="getSideLabelStyle(category.color)"
						>
						</Option>
					</Select>
					<ErrorMsg
						:isShown="isErrorShown('task_category')"
						:text="`${$t('please_enter')} ${$t('task_category')}`"
					/>
				</div>
				<div class="input-container">
					<div>{{ $t('schedule') }}</div>
					<Select
						class="select-container"
						v-model="task.schedule"
						:placeholder="$t('select')"
						:disabled="!task.category"
					>
						<Option
							v-for="(schedule, idx) in schedules"
							:key="schedule.name + idx"
							:label="$t(schedule.name)"
							:value="schedule.name"
						>
						</Option>
					</Select>
					<ErrorMsg :isShown="isErrorShown('schedule')" :text="`${$t('please_enter')} ${$t('schedule')}`" />
				</div>
				<div class="input-container" v-if="isTaskTypeActive">
					<div>{{ $t('schedule_dates') }}</div>
					<Select
						class="select-container"
						@input="handleSelectedScheduleDates"
						:placeholder="$t('select')"
						:multiple="isMultipleScheduleSelectors"
						:disabled="!task.scheduleDates[0] && scheduleOptions.length === 0"
						:value="task.scheduleDates"
					>
						<Option v-for="(date, idx) in scheduleOptions" :key="date + idx" :label="$t(date)" :value="date">
						</Option>
					</Select>
					<ErrorMsg
						:isShown="isErrorShown('schedule_dates')"
						:text="`${$t('please_enter')} ${$t('schedule_dates')}`"
					/>
				</div>
				<div class="input-container" v-else>
					<div>{{ $t('send_date') }}</div>
					<v-menu v-model="menuMP" max-width="290px" min-width="290px" offset-y transition="scale-transition">
						<template v-slot:activator="{ on }">
							<v-text-field
								:value="formattedTaskSendDate"
								:label="`${$t('date')}`"
								prepend-inner-icon="mdi-calendar"
								readonly
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							no-title
							show-adjacent-months
							v-model="task.send_date"
							@input="menuMP = false"
							:show-current="formattedTaskSendDate || true"
						></v-date-picker>
					</v-menu>
					<ErrorMsg :isShown="isErrorShown('send_date')" :text="`${$t('please_enter')} ${$t('send_date')}`" />
					<ErrorMsg :isShown="isErrorShown('invalid_date')" :text="`${$t('invalid_date')}`" />
				</div>
				<div class="input-container">
					<div>{{ $t('hour') }}</div>
					<Select
						class="select-container"
						:placeholder="$t('select')"
						v-model="task.hour"
						:disabled="!task.send_date && task.scheduleDates.length === 0"
					>
						<Option v-for="(hour, idx) in hours" :key="hour + idx" :label="hour" :value="hour"> </Option>
					</Select>
					<ErrorMsg :isShown="isErrorShown('hour')" :text="`${$t('please_enter')} ${$t('hour')}`" />
				</div>
				<div class="input-container">
					<div>{{ $t('duration') }}</div>
					<Select
						class="select-container"
						:placeholder="$t('select')"
						:value="selectedDuration"
						@input="handleSelectedDuration"
					>
						<Option :label="$t('shift')" :value="''"></Option>
						<Option :label="$t('1 day')" :value="'all day'"></Option>
						<Option :label="$t('1 week')" :value="'all week'"></Option>
						<Option :label="$t('1 month')" :value="'all month'"></Option>
					</Select>
				</div>
				<div class="input-container">
					<div>{{ $t('auto_start') }}</div>
					<Select 
						class="select-container"
						:placeholder="$t('select')" 
						v-model="task.is_auto_start">
						<Option :label="$t('yes')" :value="'true'"></Option>
						<Option :label="$t('no')" :value="'false'"></Option>
					</Select>
				</div>
				<div class="input-container">
					<div>{{ $t('rooms') }}</div>
					<Select
						class="select-container"
						@input="handleSelectedRooms"
						:placeholder="$t('select')"
						:multiple="true"
						:value="selectedTaskRoomsIds"
					>
						<Option
							v-for="(room, idx) in siteRooms"
							:key="room + idx"
							:label="room.display_name"
							:value="room.id"
						>
						</Option>
					</Select>
					<ErrorMsg :isShown="isErrorShown('rooms')" :text="`${$t('please_enter')} ${$t('rooms')}`" />
				</div>
				<div class="input-container">
					<div>{{ $t('working_areas') }}</div>
					<Select class="select-container" v-model="task.selectedWorkingAreaIds" :multiple="true">
						<Option
							v-for="(workingArea, idx) in workingAreas"
							:key="workingArea.id + idx"
							:label="workingArea.area_name"
							:value="workingArea.id"
						/>
					</Select>
				</div>
				<div class="input-container">
					<div>{{ $t('sla') }}</div>
					<v-text-field v-model="task.sla" :rules="slaRules" :counter="3"></v-text-field>
				</div>
				<div class="input-container" v-if="this.isTypeSequential">
					<div>{{ $t('buffer_time') }}</div>
					<Select class="select-container" :placeholder="$t('select')" v-model="task.buffer_time">
						<Option v-for="(minute, idx) in bufferTimeMins" :key="minute + idx" :label="minute" :value="minute">
						</Option>
					</Select>
				</div>
			</div>
		</section>
	</Modal>
</template>

<script>
import Modal from '@/modules/common/components/Modal'
import ErrorMsg from '@/modules/common/components/ErrorMsg'
import Select from '@/modules/common/components/Select'
import Option from '@/modules/common/components/Option'
import { localeData } from '@/modules/common/services/localeService'
import utilService from '@/modules/common/services/utilService'
import {
	taskMultiLangsInputFields,
	taskInputs,
	taskTypes,
	taskSchedules,
	taskOtherSchedules,
	hours,
	bufferTimeMins
} from '@/modules/task/consts/consts.js'

export default {
	name: 'TaskModal',
	components: { Modal, ErrorMsg, Select, Option },
	props: {
		taskProp: { type: Object, required: true },
		languages: { type: Array, required: true },
		taskCategories: { type: Array, required: true },
		rooms: { type: Array, required: true },
		workingAreas: { type: Array, required: true }
	},
	created() {
		this.addLangsToTaskInputs()
		this.setDefaultForm()
	},
	data() {
		return {
			taskMultiLangsInputFields,
			taskInputs,
			taskTypes,
			taskSchedules,
			taskOtherSchedules,
			hours,
			bufferTimeMins,
			scheduleOptions: [],
			selectedLanguage: '',
			task: JSON.parse(JSON.stringify(this.taskProp)),
			siteRooms: JSON.parse(JSON.stringify(this.rooms)),
			selectedRooms: [],
			errors: [],
			menuMP: false,
			slaRules: [
				(v) => !!v || this.$t('please_enter') + ' ' + this.$t('sla'),
				(v) => (v && /^\d+$/.test(v)) || this.$t('sla') + ' ' + this.$t('must_be_numbers'),
				(v) => (v && v.length <= 3) || this.$t('sla') + ' ' + this.$t('must_be_3_digits')
			],
			isConfirmDisabled: false,
			selectedDuration: '',
		}
	},
	methods: {
		emitCloseModal() {
			this.$emit('closeModal')
		},
		setDefaultForm() {
			const currWebLang = localeData.language
			const { task, languages } = this
			if (languages.length > 0) {
				this.selectedLanguage = this.languages.find((lang) => lang.value === currWebLang).label
			}
			if (task.schedule) {
				this.handleSchedule(task.schedule)
			}
			this.setSelectedDuration()
		},
		checkForm() {
			const { titles, type, category, schedule, scheduleDates, send_date, hour, rooms, sla } = this.task
			this.errors = []
			if (Object.keys(titles).every((key) => !titles[key])) this.errors.push('title')
			if (!titles['english']) this.errors.push('english_title')
			if (!type) this.errors.push('task_type')
			if (!category) this.errors.push('task_category')
			if (!schedule) this.errors.push('schedule')
			if (sla) {
				if (!/^\d+$/.test(sla) || sla.length > 3) this.errors.push('sla')
			}
			if (this.isTaskTypeActive) {
				if (scheduleDates.length === 0) this.errors.push('schedule_dates')
			} else {
				const date = this.getDateWithNewHour(send_date, hour)
				if (send_date && utilService.isPastDate(date)) this.errors.push('invalid_date')
				if (!send_date) this.errors.push('send_date')
			}
			if (!hour) this.errors.push('hour')
			if (rooms.length === 0) this.errors.push('rooms')
		},
		handleSelectedScheduleDates(selectedDates) {
			if (!selectedDates) return
			if (!Array.isArray(selectedDates)) {
				this.task.scheduleDates = [selectedDates]
			} else {
				this.task.scheduleDates = selectedDates
			}
		},
		saveTask() {
			this.task.rooms = this.selectedRooms
			this.checkForm()
			if (this.errors.length) return
			this.isConfirmDisabled = true
			this.$emit('save', this.task)
		},
		setSelectedDuration() {
			const { task } = this
			let selectedDuration = ''
			if (task.duration.is_all_day) {
				selectedDuration = 'all day'
			}
			if (task.duration.is_all_week) {
				selectedDuration = 'all week'
			}
			if (task.duration.is_all_month) {
				selectedDuration = 'all month'
			}
			this.selectedDuration = selectedDuration
		},
		handleSelectedDuration(value) {
			this.task.duration.is_all_day = value === 'all day'
			this.task.duration.is_all_week = value === 'all week'
			this.task.duration.is_all_month = value === 'all month'
			this.selectedDuration = value
		},
		getSideLabelStyle(color) {
			return `border-left: 10px solid ${color}; float:left; width: initial;`
		},
		handleSchedule(value) {
			if (!value) return
			const schedules = this.getSchedules()
			const schedule = schedules.find((schedule) => schedule.name === value)
			if (!schedule) return
			const { options } = schedule
			if (options.length) {
				this.scheduleOptions = schedule.options
			}
		},
		getSchedules() {
			const { taskSchedules, taskOtherSchedules } = this
			if (this.isTaskTypeActive) {
				return taskSchedules
			}
			return taskOtherSchedules
		},
		addLangsToTaskInputs() {
			const { languages } = this
			if (languages.length) {
				languages.forEach((lang) => {
					const { label } = lang
					this.task.titles[label] = this.task.titles[label] || ''
				})
			}
		},
		isCharValid(event) {
			const { selectedLanguage } = this
			const { key } = event
			const isValid = utilService.isCharValid(selectedLanguage, key)
			if (!isValid) {
				this.addError('invalid_language')
				event.preventDefault()
			} else {
				this.removeError('invalid_language')
			}
		},
		addError(error) {
			this.errors.push(error)
		},
		removeError(error) {
			if (this.errors.includes(error)) {
				this.errors = this.errors.filter((err) => err !== error)
			}
		},
		isErrorShown(error) {
			return this.errors.includes(error)
		},
		getDateWithNewHour(date, hour) {
			const [h, m] = hour.split(':')
			const newDate = new Date(date)
			newDate.setHours(h, m, 0, 0)
			return newDate
		},
		handleSelectedRooms(roomsIds) {
			if (!roomsIds) return
			const { siteRooms } = this
			const selectedRooms = siteRooms.filter((room) => roomsIds.includes(room.id))
			this.selectedRooms = selectedRooms
		}
	},
	computed: {
		modalTitle() {
			return this.task.id ? this.$t('edit_task') : this.$t('new_task')
		},
		sideLabelStyle() {
			const { category } = this.task
			if (!category) return
			const categoryColor = this.taskCategories.find((categoryObj) => categoryObj.name === category).color
			return this.getSideLabelStyle(categoryColor)
		},
		selectedLangFlag() {
			const { languages } = this
			return languages.find((lang) => lang.label === this.selectedLanguage).logo_url || ''
		},
		isMultipleScheduleSelectors() {
			return this.isTaskTypeActive
		},
		schedules() {
			const { taskSchedules, taskOtherSchedules } = this
			if (this.isTaskTypeActive) {
				return taskSchedules
			}
			return taskOtherSchedules
		},
		taskType() {
			const {
				task: { type }
			} = this
			return type
		},
		formattedTaskSendDate() {
			const {
				task: { send_date }
			} = this
			if (!send_date) return ''
			const date = new Date(send_date).toLocaleString().split(',')[0]
			const [month, day, year] = date.split('/')
			return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
		},
		selectedTaskRoomsIds() {
			const {
				task: { rooms }
			} = this
			if (!rooms || rooms.length === 0) return []
			return rooms.map((room) => room.id)
		},
		isTypeSequential() {
			return this.taskType === 'sequential'
		},
		isTypeSchedule() {
			return this.taskType === 'schedule'
		},
		isTaskTypeActive() {
			return this.isTypeSchedule || this.isTypeSequential
		},
		
	},
	watch: {
		'task.type': function() {
			this.task.schedule = ''
			this.task.buffer_time = 15
		},
		'task.schedule': function(newValue) {
			if (this.scheduleOptions.length > 0) {
				this.scheduleOptions = []
			}
			this.task.scheduleDates = []
			this.task.send_date = null
			this.handleSchedule(newValue)
		},
		'task.scheduleDates': function() {
			if (
				this.isTaskTypeActive && this.task.scheduleDates.length === 0) {
				this.task.hour = ''
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins';
@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons';
.form-container {
	.task-main-info,
	.task-secondary-info {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}
}

.input-container {
	margin-bottom: 15px;
	input {
		@include modal-input;
		padding: 0 10px;
		width: 10vw;
		&.description-input {
			width: 80%;
		}
	}

	div {
		@include modal-input-label;
		margin-bottom: 0;
		padding: 5px;
	}

	&.description-container {
		flex-grow: 1;
	}
}

.input-img-wrapper {
	display: flex;
	.input-img {
		width: 20px;
		margin-right: 5px;
		@include rtl {
			margin-left: 5px;
		}
	}
}

.md-field.select-container {
	width: 100% !important;
}

.select-container {
	border-bottom: 1px solid $grey-border-color;
}

.error-msg {
	@include error-msg;
}
.md-menu {
	z-index: 99;
}

</style>
