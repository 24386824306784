<template>
  <section :class="['table-container', stickyTableContainerStyle]">
    <div class="table-head table-row" :class="[isDrilldownMode ? 'narrowed' : '', stickyTableStyle]">
      <div
        class="table-cell"
        :class="field === sortBy ? 'picked' : ''"
        v-for="field in analyticsTableInfo"
        :key="field"
        @click="changeSort(field)"
      >
        {{ $t(field) }}
      </div>
    </div>
    <div class="table-body-container">
      <div
        class="table-row"
        :class="isDrilldownMode ? 'narrowed' : ''"
        v-for="(room, index) in rooms"
        :key="index"
        @click="handleTableClick(room._id)"
      >
        <div
          v-for="field in analyticsTableInfo"
          :key="field"
          class="table-cell"
          :class="{
            'station-name': field === 'displayName',
            'score-container': field === 'score',
          }"
        >
          <template v-if="field === 'score'">
              <span class="score">{{ (room.score && room.score.toFixed(0)) || 'N/A' }}</span>
              <span class="status-color-bar" :style="room.score | statusColor" />
          </template>
          <template v-else-if="field === 'avgResponseTime'">{{ room.avgResponseTime | timePassed }}</template>
          <template v-else-if="field === 'exposures'">{{ room[field] ? room[field].toFixed() + '%' : '' }}</template>
          <template v-else-if="field === 'visits'">
            {{ room[field] }} <span v-if="room.isAmended && isAmendedPermitted" class="amended-room">*</span>
          </template>
          <template v-else-if="field === 'ratio'">
            {{ getRoomRatio(room) }}
          </template>
          <template v-else>{{ room[field] || 0 }}</template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { analyticsTableInfo, analyticsTableInfoNarrowed } from '../consts/consts'
import { mapGetters } from 'vuex'

export default {
  name: 'roomTable',
  props: {
    rooms: { type: Array, required: true },
    hideCols: { type: Array, required: false },
    isExporting: { type: Boolean, required: false, default: false},
  },
  data() {
    return {
      sortBy: '',
      isAsc: false,
    }
  },
  mounted() {
    this.$emit('mounted')
  },
  computed: {
    ...mapGetters(['loggedUser']),
    isDrilldownMode() {
      const filter = this.$store.getters.filterSelected
      if (filter.hour || filter.servikName) return true
      return false
    },
    analyticsTableInfo() {
      const allCleandexCalls = this.rooms.every((room) => room.cleandexCalls === 0)
      const allManualCalls = this.rooms.every((room) => room.manualCalls === 0)
      if (this.isDrilldownMode) return analyticsTableInfoNarrowed.head
      const tableColsToHide = []
      if (allCleandexCalls) tableColsToHide.push('cleandexCalls')
      if (allManualCalls) tableColsToHide.push('manualCalls')
      if (this.hideCols.length > 0) return analyticsTableInfo.head.filter((col) => !tableColsToHide.includes(col) && !this.hideCols.includes(col))
      return analyticsTableInfo.head.filter((col) => !tableColsToHide.includes(col))
    },
    isAmendedPermitted() {
      const { level } = this.loggedUser
      return level === 'buzzz'
    },
    stickyTableStyle() {
      if (this.isExporting) {
        return ''
      } else {
        return 'sticky-table-head'
      }
    },
    stickyTableContainerStyle() {
      if (this.isExporting) { 
        return ''
      } else {
        return 'sticky-table-container'
      }
    },
  },
  methods: {
    handleTableClick(id) {
      this.$emit('click', id)
    },
    getRoomRatio(room) {
      if (!room.arrivals) return 'N/A'
      return (room.visits / room.arrivals).toFixed(0) || 'N/A'
    },
    changeSort(sortBy) {
      if (this.sortBy === sortBy) {
        this.isAsc = !this.isAsc
      } else {
        this.sortBy = sortBy
        this.isAsc = true
      }
      sortBy = this.sortBy
      const isAsc = this.isAsc

      if (sortBy === 'room_name') sortBy = 'displayName'
      else if (sortBy === 'avg_response_time') sortBy = 'avgResponseTime'
      else if (sortBy === 'missed_sla') sortBy = 'missedSla'
      else if (sortBy === 'inspections') sortBy = 'supervisors'

      this.rooms.sort((a, b) => {
        if (sortBy === 'displayName') {
          if (isAsc) return a[sortBy] > b[sortBy] ? -1 : 1
          else return a[sortBy] > b[sortBy] ? 1 : -1
        } else if (sortBy === 'ratio') {
          if (isAsc) return this.getRoomRatio(a) - this.getRoomRatio(b)
          else return this.getRoomRatio(b) - this.getRoomRatio(a)
        } else {
          if (isAsc) return b[sortBy] - a[sortBy]
          else return a[sortBy] - b[sortBy]
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars';
@import '@/styles/mixins';

.amended-room {
  color: blue;
  font-size: large;
  display: flex;
  padding-block-start: 10px;
}

.table-container {
  width: 100%;
  margin-inline-start: 0px;
  &.sticky-table-container {
    overflow: auto;
    max-height: 80vh;
  }

  .table-row {
    display: grid;
    grid-template-columns: minmax(100px, 2fr) repeat(auto-fit, minmax(100px, 0.8fr)) minmax(20px, 0.1fr);
    border-bottom: 1px solid #f3f3f3;
    height: 48px;
    transition: 0.4s;

    &.narrowed {
      grid-template-columns: 2fr 1fr 3fr;
    }

    &:hover {
      background-color: #ebebeb;
    }

    &.table-head:hover {
      background-color: #fff;
    }

    .table-cell {
      cursor: pointer;
      display: flex;
      justify-content: space-around;
      align-items: center;
      letter-spacing: -0.17px;
      color: #32355a;

      &.score-container {
        display: grid;
        grid-template-columns: 70% 30%;

        .status-color-bar {
          display: inline-block;
          margin: auto;
          width: 95%;
          height: 95%;
          justify-self: end;
        }

        .score {
          margin-inline-start: 55%;
          display: flex;
          align-items: center;
          font-weight: bold;
        }
      }
    }

    .table-cell:has(span.amended-room) {
      justify-content: center;
      gap: 5px;
    }

    .table-cell.station-name {
      justify-content: start;
      font: Bold 15px/17px Assistant;
    }

    &.table-head {
      .table-cell {
        // text-transform: capitalize;
        font: Medium 13px/14px Assistant;
        letter-spacing: -0.14px;
        color: #7f7f7f;

        &.picked {
          font: Bold 15px/17px Assistant;
          color: rgb(83, 83, 83);
        }
      }

      .table-cell:first-child {
        justify-content: start;
      }
    }
  }
  .sticky-table-head {
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: white;
  }
}
</style>
