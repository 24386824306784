<template>
  <section class="analytics-container">
    <section class="info-bar" v-if="$route.name === 'analyses' || $route.name === 'analytics'">
      <BarItem
        v-for="(item, index) in infoBarItems"
        :key="item.label"
        :isAmended="item.isAmended"
        :trendInfo="item.trendInfo"
        componentType="info"
        :text="item.text"
        :label="$t(item.label)"
        :iconUrl="item.iconUrl"
        :class="{ last: index === infoBarItems.length - 1, first: index === 0 }"
      />
    </section>
    <Loading v-if="isLoading" height="55vh" />
    <template v-else>
      <template v-if="$route.name === 'analyses' || $route.name === 'analytics'">
        <AnalyticsChartTable @chartClick="handleChartClick" v-if="analyticsData.charts" :data="analyticsData.charts" />
        <TableByHour
          @hourClick="handleHourClick"
          v-if="analyticsData.hourData && analyticsData.hourData.length"
          :dataByHour="analyticsData.hourData"
        />
      </template>
      <section
        v-if="$route.name === 'detailed' || $route.name === 'analytics'"
        :class="{ 'station-table': $route.name === 'analytics' }"
      >
        <RoomTable
          v-if="analyticsData.roomsData"
          :hideCols="hideInfo"
          :rooms="analyticsData.roomsData"
          @click="handleTableClick"
        />
      </section>
    </template>
    <IframeCMP
      :infoBarItems="infoBarItems"
      :analyticsData="analyticsData.charts"
      :hourData="analyticsData.hourData"
      :hideInfo="hideInfo"
      :roomsData="analyticsData.roomsData"
      v-if="isPrinting"
      @donePrinting="$emit('donePrinting')"
      class="pdf-print-iframe"
    />
  </section>
</template>

<script>
import IframeCMP from './IframeCMP'
import BarItem from './BarItem'
import { infoBarItems } from '../consts/consts'
import { mapGetters } from 'vuex'
import { getOverviewSums, getAnalyticsSums, getRoomsCounterCallsSum, getRoomsCleandexCallsSum, getRoomsManualCallsSum } from '../services/performanceService'
import csvService from '../../common/services/csvService'
import performanceService from '../services/performanceService'
import utilService from '../../common/services/utilService'
import AnalyticsChartTable from './AnalyticsChartTable'
import RoomTable from './RoomTable'
import TableByHour from './TableByHour'
import Loading from '@/modules/common/components/Loading'
import { trendArrowColors } from '../consts/consts'

export default {
  name: 'analytics',
  props: {
    hideInfo: { default: () => [], type: Array, required: false },
    isPrinting: { type: Boolean, required: false },
  },
  data() {
    return {
      infoBarItems: JSON.parse(JSON.stringify(infoBarItems)),
      analyticsData: {},
      isLoading: false,
    }
  },
  created() {
    this.updatePageData()
    const analyticsDataLen = Object.keys(this.analyticsData).length
    if (analyticsDataLen > 0) {
      this.$store.watch(
        (state) => this.overviewData,
        (newValue) => {
          this.updatePageData()
        },
        { deep: true }
      )
      this.$store.watch(
        (state) => this.$store.getters.analyticsData,
        (newValue) => {
          this.updatePageData()
        },
        { deep: true }
      )
    }
  },
  methods: {
    async updateStoreData() {
      this.$store.commit({ type: 'toggleLoading', value: true })
      await Promise.all([
        this.$store.dispatch({ type: 'getOverviewData', filter: this.filterSelected }),
        this.$store.dispatch({ type: 'getAnalyticsData', filter: this.filterSelected }),
      ])
      this.$store.commit({ type: 'toggleLoading', value: false })
    },
    handleHourClick(hour) {
      const dateToCalculateUTCHour = new Date()
      dateToCalculateUTCHour.setHours(+hour)
      this.$store.commit({ type: 'updateFilter', field: 'hour', value: hour })
      this.hideAdditionalFilter()
      this.updateStoreData()
    },
    handleChartClick(servikFilter) {
      this.$store.commit({
        type: 'updateFilter',
        field: 'servikName',
        value: servikFilter.label,
      })
      this.hideAdditionalFilter()
      this.updateStoreData()
    },
    updatePageData() {
      const data = JSON.parse(JSON.stringify(this.$store.getters.analyticsData))
      if (!data) return
      for (const key in data.charts) {
        if (!data.charts[key].data || !data.charts[key].labels) continue
        const sorted = utilService.sortParallelArrays(data.charts[key].data, data.charts[key].labels)
        data.charts[key].data = sorted.map((a) => a.num)
        data.charts[key].labels = sorted.map((a) => a.str)
      }
      this.refreshData(data)
      const res = utilService.sortParallelArrays(
        this.analyticsData.charts.cleaning.data,
        this.analyticsData.charts.cleaning.labels
      )
    },
    refreshData(data) {
      this.analyticsData = data
      //assign bar items data
      if (!this.infoBarItems[0]) return
      this.infoBarItems[0].text = this.calcAvgScore(this.analyticsData.roomsData)
      const overviewSums = getOverviewSums(this.overviewData)

      const analyticsSums = getAnalyticsSums(this.analyticsData.hourData)
      analyticsSums.counterCalls = getRoomsCounterCallsSum(this.analyticsData.roomsData)
      analyticsSums.cleandexCalls = getRoomsCleandexCallsSum(this.analyticsData.roomsData)
      analyticsSums.manualCalls = getRoomsManualCallsSum(this.analyticsData.roomsData)

      const trendInfo = performanceService.trendInfoForBarItems(this.overviewData)

      // to hide excluded info column & bar item
      if (this.hideInfo.length > 0)
        this.infoBarItems = this.infoBarItems.filter((col) => this.hideInfo.indexOf(col.name) === -1)
      this.infoBarItems = this.infoBarItems.reduce((acc, item) => {
        if (analyticsSums[item.name] || overviewSums[item.name]) {
          item.trendInfo = trendInfo[item.name]
          item.trendInfo ? (item.trendInfo.color = trendArrowColors[item.name][item.trendInfo.upDown]) : item.trendInfo

          if (item.name === 'visits') {
            item.isAmended = analyticsSums.isAmended
          }

          if (item.name === 'avg_score' || item.name === 'response_time_SLA') {
            item.text = utilService.kMinutesToHours(analyticsSums[item.name] || overviewSums[item.name])
          } else if (item.name === 'exposures') {
            item.text = overviewSums[item.name] + '%'
          } else {
            item.text = utilService.kFormatter(analyticsSums[item.name] || overviewSums[item.name])
          }

          if (item.name === 'response_time_SLA') {
            item.text += ` ${this.$t('min')}`
          }
          acc.push(item)
        }
        return acc
      }, [])
      const barItemsInfo = csvService.getBarItemsInfoForCsv(this.infoBarItems)
      this.$store.commit('setBarItemsData', barItemsInfo)
    },
    handleTableClick(roomId) {
      this.$router.push({ name: 'roomAnalyticsLog', params: { roomId } })
    },
    calcAvgScore(rooms) {
      if (!rooms) return console.warn('No rooms data')
      const totalScore = rooms.reduce((acc, room) => (acc += room.score), 0)
      let avgScore = totalScore / rooms.length
      return +avgScore.toFixed(0) === +avgScore.toFixed(1) ? avgScore.toFixed(0) : avgScore.toFixed(1)
    },
    async resetFilterAndGetData() {
      const { hour, servikName } = this.filterSelected
      this.$store.commit({ type: 'updateFilter', field: 'hour', value: hour })
      this.$store.commit({
        type: 'updateFilter',
        field: 'servikName',
        value: servikName,
      })

      this.$store.commit({ type: 'toggleLoading', value: true })
      const analyticsPrm = this.$store.dispatch({
        type: 'getAnalyticsData',
        filter: this.filterSelected,
      })
      const overviewPrm = this.$store.dispatch({
        type: 'getOverviewData',
        filter: this.filterSelected,
      })
      await Promise.all([analyticsPrm, overviewPrm])
      this.$store.commit({ type: 'toggleLoading', value: false })
    },
    resetFilter() {
      this.$store.commit({ type: 'updateFilter', field: 'hour', value: null })
      this.$store.commit({
        type: 'updateFilter',
        field: 'servikName',
        value: null,
      })
      this.updateStoreData()
    },
    hideAdditionalFilter() {
      this.$store.commit({ type: 'toggleAdditionalFilter', value: false })
    },
  },
  computed: mapGetters(['overviewData', 'isRTL', 'filterSelected']),
  destroyed() {
    if (this.$route.path.includes('dashboard')) return
    this.resetFilter()
  },
  components: {
    BarItem,
    AnalyticsChartTable,
    RoomTable,
    Loading,
    TableByHour,
    IframeCMP,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
@import '@/styles/vars.scss';

.info-bar {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3.1vh;
}

.station-table {
  font-size: $font-size-smaller;
  margin-top: 3vh;
  @media print {
    margin-top: 50px;
  }
}

.pdf-print-iframe {
  height: 0px;
  overflow: hidden;
}
</style>
