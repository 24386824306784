<template>
  <section class="chart-filter-panel">
    <div class="button-label-start">
      <template v-for="(button, index) in buttons.slice(0,4)">
        <BarItem
          v-if="!(button.name === 'time_saved' && !$store.getters.currentFilteringSite.isTimeSave)"
          :key="button.label"
          :isAmended="button.isAmended"
          :trendInfo="button.trendInfo"
          :activeIconUrl="button.activeIconUrl"
          :activeColor="button.color"
          :iconUrl="button.iconUrl"
          :activeStatus="button.isActive"
          :class="`${index === 3? 'last' : null} ${index === 0 ? 'first' : null}`"
          :label="$t(button.name)"
          :text="button.text"
          componentType="chart-filter-button"
          @click="handleFilterClick(index, $event)"
        />
      </template>
    </div>
    <div class="seperator"></div>
    <div class="button-label-end">
      <template v-for="(button, index) in buttons.slice(4)">
        <BarItem
          v-if="!(button.name === 'time_saved' && !$store.getters.currentFilteringSite.isTimeSave)"
          :key="button.label"
          :trendInfo="button.trendInfo"
          :activeIconUrl="button.activeIconUrl"
          :activeColor="button.color"
          :iconUrl="button.iconUrl"
          :activeStatus="button.isActive"
          :class="`${index === 2? 'last' : null} ${index === 0 ? 'first' : null}`"
          :label="$t(button.name)"
          :text="button.text"
          componentType="chart-filter-button"
          @click="handleFilterClick(index + 4, $event)"
        />
      </template>
    </div>
  </section>
</template>

<script>
import BarItem from "./BarItem";

export default {
  props: {
    /**@type {{ new(): {
     * color: String; 
     * iconUrl: String; 
     * isActive: Boolean; 
     * label: String; 
     * name: String; 
     * text: String; 
     * activeIconUrl?: String;
     * }[] }} */
    buttons: { type: Array, required: true }
  },
  methods: {
    handleFilterClick(index) {
      this.$emit("input", index);
    }
  },
  components: {
    BarItem
  }
};
</script>

<style lang="scss" scoped>
.chart-filter-panel {
  display: flex;
  justify-content: space-between;
  .button-label-start {
    display: flex;
  }
  .button-label-end {
    display: flex;
  }
}
</style>