<template>
	<section class="free-text-section">
		<div class="text-management-bar" v-if="isEdit">
			<img class="icon" @click="decreaseFontSize" :src="require('@/assets/survey/font-size-decrease-icon.svg')" />
			<img class="icon" @click="increaseFontSize" :src="require('@/assets/survey/font-size-increase-icon.svg')" />
			<img class="icon" @click="alignText('left')" :src="require('@/assets/survey/align-left.svg')" />
			<img class="icon" @click="alignText('center')" :src="require('@/assets/survey/align-center.svg')" />
			<img class="icon" @click="alignText('right')" :src="require('@/assets/survey/align-right.svg')" />
			<label>
				<input type="checkbox" @change="updatePageOpenText" v-model="openText.isChecklist" />
				<img class="icon" @change="updatePageOpenText" :src="require('@/assets/survey/checklist-icon.svg')" />
			</label>
		</div>
		<template v-if="isEdit && !isChecklist">
			<textarea
				class="free-text-editor"
				:style="openTextStyles"
				v-model="openText.text"
				:placeholder="openText.text || 'Enter Text Here'"
				dir="auto"
			/>
		</template>
		<div v-if="!isEdit && !isChecklist" class="free-text" :style="openTextStyles">{{ openText.text }}</div>
		<template v-else-if="isChecklist">
			<ul class="checklist">
				<li v-for="(item, index) in openText.text.split('\n')" :key="index" :style="openTextStyles">
					<template v-if="item.length">
						<template v-if="openText.align === 'right'">
							<span class="free-text">{{ item }}</span>
							<input type="checkbox" class="checkbox" />
						</template>
						<template v-else>
							<input type="checkbox" class="checkbox" />
							<span class="free-text">{{ item }}</span>
						</template>
					</template>
					<template v-else>
						<span>&nbsp;</span>
					</template>
				</li>
			</ul>
		</template>
	</section>
</template>

<script>
export default {
	name: 'OpenTextInput',
	props: {
		openTextProp: {
			type: Object,
			required: true,
			default: () => ({})
		},
		isEdit: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			openText: JSON.parse(JSON.stringify(this.openTextProp))
		}
	},
	methods: {
		updatePageOpenText() {
			this.$emit('updatePageOpenText', this.openText)
		},
		alignText(align) {
			this.openText.align = align
			this.updatePageOpenText()
		},
		increaseFontSize() {
			if (this.openText.fontSize > 25) return
			this.openText.fontSize += 5
			this.updatePageOpenText()
		},
		decreaseFontSize() {
			if (this.openText.fontSize < 10) return
			this.openText.fontSize -= 5
			this.updatePageOpenText()
		}
	},
	computed: {
		openTextStyles() {
			return {
				'text-align': this.openText.align,
				'font-size': this.openText.fontSize + 'px'
			}
		},
		isChecklist() {
			return this.openText.isChecklist
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars';
@import '@/styles/mixins';

.free-text-section {
	display: flex;
	border-radius: 10px;
	flex-direction: column;
	gap: 30px;
	width: 60vw;
	.text-management-bar {
		@include flex-center;
		border-radius: 10px;
		border: 2px solid $grey-border-color;
		& > * {
			margin: 0 10px;
		}
		.icon {
			width: 25px;
			height: 25px;
			cursor: pointer;
		}
	}
	.free-text-editor {
		white-space: pre-wrap;
		text-align: center;
		width: 100%;
		height: 100%;
		border-radius: 10px;
		font-size: 1.5rem;
		padding: 1rem;
		border: 1px solid $grey-border-color;
	}
	.free-text {
		white-space: pre-wrap;
	}
}

.checkbox {
	width: 18px;
	height: 18px;
	margin: 5px;
	margin-bottom: 15px;
}
</style>
