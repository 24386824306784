import ajax from '../../common/services/httpService'
import { default_survey } from '@/modules/surveys/consts/consts'
export { getCompanies, getCompanyById, saveCompany, removeCompany, getDefaultCompany, checkCompanyEmail, checkCompanyPhone }
const BASE_URL = 'company'

/**@param {String | Number} id*/
async function getCompanyById(id) {
  const company = await ajax.get(`${BASE_URL}/${id}`)
  return company
}

async function getCompanies(filter) {
  const res = await ajax.get(BASE_URL, null, filter)
  const companiesRestructured = res.map((company) => {
    const companyCopy = { ...company }
    delete companyCopy.name
    delete companyCopy.site_count
    return {
      name: company.name,
      site_count: company.site_count,
      ...companyCopy,
    }
  })
  return companiesRestructured
}

async function saveCompany(company) {
  if (!company.id) {
    company.survey = JSON.parse(JSON.stringify(default_survey))
  }
  if (company.id) {
    delete company.isDefaultShifts
    return await ajax.put('company', company)
  } else return await ajax.post('company', company)
}

async function removeCompany(id) {
  const res = await ajax.delete(BASE_URL, { id })
  return res
}

function getDefaultCompany() {
  return {
    name: '',
    default_name: '',
    type: 'client',
    email: '',
    phone: '',
    country: '',
    state: '',
    city: '',
    address: '',
    franchise_id: '',
  }
}

async function checkCompanyEmail(email) {
  const res = await ajax.get(`${BASE_URL}/is-email-valid`, null, { email })
  return res
}

async function checkCompanyPhone(phone) {
  const res = await ajax.get(`${BASE_URL}/is-phone-valid`, null, { phone })
  return res
}
