<template>
  <section :class="{ 'suggestion-item-container': true }" v-if="issue.length">
    <header>
      <span class="current-issue-number">{{ currIndexOfIssue + 1 }}</span>
      .{{ $t(title) }}
    </header>
    <!-- <div class="percentage">
      <span>{{
        (+issue[currIndexOfIssue].complaintPercentage).toFixed(0)
      }}</span
      >%
    </div> -->
    <section class="info">
      <img
        v-if="issue[currIndexOfIssue - 1]"
        :src="require('@/assets/suggestion/blue_side_arrow.svg')"
        @click="sideArrowClicked('prev')"
        class="blue-arrow"
        alt="arrow"
      />
      <div class="icon">
        <img class="issue-icon" v-if="issueIcon" :src="issueIcon" />
      </div>
      <div class="title">{{ $t("issue") }}</div>
      <div
        :class="{
          text: true,
          active: issue[currIndexOfIssue].type === 'complaint',
        }"
      >
        {{
          issue[currIndexOfIssue].localServikName
            ? issue[currIndexOfIssue].localServikName
            : issue[currIndexOfIssue].servikName
        }}
      </div>

      <div class="title">{{ $t("room") }}</div>
      <div
        :class="{ text: true, active: issue[currIndexOfIssue].type === 'room' }"
      >
        {{ issue[currIndexOfIssue].displayName }}
      </div>

      <div class="title">{{ $t("time") }}</div>
      <div
        :class="{ text: true, active: issue[currIndexOfIssue].type === 'hour' }"
      >
        {{ issue[currIndexOfIssue].hour | numberToHour }}
      </div>
      <img
        v-if="issue[currIndexOfIssue + 1]"
        :src="require('@/assets/suggestion/blue_side_arrow.svg')"
        @click="sideArrowClicked('next')"
        class="blue-arrow"
        alt="arrow"
      />
    </section>
  </section>
  <section class="suggestion-item-container" v-else>
    <header>{{ title }}</header>
    <NoData height="100px" fontSize="1.2rem" class="no-data" />
  </section>
</template>

<script>
import NoData from '@/modules/common/components/NoData';

export default {
  props: {
    title: { type: String, required: true },
    issue: { type: Array, required: true },
  },
  data() {
    return {
      currIndexOfIssue: 0,
    }
  },
  methods: {
    /**@param {'next' | 'prev'} action */
    sideArrowClicked(action) {
      if (action === 'next' && this.currIndexOfIssue < this.issue.length - 1) this.currIndexOfIssue += 1;
      else if (action === 'prev' && this.currIndexOfIssue > 0) this.currIndexOfIssue -= 1;
    }
  },
  computed: {
    issueIcon() {
      const { servikName, iconUrl } = this.issue[this.currIndexOfIssue];
      const urlIssueName = servikName.toLowerCase().split(" ").join("_");
      let icon;
      try {
        if (iconUrl) icon = iconUrl;
        else icon = require(`@/assets/servik_icons/${urlIssueName}.svg`);
      } catch (err) {
        icon = require("@/assets/servik_icons/missing_toilet_paper.svg");
      }
      return icon;
    }
  },
  components: { NoData }
}
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";
@import "@/styles/mixins.scss";

$table-border-color: #e9e9e9;
$suggestion-blue: #51acf2;

.suggestion-item-container {
  padding: 10px;
  margin-inline-end: 15px;
  margin-block-end: 15px;
  @include shadow-border;

  header {
    color: #7f7f7f;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-inline-start: 30px;
    // text-transform: capitalize;
    .current-issue-number {
      color: $suggestion-blue;
      font-weight: $font-weight-bold;
    }
  }

  .active {
    font-weight: $font-weight-bold;
    color: $suggestion-blue;
  }

  .info {
    display: grid;
    height: 20vh;
    grid-column-gap: 15px;
    margin-inline-start: 15px;
    padding-inline-end: 15px;
    grid-template-columns: 5% 25% 10% auto 5%;
    grid-template-rows: 1fr 1fr 1fr;

    .blue-arrow {
      grid-row: 2/2;
      cursor: pointer;
      &:first-child {
        transform: rotate(180deg);
        @include rtl {
          transform: unset;
        }
      }
      &:last-child {
        @include rtl {
          transform: rotate(180deg);
        }
        grid-column: 5/5;
      }
    }
  }

  .percentage {
    height: 35px;
    display: flex;
    align-items: center;
    margin-inline-start: 15px;
    span {
      font-weight: $font-weight-bold;
      font-size: $font-size-large;
    }
    font-size: $font-size-medium-large;
  }

  .icon {
    grid-column: 2/2;
    grid-row: 2/4;
    align-items: center;
    img {
      margin: 10px;
    }
  }

  .title {
    grid-column: 3/3;
    font-size: $font-size-small;
    // text-transform: capitalize;
    @include align-center;
  }

  .text {
    @include align-center;
  }
}

@media print {
  .suggestion-item-container {
    height: 200px;
    .info {
      font-size: 20px;
    }
    header {
      height: 52px;
      font-size: 18px;
    }
  }
}
</style>