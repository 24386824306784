<template>
  <section class="frenchise">
    <AdminHeader
      @add="openModal"
      :texts="{title: $t('franchises'), add: $t('add franchise')}"
      @input="handleInput"
      :isSearchShown="false"
    >
      <TableSearch :tableTitles="tableTitles" @input="handleInput" />
    </AdminHeader>
    <Table
      v-if="franchiseToShow.length"
      class="table"
      :tableData="franchiseToShow"
      :editOptions="{ remove: true, edit: true }"
      :isHidingId="false"
      idField="id"
      @editRow="openModal"
      @removeRow="removeRow"
    />

    <Modal
      v-if="currFranchise"
      @submit="saveFranchise"
      @close="closeModal"
      width="medium"
      :texts="{headerPrimary: currFranchise.name ? $t('edit_franchise') : $t('new_franchise')}"
    >
      <template v-slot:default>
        <div class="modal-body">
          <label>
            {{$t('franchise_name')}}
            <Input class="input" v-model="currFranchise.name" :style="{ marginBottom: 0 }" />
          </label>
          <label>
            {{$t('country')}}
            <Input class="input" v-model="currFranchise.country" :style="{ marginBottom: 0 }" />
          </label>
          <label>
            {{$t('email')}}
            <Input class="input" v-model="currFranchise.email" :style="{ marginBottom: 0 }" />
          </label>
          <label>
            {{$t('phone')}}
            <Input class="input" v-model="currFranchise.phone" :style="{ marginBottom: 0 }" />
          </label>
          <label>
            {{$t('address')}}
            <Input class="input" v-model="currFranchise.address" :style="{ marginBottom: 0 }" />
          </label>
          <label>
            {{$t('region')}}
            <Input class="input" v-model="currFranchise.region" />
          </label>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import swalService from "@/modules/common/services/swalService";
import util from '../../common/services/utilService';
import Input from "@/modules/common/components/Input";
import Modal from "@/modules/common/components/Modal";
import Table from "@/modules/common/components/customTable/Table";
import TableSearch from "@/modules/common/components/customTable/TableSearch";
import AdminHeader from "../components/AdminHeader";
import { getFranchises, saveFranchise, removeFranchise } from "../services/franchiseService";
import { countries } from '@/modules/common/consts/consts'

export default {
  created() {
    this.loadPage();
  },
  data() {
    return {
      currFranchise: null,
      franchises: [],
      countryList: null,
      tableTitles: null,
      searchTxt: '',
      searchBy: ''
    };
  },
  computed: {
    franchiseToShow() {
      const { searchTxt, searchBy } = this;
      return util.filterArrayOfObjectsWithString(this.franchises, searchTxt, searchBy)
    }
  },
  methods: {
    closeModal() {
      this.currFranchise = null;
    },
    async openModal(franchise) {
      if (franchise) this.currFranchise = franchise;
      else {
        this.countryList = countries
        this.currFranchise = {
          name: "",
          region: "",
          phone: '',
          email: '',
          country: '',
          address: ''
        };
      }
    },
    async removeRow(id) {
      const result = await swalService.confirmMsg();

      if (!result.value) return;
      try {
        await removeFranchise(id);
        swalService.deletedMsg();
      } catch (err) {
        swalService.errorMsg();
      }
      this.loadPage();
    },
    async saveFranchise() {
      const { currFranchise } = this;
      try {
        await saveFranchise(currFranchise);
        swalService.savedMsg(this.$t('Your work has been saved'))
      } catch (err) {
        swalService.errorMsg();
      }
      this.loadPage();
      this.closeModal();
    },
    async loadPage() {
      const franchises = await getFranchises();
      this.franchises = franchises
        .map(f => {
          const franchise = { ...f }
          delete franchise.logo
          return franchise
        });
      this.setTableTitles()
    },
    setTableTitles() {
      this.tableTitles = Object.keys(this.franchises[0]).filter(key => key !== "id")
    },
    handleInput(value, searchBy) {
      if (searchBy) this.searchBy = searchBy
      this.searchTxt = value
    }
  },
  components: {
    Modal,
    Input,
    Table,
    AdminHeader,
    TableSearch
  }
};
</script>

<style lang="scss" scoped>
.input {
  border: 1px solid gray;
  width: 24vw;
}
.modal-body {
  @import "@/styles/vars.scss";
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 30px;
  label {
    &.email {
      grid-column: 2/4;
    }
    &.full-row {
      grid-column: 1/4;
    }
    // text-transform: capitalize;
    & > * {
      border: 1px solid $grey-border-color;
      border-radius: 4px;
      padding: 4px;
    }
  }
}
</style>