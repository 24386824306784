import ajax from '../../modules/common/services/httpService';
const BASE_URL = 'installation';

/**@typedef  {{
 * site_id: Number | String;
 * building?: String;
 * floor?: String;
 * zone?: String;
 * gender?:String;
 * name?:String;
 * }} RoomDetails*/

export default {
    getPermittedSites,
    getNextOptions,
    getSurveyById,
    assignTabletAsSensor,
    getSurveyByDeviceCode,
    getSurveyWithRoomDetails,
    getCurrSelectingKey,
    getPrevRoomKey,
    getRoomByDeviceCode,
    getRoomById
};

/**
 * @typedef {'site' | 'building' | 'floor' | 'zone' | 'gender' | 'name' | 'survey'} RoomKey
 * @typedef {{ 
 * site: String;
 * building: String;
 * floor: String;
 * zone: String;
 * gender: String;
 * name: String;
 * id:Number | String;
 * survey?: Number;
 * }} Room
 * @param {Room} room
 * @returns {RoomKey}*/
function getCurrSelectingKey(room) {
    const { site, building, floor, zone, gender, name, id } = room;
    /**@type {RoomKey} */
    let currKey;
    if (!site) currKey = 'site';
    else if (!building) currKey = 'building';
    else if (!floor) currKey = 'floor';
    else if (!zone) currKey = 'zone';
    else if (!gender) currKey = 'gender';
    else if (!name) currKey = 'name';
    else currKey = 'survey';
    if (id) currKey = 'survey';
    return currKey;
}

/** @param {Room} room */
function getPrevRoomKey(room) {
    const keys = Object.keys(room);
    const currKey = getCurrSelectingKey(room);
    const index = keys.findIndex(a => a === currKey);
    let prevKey = '';
    if (currKey === 'survey' && !room.survey) prevKey = 'name';
    else if (currKey === 'survey' && room.survey) prevKey = 'survey';
    else prevKey = keys[index - 1];
    return prevKey;
}

/**@param {String} deviceCode */
async function getSurveyByDeviceCode(deviceCode) {
    const survey = await ajax.get(`${BASE_URL}/survey/${deviceCode}`);
    survey['preview_arrival'] = JSON.parse(survey['preview_arrival']);
    return survey;
}

/**@param {String} deviceCode */
async function getRoomByDeviceCode(deviceCode) {
    const room = await ajax.get(`${BASE_URL}/room/${deviceCode}`);
    for (const key in room) {
        const value = room[key]
        if (!value) room[key] = 'not_specified'
    }
    return room;
}



async function getPermittedSites() {
    const sites = await ajax.get(`${BASE_URL}/sites`);
    return sites
}

/**@param {String | Number} id*/
async function getSurveyById(id) {
    const survey = await ajax.get(`survey/${id}`);
    return survey;
}

async function getSurveyWithRoomDetails(room) {
    let roomDetails = _convertToQuery(room);
    roomDetails = _removeNotSpecified(roomDetails);
    const survey = await ajax.get(`${BASE_URL}/survey`, null, roomDetails);
    return survey;
}

async function assignTabletAsSensor(room, deviceCode, surveyId) {
    let roomDetails = _convertToQuery(room);
    roomDetails = _removeNotSpecified(roomDetails)
    const res = await ajax.post(`${BASE_URL}/sensor`, { ...roomDetails, deviceCode, surveyId });
    return res;
}

/**@param {RoomDetails} query
 * @param {'building' | 'floor' | 'zone' | 'gender' | 'name'} routeName*/
async function _getListOfOptions(query, routeName) {
    const names = await ajax.get(`${BASE_URL}/${routeName}`, null, query);
    return names;
}

/**@param {{
 * site: Number | String;
 * building?: String;
 * floor?: String;
 * zone?: String;
 * gender?:String;
 * name?:String;
 * id?:String | Number;
 * }} room
 * @param {'building' | 'floor' | 'zone' | 'gender' | 'name' | 'survey' | 'id'} currKey*/
async function getNextOptions(room, currKey) {
    const keys = Object.keys(room);
    const site_id = room.site;
    const index = keys.findIndex(a => a === currKey);
    let nextKey = keys[index + 1];
    if (currKey === 'name' || currKey === 'id') nextKey = 'survey';
    let query = { site_id };
    if (room.building && room.building !== 'not_specified') query.building = room.building;
    if (room.floor && room.floor !== 'not_specified') query.floor = room.floor;
    if (room.zone && room.zone !== 'not_specified') query.zone = room.zone;
    if (room.gender && room.gender !== 'not_specified') query.gender = room.gender;
    if (room.name && room.name !== 'not_specified') query.display_name = room.name;
    // @ts-ignore
    let options = await _getListOfOptions(query, nextKey);
    nextKey !== 'survey' ? options = options.map(a => ({ value: a, name: a })) : '';
    return [{ value: 'not_specified', name: 'not specified' }, ...options];
}

/**@param {Number | String} roomId */
async function getRoomById(roomId) {
    /**@type {Room} */
    let room = await ajax.get(`room/${roomId}`);
    room.name = room.display_name
    delete room.display_name
    room.site = room.site_id
    delete room.site_id
    const { site, building, floor, zone, gender, name, id, survey_id } = room;
    room = { site, building, floor, zone, gender, name,id, survey_id };
    return room;
}


function _removeNotSpecified(query) {
    for (const key in query) {
        if (query[key] === 'not_specified') delete query[key];
    }
    return query;
}

function _convertToQuery(room) {
    if (room.id) return { id: room.id, site_id: room.site };
    let roomDetails = { ...room };
    roomDetails.site_id = roomDetails.site;
    roomDetails.display_name = roomDetails.name;
    if (!roomDetails.id) delete roomDetails.id;
    delete roomDetails.name;
    delete roomDetails.site;
    return roomDetails;
}
