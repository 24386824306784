<template>
  <section>
    <AnalyticsPrint
      :infoBarItems="infoBarItems"
      :analyticsData="analyticsData"
      :hourData="hourData"
      :hideInfo="hideInfo"
      :roomsData="roomsData"
      @donePrinting="$emit('donePrinting')"
    />
  </section>
</template>

<script>
import AnalyticsPrint from './AnalyticsPrint.vue'
export default {
  name: 'iframe-cmp',
  props: {
    infoBarItems: { type: Array, required: true },
    analyticsData: { type: Object, required: true },
    hourData: { type: Array, required: true },
    hideInfo: { type: Array, required: false },
    roomsData: { type: Array, required: false },
  },
  components: {
    AnalyticsPrint,
  },
}
</script>

<style></style>
