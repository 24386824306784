<template>
  <div class="no-data-message-container" :style="{ height }">
    <div class="no-data-message" :style="{ fontSize }">No data available</div>
  </div>
</template>

<script>
import { fontSizeLarge } from '@/styles/vars.scss';
export default {
  props: {
    height: { type: String, default: '45vh' },
    fontSize: { type: String, default: fontSizeLarge }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";
@import "@/styles/mixins.scss";

.no-data-message-container {
  @include flex-center;
  .no-data-message {
    width: 17vw;
    height: 5vh;
    font-size: $font-size-large;
    color: $black-blue;
    @include border($grey-border-color);
    @include flex-center;
    border-radius: 4px;
  }
}
</style>