export {
  companies,
  sites,
  devices
}

const companies = [
    {
      id: "1",
      name:"Intel"
    },
    {
      id: "2",
      name:"HP"
    }
];

const sites = [
    {
      id: "38",
      name:"fub",
      "location":"Keriat Gat"
    },
    {
      id: "39",
      name:"haifa",
      "location":"Haifa"
    },
    {
      id: "40",
      name:"ptk1",
      "location":"Petah Tikva"
    }
];

const devices = [
    {
      id: "1",
      name : "Cleaned floor"
    },
    {
      id: "2",
      name : "Cleaned toilet"
    },
    {
      id: "1",
      name : "Cleaned shower"
    }	
];