import ajax from '../../common/services/httpService'
import moment from 'moment'
const BASE_URL = 'sla'

export default { getRealTimeData, filterFromByShiftStartHour, getDurationInfo, getCleandexData }


/**
 * @param {Number | String} openTime
 * @param {number} maxSla
*/
function getDurationInfo(openTime, maxSla) {
    if (!openTime) return {
        durationStr: '00:00:00',
        isExpired: false
    }
    if (!isSameDay(new Date(openTime), new Date())) return {
        durationStr: moment(openTime).fromNow(),
        isExpired: true
    }
    const msPassed = new Date().getTime() - new Date(openTime).getTime()
    let isExpired = maxSla < ((msPassed / 1000) / 60)
    let seconds = Math.floor((msPassed / 1000) % 60)
    let minutes = Math.floor((msPassed / (1000 * 60)) % 60)
    let hours = Math.floor((msPassed / (1000 * 60 * 60)) % 24)

    const hoursStr = (hours < 10) ? "0" + hours : hours
    const minutesStr = (minutes < 10) ? "0" + minutes : minutes
    const secondsStr = (seconds < 10) ? "0" + seconds : seconds
    return {
        durationStr: hoursStr + ":" + minutesStr + ":" + secondsStr,
        isExpired
    }
}

function isSameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
}

/**
 * @param {Number | String} siteId
 * @param {string} timeFrom
 * @param {'clean' | 'maintenance'} type
*/
async function getRealTimeData(siteId, timeFrom, type = 'clean', isSurveyRealTime = false) {
    const realTimeData = await ajax.get('sla/real-time', null, { siteId, type, timeFrom, isSurveyRealTime })
    return realTimeData
}

async function getCleandexData(siteId) {
    const cleandexData = await ajax.get(`sla/cleandex/${siteId}`)
    return cleandexData
}

// /** @param {Number | String} device_code */
// async function getRoomArrivalPreview(device_code){
//     try{
//         const res = await ajax.get(`${BASE_URL}/real-time/${device_code}`)
//         return res
//     }catch(err){
//         throw new Error('Getting arrival preview info failed')
//     }
// }

/**
 * @param {Number} shiftStart
 * @returns {Date}
*/
function filterFromByShiftStartHour(shiftStart) {
    const timeFrom = new Date()
    timeFrom.setHours(shiftStart)
    if (Number.isInteger(shiftStart)) {
        timeFrom.setMinutes(0)
    } else {
        const partOfHour = +(shiftStart - Math.floor(shiftStart)).toFixed(1)
        timeFrom.setMinutes(partOfHour * 60)
    }
    if (timeFrom > new Date()) timeFrom.setDate(new Date().getDate() - 1)
    return timeFrom
}