<template>
	<section>
		{{ 'here schedule modal' }}
	</section>
</template>

<script>
export default {
	name: 'ScheduleModal'
}
</script>

<style></style>
