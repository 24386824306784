<template>
  <section class="site-details-container">
    <AdminHeader :isAddShown="false" :texts="{ title: `${siteDetails.name} / ${siteDetails.company_name}`, add: 'new site' }" />
    <section class="site-details">
      <div>
        <section class="basic-site-info">
          <span>{{ $t('site_information') }}</span>
          <div>
            <div class="info-item-container" v-for="(value, name, idx) in siteDetailsToShow" :key="value + idx">
              <div class="property">{{ $t(name) }}</div>
              <div class="value">{{ value }}</div>
            </div>
          </div>
        </section>
        <HolidayList v-if="siteDetails.company_id" shiftParentType="site" :siteId="+siteId" :companyId="+companyId" :companyName="siteDetails.company_name" :companyCountry="siteDetails.country" :shifts="siteDetails.shifts" />
      </div>
      <ShiftList v-show="siteDetails.company_id" shiftParentType="site" :siteId="+siteId" :companyId="+companyId" :companyName="siteDetails.company_name" />
    </section>
  </section>
</template>

<script>
import AdminHeader from '../components/AdminHeader'
import siteService from '../services/siteService'
import ShiftList from '../components/ShiftList'
import HolidayList from '../components/HolidayList'

export default {
  data() {
    return {
      siteDetails: {},
      siteTableData: [],
    }
  },
  async created() {
    const site = await siteService.getSiteById(this.siteId)
    this.siteDetails = site
  },
  methods: {
    isChecked(dayValue) {
      if (this.shift.week_arrivals.includes(dayValue)) return true
      else return false
    },
  },
  computed: {
    siteId() {
      return this.$route.params.id
    },
    companyId() {
      return this.siteDetails.company_id
    },
    siteDetailsToShow() {
      return Object.fromEntries(Object.entries(this.siteDetails).filter(([key]) => key !== 'company_id' && key !== 'id' && key !== 'shifts'))
    },
  },
  components: {
    AdminHeader,
    ShiftList,
    HolidayList,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars';
$table-grey: #7f7f7f;

.site-details {
  // text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  margin-block-start: 40px;
  padding-block-end: 50px;
  gap: 100px;

  & > div {
    display: flex;
    flex-direction: column;
    min-width: 25%;
    gap: 30px;
  }

  .basic-site-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-inline: 50px;
    width: 100%;

    & > span {
      color: $table-grey;
      font-weight: bold;
    }

    & > div {
      padding-block: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      border: 1px solid #e7e7e7;
    }
  }

  .shift-table {
    flex-grow: 1;
    margin-inline-end: 50px;
  }
}

.info-item-container {
  display: flex;
  margin-inline: 15px;
  gap: 15px;
  .property {
    width: 6vw;
    color: $table-grey;
    font-weight: bold;
  }

  .value {
    font-size: $font-size-small;
  }
}
</style>
