<template>
  <header>
    <div class="logo-container">
      <img class="buzz-logo" :src="require('@/assets/buzzztv_logo.png')" alt="buzzztv-logo" />
      <div class="dates">{{ time.timeFrom }} - {{ time.timeTo }}</div>
    </div>
    <div class="text-company-logo">
      <div>
        <SVGIcon
          v-if="currCompany && currCompany.logo.split('.').pop() === 'svg'"
          :svg="currCompany.logo"
          @doneRender="$emit('mounted')"
          class="logo"
        />
        <img
          class="logo"
          @load="$emit('mounted')"
          v-else-if="currCompany && currCompany.logo.split('.').pop() !== 'svg'"
          :src="currCompany.logo"
        />
        <div class="text">{{ $t($route.name) }}</div>
        <div class="text" v-if="currCompany">{{ currCompany.name }} | {{ currSite.name }}</div>
        <div class="text" v-if="currRoom">{{ currRoom.name }}</div>
      </div>
    </div>
  </header>
</template>

<script>
import SVGIcon from './SVGIcon.vue'

export default {
  name: 'pdf-header',
  props: {
    currCompany: { type: Object, required: true },
    currSite: { type: Object, required: true },
    currRoom: { type: [Object], required: false },
    time: { type: Object, required: true },
  },
  components: { SVGIcon },
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars';
@import '@/styles/mixins';

header {
  display: flex;
  background-color: white;
  height: 240px;
  width: 100%;
  .logo-container {
    @include flex-center;
    justify-content: center;
    flex-direction: column;
    flex-basis: 45%;
    padding-block: 10px;
    justify-content: space-between;
    .buzz-logo {
      height: 100px;
      width: auto;
    }

    .dates {
      font: Bold 28px Assistant;
      text-align: center;
    }
  }

  .text-company-logo {
    @include flex-center;
    background-image: url('../assets/pdf/pdf-header.png');
    height: 260px;
    width: 150%;
    background-size: cover;
    padding-block: 10px;
    height: 100%;
    & > div {
      display: flex;
      flex-direction: column;

      *:not(:last-child) {
        align-self: center;
      }
    }
    .text {
      font: Bold 50px Assistant;
      text-transform: capitalize;
      letter-spacing: -0.71px;
      color: #2b2f35;
    }
    img,
    .logo {
      width: 250px;
    }
  }
}
</style>
