export const mockRealTimeTable = [
    {
        name: 'LC12 F3 south women',
        building: 'LC12',
        zone: 'South',
        floor: '3',
        gender: 'men-women',
        isOpen: true,
        openTime: 1590916287876,
        complaints: [
            {
                name: 'dirty_floor',
                time: 1590916287876,
                count: 2
            },
            {
                name: 'like',
                time: 1590916287876,
                count: 1
            }
        ]
    },
    {
        name: 'LC12 F3 north men',
        building: 'LC12',
        zone: 'North',
        floor: '3',
        gender: 'men',
        isOpen: false,
        openTime: 1590916287876,
        complaints: []
    },
    {
        name: 'LC12 F3 south women',
        building: 'LC12',
        zone: 'South',
        floor: '3',
        gender: 'kitchen',
        isOpen: true,
        openTime: 1590916287876,
        complaints: [
            {
                name: 'dirty_floor',
                time: 1590916287876,
                count: 2
            },
            {
                name: 'like',
                time: 1590916287876,
                count: 1
            }
        ]
    },
    {
        name: 'LC12 F3 north men',
        building: 'LC12',
        zone: 'North',
        floor: '3',
        gender: 'handicap',
        isOpen: false,
        openTime: 1590916287876,
        complaints: []
    },
];