<template>
  <section class="cleaner-arrival">
    <div class="shift-row">
      <span>{{$t('night_shift')}}</span>
      <span>{{$t('morning_shift')}}</span>
      <span>{{$t('afternoon_shift')}}</span>
      <span>{{$t('night_shift')}}</span>
    </div>
    <div class="hour-row">
      <div class="row-title">{{$t('date')}}</div>
      <div class="hour" v-for="(hour, idx) in hours" :key="hour + idx">{{hour}}</div>
    </div>
    <template v-for="(dayArrivals, idx) in cleanerArrivalsToShow">
      <div class="date-row" :key="'' + dayArrivals[0].time + idx">
        <div class="row-title">{{dayArrivals[0].time | ddmmyy}}</div>
        <span class="cleaner-count">{{dayArrivals.length}}</span>
        <img :src="require('@/assets/performance/cleaner.svg')" alt="cleaner" />
        <div class="line"></div>
          <template v-for="(hour, hourIndex) in hours">
              <div class="cleaner" :key="hour + hourIndex">
                <div
                    class="cleaner-container"
                    v-if="getDayArrivalsInfo(dayArrivals)[hour]"
                    :set="dayArrivalInfo = getDayArrivalsInfo(dayArrivals)[hour]"
                  >
                  
                  <div class="cleaner-icon-container" 
                    v-if="dayArrivalInfo.isCleaner"
                    :set="hourArrivalsInfo = getHourArrivalsInfo(dayArrivals, hourIndex, 'cleaner')"
                  >
                    <Tooltip
                      v-if="hourArrivalsInfo.length"
                      :texts="[getFormattedHour(hour, hourIndex),
                      `${$t('cleaner')}: ${hourArrivalsInfo}`]"
                    >
                      <img 
                        :style="getArrivalBackgroundStyle('cleaner', isArrivalsOffline(dayArrivals, hourIndex, 'cleaner'))"
                        :src="require('@/assets/performance/cleaner.svg')" alt="cleaner" />
                    </Tooltip>
                  </div>

                  <div class="cleaner-icon-container" 
                    v-if="dayArrivalInfo.isSupervisor"
                    :set="hourArrivalsInfo = getHourArrivalsInfo(dayArrivals, hourIndex, 'supervisor')"
                  >
                    <Tooltip
                      v-if="hourArrivalsInfo.length"
                      :texts="[getFormattedHour(hour, hourIndex),
                      `${$t('supervisor')}: ${hourArrivalsInfo}`]"
                    >
                      <img 
                        :style="getArrivalBackgroundStyle('supervisor', isArrivalsOffline(dayArrivals, hourIndex, 'supervisor'))"
                        :src="require('@/assets/performance/cleaner.svg')" alt="supervisor" />
                    </Tooltip>
                  </div>
                  
                </div>
              </div>
          </template>
      </div>
    </template>
  </section>
</template>

<script>
import Tooltip from "../../common/components/Tooltip.vue";
import { localeData } from "../../common/services/localeService.js"

export default {
  props: {
    cleanerArrivals: { type: Array, required: true }
  },
  data() {
    return {
      hours: [],
      selectedLanguage: localeData.language,
    }
  },
  created() {
    for (let i = 0; i <= 24; i++) {
      i < 10 ? this.hours.push(`0${i}:00`) : this.hours.push(`${i}:00`)
    }
  },
  methods: {
    getArrivalBackgroundStyle(type, isOffline) {
      if (isOffline) return { backgroundColor: '#808080'}
      return {
        backgroundColor: type === 'cleaner' ? '#5f9b46' : '#FF984C'
      }
    },
    getDayArrivalsInfo(dayArrivals) {
      let hoursInfo = {}
      dayArrivals.forEach(arrival => {
        let hour = (arrival.time).getHours()
        hour = hour < 10 ? `0${hour}:00` : `${hour}:00`
        if (!hoursInfo[hour]) {
          hoursInfo[hour] = {
            hour: hour,
            isCleaner: false,
            isSupervisor: false,
            isOffline: false
          }
        }
        if (arrival.type === 'cleaner') hoursInfo[hour].isCleaner = true
        if (arrival.type === 'supervisor') hoursInfo[hour].isSupervisor = true
        if (arrival.isOffline) hoursInfo[hour].isOffline = true
      })
      return hoursInfo
    },
    getHourArrivalsInfo(dayArrivals, hourIndex, type) {
      dayArrivals = dayArrivals.filter(arrival => arrival.type === type)
      return dayArrivals.map(arrival => {
        const hour = (arrival.time).getHours()
        if (hour !== hourIndex) return
        const minutes = (arrival.time).getMinutes()
        return this.getFormattedHourAndMins(hour, minutes)
      }).filter(item => item)
    },
    isArrivalsOffline(dayArrivals,hourIndex, type) {
      dayArrivals = dayArrivals.filter(arrival => {
        return arrival.type === type && (arrival.time).getHours() === hourIndex
      })
      const isOffline = dayArrivals.some(arrival => arrival.isOffline)
      return isOffline
    },
    getFormattedHour(hour, hourIndex) {
      if (this.selectedLanguage === 'he') {
        return `${hourIndex + 1 < 10 ? '0' + (hourIndex + 1) : hourIndex + 1}:00 - ${hour}`
      } else {
        return `${hour} - ${hourIndex + 1 < 10 ? '0' + (hourIndex + 1) : hourIndex + 1}:00`
      }
    },
    getFormattedHourAndMins(hour, minutes) {
      return `${hour < 10 ? '0' + hour : hour}:${minutes < 10 ? '0' + minutes : minutes}`
    }
  },
  computed: {
    //Dont show duplicates
    cleanerArrivalsToShow() {
      return this.cleanerArrivals.map(dateArray => {
        return dateArray.reduce((acc, dateString) => {
          if (acc.includes(dateString)) return acc;
          else acc.push(dateString)
          return acc;
        }, [])
      })
    }
  },
  components: { Tooltip }
}
</script>

<style lang="scss" scoped>
@import "@/styles/vars";
@import "@/styles/mixins";

$arrival-green: #5f9b46;

.shift-row {
  display: flex;
  height: 20px;
  justify-content: space-between;
  margin: 0px;
  padding: 0px;
  margin-inline-end: 100px;
  margin-inline-start: 150px;
  // text-transform: capitalize;
  > * {
    opacity: 0.7;
  }
}

.hour-row {
  display: flex;
  align-items: center;
  height: 40px;
  .hour {
    display: flex;
    height: 100%;
    align-items: center;
    color: $black-blue;
    width: 5%;
    font-size: $font-size-medium;
    border-bottom: 1px dashed rgba(187, 187, 187, 1);
  }
  .row-title {
    font-weight: $font-weight-bold;
    color: $spun-pearl;
  }
}
.date-row {
  .row-title {
    margin-inline-end: 5px;
  }
  &:nth-child(3) {
    margin-top: 15px;
  }
  .cleaner-count {
    position: relative;
    top: -10px;
    left: 8px;
    @include rtl {
      right: 8px;
      left: unset;
    }
    padding: 5px;
    @include flex-center;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    font-size: $font-size-tiny;
    font-weight: $font-weight-bold;
    background-color: $white;
    @include border($arrival-green);
  }
  & > img {
    box-shadow: 0px 1px 1px #0000001e;

    background-color: $arrival-green;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    padding: 4px;
  }
  .cleaner {
    width: 5%;
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .cleaner-container {
      display: flex;
    }
    .cleaner-icon-container {
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      padding:1px;
      > * {
        text-align: center;
      }
      .hour-container {
        border-radius: 50%;
        border: 1px solid $arrival-green;
        background-color: $white;
        font-size: $font-size-tiny;
        font-weight: $font-weight-bold;
        @include flex-center;
        height: 29px;
        width: 32px;
      }
      img {
        box-shadow: 0px 1px 1px #0000001e;

        position: relative;
        top: -9px;
        left: 4px;
        @include rtl {
          right: 4px;
          left: unset;
        }
        border-radius: 50%;
        background-color: $arrival-green;
        padding: 2px;
        height: 18px;
        width: 18px;
      }
    }
  }
  height: 50px;
  display: flex;
  align-items: center;
  .row-title {
    color: $boulder;
    font-size: 1.125rem;
  }
  .line {
    border-bottom: 1px dashed rgba(187, 187, 187, 0.4);
    position: fixed;
    width: 90%;
    margin-inline-start: 11%;
    margin-inline-end: 2%;
    z-index: -1;
  }
}
.hour-row {
  .row-title {
    margin-inline-end: 100px;
    // text-transform: capitalize;
  }
}
</style>