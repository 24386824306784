<template>
  <section class="setup-container">
    <SetupHeader :texts="headerTexts" :isBackButton="!!user" @backClick="backClick" />
    <SetupBody
      :user="user"
      :identifier="identifier"
      :survey="survey"
      @login="login"
      @surveySet="emitSurveySet"
      @selected="changeSelectText"
      @logout="logout"
      @restartTimeout="restartTimeout"
      ref="setupBody"
    />
  </section>
</template>

<script>
import SetupHeader from '../components/SetupHeader';
import SetupBody from '../components/SetupBody';
import authService from '../../modules/auth/services/authService';
import installService from '../services/installationService';

export default {
  props: {
    identifier: { type: String, required: true },
    survey: { type: Object, required: false },
  },
  data() {
    return {
      user: null,
      currSelect: 'select site',
      surveyTimeout:null
    }
  },
  created() {
    this.surveyTimeout = setTimeout(()=>this.$emit('surveySet'), 4000);
  },
  methods: {
    async login(userCred) {
      const user = await authService.login(userCred);
      this.user = user;
    },
    emitSurveySet() {
      this.$emit('surveySet');
    },
    restartTimeout() {
      clearTimeout(this.surveyTimeout)
      this.$emit('restartTimeout')
    },
    backClick() {
      this.$refs.setupBody.goBack();
    },
    changeSelectText(room) {
      const currKey = installService.getCurrSelectingKey(room);
      this.currSelect = 'select ' + currKey;
    },
    logout() {
      this.user = null;
    }
  },
  computed: {
    headerTexts() {
      if (this.user) return { startText: this.identifier, endText: this.currSelect }
      return { startText: 'welcome', endText: 'login' }
    }
  },
  components: { SetupHeader, SetupBody }
}
</script>

<style lang="scss" scoped>
.setup-container {
  height: 100vh;
}
</style>