<template>
  <section class="login-form">
    <div class="form-header">
      Welcome to BuzzzTech survey app, Follow the
      <br />instruction to configure the device for the first time
    </div>
    <SurveyInput label="user" placeholder="email / phone" v-model="email" class="email" />
    <SurveyInput label="password" type="password" placeholder="password" v-model="password" />
    <div :class="['button-container', isBackButton ? 'back-button' : '']">
      <Button skin="primary" class="login" @click="emitGoBack" v-if="isBackButton">BACK TO SURVEY</Button>
      <Button skin="primary" class="login" @click="emitUserCred">LOGIN</Button>
    </div>
  </section>
</template>

<script>
import SurveyInput from './SurveyInput';
import Button from '../../modules/common/components/Button';

export default {
  props: {
    isBackButton: { type: Boolean, required: true }
  },
  data() {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    emitUserCred() {
      const { email, password } = this;
      this.$emit('login', { email, password });
    },
    emitGoBack() {
      this.$emit('backToSurvey');
    }
  },
  watch: {
    email() {
      this.$emit('restartTimeout')
    },
    password() {
      this.$emit('restartTimeout')
    },
  },
  components: { SurveyInput, Button }
}
</script>

<style lang="scss" scoped>
@import "@/styles/mixins";
@import "@/styles/vars";

.email {
  margin-bottom: 15px;
}

.button-container {
  display: flex;
  height: 20vh;
  align-items: flex-end;
  justify-content: flex-end;
  &.back-button {
    justify-content: space-between;
  }
}

.form-header {
  @include survey-form-header;
}
</style>