<template>
	<v-main>
		<v-row class="fill-height">
			<v-col>
				<v-sheet height="64">
					<v-toolbar flat>
						<v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
							Today
						</v-btn>
						<v-btn fab text small color="grey darken-2" @click="prev">
							<v-icon small>
								mdi-chevron-left
							</v-icon>
						</v-btn>
						<v-btn fab text small color="grey darken-2" @click="next">
							<v-icon small>
								mdi-chevron-right
							</v-icon>
						</v-btn>
						<v-toolbar-title v-if="$refs.calendar">
							{{ $refs.calendar.title }}
						</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-menu bottom right>
							<template v-slot:activator="{ on, attrs }">
								<v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
									<span>{{ typeToLabel[type] }}</span>
									<v-icon right>
										mdi-menu-down
									</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item @click="type = 'day'">
									<v-list-item-title>Day</v-list-item-title>
								</v-list-item>
								<v-list-item @click="type = 'week'">
									<v-list-item-title>Week</v-list-item-title>
								</v-list-item>
								<v-list-item @click="type = 'month'">
									<v-list-item-title>Month</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-toolbar>
				</v-sheet>
				<v-sheet height="600">
					<v-calendar
						ref="calendar"
						color="primary"
						:type="type"
						v-model="focus"
						:events="events"
						:event-color="getEventColor"
						@click:event="editEvent"
						@click:day="addEvent"
						@click:more="viewDay"
						@click:date="viewDay"
						@change="updateEvents"
					>
						<template v-slot:event="{ event }">
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<div class="event-text" v-on="on">{{ event.start | hhmm }} {{ event.name }}</div>
								</template>
								<span>{{ `${$t('edit')}` }}</span>
							</v-tooltip>
						</template>
					</v-calendar>
				</v-sheet>
			</v-col>
		</v-row>
	</v-main>
</template>

<script>
export default {
	name: 'Calendar',
	props: {
		data: {
			type: Array,
			default: () => [],
			required: true
		}
	},
	data() {
		return {
			focus: '',
			type: 'month',
			events: [],
			typeToLabel: {
				month: 'Month',
				week: 'Week',
				day: 'Day'
			}
		}
	},
	methods: {
		viewDay({ date }) {
			this.focus = date
			this.type = 'day'
		},
		setToday() {
			this.focus = ''
		},
		prev() {
			this.$refs.calendar.prev()
		},
		next() {
			this.$refs.calendar.next()
		},
		getEventColor(event) {
			return event.color
		},
		updateEvents() {
			const events = []
			for (let i = 0; i < this.data.length; i++) {
				const { description, color, dates, id } = this.data[i]
				for (let j = 0; j < dates.length; j++) {
					const date = dates[j]
					events.push({
						id,
						name: description,
						color,
						start: date,
						end: date,
						timed: true
					})
				}
			}
			this.events = events
		},
		editEvent({ nativeEvent, event }) {
			nativeEvent.stopPropagation()
			const { start } = event
			if (start < new Date()) {
				this.$emit('errorMsg', 'Cannot edit past events')
				return
			}
			this.$emit('editRow', event)
		},
		addEvent(event) {
			const { past } = event
			if (past) {
				this.$emit('errorMsg', 'Cannot add events to past dates')
				return
			}
			this.$emit('addEvent', event)
		}
	},
	watch: {
		data: function(newVal, oldVal) {
			this.updateEvents()
		}
	}
}
</script>

<style lang="scss" scoped>
@import 'https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css';

.event-text {
	padding-inline-start: 4px;
}
</style>
