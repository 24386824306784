import ajax from '@/modules/common/services/httpService';
const BASE_URL = 'franchise'

export {
    getFranchises,
    saveFranchise,
    removeFranchise,
    getPermittedFranchises
}

async function getFranchises(filter) {
    const res = await ajax.get(BASE_URL, null, filter)
    return res
}

async function getPermittedFranchises() {
    const res = await ajax.get(`${BASE_URL}/company`)
    return res
}

async function saveFranchise(franchise) {
    if (franchise.id) return await ajax.put(BASE_URL, franchise)
    else return await ajax.post(BASE_URL, franchise)
}

async function removeFranchise(id) {
    const res = await ajax.delete(BASE_URL, {id})
    return res
}