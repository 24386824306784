import { render, staticRenderFns } from "./SetupBody.vue?vue&type=template&id=3c74f208&scoped=true&"
import script from "./SetupBody.vue?vue&type=script&lang=js&"
export * from "./SetupBody.vue?vue&type=script&lang=js&"
import style0 from "./SetupBody.vue?vue&type=style&index=0&id=3c74f208&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c74f208",
  null
  
)

export default component.exports