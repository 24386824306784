var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v("\n\t\t\t\t\t\tToday\n\t\t\t\t\t")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("\n\t\t\t\t\t\t\tmdi-chevron-left\n\t\t\t\t\t\t")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("\n\t\t\t\t\t\t\tmdi-chevron-right\n\t\t\t\t\t\t")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$refs.calendar.title)+"\n\t\t\t\t\t")]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("\n\t\t\t\t\t\t\t\t\tmdi-menu-down\n\t\t\t\t\t\t\t\t")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v("Day")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v("Week")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v("Month")])],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","type":_vm.type,"events":_vm.events,"event-color":_vm.getEventColor},on:{"click:event":_vm.editEvent,"click:day":_vm.addEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"change":_vm.updateEvents},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"event-text"},on),[_vm._v(_vm._s(_vm._f("hhmm")(event.start))+" "+_vm._s(event.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(("" + (_vm.$t('edit')))))])])]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }