<template>
	<section v-if="workingArea" class="working-area-modal-container">
		<Modal 
			v-if="!showManualCallsModal"
			:showCancelButton="true"
			:texts="texts" 
			@close="emitCloseModal" 
			@submit="emitWorkingArea" 
			:isConfirmDisabled="isConfirmDisabled"
			class="working-area-modal" 
			width="medium">
			<div class="modal-row">
				<div class="field">
					<span class="text">{{ $t('area_name') }}</span>
					<input v-model="workingArea.area_name" type="text" />
				</div>
				<div class="checkbox-list-container">
					<div class="field">
						<span class="text">{{ $t('notified') }}</span>
						<CheckBox v-model="workingArea.notified" />
					</div>
					<div class="field">
						<span class="text">{{ $t('sms') }}</span>
						<CheckBox v-model="workingArea.sms.isActive" />
					</div>
					<div class="field">
						<span class="text">{{ $t('email') }}</span>
						<CheckBox v-model="workingArea.emails.isActive" />
					</div>
					<div class="field">
						<span class="text">api</span>
						<CheckBox v-model="workingArea.api.isActive" />
					</div>
				</div>
			</div>
			<div class="modal-row">
				<div class="selectors">
					<div class="selector-field" v-for="field in multiSelectFields" :key="field">
						<transition name="fade">
							<label class="field" v-if="selectOptions[field]">
								<span class="text">{{ $t(field) }}</span>
								<div class="multi-select">
									<MultiSelectDialog
										v-if="openSelectMenus[field]"
										:data="selectOptions[field]"
										:initialSelected="workingArea[field]"
										fieldName="name"
										idFieldName="id"
										@selected="handleMultiSelectInput($event, field)"
										@close="toggleSelect(field)"
									/>
									<div @click="toggleSelect(field)" class="multi-select-input">
										<template v-for="item in workingArea[field]">
											<span @click="toggleSelect(field)" @click.stop :key="item.id" class="item" v-if="item.name !== 'All'">
												<span>{{ item.name }}</span>
												<span class="close-btn" @click.stop="removeItemFromSelected('workingArea', item.id, field)">x</span>
											</span>
										</template>
									</div>
								</div>
								<span v-if="selectOptions[field].length === 0">{{ $t(`no_data_available`) }}</span>
							</label>
						</transition>
					</div>
				</div>
			</div>
			<div class="modal-row">
				<div class="field">
					<span class="text">{{ $t('emails') }}</span>
					<input v-model="emailListStr" />
				</div>
				<div class="field">
					<span class="text">{{ $t('sms') }}</span>
					<input v-model="phoneListStr" />
				</div>
			</div>
			<div class="modal-row">
				<div class="scheduled-selector">
					<div class="field">
						<span class="text">{{ $t('ai') }}</span>
						<CheckBox v-model="workingArea.is_suggestion" />
					</div>
					<div class="field">
						<span class="text">{{ $t('shift') }}</span>
						<CheckBox v-model="workingArea.is_scheduled_cleaning" />
					</div>
					<div class="field">
						<span class="text">{{ $t('counter') }}</span>
						<CheckBox v-model="workingArea.counter.isActive" />
					</div>
					<div class="field">
						<span class="text">{{ $t('cleandex') }}</span>
						<CheckBox v-model="workingArea.cleandex.isActive" />
					</div>
					<div class="field">
						<span class="text">{{ $t('offline_calls') }}</span>
						<CheckBox v-model="workingArea.offline_calls.isActive" />
					</div>
				</div>
				<div class="field">
					<span class="text">{{ $t('amount_limit') }}</span>
					<input type="number" v-model="workingArea.counter.amount_limit" />
				</div>
				<div class="field">
					<span class="text">{{ $t('offline_duration_calls') }}</span>
					<input type="number" v-model="workingArea.offline_calls.amount_limit" />
				</div>
			</div>
			<div class="modal-row">
				<Button skin="primary" @click="showManualCallsModal = true">{{ $t('manual_calls') }}</Button>
			</div>
		</Modal>
		<Modal 
			v-if="showManualCallsModal" 
			@submit="showManualCallsModal = false"
			@close="showManualCallsModal = false"
			:showCloseCorner="false"
			:showCancelBtn="false"
			:texts="{ headerPrimary: $t('call_cleaner'), headerSecondary: $t('manual_calls') }"
			width="small"
		>
			<ManualCallsOptions :callOptionsProp="workingArea.manual_calls" @saveManualCallOptions="saveManualCallOptions" />
		</Modal>
	</section>
</template>

<script>
import Modal from '@/modules/common/components/Modal'
import MultiSelectDialog from '@/modules/common/components/MultiSelectDialog'
import ManualCallsOptions from '@/modules/common/components/ManualCallsOptions'
import CheckBox from '@/modules/surveys/components/CheckBox.vue'
import siteService from '../../control-panel/services/siteService'
import roomService from '../../control-panel/services/roomService'
import shiftService from '../../control-panel/services/shiftService'
import workingAreaService from '../services/workingAreaService'
import swalService from '@/modules/common/services/swalService'
import Button from '@/modules/common/components/Button'

export default {
	name: 'WorkingAreaModal',
	props: {
		workingAreaProp: { type: Object, required: false },
		workingAreasServiks: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			texts: {
				headerPrimary: 'new_area',
				headerSecondary: ''
			},
			workingArea: null,
			selectOptions: {
				rooms: [],
				shifts: [],
				active_notification_task: [],
				supervisors: []
			},
			openSelectMenus: {
				rooms: false,
				shifts: false,
				active_notification_task: false,
				supervisors: false
			},
			supervisorUsers: null,
			emailListStr: '',
			phoneListStr: '',
			isConfirmDisabled: false,
			showManualCallsModal: false,
		}
	},
	async created() {
		const site = await siteService.getSiteById(this.filterSelected.site)
		this.texts.headerSecondary = '/ ' + site.name
		this.workingArea = JSON.parse(JSON.stringify(this.workingAreaProp))
		this.emailListStr = this.workingArea.emails.addresses.join(',')
		this.phoneListStr = this.workingArea.sms.numbers.join(',')
		const rooms = await roomService.getRooms(this.filterSelected)
		this.supervisorUsers = await workingAreaService.getUsers(this.filterSelected.site)
		this.selectOptions.rooms = rooms.map((r) => ({ id: r.id, name: r.display_name }))
		this.selectOptions.supervisors = this.supervisorUsers.map((s) => ({ id: s.id, name: s.firstName + ' ' + s.lastName }))
		const shifts = await shiftService.getShifts(this.filterSelected.site)
		this.selectOptions.shifts = shifts.map((s) => ({ id: s.id, name: s.shift_name }))
		this.selectOptions.active_notification_task = this.workingAreasServiks
		this.selectOptions.active_notification_task.forEach((item) => {
			item.name = item.name.split('_').join(' ')
		})
	},
	computed: {
		multiSelectFields() {
			const fields = ['shifts', 'active_notification_task']
			let fieldsToShow = ['rooms', 'shifts', 'supervisors', 'active_notification_task']
			for (let i = 0; i < fields.length; i++) {
				fieldsToShow.unshift(`${fields[i]}`)
			}
			return ['rooms', 'shifts', 'supervisors', 'active_notification_task']
		},
		filterSelected() {
			return this.$store.getters.filterSelected
		}
	},
	methods: {
		async handleMultiSelectInput(values, field) {
			if (!values) return
			this.workingArea[field] = values
			if (field === 'rooms') {
				if (this.workingArea.rooms.length === 0) this.workingArea.active_notification_task = []
				this.updateActiveNotificationsSelectOptions()
			}
		},
		removeItemFromSelected(objName, id, field) {
			const idx = this[objName][field].findIndex((item) => item.id === id)
			if (idx !== -1) this[objName][field].splice(idx, 1)
			if (field === 'rooms') this.updateActiveNotificationsSelectOptions()
		},
		async updateActiveNotificationsSelectOptions() {
			// To update the selection list of active_notification_task based on the selected rooms
			const roomsIds = this.workingArea.rooms.map((r) => r.id)
			const defaultServiks = workingAreaService.defaultSelectedWorkingAreaServiks()
			if (roomsIds.length === 0) {
				this.workingArea.active_notification_task = [...defaultServiks]
				this.selectOptions.active_notification_task = [...defaultServiks]
				return
			} else {
				const roomsActiveNotificationTasks = await workingAreaService.getServiksByRoomsIds(roomsIds)
				this.selectOptions.active_notification_task = [...roomsActiveNotificationTasks, ...defaultServiks]
				const defaultToSelect = workingAreaService.getDefaultToSelectWorkingAreaServiks()
				defaultToSelect.forEach((item) => {
					const isItemInActiveNotificationTasks = roomsActiveNotificationTasks.find((task) => task.id === item.id)
					if (isItemInActiveNotificationTasks) {
						this.workingArea.active_notification_task.push(item)
					}
				})
			}
		},
		toggleSelect(field) {
			for (const key in this.openSelectMenus) {
				if (key === field) continue
				if (this.openSelectMenus[key]) this.openSelectMenus[key] = false
			}
			this.openSelectMenus[field] = !this.openSelectMenus[field]
		},
		emitCloseModal() {
			this.$emit('closeModal')
		},
		async emitWorkingArea() {
			if (!this.workingArea.area_name) {
				await swalService.errorMsg('Please Enter Area Name')
				return
			} else if (!this.workingArea.rooms.length) {
				await swalService.errorMsg('Please Select Rooms')
				return
			}
			this.isConfirmDisabled = true
			this.$emit('submit', this.workingArea)
		},
		saveManualCallOptions(callOptions) {
			if (!Array.isArray(callOptions)) return
			// CallOptions is an array of objects with num, hour and gapInMinutes properties
			if (callOptions.length > 0) {
				if (callOptions.every((option) => {
					if (!option.num || !option.hour || !option.gapInMinutes) return false
					return true
				})) {
					this.workingArea.manual_calls = callOptions
				}
			} else {
				this.workingArea.manual_calls = []
			}
		}
	},
	watch: {
		'workingArea.counter.isActive'(to, from) {
			// To automatically add call cleaner servik to active_notification_task when counter is active
			const callCleanerServikInfo = { id: 216, name: 'call cleaner' }
			const field = 'active_notification_task'
			const isCallCleanerServikActive = this.workingArea[field].find((item) => item.id === callCleanerServikInfo.id)
			if (!from && to) {
				if (this.workingArea[field].length === 0) {
					this.workingArea[field] = [callCleanerServikInfo]
				} else if (this.workingArea[field].length > 0 && !isCallCleanerServikActive) {
					this.workingArea[field].push(callCleanerServikInfo)
				}
			} else if (from && !to) {
				if (this.workingArea[field].length === 1) {
					this.workingArea[field] = []
				} else if (this.workingArea[field].length > 1 && isCallCleanerServikActive) {
					this.workingArea[field] = this.workingArea[field].filter((item) => item.id !== callCleanerServikInfo.id)
				}
			}
			if (this.workingArea.is_scheduled_cleaning && to) this.workingArea.is_scheduled_cleaning = false
		},
		'workingArea.is_scheduled_cleaning'(to, from) {
			if (this.workingArea.counter.isActive && to) this.workingArea.counter.isActive = false
		},
		'workingArea.cleandex.isActive'(to, from) {
			const cleandexServikInfo = { id: 1053, name: 'cleandex call cleaner' }
			const field = 'active_notification_task'
			const isCleandexServikActive = this.workingArea[field].find((item) => item.id === cleandexServikInfo.id)
			if (!from && to) {
				if (this.workingArea[field].length === 0) {
					this.workingArea[field] = [cleandexServikInfo]
				} else if (this.workingArea[field].length > 0 && !isCleandexServikActive) {
					this.workingArea[field].push(cleandexServikInfo)
				}
			} else if (from && !to) {
				if (this.workingArea[field].length === 1) {
					this.workingArea[field] = []
				} else if (this.workingArea[field].length > 1 && isCleandexServikActive) {
					this.workingArea[field] = this.workingArea[field].filter((item) => item.id !== cleandexServikInfo.id)
				}
			}
		},
		emailListStr(val) {
			this.workingArea.emails.addresses = val.split(',')
		},
		phoneListStr(val) {
			this.workingArea.sms.numbers = val.split('/')
		}
	},
	components: {
		Modal,
		CheckBox,
		MultiSelectDialog,
		ManualCallsOptions,
		Button
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';

#app:has(.working-area-modal) {
	.selectors {
		max-width: 46.3vw;
		@include flex-wrap;
		gap: 15px;
		.selector-field {
			margin-bottom: 10px;
		}
	}
}

.modal-row {
	@include flex-space-between;
	margin-bottom: 10px;
	gap: 15px;
	& .field.full {
		margin: 0 20px 0 0;
		width: 100%;
	}
	.scheduled-selector {
		@include flex-space-between;
		text-align: center;
		gap: 15px;
		& .field {
			@include flex-center;
			flex: none;
		}
	}
}

.checkbox-list-container {
	width: 12.5vw;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-inline-end: 15px;
	& > * {
		margin: 10px;
		text-align: center;
	}
}

span {
	display: flex;
	justify-content: space-between;
	.field {
		flex: unset;
		width: 4vw;
	}
}

.field {
	display: flex;
	flex-direction: column;
	font-size: $font-size-smaller;
	flex: 1;
	height: 100%;
	> * {
		padding: 2px;
	}
	input {
		@include border($grey-border-color);
		height: 100%;
		padding: 0 10px;
		height: 4.25vh;
	}
	.checkbox-container {
		margin: 0;
		width: 2.4vw;
		height: 2.4vw;
	}
}

.multi-select-input {
	min-height: 8vh;
	max-height: 8vh;
	border-radius: 4px;
	overflow-y: auto;
	overflow-x: hidden;
	min-width: 8vw;
	max-width: 10vw;
	cursor: pointer;
	.item {
		@include align-center;
		margin-bottom: 2px;
		margin-top: 2px;
		margin-inline-end: 7px;
		padding: 10px;
		border-radius: 4px;
		background-color: $grey-border-color;
		span {
			max-height: 40px;
		}
	}
}

.multi-select {
	position: relative;
	@include border($grey-border-color);
	.dialog {
		position: absolute;
		max-width: 300px;
		right: 0;
		@include rtl {
			right: unset;
			left: 0;
		}
	}
}

.text {
	margin-bottom: 5px;
}

</style>
