<template>
  <header class="setup-header">
    <div class="start-text" @click="emitClick">
      <img v-if="isBackButton" class="left-arrow" :src="require('../../assets/survey/arrow_left_dark.svg')" />
      {{texts.startText}}
    </div>
    <div class="end-text">{{texts.endText}}</div>
  </header>
</template>

<script>
export default {
  props: {
    /**@type {{ new(): { startText: String; endText:String; } }} */
    texts: { type: Object, required: true },
    isBackButton: { type: Boolean, default: false }
  },
  methods: {
    emitClick() {
      this.$emit('backClick');
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";
@import "@/styles/mixins.scss";

.setup-header {
  text-transform: uppercase;
  height: 11vh;
  background-color: #E8E8EF;
  display: flex;
  justify-content: space-around;

  .left-arrow {
    height: 18px;
    margin-inline-end: 5px;
  }
  div {
    @include flex-center;
    color: $cadet-blue;
    font-size: $font-size-large;
  }
}
</style>