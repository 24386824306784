<template>
  <section class="holiday-table">
    <div>
      <span>{{ $t('holiday_offdays') }}</span>
      <div>
        <Button :isClean="true" @click="openModal('add_offday')" skin="primary" :dense="true">{{ $t('add_offday') }}</Button>
        <Button :isClean="true" @click="openModal('add_holiday')" skin="primary" :dense="true">{{ $t('add_holiday') }}</Button>
      </div>
    </div>
    <Modal :parent="shiftParentType" width="medium" v-if="isAddingHoliday" @close="closeModal" :texts="{ headerPrimary: $t(headerTxt), headerSecondary: `/ ${companyName}` }" @submit="handleSubmit">
      <template v-if="headerTxt === 'add_holiday'">
        <section class="selects holiday">
          <div>
            <div>{{ $t('country') }}</div>
            <Select v-model="country" class="select" @input="fetchHolidays">
              <Option v-for="[code, country] in Object.entries(countries).filter((country, idx) => idx % 2 === 0)" :key="code" :value="code" :label="$t(country)" />
            </Select>
          </div>
          <div>
            <div>{{ $t('year') }}</div>
            <Select v-model="year" class="select" @input="fetchHolidays">
              <Option v-for="year in years" :key="year" :value="year" :label="year" />
            </Select>
          </div>
        </section>
      </template>
      <template v-else>
        <section class="selects offday">
          <div>
            <div>{{ $t(`${offdayType === 'holidays' ? 'holiday_name' : 'offday_name'}`) }}</div>
            <Input class="input" type="text" :value="offday.name" @blur="handleInput('name', $event)" />
            <ErrorMsg :isShown="errors['name']" :text="`${$t('please_enter')} ${$t('name_under_chars')}`" />
          </div>
          <div>
            <div>{{ $t('year') }}</div>
            <Select class="select" :value="offday.year" @input="handleInput('year', $event)">
              <Option v-for="year in years" :key="year" :value="year" :label="year" />
            </Select>
            <ErrorMsg :isShown="errors['year']" :text="`${$t('please_enter')} ${$t('valid_year')}`" />
          </div>
          <div>
            <div>{{ $t('month') }}</div>
            <Select class="select" :value="offday.month" @input="handleInput('month', $event)">
              <Option v-for="(month, code) in months" :key="code" :value="code" :label="$t(month)" />
            </Select>
            <ErrorMsg :isShown="errors['month']" :text="`${$t('please_enter')} ${$t('valid_month')}`" />
          </div>
          <div>
            <div>{{ $t('day') }}</div>
            <Input class="input" type="text" :value="offday.day" @blur="handleInput('day', $event)" />
            <ErrorMsg :isShown="errors['day']" :text="`${$t('please_enter')} ${$t('days_comma_separated')}`" />
          </div>
        </section>
        <Button :isClean="true" :disabled="isError" skin="primary" @click="addOffday">{{ $t('add') }}</Button>
      </template>
      <header class="titles">
        <div v-for="title in [$t(`${offdayType === 'holidays' ? 'holiday_name' : 'offday_name'}`), $t('month'), $t('day'), $t('year')]" :key="title">
          {{ title }}
        </div>
      </header>
      <section class="holidays">
        <article v-for="({ name, start }, idx) in offdayType === 'holidays' ? holidays : offdays.custom" :key="name + idx" class="holiday">
          <Checkbox :value="isChecked(name, start)" @input="handleCheck($event, `${name}-${start}`)" />
          <div class="name" :class="{ offday: offdayType === 'custom' }">{{ $t(name) }}</div>
          <div v-for="type in ['month', 'day', 'year']" :class="type" :key="type">{{ date(type, start) }}</div>
          <img v-show="!isShiftsOpen" v-if="offdayType === 'custom'" :src="require('@/assets/delete-icon.svg')" alt="remove-icon" class="cell-icon action" @click="deleteOffDay(`${name}-${start}`)" />
          <img v-show="!isShiftsOpen" :src="require('@/assets/edit-icon.svg')" title="Edit Shifts" alt="edit-icon" class="cell-icon action" @click="editShifts(offdayType, `${name}-${start}`, $event)" />
        </article>
        <template v-if="editedDay">
          <md-field class="multi-select offday">
            <md-select ref="multi-select" v-model="editedDay.shiftIds" name="shifts" id="shifts" multiple @md-closed="isShiftsOpen = false">
              <md-option v-for="{ id, shift_name } in shifts" :value="id" :key="id" :disabled="isDisabledShift(id)">{{ shift_name }}</md-option>
            </md-select>
          </md-field>
        </template>
      </section>
    </Modal>
    <Table :tableData="selectedDays" class="selected-days-table" headerFontSize="11px" bodyFontSize="14px" />
  </section>
</template>

<script>
import Input from '@/modules/common/components/Input'
import Modal from '@/modules/common/components/Modal'
import Checkbox from '@/modules/common/components/Checkbox'
import Select from '@/modules/common/components/Select'
import Option from '@/modules/common/components/Option'
import Button from '@/modules/common/components/Button'
import ErrorMsg from '@/modules/common/components/ErrorMsg'
import Table from '@/modules/common/components/customTable/Table'
import siteService from '@/modules/control-panel/services/siteService'
import swalService from '@/modules/common/services/swalService'
import { months } from '../consts.js'

export default {
  props: {
    shiftParentType: {
      type: String,
      required: true,
      validator: function(value) {
        // The value must match one of these strings
        return ['site', 'shift'].includes(value)
      },
    },
    siteId: { type: Number, required: true },
    companyId: { type: Number, required: true },
    companyName: { type: String, required: true },
    roomId: { type: Number, required: false },
    companyCountry: { type: String, required: false },
    shifts: { type: Object, required: false },
  },
  data() {
    return {
      holidays: [],
      offdays: { holidays: {}, custom: {} },
      offday: { name: '', year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: '', shiftIds: [] },
      errors: { name: false, year: false, month: false, day: false },
      editedDay: null,
      headerTxt: '',
      year: new Date().getFullYear(),
      country: null,
      isAddingHoliday: false,
      isShiftsOpen: false,
      shiftsAvailabilty: null,
      isNewlyAdded: false,
    }
  },
  async created() {
    await this.updateViewData()
  },
  methods: {
    async updateViewData() {
      await this.getSiteOffDays()
      this.country = this.countries[this.companyCountry.toLowerCase()]
      this.shiftsAvailabilty = { ...this.shifts }
      this.$emit('loaded')
    },
    async openModal(type) {
      this.headerTxt = type

      try {
        if (type === 'add_holiday') await this.fetchHolidays()
        this.isAddingHoliday = true
      } catch (err) {
        this.closeModal()
      }
    },
    closeModal() {
      this.isAddingHoliday = false
      this.errors = { name: false, year: false, month: false, day: false }
      this.offday = {
        name: '',
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: '',
        shiftIds: [],
      }
      this.getSiteOffDays()
    },
    async fetchHolidays() {
      const { country, year } = this

      try {
        this.holidays = await siteService.getHolidayList(country, year)
      } catch (err) {
        swalService.errorMsg('Could not load holidays list. Please try again later.')
        throw err
      }
    },
    async getSiteOffDays() {
      try {
        this.offdays = await siteService.getSiteOffDays(this.siteId)
      } catch (err) {
        swalService.errorMsg("Could not load site's holidays & days-off. Please reload to try again.")
      }
    },
    async handleSubmit() {
      try {
        await siteService.updateSiteOffDays(this.siteId, this.offdays)
        swalService.savedMsg(this.$t('Your work has been saved'))
        this.closeModal()
      } catch (err) {
        swalService.errorMsg()
      }
    },
    handleCheck(value, holiday) {
      const { country } = this
      const [name, year, month, day] = holiday.split('-')

      if (!this.offdays[this.offdayType][holiday]) {
        this.offdays[this.offdayType][holiday] = {
          name,
          country,
          start: `${year}-${month}-${day}`,
          lastChanged: new Date().toISOString(),
        }
        this.isNewlyAdded = true
      }
      this.editedDay = this.offdays[this.offdayType][holiday]
      this.getAvailableShifts()
      this.offdays[this.offdayType][holiday].shiftIds = this.selectShifts(this.offdayType, holiday)
      this.offdays[this.offdayType][holiday].isChecked = value
    },
    handleInput(field, value) {
      const validations = {
        day: '^(?:0?[1-9]|1[0-9]|2[0-9]|3[01])(?:,(?:0?[1-9]|1[0-9]|2[0-9]|3[01]))*$',
        month: '^([1-9]|1[0-2])$',
        year: '^(?:20[2-9][3-9]|[3-9]\d{3}|[1-9]\d{4,})$',
        name: '^.{1,25}$',
      }

      this.errors[field] = !new RegExp(validations[field]).test(value)

      if (!this.errors[field]) this.offday[field] = value
      else if (field === 'day' && this.errors['day']) this.offday['day'] = ''

      // if (field === 'day') this.offday.shiftIds = this.offday.shiftIds.filter(id => this.getAvailableShifts(id))
    },
    handleMultiSelectInput(values) {
      if (!values) return
      this.shifts = values
    },
    toggleSelect() {
      this.openSelectMenu = !this.openSelectMenu
    },
    addOffday() {
      const {
        offday: { year, month, name, shiftIds },
        errors,
      } = this
      const days = this.offday.day.split(',')

      if (errors.name || errors.year || errors.month || errors.day) return

      days.forEach((day) => {
        const date = new Date(year, month - 1, day)

        if (!(date.getFullYear() == year && date.getMonth() == month - 1 && date.getDate() == day)) {
          this.errors['day'] = true
          return
        }

        const start = `${year}-${month}-${day}`
        if (Object.values(this.offdays.custom).find(({ start: s }) => s === start)) return

        this.offdays.custom[`${name}-${start}`] = {
          name,
          start,
          isChecked: true,
          lastChanged: new Date().toISOString(),
        }
        this.isNewlyAdded = true
        this.editedDay = this.offdays.custom[`${name}-${start}`]
        this.getAvailableShifts()
        this.offdays.custom[`${name}-${start}`].shiftIds = this.selectShifts('custom', `${name}-${start}`)
        this.isNewlyAdded = false
      })

      this.offday = {
        name: '',
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: '',
        shiftIds: [],
      }
    },
    async deleteOffDay(key) {
      const { value } = await swalService.confirmMsg()
      if (!value) return

      delete this.offdays.custom[key]
      this.offdays = { ...this.offdays }
      swalService.deletedMsg()
    },
    async editShifts(type, name, { x, y }) {
      if (!this.offdays[type][name] || (this.offdays[type][name] && !this.offdays[type][name].isChecked)) this.handleCheck(true, name)
      this.editedDay = this.offdays[type][name]

      await this.$nextTick()

      this.getAvailableShifts()
      if (!this.isNewlyAdded) this.editedDay.shiftIds = this.selectShifts(type, name)
      this.isShiftsOpen = true

      const elContent = document.querySelector('.multi-select')
      elContent.style.top = `${y / 2 + 150}px`
      elContent.style.left = `${x / 2}px`

      await this.$nextTick()

      this.$refs['multi-select'].openSelect()
      this.isNewlyAdded = false
    },
    getAvailableShifts() {
      if (!this.editedDay) return
      const { start } = this.editedDay
      const [year, month, day] = start.split('-')
      Object.values(this.shifts).forEach(({ id, week_arrivals }) => (this.shiftsAvailabilty[id].disabled = !week_arrivals.includes(new Date(year, month - 1, day).getDay() + 1)))
    },
    selectShifts(type, name) {
      return Object.values(this.shiftsAvailabilty)
        .filter(({ id, disabled }) => (!this.isNewlyAdded && this.offdays?.[type]?.[name]?.shiftIds?.includes(id) && !disabled) || (this.isNewlyAdded && !disabled))
        .map(({ id }) => id)
    },
  },
  computed: {
    date() {
      return (type, date) =>
        new Date(date).toLocaleDateString(JSON.parse(localStorage['language']), {
          [type]: (type === 'year') | (type === 'day') ? 'numeric' : 'long',
        })
    },
    months() {
      return months
    },
    years() {
      return [new Date().getFullYear(), new Date().getFullYear() + 1]
    },
    countries() {
      return {
        il: 'israel',
        israel: 'il',
        us: 'USA',
        'united states': 'us',
        pl: 'poland',
        poland: 'pl',
        mx: 'mexico',
        mexico: 'mx',
        cy: 'cyprus',
        cyprus: 'cy',
        br: 'brazil',
        brazil: 'br',
      }
    },
    isError() {
      const {
        offday: { name, day },
        errors,
      } = this
      return errors.name || !name || errors.year || errors.month || (errors.day || !day)
    },
    isChecked() {
      return (name, start) => this.offdays[this.offdayType]?.[`${name}-${start}`]?.isChecked
    },
    selectedDays() {
      const { offdays } = this
      const selectedDays = Object.values({ ...offdays.custom, ...offdays.holidays }).filter(({ isChecked }) => isChecked)
      if (!selectedDays.length) return [{ name: 'placeholder', month: '', day: '', year: '' }]
      return selectedDays.map(({ name, start }) => {
        const [year, month, day] = start.split('-')
        return { name, month: this.$t(months[month[0] < 1 ? month[1] : month]), day: day[0] < 1 ? day[1] : day, year }
      })
    },
    offdayType() {
      return this.headerTxt === 'add_holiday' ? 'holidays' : 'custom'
    },
    selectedShifts() {
      return (shifts) => {
        return shifts.reduce((acc, id) => acc + this.shifts[id].shift_name + ', ', '')
      }
    },
    isDisabledShift() {
      return (id) => this.shiftsAvailabilty[id]?.disabled
    },
  },
  watch: {
    siteId: {
      async handler() {
        await this.updateViewData()
      },
      immediate: true,
    },
    shifts: {
      handler() {
        this.shiftsAvailabilty = { ...this.shifts }
      },
      immediate: true,
      deep: true,
    },
  },
  components: {
    Modal,
    Input,
    Checkbox,
    Select,
    Option,
    Button,
    ErrorMsg,
    Table,
  },
}
</script>

<style lang="scss">
.holiday-table,
.main:has(.shift-holiday) .main-content:not(:has(.form-container)) {
  .secondary-title {
    font-size: 24px !important;
  }

  .b-input {
    position: static !important;
    height: 0.85rem !important;
    width: 0.85rem !important;

    &::after {
      width: 0.2rem !important;
      height: 0.5rem !important;
      left: 0.37rem !important;
      top: 0.125rem !important;
    }
  }

  .main-content {
    margin-inline: 40px !important;
    gap: unset !important;
    margin-top: 40px !important;
  }

  .footer {
    justify-content: end !important;
    margin-inline-end: 40px !important;
  }

  .close-btn {
    margin-inline-end: 40px !important;
  }

  .md-field {
    min-height: 46px !important;
    margin: 0 0 0 !important;

    .md-input {
      height: unset !important;
      max-height: 95% !important;
      width: 100px;
    }

    label {
      top: -28px;
    }

    &.md-has-value label,
    &.md-focused label {
      top: -28px;
      font-size: 16px;
    }
  }

  .error-msg {
    position: absolute;
    top: -33px;
    left: 0;
  }

  .table-row {
    grid-template-columns: 2fr 1fr 1fr 1fr !important;
    div:first-of-type {
      width: unset !important;
      max-width: 170px !important;
    }
    div:nth-of-type(n + 2) {
      width: unset !important;
      max-width: 70px !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/styles/vars';
@import '@/styles/mixins.scss';
$table-grey: #7f7f7f;

.holiday-table {
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-inline: 50px;
  width: 100%;

  & > div:first-of-type {
    display: flex;
    justify-content: space-between;

    span {
      color: $table-grey;
      font-weight: bold;
    }

    div {
      display: flex;
      gap: 16px;
    }
  }

  & > div:nth-of-type(2) {
    padding-block: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #e7e7e7;
  }

  .add-button {
    cursor: pointer;
    color: $blue;
    line-height: unset;
  }
}

.holiday-table .main-content {
  gap: unset;
}

.main-content button {
  margin-block-end: 20px;
  width: 50px;
  align-self: center;
}

.selects {
  display: flex;
  justify-content: space-between;
  gap: 22px;
  margin-block-end: 20px;

  & > div {
    display: flex;
    flex-direction: column;
    width: 50%;
    color: $table-grey;
    gap: 8px;
    position: relative;
  }

  .select {
    height: 46px;
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    padding: 0 5px;
    margin: 0;
    width: unset;
  }

  &.offday .input {
    width: 140px;
    padding: 0 14px;
    border: 1px solid $grey-border-color;
    border-radius: 4px;
    padding: 0 10px;
  }

  &.offday .select {
    width: 120px;
  }
}

.multi-select.offday {
  visibility: hidden;
  width: 0;
  height: 0;
  min-height: 0;
  padding: 0;
  margin: 0;
  position: fixed;
  transform: translate(-50%, -50%);

  * {
    visibility: none;
    width: 0;
    height: 0;
    min-height: 0;
    padding: 0;
    margin: 0;
  }
}

.holidays {
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid #e7e7e7;
  padding-block: 10px;
  max-height: 310px;
  min-height: 310px;
  min-width: 600px;
  overflow-y: scroll;
  @include scroll;

  .holiday {
    position: relative;
    display: flex;
    font-size: 14px;
    align-items: center;

    & > * {
      margin-inline-end: 15px;
    }

    & > label {
      margin-inline-start: 25px;
    }

    .name {
      width: 200px;
    }

    .month {
      width: 80px;
    }

    .day {
      width: 80px;
    }

    .year {
      width: 45px;
    }

    &:not(:last-of-type)::after {
      content: '';
      height: 1px;
      background-color: #e7e7e7;
      width: 93%;
      position: absolute;
      bottom: -7px;
      left: 17px;
      pointer-events: none;
    }
    &:hover {
      .action,
      .cell-button {
        visibility: unset;
      }
    }
  }
}

.titles {
  display: flex;
  font-size: 16px;
  margin-block-end: 6px;

  & > * {
    margin-inline-end: 15px;
  }

  & > div {
    width: 80px;
  }

  & > div:first-of-type {
    margin-inline-start: 25px;
    width: 230px;
  }

  & > div:last-of-type {
    width: 45px;
  }
}

.cell-icon {
  &:hover {
    cursor: pointer;
  }

  &.action {
    visibility: hidden;
  }
}

.selected-days-table {
  border: 1px solid #e7e7e7;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  max-height: 320px;
  @include scroll;

  .shift-holiday & {
    max-height: 660px;
  }
}
</style>
