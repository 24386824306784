<template>
  <div class="chart-filter">
    <Select
      @input="updateLocalFilter('viewTimeBy', $event)"
      :placeholder="$t('view_by_days')"
      class="chart-filter-select"
    >
      <Option
        v-for="item in viewTimeBy"
        :key="item.label"
        :label="$t(item.label)"
        :value="item.value"
      />
    </Select>
  </div>
</template>

<script>
import Option from "@/modules/common/components/Option";
import Select from "@/modules/common/components/Select";

export default {
  data() {
    return {
      viewTimeBy: [
        { label: "view_by_years", value: "year" },
        { label: "view_by_months", value: "month" },
        { label: "view_by_weeks", value: "week" },
        { label: "view_by_days", value: "day" }
      ],
      localFilter: { viewTimeBy: "day", men: false, women: false },
    };
  },
  methods: {
    updateLocalFilter(field, val) {
      this.$emit("updateLocalFilter", field, val);
    },
  },
  components: {
    Select,
    Option
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins";

.chart-filter {
  margin-bottom: 40px;
  display: flex;
  align-items: center;

  .gender-btn {
    height: 46px;
  }
  .chart-filter-select {
    margin-bottom: unset;
    margin-top: unset;
    margin-inline-end: 10px;
  }
}

.md-field.chart-filter-select {
  width: 12.7vw;
  min-height: 4.2vh;
  @include border(#e7e7e7);
  border-radius: 4px;
  padding-top: 0;
}
</style>
