<template>
	<section class="overview-container">
		<OverviewLineChartList v-if="chartData" :chartData="chartData" :dynamicChartDates="dynamicChartDates" />
		<OverviewRoomTable
			v-if="roomsData && roomsData.length && realTimeData"
			:shift="currShift"
			:roomsData="roomsData"
		/>
		<OverviewDonuts v-if="analyticsData" :analyticsChartData="analyticsData.charts" />
	</section>
</template>

<script>
import OverviewDonuts from '../components/OverviewDonuts'
import OverviewRoomTable from '../components/OverviewRoomTable'
import OverviewLineChartList from '../components/OverviewLineChartList'
import { OverviewLineChartsData } from '../services/performanceChartService'
import { getOverviewData, getOverviewSums } from '../services/performanceService'
import shiftService from '../../control-panel/services/shiftService'
import realTimeService from '../../real-time/services/realTimeService'
import chartService from '../services/performanceChartService'
import util from '../../common/services/utilService'
import { overviewLineChartsColors, trendArrowsBackgroundColors } from '../consts/consts'
import { mapGetters } from 'vuex'
import scssVars from '@/styles/vars.scss'
import io from 'socket.io-client/dist/socket.io'

const datasetMatchingProperties = {
	borderWidth: 1,
	pointHitRadius: 5,
	pointHoverRadius: 5,
	hoverBorderWidth: 3,
	pointRadius: 2
}
const SOCKET_URL = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:3000'

export default {
	data() {
		return {
			chartData: null,
			realTimeData: null,
			roomsData: null,
			currShift: null,
			socket: null,
			yearChartData: null,
			isCustomDates: false,
			trendColors: {
				avg_score: { up: scssVars.trendArrowGreen, down: scssVars.trendArrowRed },
				complaints: { up: scssVars.trendArrowRed, down: scssVars.trendArrowGreen },
				response_time_SLA: { up: scssVars.trendArrowRed, down: scssVars.trendArrowGreen },
				time_saved: { up: scssVars.trendArrowGreen, down: scssVars.trendArrowRed }
			}
		}
	},
	async created() {
		const { company, site, rooms, timeType, time } = this.filterSelected
		this.isCustomDates = timeType === 'custom_time'
		const filter = { company, site, rooms }
		await this.getRealTimeData(site)
		this.handleIncomingRealTimeData(site)
		const [lastMonthFilter, lastYearFilter] = [util.getTimeByType('last_month'), util.getTimeByType('last_year')]
		const monthlyDataPrm = getOverviewData({ ...filter, time: lastMonthFilter, viewTimeBy: 'day' })
		const otherDataPrm = getOverviewData({
			...filter,
			timeType,
			time: this.isCustomDates ? time : lastYearFilter,
			viewTimeBy: 'month'
		})
		const [monthData, otherData] = await Promise.all([monthlyDataPrm, otherDataPrm])

		//month charts
		const monthSums = getOverviewSums(monthData)

		const monthOverviewData = new OverviewLineChartsData(JSON.parse(JSON.stringify(monthData)))

		const chartData = []
		for (const property in monthOverviewData) {
			const labels = monthOverviewData.labels(property)
			const values =
				property === 'time_saved'
					? monthOverviewData.values(property).map((val) => val && val / 60)
					: monthOverviewData.values(property)
			this.removeLabelsWithoutData(labels, values)
			const { start, end } = chartService.trendStartEnd(values)
			const isTrendUp = start < end
			chartData.push({
				labels,
				dataset: [
					{
						...datasetMatchingProperties,
						isTrendUp,
						trendArrowBackground: this.trendColors[property][isTrendUp ? 'up' : 'down'],
						sum:
							(property === 'complaints'
								? util.kFormatter(monthSums[property])
								: util.kMinutesToHours(monthSums[property])) +
							(['time_saved', 'response_time_SLA'].includes(property) && monthSums[property] < 999
								? ` ${this.$t('min')}`
								: ''),
						label: this.$t(property),
						data: values,
						pointBackgroundColor: overviewLineChartsColors[property],
						borderColor: overviewLineChartsColors[property],
						pointHoverBackgroundColor: 'white'
					}
				]
			})
		}

		const otherChartData = new OverviewLineChartsData(otherData)
		const otherDataSums = getOverviewSums(otherData)
		for (const property in monthOverviewData) {
			const labels = this.isCustomDates
				? otherChartData.labels(property)
				: otherChartData.labels(property, this.$t('monthMap'))
			const values =
				property === 'time_saved'
					? otherChartData.values(property).map((val) => val && val / 60)
					: otherChartData.values(property)
			this.removeLabelsWithoutData(labels, values)
			const { start, end } = chartService.trendStartEnd(values)
			const isTrendUp = start < end
			chartData.push({
				labels,
				dataset: [
					{
						...datasetMatchingProperties,
						isTrendUp,
						trendArrowBackground: trendArrowsBackgroundColors[property + '_static'],
						trendArrowColor: this.trendColors[property][isTrendUp ? 'up' : 'down'],
						sum:
							(property === 'complaints'
								? util.kFormatter(otherDataSums[property])
								: util.kMinutesToHours(otherDataSums[property])) +
							(['time_saved', 'response_time_SLA'].includes(property) && monthSums[property] < 999
								? ` ${this.$t('min')}`
								: ''),
						gradientEnd: overviewLineChartsColors[property + '_static'],
						label: this.$t(property),
						data: values,
						borderColor: 'white',
						pointHoverBorderColor: 'white',
						pointHoverBackgroundColor: overviewLineChartsColors[property + '_static']
					}
				]
			})
		}
		this.chartData = chartData
		this.roomsData = this.$store.getters.analyticsData.roomsData
			.map((a) => ({ name: a.displayName, score: a.score, trend: a.trend }))
			.sort((a, b) => a.score - b.score)
		this.mergedRoomsDataWithRealTime(this.roomsData, this.realTimeData)
	},
	methods: {
		async getRealTimeData(siteId) {
			if (!siteId) return
			const shift = await shiftService.getCurrentShift(siteId)
			if (shift) {
				this.currShift = shift
				const timeFrom = realTimeService.filterFromByShiftStartHour(shift.start_hour)
				this.realTimeData = await realTimeService.getRealTimeData(siteId, timeFrom)
			} else this.realTimeData = []
			return this.realTimeData
		},
		mergedRoomsDataWithRealTime(roomsData, realTimeData) {
			if (!realTimeData.length || !roomsData) return
			let copyRoomsData = JSON.parse(JSON.stringify(roomsData))
			const copyRealTimeData = JSON.parse(JSON.stringify(realTimeData))
			const mappedRealTimeData = copyRealTimeData.reduce((acc, room) => {
				if (!acc[room.name]) acc[room.name] = room
				return acc
			}, {})
			copyRoomsData.map((room) => {
				if (mappedRealTimeData[room.name] && mappedRealTimeData[room.name].arrival) {
					room.arrival = mappedRealTimeData[room.name].arrival
				} else room.arrival = { time: '', count: '' }
				room.openTime = mappedRealTimeData[room.name].openTime || ''
				room.isOpen = mappedRealTimeData[room.name].isOpen
			})
			copyRoomsData.sort((a, b) => {
				if (a.arrival.time || b.arrival.time) {
					if (b.arrival.time > a.arrival.time) return 1
					if (a.arrival.time > b.arrival.time) return -1
				} else if (a.openTime || b.openTime) {
					if (b.openTime > a.openTime) return 1
					if (a.openTime > b.openTime) return -1
				}
				return 0
			})
			this.roomsData = copyRoomsData
			return copyRoomsData
		},
		async handleIncomingRealTimeData(siteId) {
			if (this.socket) this.socket.disconnect()
			this.socket = io.connect(SOCKET_URL, {
				// withCredentials: true,
				// transports: ['websocket', 'polling']
			})
			const shift = await shiftService.getCurrentShift(siteId)
			if (shift) {
				const timeFrom = realTimeService.filterFromByShiftStartHour(shift.start_hour)
				this.socket.emit('site-id', { siteId: siteId, timeFrom: timeFrom.toUTCString() })
				this.socket.on('site-data', ({ cleanRealTimeData }) => {
					const roomsData = this.$store.getters.analyticsData.roomsData
						.map((a) => ({ name: a.displayName, score: a.score, trend: a.trend }))
						.sort((a, b) => a.score - b.score)
					this.mergedRoomsDataWithRealTime(roomsData, cleanRealTimeData)
				})
			}
		},
		removeLabelsWithoutData(labels, values) {
			if (labels.length !== values.length) return
			for (let i = 0; i < labels.length; i++) {
				if (values[i] === false) {
					labels.splice(i, 1)
					values.splice(i, 1)
					i--
				}
			}
		}
	},
	computed: {
		...mapGetters(['analyticsData', 'filterSelected']),
		dynamicChartDates() {
			if (this.isCustomDates) {
				const { time } = this.filterSelected
				const { timeFrom, timeTo } = time
				const timeFromArr = timeFrom.split('-')
				const timeToArr = timeTo.split('-')
				const timeFromMonth = timeFromArr[1]
				const timeFromYear = timeFromArr[0].slice(2,4)
				const timeToMonth = timeToArr[1]
				const timeToYear = timeToArr[0].slice(2,4)
				return `${timeFromMonth}/${timeFromYear} - ${timeToMonth}/${timeToYear}`
			}
			return 'last_year'
		}
	},
	destroyed() {
		if (this.socket) this.socket.disconnect()
	},
	components: { OverviewDonuts, OverviewRoomTable, OverviewLineChartList }
}
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";
@import "@/styles/mixins.scss";
.overview-container {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(6, 1fr);
  height: 75vh;

  @include screen-size(xs) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: auto;
  }
}

</style>
