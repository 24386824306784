<template>
  <div class="working-area-item-container">
    <h4>{{workingArea.area_name}}</h4>
    <h4>{{workingArea.rooms_number}}</h4>
    <h4 class="long-list">{{workingArea.rooms_names.join(' / ')}}</h4>
    <h4 class="long-list">{{workingArea.shifts.join(' / ')}}</h4>
    <!-- <h4 class="phone_number">{{workingArea.assign_number}}</h4> -->
    <CheckBox :disabled="true" :value="workingArea.sms" class="checkbox-container" />
    <CheckBox :disabled="true" :value="workingArea.email" class="checkbox-container" />
    <CheckBox :disabled="true" :value="workingArea.counter" />
    <!-- <CheckBox :disabled="true" :value="workingArea.cleandex" /> -->
    <CheckBox :disabled="true" :value="workingArea.offline_calls" />
    <CheckBox :disabled="true" :value="workingArea.notified" />
    <CheckBox :disabled="true" :value="workingArea.api" />
    <template v-if="loggedUser.type === 'admin'">
      <img class="edit-icon" @click="emitEdit" src="@/assets/survey/edit-selected.svg" />
      <img class="edit-icon" @click="emitRemove" src="@/assets/delete-icon.svg" />
    </template>
  </div>
</template>

<script>
import CheckBox from '@/modules/surveys/components/CheckBox.vue';
import { mapGetters } from 'vuex';

export default {
  props: {
    workingArea: {
      type: Object,
      required: true
    }
  },
  methods: {
    emitEdit() {
      this.$emit('editWorkingArea', this.workingArea.id)
    },
    emitRemove() {
      this.$emit('removeWorkingArea', this.workingArea.id)
    },
  },
  computed: mapGetters(['loggedUser']),
  components: { CheckBox }
}
</script>

<style lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/vars.scss";

.working-area-item-container {
  @include flex-center;
  width: 80vw;
  height: 5vh;
  margin: 0 auto;
  padding: 0 30px;
  border-bottom: 1px solid $grey-border-color;
  display: grid;
  grid-template-columns: 2.2fr 1fr 6fr 3.8fr 1.6fr repeat(7, 1fr);
  h4 {
    font-size: $font-size-smaller;
    &.long-list {
      width: 90%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  img.edit-icon {
    width: 35%;
    height: 45%;
    margin: 0 auto;
    &:hover {
      cursor: pointer;
    }
  }
  .checkbox-container {
    margin: 0 auto;
    width: 1.3vw;
    height: 1.3vw;
    &:hover {
      cursor: pointer;
    }
  }
  .phone_number {
    color: $blue;
  }
}
</style>