<template>
  <div class="info-panel" v-if="overviewData">
    <div v-for="(card, index) in cards" :key="card.label">
      <BarItem
        componentType="chart-filter-button"
        isAmended="InfoPanel"
        :activeColor="card.color"
        :iconUrl="card.iconUrl"
        :text="getCardText(index)"
        :label="$t(card.name)"
        :class="`info-card ${index === cards.length - 1 ? 'last' : null}`"
        @click="handleCardClick($event, index)"
      />
    </div>
  </div>
</template>

<script>
import BarItem from "./BarItem";
import util from "@/modules/common/services/utilService";
import { infoCards } from "../consts/consts";

export default {
  data() {
    return {
      cards: infoCards
    };
  },
  methods: {
    getCardText(index) {
      if (this.cards[index].name === "avg_score") return this.avgScore;
      if (this.cards[index].name === "time_saved") return this.timeSaved;
      if (this.cards[index].name === "est_ROI") return this.estRoi;
      else return this.cards[index].text;
    },
    handleCardClick({ isActive }, index) {
    }
  },
  computed: {
    overviewData() {
      return this.$store.getters.overviewData;
    },
    avgScore() {
      if (this.overviewData && this.overviewData.score) {
        return this.overviewData.score.reduce((a, b) => a + b, 0) + "";
      }
    },
    timeSaved() {
      if (this.overviewData && this.overviewData.timeSave) {
        const res = this.overviewData.timeSave.reduce((a, b) => a + b, 0);
        return util.kFormatter(res) + "min";
      }
    },
    estRoi() {
      if (this.overviewData && this.overviewData.estRoi) {
        const res = this.overviewData.estRoi.reduce((a, b) => a + b, 0);
        return "$" + util.kFormatter(res);
      }
    }
  },
  components: {
    BarItem
  }
};
</script>

<style lang="scss">
.info-panel {
  display: flex;
}
</style>