import ajax from '../../common/services/httpService'
const BASE_URL = 'contact'

async function getFilterdContacts(filter) {
	const res = await ajax.get(`${BASE_URL}/filtered`, null, filter)
	return res
}

async function getContactById(id) {
	const res = await ajax.get(`${BASE_URL}/${id}`)
	return res
}

async function removeContactById(id) {
	const res = await ajax.delete(`${BASE_URL}/${id}`)
	return res
}

async function saveContact({ id, username, phone, device, lang, userId, workingAreaIds, isActive }) {
	const contactToSave = {
		id, // Contact ID
		username,
		phone, // Phone
		lang, // Language
		device, // Device
		user_id: userId, // User ID
		working_area_id: workingAreaIds, // Working Area IDs
		is_active: isActive // Is Active,
	}
	if (id) {
		const res = await ajax.put(`${BASE_URL}`, {
			contact: contactToSave
		})
		return res
	}
	const res = await ajax.post(`${BASE_URL}`, {
		contact: contactToSave
	})
	return res
}

function getDefaultContact() {
	return {
		phone: '',
		username: '',
		lang: '',
		userId: '',
		device: '',
		workingAreaIds: [],
		isActive: true
	}
}

export { getFilterdContacts, getContactById, removeContactById, saveContact, getDefaultContact }
