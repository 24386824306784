import JSZip from 'jszip';
import  { saveAs } from 'file-saver';

/**
 * @param {string} folderName 
 * @param  {...{ name: string; file: any; }} args 
 */
async function generateFolder(folderName, ...args) {
    const zip = new JSZip();
    const folder = zip.folder(folderName);
    args.forEach(async arg => {
        folder.file(arg.name, arg.file);
    });
    const result = await folder.generateAsync({ type: 'blob' });
    saveAs(result, 'buzzztech.zip');
}

async function downloadImagesAsZip(imagesInfo) {
    const zip = new JSZip();

    // Iterate over the image URLs
    for (let i = 0; i < imagesInfo.length; i++) {
    const { url, name } = imagesInfo[i]
  
      // Convert the data URL to a Blob
      const byteString = atob(url.split(',')[1]);
      const mimeString = url.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let j = 0; j < byteString.length; j++) {
        ia[j] = byteString.charCodeAt(j);
      }
      const blob = new Blob([ab], { type: mimeString });
      zip.file(name, blob);
    }
  
    // Generate the zip file asynchronously
    return zip.generateAsync({ type: 'blob' });

}

export default {
    generateFolder,
    downloadImagesAsZip
}