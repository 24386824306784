<template>
	<section v-if="roomsData" class="overview-room-table">
		<div :class="['table-header', 'table-row']">
			<div
				v-for="header in inSurveyOverviewPage ? surveyOverviewTableHeader : overviewTableHeader"
				:key="header"
				@click="sortTable(header)"
				:class="{ sorted: sortKey === header }"
			>
				{{ $t(header) }}
				<!-- Show sort indicator -->
				<!-- <span v-if="sortKey === header">
					{{ sortOrder === 1 ? '▲' : '▼' }}
				</span> -->
			</div>
		</div>
		<div :class="[{ 'custom-table-body': inSurveyOverviewPage }]" class="table-body">
			<div class="table-row" v-for="(room, index) in roomsData" :key="room.name + index">
				<div class="name">{{ room.name }}</div>
				<div v-if="!inSurveyOverviewPage" class="arrival-container">
					<div class="arrival" v-if="room.arrival && room.arrival.time !== ''">
						{{ new Date(room.arrival.time).toTimeString().slice(0, 5) }}
						<div class="arrival-count">{{ room.arrival.count }}</div>
					</div>
				</div>
				<img v-if="!inSurveyOverviewPage" :src="iconToShow(room.openTime, room.isOpen)" />
				<div v-if="!inSurveyOverviewPage" class="score" :style="room.score | statusColor">
					{{ Math.round(room.score) }}
					<img :src="require('@/assets/performance/score-trend-up.svg')" :class="room.trend" />
				</div>
				<div class="arrival" v-if="inSurveyOverviewPage">{{ room.count }}</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		roomsData: { type: Array, required: true },
		shift: { type: Object, required: false },
		inSurveyOverviewPage: { type: Boolean, default: false }
	},
	data() {
		return {
			overviewTableHeader: ['room_name', 'arrival', 'status', 'score'],
			surveyOverviewTableHeader: ['room_name', 'clicks'],
			sortKey: null,
			sortOrder: 1,
			originalRoomsData: [] // To store the default array order
		}
	},
	created() {
		this.originalRoomsData = [...this.roomsData] // Store a copy of the original data
	},
	methods: {
		iconToShow(openTime, isOpen) {
			let icon
			try {
				const minPassed = Math.floor((new Date().getTime() - new Date(openTime).getTime()) / (1000 * 60))
				if (!isOpen) icon = require('@/assets/real_time_icons/OK.svg')
				else if (minPassed > this.shift.max_sla) icon = require('@/assets/performance/warning.svg')
				else icon = require('@/assets/real_time_icons/warning_orange.svg')
			} catch (err) {
				icon = null
			}
			return icon
		},
		getIconSortValue(openTime, isOpen) {
			let minPassed
			try {
				minPassed = Math.floor((new Date().getTime() - new Date(openTime).getTime()) / (1000 * 60))
			} catch (err) {
				minPassed = Infinity
			}
			if (!isOpen) return 0
			if (minPassed > this.shift.max_sla) return 2
			return 1
		},
		sortTable(header) {
			if (this.sortKey === header) {
				this.sortOrder *= -1
			} else {
				this.sortKey = header
				this.sortOrder = 1
			}
			this.roomsData = [...this.originalRoomsData] // Reset to the original order before sorting
			this.sortData()
		},
		sortData() {
			const header = this.sortKey

			this.roomsData.sort((a, b) => {
				let aValue, bValue

				if (header === 'arrival') {
					aValue = a.arrival ? new Date(a.arrival.time).getTime() : 0
					bValue = b.arrival ? new Date(b.arrival.time).getTime() : 0
				} else if (header === 'status') {
					aValue = this.getIconSortValue(a.openTime, a.isOpen)
					bValue = this.getIconSortValue(b.openTime, b.isOpen)
				} else {
					if (header === 'room_name') {
						aValue = a.name.toLowerCase()
						bValue = b.name.toLowerCase()
					} else {
						aValue = typeof a[header] === 'string' ? a[header].toLowerCase() : a[header]
						bValue = typeof b[header] === 'string' ? b[header].toLowerCase() : b[header]
					}
				}

				if (aValue < bValue) return this.sortOrder
				if (aValue > bValue) return -this.sortOrder
				return 0
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';

.overview-room-table {
	grid-column: 13 / 17;
	grid-row: 1 / 7;
	padding: 10px 5px;
	margin-bottom: 3%;
	@include shadow-border;

	.table-body {
		height: 94%;
		overflow-y: scroll;
	}
	.custom-table-body {
		height: 92%;
	}

	.table-row {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid $grey-border-color;

		&.table-header div {
			padding: 10px 0px;
			color: #848593;
			font-size: 1.125rem;
			cursor: pointer;
			&.sorted {
				font-weight: bold;
				color: #000;
			}
		}

		div {
			&:first-child {
				padding: 10px 0px;
			}
			&.name {
				font-weight: $font-weight-bold;
				font-size: $font-size-small;
				color: #707070;
				width: 30%;
			}

			&.score {
				font-weight: $font-weight-bold;
				color: $white;
			}
		}

		.score {
			width: 20%;
			padding: 5px;
			display: flex;
			justify-content: space-around;
			align-items: center;
			img.down {
				transform: rotate(180deg);
			}
		}
	}
}
.arrival-container {
	width: 25%;
}
.arrival {
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.arrival-count {
	border: 1px solid #5f9b46;
	border-radius: 50%;
	@include flex-center;
	width: 20px;
	padding: 10px;
	height: 20px;
}
</style>
