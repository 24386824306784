<template>
  <section class="issue-number-list-container">
    <div class="header">{{$t('number_of_issues')}}</div>
    <div class="issue-list">
      <div class="issue-item" v-for="(issue,idx) in issues" :key="idx">
        <h4>{{issue.percent}}%</h4>
        <h5>{{issue.val}} issues</h5>
        <span class="bar"></span>
        <h4>{{ issue.time }}</h4>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      issues: [
        { time: "Sep 18", val: 7, percent: 10 },
        { time: "Oct 18", val: 5, percent: 7 },
        { time: "Nov 18", val: 4, percent: 5 },
        { time: "Dec 18", val: 1, percent: 2 },
        { time: "Jan 19", val: 4, percent: 5 },
        { time: "Feb 19", val: 5, percent: 6 },
        { time: "Mar 19", val: 5, percent: 6 },
        { time: "Apr 19", val: 4, percent: 5 },
        { time: "May 19", val: 5, percent: 7 },
        { time: "Jun 19", val: 5, percent: 6 },
        { time: "Jul 19", val: 4, percent: 5 },
        { time: "Aug 19", val: 2, percent: 3 }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
@import "@/styles/vars.scss";

.issue-number-list-container {
  // text-transform: capitalize;
  width: 70vw;
  height: 31vh;
  background-color: $white;
  box-shadow: 0 0 1px #bdbdbd;
  display: flex;
  flex-direction: column;
  .header {
    padding: 15px 30px;
    border-bottom: 1px solid #eaeaea;
    width: 100%;
    color: #7f7f7f;
    font-size: 16px;
  }
  .issue-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .issue-item {
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      flex: 1;
      height: 100%;
      h4 {
        font-size: 16px;
        color: #7f7f7f;
      }
      h5 {
        font-size: 12px;
        color: #b7b7b7;
      }
      .bar {
        width: 0.5rem;
        border-radius: 0.5rem;
        height: 100%;
        margin: 25px 0;
        background-color: $blue;
      }
    }
  }
}
</style>