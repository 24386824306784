<template>
	<div>
		<Option
			v-for="(option, idx) in options"
			:key="option + idx"
			class="select"
			:value="option.id || option.name || option"
			:label="$t(option.name || option)"
		/>
	</div>
</template>

<script>
import Option from '@/modules/common/components/Option'

export default {
	name: 'OptionList',
	props: {
		options: { required: true }
	},
	components: {
		Option
	}
}
</script>
