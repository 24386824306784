import ajax from '@/modules/common/services/httpService';


export {
    getServiceProviders,
    saveServiceProvider,
    removeServiceProvider
}

async function getServiceProviders(filter) {
    const serviceProvidersMap = await ajax.get('serviceProvider', null, filter)
    const serviceProvidersRestructured = Object.keys(serviceProvidersMap).map(key => {
        const sp = serviceProvidersMap[key]
        const spCopy = {...sp}
        delete spCopy.name
        delete spCopy.companies
        return {
            name: sp.name,
            associated_companies: sp.companies,
            ...spCopy
        }
    })
    return serviceProvidersRestructured
}

async function saveServiceProvider(serviceProvider) {
    if (serviceProvider.id) return await ajax.put('serviceProvider', serviceProvider)
    else return await ajax.post('serviceProvider', serviceProvider)
}

async function removeServiceProvider(id) {
    const res = await ajax.delete('serviceProvider', {id})
    return res
}