import axios from 'axios'
import { sessionLoad, sessionStore, localLoad, localStore } from '../../modules/common/services/storageService'

axios.defaults.withCredentials = true;

const BASE_URL = process.env.NODE_ENV === 'production'
    ? '/api'
    : 'http://localhost:3000/api'

const FAILED_REQUESTS_KEY = 'failedRequests'
const LAST_CLEANER_ARRIVAL_KEY = 'lastCleanerArrival'
const CLEANER_ARRIVAL_TIME = 2

/**
 * @param {Number} servik_id
 * @param {String} device_code
 * @param {boolean} isTablet
 */
async function sendSignal(servik_id, device_code, isTablet = true, roomId, device_type, stageNo = null) {
    if (servik_id === 48 && isTablet) {
        const lastCleanerArrival = new Date(sessionLoad(LAST_CLEANER_ARRIVAL_KEY)).getTime();
        const now = new Date().getTime();
        const diff = (now - lastCleanerArrival) / 1000 / 60
        if (diff < CLEANER_ARRIVAL_TIME) return
        else sessionStore(LAST_CLEANER_ARRIVAL_KEY, now)
    }

    /**@type {{time: Date;
     * device_code: String;
     * payload: { type:String; servik_id:Number; };
     * err: Number;}} */
    const signal = {
        time: new Date(),
        device_code,
        payload: { type: (isTablet) ? 'tablet' : device_type, servik_id },
        err: 0
    };
    if (roomId) signal.payload.room_id = roomId;
    if (isTablet && (stageNo || stageNo !== null)) signal.payload.stage_no = stageNo;
    if(!signal || !signal.device_code) return
   
    axios({
        method: 'post',
        url: 'https://proxy.buzzztech.technology',
        data: signal
    }).catch((err) => {
        signal.offline = true;
        saveFailedRequest(signal)
    })
    axios({
        method: 'post',
        url: `${BASE_URL}/notification/survey`,
        data: signal
    })

}
function saveFailedRequest(signal) {
    let failedRequests = localLoad(FAILED_REQUESTS_KEY)
    if (!failedRequests) failedRequests = []
    failedRequests.push({
        method: 'post',
        url: 'https://proxy.buzzztech.technology',
        data: signal
    })
    localStore(FAILED_REQUESTS_KEY, failedRequests)
}

async function handleFailedRequests() {
    if (!navigator.onLine) return
    let failedRequests = localLoad(FAILED_REQUESTS_KEY)
    if (!failedRequests) return
    const requestPrms = [];
    failedRequests.forEach(request => requestPrms.push(axios(request)))
    Promise.all(requestPrms).then(res => localStorage.removeItem(FAILED_REQUESTS_KEY))
}

export default { sendSignal, handleFailedRequests };