<template>
  <section 
    :class="[isEditClass, 'survey-page-container']"
    :style="isBackgroundImage ? { 
      backgroundImage: `url(${pageImgURL})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '100vh'
      } : {}"
    >
    <div class="text-management-bar" v-if="isEdit">
			<img class="icon" @click="decreaseFontSize" :src="require('@/assets/survey/font-size-decrease-icon.svg')" />
			<img class="icon" @click="increaseFontSize" :src="require('@/assets/survey/font-size-increase-icon.svg')" />
			<img class="icon" @click="alignText('left')" :src="require('@/assets/survey/align-left.svg')" />
			<img class="icon" @click="alignText('center')" :src="require('@/assets/survey/align-center.svg')" />
			<img class="icon" @click="alignText('right')" :src="require('@/assets/survey/align-right.svg')" />
      <input v-model="page.question.color" class="color-picker" type="color" name="btnColor" />
		</div>
    <textarea
      v-if="isEdit"
      class="question"
      :placeholder="page.question.text || 'Enter Stage Question Here'"
      v-model="page.question.text"
      :style="questionStyles"
      dir="auto"
    />
    <!-- <textarea
      v-if="isEdit"
      class="question"
      :placeholder="page.question || 'Enter Stage Question Here'"
      v-model="page.question"
      dir="auto"
    /> -->
    <div class="question" :style="questionStyles" v-else>{{ pageQuestion }}</div>
    <!-- <div class="question" v-else>{{ pageQuestion }}</div> -->
    <section class="open-text-section" v-if="page.type === 'open_text'">
      <OpenTextInput 
        :openTextProp="page.open_text"
        :isEdit="isEdit"
        @updatePageOpenText="updatePageOpenText($event)" />
    </section>
    <section class="content-section" v-if="page.content && page.content.length > 0">
      <section
        v-if="!isBackgroundImage"
        class="page-img-container resizable"
        :class="{ 'zero-size': page.imgInfo.width === 0 && page.imgInfo.height === 0 }"
        :style="imageStyle"
        >
      <div v-if="pageImgURL.length > 0 && !isBackgroundImage" class="img-container">
        <img :src="pageImgURL" class="img" />
        <img 
          v-if="isEdit"
          @click="deleteImage"
          :src="require('@/assets/delete-icon.svg')"
          class="delete-icon" />
      </div>
      <div v-else-if="isEdit" class="upload-container">
        <label for="img-upload">
          <img class="control upload" :src="require('@/assets/upload_icon.svg')" />
            <input type="file" id="img-upload" @change="showAddedImage" />
        </label>
        <span>{{ $t("image") }}</span>
      </div>
    </section>
    <img 
      v-if="isEdit && pageImgURL.length > 0"
      :src="require('@/assets/full_screen.svg')"
      class="full-screen-icon"
      @click="handleBackgroundImage"
      alt="full screen" />
      <template v-for="(content, idx) in page.content">
        <ContentInput 
          :key="content+idx"
          :contentProp="content"
          :isEdit="isEdit"
          @updateContent="updateContent($event, idx)"
          :textColor="page.question.color"
          />
          <div :key="idx + 'settings'" class="response-settings">
            <img
                v-if="isEdit"
                class="action-settings"
                @click="toggleContentSettings(idx)"
                :src="require('@/assets/admin_icon.svg')"
                />
                <div v-if="openSettingsIndex === idx" class="field">
                  <span>{{ $t("type") }}</span>
                  <Select v-model="content.type" class="select-container">
                    <Option v-for="(type, idx) in ['text', 'number']"
                      :value="type"
                      :key="type + idx"
                      :label="type"
                    />
                  </Select>
                </div>
          </div>
        </template>
      <section class="content-actions" :style="submitDirectionStyle">
        <div>
          <div class="text-management-bar" v-if="isEdit">
            <img class="icon" @click="page.submit_direction = 'left'" :src="require('@/assets/survey/left-arrow.svg')" />
            <img class="icon" @click="page.submit_direction = 'right'" :src="require('@/assets/survey/right-arrow.svg')" />
            <input v-model="page.submit_btn_color" class="color-picker" type="color" name="btnColor" />
          </div>
          <button class="submit-btn" @click="handleContentSubmit" :style="{ 'background-color': page.submit_btn_color}">
            <img
              v-if="isEdit"
              @click="isEditingSubmitBtn = !isEditingSubmitBtn"
              class="action-settings"
              :src="require('@/assets/admin_icon.svg')"
            />
            <input v-if="isEdit" v-model="page.submit_text" class="submit-btn-text" placeholder="Text" :style="submitBtnTxtColor"/>
            <div v-else :style="submitBtnTxtColor" class="submit-btn-text">{{ page.submit_text }}</div>
          </button>
          <div v-if="isEditingSubmitBtn" class="submit-options-btns" >
            <div class="field">
            <span>{{ $t("go_to_stage") }}</span>
              <Select v-model="page.submit_route_to" class="select-container">
                <Option v-for="(page) in ['', ...pages]"
                  :value="page"
                  :key="page"
                  :label="(!page ? 'None' : ++page)"
                />
              </Select>
            </div>
            <div class="field">
              <span>{{ $t("color") }}</span>
              <input v-model="page.submit_text_color" class="color-picker" type="color" name="btnColor" />
            </div>
            <template v-if="page.content && page.content.length > 0">
              <div class="field">
                <span>{{ $t("email") }}</span>
                <input class="input-container" v-model="submitEmailList" />
              </div>
              <div class="field">
                <span>{{ $t("sms") }}</span>
                <input class="input-container" v-model="submitSMSList" />
              </div>
            </template>
          </div>
        </div>
      </section>
    </section>
    <section class="rating-section" v-if="page.ratings && page.ratings.length > 0">
      <template v-for="(rating, idx) in page.ratings">
        <div :key="rating+idx" class="rating-container">
          <textarea
            v-if="isEdit"
            :placeholder="rating.main_text || 'Enter Text Here'"
            v-model="rating.main_text"
            class="rating-main-text"
            dir="auto"
          />
          <div class="rating-main-text" v-else>{{ rating.main_text }}</div>
          <section class="rating-icons">
            <template v-for="iconNum in 5">
              <img
                class="rating-icon"
                :key="iconNum"
                :src="iconSrc(iconNum, rating)"
                @mouseover="setRatingValue(iconNum, rating)"
                @mouseleave="resetRatingValue(rating)"
                @click="saveRating(iconNum, rating)"
              />
            </template>
          </section>
          <section class="rating-texts">
            <textarea
              v-if="isEdit"
              :placeholder="rating.left_text || 'Enter Text Here'"
              v-model="rating.left_text"
              class="rating-left-text"
              dir="auto"
            />
            <div class="rating-left-text" v-else>{{ rating.left_text }}</div>
            <textarea
              v-if="isEdit"
              :placeholder="rating.right_text || 'Enter Text Here'"
              v-model="rating.right_text"
              class="rating-right-text"
              dir="auto"
            />
            <div class="rating-right-text" v-else>{{ rating.right_text }}</div>
          </section>
        </div>
      </template>
    </section>
    <section
        v-if="pageType === 'image' && !isBackgroundImage"
        class="page-img-container resizable"
        :class="{ 'zero-size': page.imgInfo.width === 0 && page.imgInfo.height === 0 }"
        :style="imageStyle"
        >
      <div v-if="pageImgURL.length > 0 && !isBackgroundImage" class="img-container">
        <img :src="pageImgURL" class="img" />
        <img 
          v-if="isEdit"
          @click="deleteImage"
          :src="require('@/assets/delete-icon.svg')"
          class="delete-icon" />
      </div>
      <div v-else-if="isEdit" class="upload-container">
        <label for="img-upload">
          <img class="control upload" :src="require('@/assets/upload_icon.svg')" />
            <input type="file" id="img-upload" @change="showAddedImage" />
        </label>
        <span>{{ $t("image") }}</span>
      </div>
    </section>
    <img 
      v-if="isEdit && pageImgURL.length > 0 && pageType === 'image'"
      :src="require('@/assets/full_screen.svg')"
      class="full-screen-icon"
      @click="handleBackgroundImage"
      alt="full screen" />
    <section
      v-for="(buttonList, idx) in buttonLists"
      :key="idx"
      :class="getPageLayout(buttonList.length)"
      class="button-list-container"
    >
      <template v-if="buttonList.length">
        <SurveyButton
          v-for="(option, idx) in buttonList"
          :key="idx"
          :currOption="option"
          :isEdit="isEdit"
          :idx="option.idx"
          :pages="pages"
          @click="handleClick"
          @updatedOption="handleOptionUpdate"
          :textColor="page.question.color"
        />
      </template>
    </section>
    <section v-if="isPageEmpty && isBackgroundImage" class="empty-container" :style="{'height': '100vh'}"/>
  </section>
</template>

<script>
import SurveyButton from "../components/SurveyButton";
import Select from '@/modules/common/components/Select';
import Option from '@/modules/common/components/Option';
import ContentInput from './ContentInput.vue';
import OpenTextInput from './OpenTextInput.vue';

export default {
  props: {
    currPage: { type: Object, required: true },
    isEdit: { type: Boolean, required: false, default: false },
    pages: { type: Array, required: true },
    attachStageNoToSignal: { type: Boolean, required: false, default: false },
    activePageNum: { type: Number, required: false },
    previewImgInfoProp: { type: Object, required: false },
  },
  data() {
    return {
      page: this.currPage,
      mobileView: window.innerWidth <= 480,
      isEditingSubmitBtn: false,
      previewImgInfo: null,
      submitSMSList: [],
      submitEmailList:[],
      openSettingsIndex: null, // Keep track of the open settings index
    };
  },
  created() {
    if (this.previewImgInfoProp) {
      this.previewImgInfo = JSON.parse(JSON.stringify(this.previewImgInfoProp))
    }
    if (this.page.type === 'content') {
      this.submitSMSList = this.page.submit_sms_list.join(","),
      this.submitEmailList = this.page.submit_email_list.join(",")
    }
  },
  mounted() {
    const isBackgroundImage = this.page.type === 'image' && (this.previewImgInfo?.isBackground || this.page.imgInfo?.isBackground)
    if ((this.previewImgInfo || this.page.imgInfo?.url) && !isBackgroundImage) {
      this.setResizeObserver('page-img-container')
    }
  },
  computed: {
    isEditClass() {
      return { edit: this.isEdit };
    },
    isPageEmpty() {
      const { options, content, ratings } = this.page
      return !options?.length && !content?.length && !ratings?.length
    },
    buttonLists() {
      const optionsCopy = JSON.parse(JSON.stringify(this.currPage.options));
      if (!optionsCopy.length) return []
      let buttonsPerLine = optionsCopy.length;
      if (this.mobileView) buttonsPerLine = 2;
      else if (buttonsPerLine > 5) {
        if (buttonsPerLine === 9 || buttonsPerLine === 12) buttonsPerLine = buttonsPerLine / 3;
        else buttonsPerLine = buttonsPerLine / 2;
      }
      const buttonLists = [];
      let count = 0;
      for (var i = 0, k = -1; i < optionsCopy.length; i++) {
        if (i % buttonsPerLine === 0) {
          k++;
          buttonLists[k] = [];
        }
        const currOption = optionsCopy[i];
        currOption.idx = count;
        buttonLists[k].push(optionsCopy[i]);
        count++;
      }
      return buttonLists;
    },
    iconSrc() {
      return (iconNum, rating) => {
        if (iconNum <= rating.value) {
          return require(`@/assets/survey/icon_shapes/${this.page.rating_shape}_filled.svg`) 
        }
        return require(`@/assets/survey/icon_shapes/${this.page.rating_shape}.svg`)
      }
    },
    pageImgURL() {
      const { imgInfo } = this.page
      return imgInfo?.url || this.previewImgInfo?.blobURL || ''
    },
    imageStyle() {
      const { imgInfo } = this.page
      if (imgInfo.url) {
        const { width, height } = imgInfo
        if (width !== 0 && height !== 0) return { width: `${width}px`, height: `${height}px` }
      } else {
        if (this.previewImgInfo) {
          const { blobURL, width, height } = this.previewImgInfo
          if (blobURL && width !== 0 && height !== 0) return { width: `${width}px`, height: `${height}px` }
        }
      }
      return {}
    },
    isBackgroundImage() {
      return this.page.imgInfo?.isBackground || this.previewImgInfo?.isBackground || false
    },
    pageType() {
      return this.page.type
    },
    questionStyles() {
        const { question } = this.page
        return { 'text-align': question.align, 'font-size': question.fontSize + 'px', 'color': question.color }
    },
    pageQuestion() {
      const { question } = this.page
      // if it's a string
      if (typeof question === 'string') return question
      // return ''
      // if it's an object
      return question.text
    },
    submitDirectionStyle() {
      const { submit_direction } = this.page
      return { 'justify-content': submit_direction === 'left' ? 'flex-start' : 'flex-end' }
    },
    submitBtnTxtColor() {
      const { submit_text_color } = this.page
      return { 'color': submit_text_color }
    },
  },
  methods: {
    handleClick(servikId, routeTo, connectedRoomId, isLinkServik) {
      if (this.isEdit) return;
      const dataToSend = { servikId, connectedRoomId, isLinkServik }
      if (this.attachStageNoToSignal) dataToSend.stageNo = this.activePageNum;
      if (servikId) this.$emit("click", dataToSend);
      if (routeTo) this.$emit("changePage", routeTo);
    },
    handleContentSubmit() {
      if (this.isEdit) return
      const { submit_route_to, content } = this.page
      this.$emit("changePage", submit_route_to)
      this.$emit("submitContent", JSON.parse(JSON.stringify(content)), this.page.submit_sms_list, this.page.submit_email_list)
      this.page.content.forEach(obj => obj.response = '')
    },
    handleOptionUpdate({ idx, option }) {
      this.page.options[idx] = option;
    },
    getPageLayout(buttonCount) {
      return [`count-${buttonCount}`, this.currPage.options.length <= 5 ? "down" : ""];
    },
    setRatingValue(iconNum, rating) {
      rating.value = iconNum
    },
    resetRatingValue(rating) {
      rating.value = 0
    },
    saveRating(iconNum, rating) {
      if (this.isEdit) return
      rating.value = iconNum
      this.$emit("saveRating", JSON.parse(JSON.stringify(rating)))
    },
    showAddedImage(ev) {
      const file = ev.target.files[0]
      const { width, height } = this.getDimensions('page-img-container')
      this.previewImgInfo = {
        file,
        blobURL: URL.createObjectURL(file),
        pageId: this.activePageNum,
        width,
        height,
        isBackground: false
      }
      if (!this.previewImgInfo.isBackground) {
        this.setResizeObserver('page-img-container')
      }
      this.$emit("updatePagePreviewImage", this.previewImgInfo)
    },
    setResizeObserver(className) {
      const resizeObserver = new ResizeObserver(this.onResize)
      resizeObserver.observe(document.querySelector(`.${className}`))
    },
    onResize() {
      const { width, height } = this.getDimensions('page-img-container')
      if (this.previewImgInfo) {
        this.previewImgInfo.width = width
        this.previewImgInfo.height = height
        this.$emit("updatePagePreviewImage", this.previewImgInfo)
      }
      if (this.page.imgInfo.url) {
        this.$emit("changePageImgSize", { width, height })
      }
    },
    getDimensions(className) {
      const el = document.querySelector(`.${className}`)
      if (!el) return { width: 0, height: 0 }
      const { width, height } = el.getBoundingClientRect()
      return { width, height }
    },
    deleteImage() {
      if (this.page.imgInfo.url) {
        this.$emit("deletePageImage", this.activePageNum)
      } else {
        this.previewImgInfo = null
        this.$emit("deletePagePreviewImage", this.activePageNum)
      }
    },
    handleBackgroundImage() {
      if (this.page.imgInfo.url) {
        this.$emit("handlePageBackgroundImage", this.activePageNum)
      } else {
        this.previewImgInfo.isBackground = !this.previewImgInfo.isBackground
        this.$emit("updatePagePreviewImage", this.previewImgInfo)
      }
    },
    updateContent(updatedContent, idx) {
      this.page.content.splice(idx, 1, updatedContent)
    },
    updatePageOpenText(updatedOpenText) {
      this.page.open_text = updatedOpenText
    },
    alignText(align) {
			this.page.question.align = align
		},
		increaseFontSize() {
			if (this.page.question.fontSize > 80) return
			this.page.question.fontSize += 10
		},
		decreaseFontSize() {
			if (this.page.question.fontSize < 20) return
			this.page.question.fontSize -= 10
		},
    toggleContentSettings(idx) {
      this.openSettingsIndex = this.openSettingsIndex === idx ? null : idx
    }
  },
  watch: {
    currPage(newPage) {
      this.page = newPage;
    },
    page: {
      deep: true,
      handler(to, from) {
        this.$emit("updatePage", this.page);
      }
    },
    submitSMSList(newPhoneNumbes, oldPhoneNumbes) {
      const numbersArray = newPhoneNumbes.split(",").filter(item => item.trim() !== "")
      if (numbersArray.length > 0) {
        this.page.submit_sms_list = numbersArray
      } else {
        // Handle the case when the array is empty (no non-empty elements)
        // For example, you might want to set it to null or an initial value.
        this.page.submit_sms_list = []
      }
    },
    submitEmailList(newEmails, oldEmails) {
      const emailsArray = newEmails.split(",").filter(item => item.trim() !== "")
      if (emailsArray.length > 0) {
        this.page.submit_email_list = emailsArray
      } else {
        // Handle the case when the array is empty (no non-empty elements)
        // For example, you might want to set it to null or an initial value.
        this.page.submit_email_list = []
      }
    },
  },
  components: { SurveyButton, Select, Option, ContentInput, OpenTextInput }
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars";
@import "@/styles/mixins";

.down {
  margin-top: 100px;
}

.edit {
  padding-top: 20px;
  .text-management-bar {
		@include flex-center;
    z-index: 1;
		border-radius: 10px;
		border: 2px solid $grey-border-color;
		& > * {
			margin: 0 10px;
		}
		.icon {
			width: 25px;
			height: 25px;
			cursor: pointer;
		}
	}
  .question {
    width: 49vw;
    margin-bottom: 60px;
    @include border($grey-border-color);
    @include flex-center;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .page-img-container {
    @include border($grey-border-color);
    &.zero-size {
      width: 90%;
      height: 50vh;
    }

    &.resizable {
    resize: both;
    overflow: auto;
      .resize-handle {
        width: 10px;
        height: 10px;
        background-color: #000;
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: nwse-resize;
      }
    }
    .upload-container {
      @include flex-center;
      width: 100%;
      height: 100%;
      gap: 5px;
      font-weight: $font-weight-bold;
      input[type="file"] {
        width: 100%;
        height: 100%;
        display: none;
      }
      .control {
        width: 1vw;
        height: 1vw;
        display: block;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.survey-page-container {
  @include flex-center;
  flex-direction: column;
  padding-top: 110px;
  &.edit {
    // padding-top: 40px;
  }
}

.question {
  white-space: pre;
  z-index: 0;
  border-radius: 16px;
  font-size: 2.8rem;
  font-weight: $font-weight-bold;
  text-transform: capitalize;
  color: $black-blue;
  text-align: center;
  height: 11.2vh;
  margin: 0 auto;
  min-width: 49vw;
  @include screen-size(xlrg) {
    margin: 20px auto;
  }
  @include screen-size(xxs) {
    font-size: 1.8rem;
    height: auto;
  }
  font-family: inherit;
}

.button-list-container {
  @include flex-center;
  width: 83.5vw;
  &.count-1 {
    justify-content: center;
  }
  &.count-2 {
    justify-content: space-around;
  }
  &.count-3 {
    justify-content: space-around;
  }
  &.count-4 {
    justify-content: space-between;
  }
  &.count-5 {
    justify-content: space-between;
  }
   @include screen-size(xxs) {
        margin-top:15%;
  }
}

.content-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .content-actions {
    display: flex;
    width: 100%;
    .submit-btn {
      position: relative;
      margin-right: 30px;
      margin-left: 30px;
      width: 100px;
      height: 50px;
      font-size: 1.3rem;
      font-weight: $font-weight-bold;
      background-color: $goblin-green;
      border-radius: 10px;
      border: none;
      cursor: pointer;
      @include screen-size(xxs) {
        font-size: 0.3rem;
        height: auto;
      }
      img {
        z-index: 1;
        position: absolute;
        top: 120%;
        right: 80%;
        transform: translate(-50%, -50%);
      }
      .submit-btn-text {
        // color: $white;
        width: 100px;
        text-align: center;
        &::placeholder {
          color: $white;
        }
      }
    }
  }

  .field {
    @include border($grey-border-color);
    @include flex-center;
    color: $black;
    padding: 10px;
    justify-content: space-between;
    border-radius: 5px;
    width: 200px;
    .select-container {
      @include border($grey-border-color);
      cursor: pointer;
      height: 35px;
      width: 50px;
      text-align: center;
      border-radius: 4px;
      margin: 5px;
      padding: 0 1px;
    }
  }
  .response-settings {
    display: flex;
  }
}

.rating-section {
  width: 90%;
  padding: 10px 50px;
  .rating-container {
    display: flex;
    flex-direction: column;
    .rating-main-text {
      text-align: center;
      white-space: pre;
      font-family: inherit;
      font-size: 1.3rem;
      font-weight: $font-weight-bold;
      color: $black-blue;
      margin-bottom: 20px;
      @include screen-size(xxs) {
        font-size: 0.3rem;
        height: auto;
      }
    }
    .rating-icons {
      display: flex;
      justify-content: space-between;
      .rating-icon {
        cursor: pointer;
        width: 80px;
        height: 80px;
      }
    }
    .rating-texts {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .rating-left-text {
        text-align: left;
        white-space: pre;
        font-family: inherit;
        font-size: 1.3rem;
        font-weight: $font-weight-bold;
        color: $black-blue;
      }
      .rating-right-text {
        text-align: right;
        white-space: pre;
        font-family: inherit;
        font-size: 1.3rem;
        font-weight: $font-weight-bold;
        color: $black-blue;
      }
    }
  }
}

.page-img-container {
  max-width: 90%;
  border-radius: 16px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
  }
  .img-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
  }
  .delete-icon {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    background-color: $white;
    &:hover {
      background-color: $black;
      cursor: pointer;
    }
  }

}

</style>