<template>
  <section class="survey-list-container">
    <header>
      <div class="header-text">{{$t('surveys')}}</div>
      <Button
        class="button-container"
        v-if="siteId && isAdmin"
        skin="primary"
        @click="routeToEdit(null, true)"
      >{{$t('new_survey')}}</Button>
    </header>
    <div class="error-msg" v-if="errorMsg">{{errorMsg}}</div>
    <section class="table-container" v-else>
      <Loading v-if="!surveysToShow" class="loader" style="height:75vh;" />
      <Table
        v-if="surveysToShow"
        :tableData="surveysToShow"
        @removeRow="removeSurvey"
        idField="id"
        @editRow="payload => routeToEdit(payload, true)"
        customRowGridStr="1fr 1fr 1fr 1fr 1fr 1fr 50px 50px 50px 50px 50px 50px 50px"
        :editOptions="{ edit: isAdmin, remove: isAdmin }"
        :extraActions="permittedActions"
        @action="handleAction"
      />
    </section>
    <ApiModal @close="closeApiModal" v-if="apiEditMode && currSurvey" :selectedSurvey="currSurvey" />
    <EmailModal @close="closeEmailModal" v-if="emailEditMode && currSurvey" :selectedSurvey="currSurvey" :languages="languages" />
    <!-- <Modal
     width="small"
     v-if="isArrivalModalOpen && currSurvey"
     :texts="{ headerPrimary: $t('preview_arrivals')}" 
     @close="closeArrivalModal" 
     @submit="saveArrivalSettings">
        <ErrorMsg
          :isShown="errors.includes('invalid arrival settings')"
          text="You cannot do this."
        />
        <div class="preview-arrival-info">
          <h4>{{ $t("last_time_of_arrival") }}</h4>
          <Checkbox v-model="currSurvey['preview_arrival'].isLastTimeOfArrival"/>
        </div>
        <div class="preview-arrival-info">
          <h4>{{ $t("arrival_and_status") }}</h4>
          <Checkbox v-model="currSurvey['preview_arrival'].isArrivalAndStatus"/>
        </div>
    </Modal> -->
  </section>
</template>

<script>
import Button from "../../common/components/Button";
import Table from "../../common/components/customTable/Table";
import surveyService, { SurveyToShow } from "../services/surveyService";
import swalService from "@/modules/common/services/swalService";
import ApiModal from "../components/ApiModal";
import EmailModal from "../components/EmailModal";
import Loading from "@/modules/common/components/Loading";
import Modal from "@/modules/common/components/Modal";
import Checkbox from "@/modules/common/components/Checkbox";
import ErrorMsg from '@/modules/common/components/ErrorMsg';
import { mapGetters } from 'vuex';

export default {
  name:'survey-list',
  data() {
    return {
      errorMsg: "",
      surveys: [],
      errors:[],
      surveysToShow: null,
      actions: [
        { name: "api_icon", type: "api" },
        { name: "email_icon", type: "email" },
        { name: "eye-selected", type: "preview" },
        { name: "duplicate_icon", type: "duplicate" },],
      apiEditMode: false,
      emailEditMode: false,
      // isArrivalModalOpen:false,
      currSurvey: null,
      selectedSurveyIdx: null,
      languages: [],
    };
  },
  async created() {
    const languages = await surveyService.getLanguages()
    this.languages = languages
  },
  computed: {
    ...mapGetters(['loggedUser']),
    selectedSurvey() {
      return this.surveysToShow[this.selectedSurveyIdx];
    },
    siteId() {
      return this.$store.getters.filterSelected.site;
    },
    permittedActions() {
      if (!this.isAdmin) {
        return [
          { name: "eye-selected", type: "preview" },
        ]
      }
      return [
        { name: "api_icon", type: "api" },
        { name: "email_icon", type: "email" },
        { name: "eye-selected", type: "preview" },
        { name: "duplicate_icon", type: "duplicate" },
        // { name: "preview_arrival", type: "preview_arrival" },
      ]
    },
    isAdmin() {
      return this.$store.getters.loggedUser.type === 'admin'
    }
  },
  methods: {
    async closeApiModal(newApi) {
      if (newApi) {
        this.currSurvey.api = newApi;
        try {
          await surveyService.saveSurvey(this.currSurvey);
          this.apiEditMode = false
          swalService.savedMsg(this.$t('Your work has been saved'))
        } catch (err) {
          swalService.errorMsg();
        }
      }
      this.currSurvey = null;
      this.apiEditMode = false
    },
    async closeEmailModal(emailSettings) {
      try {
        if (emailSettings) {
          this.currSurvey.email_settings = emailSettings
          await surveyService.saveSurvey(this.currSurvey)
          swalService.savedMsg(this.$t('Your work has been saved'))
        } 
        this.currSurvey = null
        this.emailEditMode = false
      } catch (err) {
        swalService.errorMsg()
      }
    },
    // closeArrivalModal(){
    //   this.isArrivalModalOpen
    //   this.currSurvey = null;
    //   if(this.errors.length) this.errors = []
    // },
    // async saveArrivalSettings(){
    //   try {
    //     const {isLastTimeOfArrival, isArrivalAndStatus} = this.currSurvey['preview_arrival']
    //     const errMsg = 'invalid arrival settings'
    //     if(isArrivalAndStatus && !isLastTimeOfArrival){
    //       if(!this.errors.includes(errMsg)) this.errors.push(errMsg)
    //       return
    //     }
    //     if(this.errors.length) this.errors = this.errors.filter(err => err !== errMsg);
    //     await surveyService.saveSurvey(this.currSurvey);
    //     this.closeArrivalModal();
    //     swalService.savedMsg();
    //   } catch (err) {
    //     swalService.errorMsg();
    //   }
    //   this.closeArrivalModal();
    // },
    routeToPreview(id) {
      if (id)
        this.$router.push({
          name: "surveyEdit",
          params: { surveyId: id, isEdit: false, isRename: false }
        });
      else swalService.error();
    },
    routeToEdit(payload, isRename) {
      if (!this.isAdmin) return
      let id;
      let params = {};
      if (!isNaN(payload)) {
        params.surveyId = payload;
        params.isEdit = true;
      } else if (payload && payload.id) {
        id = payload.id;
        params.surveyId = payload.id;
        params.isEdit = true;
      }
      if (isRename) params.isRename = isRename;
      // if (id)this.$router.push({ name: "surveyEdit", params });
      this.$router.push({ name: "surveyEdit", params });
    },
    async handleAction({ type, idx }) {
      this.selectedSurveyIdx = idx;
      const currSurveyId = this.surveys[this.selectedSurveyIdx].id;
      switch (type) {
        case 'api':
          this.currSurvey = await surveyService.getSurveyById(currSurveyId);
          this.apiEditMode = true
          break;
        case 'email':
          this.currSurvey = await surveyService.getSurveyById(currSurveyId);
          this.emailEditMode = true
          break
        case 'preview':
          surveyService.openSurveyPreview(currSurveyId)
          break;
        case 'duplicate':
          const result = await swalService.confirmMsg();
          if (!result.value) break;
          await surveyService.duplicateSurvey(currSurveyId)
          this.updatePage()
          break;
        case 'survey-template':
          const survey = await surveyService.getSurveyById(currSurveyId);
          survey.is_template = !survey.is_template
          const res = await surveyService.saveSurvey(survey, survey.site_id)
          break;
      //   case 'preview_arrival':
      //     this.currSurvey = await surveyService.getSurveyById(currSurveyId);
      //     if(!this.currSurvey['preview_arrival']){
      //       this.currSurvey['preview_arrival'] = {"isLastTimeOfArrival":false,"isArrivalAndStatus":false}
      //     }
      //     this.isArrivalModalOpen = true
      //     break;
      }
    },
    async removeSurvey(surveyId) {
      const result = await swalService.confirmMsg();

      if (!result.value) return;
      try {
        await surveyService.removeSurvey(surveyId);
        swalService.deletedMsg();
      } catch (err) {
        swalService.errorMsg();
      }
      this.updatePage();
    },
    async updatePage() {
      this.errorMsg = "";
      const { site } = this.$store.getters.filterSelected;
      if (!site) return (this.errorMsg = this.$t("please select a company & site"));
      //TOM: saving surveys in component and making another list in table format
      this.surveys = await surveyService.getSurveyList({ site_id: site });
      if (!this.surveys.length) {
        return (this.errorMsg = this.$t("no_surveys_created"));
      }
      this.surveysToShow = this.surveys.map((survey) => new SurveyToShow(survey, this.loggedUser.type));
    }
  },
  watch: {
    '$store.getters.filterSelected.site': {
      handler() {
        this.updatePage();
      },
      immediate: true
    }
  },
  components: { Button, Table, ApiModal, EmailModal, Loading, Modal, Checkbox, ErrorMsg }
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars";
@import "@/styles/mixins";

.table-container {
  padding: 15px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 9.5vh;
  border-bottom: 1px solid $grey-border-color;
  text-transform: uppercase;

  .button-container {
    margin-inline-end: 20px;
  }

  .header-text {
    font-weight: $font-weight-bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase !important;
    max-width: 21vw;
    min-width: 10vw;
    height: 9.5vh;
    font-size: 13px;
    border-bottom: 9px solid #448aff;
    text-align: center;
  }
}

// .preview-arrival-info{
//   display:flex;
//   gap: 10px;
// }

</style>