export const emptyWorkingArea = {
	area_name: '',
	rooms: [],
	shifts: [],
	active_notification_task: [],
	notified: false,
	supervisors: [],
	emails: {
		isActive: false,
		addresses: []
	},
	sms: {
		isActive: false,
		numbers: []
	},
	counter: { isActive: false, amount_limit: 200, after_max: 50 },
	cleandex: { isActive: false },
	offline_calls: { isActive: false, amount_limit: 60 },
	api: {
		isActive: false,
		method: 'POST',
		url: '',
		auth: 'API KEY',
		token: '',
		info: {
			siteName: true,
			roomName: true,
			servikName: true,
			time: true
		}
	},
	manual_calls: [],
	is_suggestion: false,
	is_scheduled_cleaning: false
}
