<template>
	<section class="content-container">
		<textarea
			class="content-text"
			v-if="isEdit"
			:placeholder="content.text || 'Enter Text Here'"
			v-model="content.text"
			@input="updatePageContent"
			:style="{'color': textColor}"
			dir="auto"
		/>
		<div :style="{'color': textColor}" v-else class="content-text">{{ content.text }}</div>
		<input
			class="content-response"
			:placeholder="content.placeholder || 'Enter your response here'"
			v-model="responseModel"
			@input="updatePageContent"
			:type="content.type"
			:style="{'color': textColor}"
			dir="auto"
		/>
	</section>
</template>

<script>
export default {
	name: 'ContentInput',
	props: {
		contentProp: {
			type: Object,
			required: true
		},
		isEdit: {
			type: Boolean,
			default: false
		},
		textColor: { type: String, required: false }
	},
	data() {
		return {
			content: JSON.parse(JSON.stringify(this.contentProp))
		}
	},
	methods: {
		updatePageContent() {
			this.$emit('updateContent', this.content)
		},
		alignText(align) {
			this.content.align = align
			this.updatePageContent()
		}
	},
	computed: {
		responseModel: {
			get() {
				if (this.isEdit) {
					return this.content.placeholder;
				} else {
					return this.content.response;
				}
			},
			set(value) {
				if (this.isEdit) {
					this.content.placeholder = value;
				} else {
					this.content.response = value;
				}
			}
		}
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars';
@import '@/styles/mixins';
.content-container {
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 50px;
	width: 49vw;
	.content-text {
		text-align: center;
		white-space: pre;
		font-family: inherit;
		font-size: 1.7rem;
		font-weight: $font-weight-bold;
		@include screen-size(xxs) {
			font-size: 0.3rem;
			height: auto;
		}
	}
	.content-response {
		border: none;
		border-bottom: 1px dashed;
		background-color: transparent;
		text-align: center;
		font-family: inherit;
		font-size: 1.2rem;
		font-weight: $font-weight-bold;
		color: var(--textColor);
		&:not(:last-child) {
			margin-bottom: 70px;
		}
		&:last-child {
			margin-bottom: 30px;
		}
		&::placeholder {
			color: var(--textColor);
		}
		@include screen-size(xxs) {
			font-size: 0.3rem;
			height: auto;
		}
		line-height: 0.8em;
	}
}
</style>
