<template>
  <div class="input-container">
    <div class="label">
      <img :src="require('@/assets/icon_user.svg')" alt="user icon" />
      <span>{{label}}</span>
    </div>
    <input :type="type" :placeholder="placeholder" :value="value" @input="emitInput($event.target.value)"/>
  </div>
</template>

<script>

export default {
  props: {
    placeholder: { type: String, required: false },
    label: { type: String, required: false },
    value: { type: String | Number, required: false },
    type: { type: String, default: 'text' }
  },
  methods: {
    emitInput(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";
@import "@/styles/mixins.scss";

.input-container {
  text-transform: capitalize;
  .label {
    @include survey-label;
  }

  input {
    width: 100%;
    border: 1px solid $blue;
    height: 46px;
    border-radius: 7px;
    padding-inline-start: 25px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      text-transform: capitalize;
      color: $grey-border-color;
    }
  }
}
</style>