<template>
  <div @click="handleClick" :class="['card', componentType, activeColor, { active: activeStatus }]">
    <div class="label-container">
      {{ label }}
      <div class="trend-info" v-if="trendInfo">
        <img
          :class="trendInfo.upDown"
          :src="require(`@/assets/performance/${activeStatus ? 'white' : trendInfo.color}_arrow.svg`)"
          v-if="trendInfo.color"
        />
        {{ trendInfo.text }}
      </div>
    </div>
    <div :class="['icon-text-container', spaceIconText ? 'space' : '']">
      <img v-if="activeIconUrl && activeStatus" class="icon-active" :src="require(`@/${activeIconUrl}`)" />
      <img v-else-if="iconUrl" class="icon" :src="require(`@/${iconUrl}`)" />
      {{ text }}
      <span v-if="isAmended && isAmendedPermitted" class="amended-room">*</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    isAmended: {
      type: Number,
      required: false,
    },
    componentType: {
      type: String,
      validator: (prop) => prop === 'chart-filter-button' || prop === 'info',
      required: true,
    },
    text: { type: [String, Number], required: true },
    spaceIconText: { type: Boolean, default: true },
    label: String,
    activeColor: String,
    activeStatus: Boolean,
    iconUrl: String,
    activeIconUrl: String,
    /**@type {{
     * new(): {
     * text: String;
     * upDown: 'up' | 'down';
     * }
     * }} */
    trendInfo: { type: Object, required: false },
  },
  methods: {
    handleClick() {
      this.$emit('click', { isActive: this.activeStatus })
    },
  },
  mounted() {
    this.$emit('mounted')
  },
  computed: {
    ...mapGetters(['loggedUser']),
    isAmendedPermitted() {
      const { level } = this.loggedUser
      return level === 'buzzz'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';

.amended-room {
  color: blue;
  display: flex;
  padding-block-start: 10px;
}
.card {
  padding: 14px;
  min-width: 10.9vw;
  max-height: 130px;

  .label-container {
    margin-bottom: 20px;
    // text-transform: capitalize;
    height: 15px;
    font-size: 0.875rem;
    display: flex;
    justify-content: space-between;

    .trend-info {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: $font-size-tiny;
      font-weight: $font-weight-bold;
      margin-inline-start: 5px;

      img {
        &.down {
          transform: rotate(180deg);
        }

        height: 100%;
      }
    }
  }

  .icon-text-container {
    font-size: $font-size-large;
  }

  &.info {
    max-width: 10vw;
    min-width: 8vw;

    @include border(#e9e9e9);

    .trend-info {
      color: #848593;
    }

    &.last {
      border-radius: 0 8px 8px 0;

      @include rtl {
        border-radius: 8px 0px 0px 8px;
      }
    }

    &.first {
      border-radius: 8px 0px 0px 8px;

      @include rtl {
        border-radius: 0px 8px 8px 0px;
      }
    }

    @media print {
      & {
        width: 17vw;
        margin: 0 5px;
        border-radius: 8px 8px 8px 8px !important;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 1px 2px 4px #00000029;
        -ms-box-shadow: 1px 2px 4px #00000029;
        border: 1px solid #e9e9e9;
        border-radius: 10px;
      }
    }

    .icon-text-container {
      display: flex;
      align-items: center;
      height: 30px;

      img {
        margin-inline-end: 10px;
      }

      &.space {
        justify-content: space-between;
      }
    }
  }

  &.chart-filter-button {
    cursor: pointer;

    @include border(#e9e9e9);

    transition: 0.2s ease-in;
    border-inline-end: unset;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .label-container {
      color: #848593;
      font-size: $font-size-small;
    }

    &.last {
      border-radius: 0 8px 8px 0;
      border-inline-end: 1px solid #e9e9e9;

      @include rtl {
        border-radius: 8px 0px 0px 8px;
      }
    }

    &.first {
      border-radius: 8px 0px 0px 8px;

      @include rtl {
        border-radius: 0px 8px 8px 0px;
      }
    }

    &.active {
      border-radius: 8px;
      border: 0px;
      color: #ffffff;
      transition: 0.2s ease-in;

      .label-container {
        color: #ffffff;
      }

      &.bouquet {
        background-color: $bouquet;
      }

      &.persian-red {
        background-color: $persian-red;
      }

      &.yellow {
        background-color: $yellow;
      }

      &.flame-pea {
        background-color: $flame-pea;
      }

      &.jaffa {
        background-color: $jaffa;
      }

      &.saffron {
        background-color: $saffron;
      }

      &.goblin-green {
        background-color: $goblin-green;
      }

      &.wedgewood-blue {
        background-color: $wedgewood-blue;
      }
    }

    @media print {
      width: 150px;
      height: 100px;
    }

    .icon-text-container {
      display: flex;
      justify-content: space-between;
      letter-spacing: -0.27px;
      align-items: center;
    }
  }
}
</style>
