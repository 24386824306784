<template>
  <section class="working-area-container">
    <WorkingAreaModal
      v-if="isopenWorkingAreaModal"
      @closeModal="closeWorkingAreaModal"
      :workingAreaProp="workingArea"
      :workingAreasServiks="workingAreasServiks"
      @submit="submitWorkingArea"
    />
    <transition v-if="routeName === 'workingArea'" name="fade">
      <div class="custom-button-container">
        <Button
          @click="openWorkingAreaModal"
          v-if="loggedUser.type ==='admin'"
          skin="primary"
        >{{$t('new_area')}}</Button>
      </div>
    </transition>
    <Tabs :isRTL="isRTL" >
      <Tab :label="$t('working_area')" to="/real-time/working-area">
        <template v-if="filterSelected && filterSelected.company && filterSelected.site">
          <Loading height="50vh" v-if="isLoading || !workingAreas" />
          <WorkingAreaList
            @editWorkingArea="editWorkingArea"
            @removeWorkingArea="removeWorkingArea"
            v-else
            :workingAreas="workingAreas"
          />
        </template>
        <h1 v-else>Please select a company and a site</h1>
      </Tab>
    </Tabs>
  </section>
</template>

<script>
import { emptyWorkingArea } from '../consts/consts';
import Tabs from '../../common/components/Tabs/Tabs';
import Tab from '../../common/components/Tabs/Tab';
import Button from '../../common/components/Button';
import Loading from '../../common/components/Loading';
import WorkingAreaList from '../components/WorkingAreaList';
import WorkingAreaModal from '../components/WorkingAreaModal';
import workingAreaService from '../services/workingAreaService';
import swalService from '@/modules/common/services/swalService'
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      workingAreas: null,
      workingArea: null,
      workingAreasServiks: [],
      isopenWorkingAreaModal: false,
      isLoading: false
    }
  },
  methods: {
    async submitWorkingArea(newWorkingArea) {
      try {
        const site_id = this.filterSelected.site;
        const res = await workingAreaService.save(newWorkingArea, site_id)
        if (res) {
          swalService.savedMsg(this.$t('Your work has been saved'))
          this.closeWorkingAreaModal()
          this.workingAreas = await workingAreaService.getWorkingAreas(this.filterSelected);
        } else console.log('ERROR WHILE SAVING WORKING')
      } catch (err) {
        swalService.errorMsg()
      }
    },
    async editWorkingArea(workingAreaId) {
      const workingAreaToEdit = await workingAreaService.getById(workingAreaId)
      this.openWorkingAreaModal(workingAreaToEdit)
    },
    async removeWorkingArea(workingAreaId) {
      const confirm = await swalService.confirmMsg()

      if (!confirm.value) return
      try {
        await workingAreaService.remove(workingAreaId)
        swalService.savedMsg(this.$t('Your work has been saved'))
        this.workingAreas = await workingAreaService.getWorkingAreas(this.filterSelected);
      } catch (err) {
        await swalService.errorMsg()
      }
    },
    closeWorkingAreaModal() {
      this.isopenWorkingAreaModal = false;
      this.workingArea = JSON.parse(JSON.stringify(emptyWorkingArea))
    },
    async openWorkingAreaModal(workingAreaToEdit) {
      this.workingArea = workingAreaToEdit.id ? workingAreaToEdit : JSON.parse(JSON.stringify(emptyWorkingArea))
      if (workingAreaToEdit.id) {
        if (workingAreaToEdit.rooms.length > 0) {
          const roomsIds = workingAreaToEdit.rooms.map(room => room.id)
          const { active_notification_task } = workingAreaToEdit
          const roomsServiks = await workingAreaService.getServiksByRoomsIds(roomsIds)
          // Already selected serviks + all rooms serviks + default selected working area serviks
          const combinedServiks = [...roomsServiks, ...active_notification_task, ...this.workingAreasServiks]
          // Remove duplicates using Set and set the combined serviks as select options
          this.workingAreasServiks = [...new Set(combinedServiks.map(item => item.id))]
            .map(id => combinedServiks.find(item => item.id === id))
          }
      } else {
        // new working area
        const defaultServiks = workingAreaService.defaultSelectedWorkingAreaServiks()
        this.workingAreasServiks = [...defaultServiks]
        this.workingArea.active_notification_task = [...defaultServiks]
      }
      this.isopenWorkingAreaModal = true;
    },
  },
  watch: {
    '$store.getters.filterSelected.site': {
      async handler() {
        if (!this.filterSelected.site) return;
        this.isLoading = true;
        this.workingAreas = await workingAreaService.getWorkingAreas(this.filterSelected);
        this.workingAreasServiks = workingAreaService.defaultSelectedWorkingAreaServiks()
        this.isLoading = false;
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters(['filterSelected','loggedUser','isRTL']),
    routeName() {
      return this.$route.name;
    }
  },
  components: { Tabs, Tab, Button, Loading, WorkingAreaList, WorkingAreaModal }
}
</script>

<style lang="scss" scoped>
@import "@/styles/mixins";

.working-area-container{
  background-color: white;
  min-height: 100%;
}

.custom-button-container {
  height: 80px;
  display: flex;
  align-items: center;
  margin-inline-end: 20px;
  text-transform: uppercase;
  position: absolute;
  z-index: 3;
  right: 0;
  @include rtl {
    left: 0;
    right: unset;
  }
  span {
    @include modal-input-label;
    margin-bottom: 0px;
    font-size: 13px;
  }
  input {
    @include modal-input;
    margin-inline-start: 5px;
    margin-bottom: 0px;
    padding: 0 5px;
    width: 150px;
  }
  button {
    font-size: 12px;
    font-weight: bold;
  }
}
</style>