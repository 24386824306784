<template>
  <section class="overview-container">
    <div class="filter-info-container">
      <ChartFilterPanel :buttons="chartFilterPanelData.buttons" @input="handleFilterClick" />
    </div>
    <div class="score-container">
      <div class="chart-y-titles">
        <div
          v-if="chartTitles[0]"
          :style="{ color: chartTitles[0].color }"
        >{{$t(chartTitles[0].text)}}</div>
        <div
          v-if="chartTitles[1]"
          :style="{ color: chartTitles[1].color }"
        >{{$t(chartTitles[1].text)}}</div>
      </div>
      <Loading v-if="isLoading" height="55vh" />
      <NoData v-else-if="!isData" />
      <template v-else-if="chartData.datasets && chartData.datasets[0]">
        <Chart
          class="no-print-chart"
          type="bar"
          :labels="chartData.labels"
          :datasets="chartData.datasets"
          height="55vh"
          :options="chartOptions"
          @click="handleChartClick"
          :isClickable="true"
        />
        <Chart
          class="print-chart"
          type="bar"
          :labels="chartData.labels"
          :datasets="chartData.datasets"
          width="1090px"
          height="400px"
          :options="chartOptions"
        />
      </template>
    </div>
  </section>
</template>

<script>
import Loading from "@/modules/common/components/Loading";
import NoData from "@/modules/common/components/NoData";
import Chart from "@/modules/common/components/Chart";
import { mapGetters } from "vuex";
import { buttonsInfo, overviewChartOptions } from "../consts/consts";
import ChartFilterPanel from "./ChartFilterPanel";
import {
  getOverviewSums,
  getAnalyticsSums,
} from "../services/performanceService";
import performanceService from "../services/performanceService";
import performanceChartService from "../services/performanceChartService";
import util from "../../common/services/utilService";
import { Toast } from "@/modules/common/Toast";
import { trendArrowColors } from "../consts/consts";

export default {
  created() {
    this.updatePageData();
    this.$store.watch(
      (state) => this.overviewData,
      (newValue) => {
        this.updatePageData();
      },
      { deep: true }
    );
    this.$store.watch(
      (state) => this.analyticsData,
      (newValue) => {
        this.updatePageData();
      },
      { deep: true }
    );
  },
  data() {
    return {
      chartTitles: [],
      chartFilterPanelData: {
        buttons: JSON.parse(JSON.stringify(buttonsInfo)),
      },
      chartFilter: { avg_score: true, visits: false, complaints: false }, //shows avg score first in chart
      chartData: {},
      chartOptions: overviewChartOptions,
      isShownTicks: true,
      isLoading: false,
      isData: false,
    };
  },
  computed: {
    ...mapGetters(["overviewData", "analyticsData", "filterSelected"]),
    roomId() {
      return this.$route.params.roomId;
    },
  },
  methods: {
    async handleChartClick({ label }) {
      if (
        this.filterSelected.viewTimeBy !== "day" ||
        !label.split("").includes("/")
      )
        return;
      const now = new Date();
      const splitted = label.split("");
      const middleIndex = splitted.findIndex((a) => a === "/");
      const dayPart = splitted.slice(0, middleIndex);
      if (dayPart.length < 2) dayPart.unshift("0");
      const monthPart = splitted.slice(middleIndex + 1, splitted.length);
      if (monthPart.length < 2) monthPart.unshift("0");
      const timeFrom = [
        now.getMonth() + 1 < monthPart.join("") ? now.getFullYear() - 1 : now.getFullYear(),
        "-",
        ...monthPart,
        "-",
        ...dayPart,
      ].join("");
      const timeToDate = new Date(timeFrom);
      const timeToMap = {
        year: timeToDate.getFullYear(),
        month:
          `${timeToDate.getMonth() + 1}`.length < 2
            ? `0${timeToDate.getMonth() + 1}`
            : timeToDate.getMonth() + 1,
        day:
          `${timeToDate.getDate()}`.length < 2
            ? `0${timeToDate.getDate()}`
            : timeToDate.getDate(),
      };
      const timeTo = `${timeToMap.year}-${timeToMap.month}-${timeToMap.day}`;
      this.$store.commit({
        type: "updateFilter",
        field: "timeType",
        value: "custom_time",
      });
      this.$store.commit({
        type: "updateFilter",
        field: "time",
        value: { timeFrom, timeTo },
      });
      if (this.$route.name === "performance")
        this.$router.push({ name: "analytics" });
      else if (this.$route.name === "roomAnalytics")
        this.$router.push({ name: "cleanLog" });
      this.$store.commit({ type: "toggleLoading", value: true });

      const filter = !this.roomId
        ? { ...this.filterSelected }
        : { ...this.filterSelected, roomId: this.roomId };
      await Promise.all([
        this.$store.dispatch({ type: "getOverviewData", filter }),
        this.$store.dispatch({ type: "getAnalyticsData", filter }),
      ]);

      this.$store.commit({ type: "toggleLoading", value: false });
    },
    handleFilterClick(index) {
      let activeButtonCount = 0;
      this.chartFilterPanelData.buttons.forEach((button) => {
        return button.isActive ? (activeButtonCount += 1) : "";
      });
      if (
        activeButtonCount >= 3 &&
        !this.chartFilterPanelData.buttons[index].isActive
      ) {
        new Toast({ message: "Cannot Select More Than Three", type: "danger" });
        return;
      }
      this.chartFilterPanelData.buttons[index].isActive = !this
        .chartFilterPanelData.buttons[index].isActive;
      this.chartFilterPanelData.buttons[index].isActive
        ? (activeButtonCount += 1)
        : (activeButtonCount -= 1);
      activeButtonCount === 3
        ? (this.isShownTicks = false)
        : (this.isShownTicks = true);
      this.chartFilterPanelData.buttons.forEach(
        (button) => (this.chartFilter[button.name] = button.isActive)
      );
      this.updateChart(this.chartFilter);
    },
    updatePageData() {
      if (!this.analyticsData || !this.overviewData) return;
      //for showing "no data" screen
      let isData = false;
      for (let key in this.overviewData) {
        if (this.overviewData[key].length) {
          isData = true;
          break;
        }
      }
      if (!isData) this.isData = false;
      else this.isData = true;

      const overviewSums = getOverviewSums(this.overviewData);
      const analyticsSums = getAnalyticsSums(this.analyticsData.hourData);
      const trendInfo = performanceService.trendInfoForBarItems(
        this.overviewData
      );
      this.updateChart(this.chartFilter);
      this.chartFilterPanelData = {
        ...overviewSums,
        ...analyticsSums,
        buttons: this.chartFilterPanelData.buttons,
      };
      this.chartFilterPanelData.buttons = this.chartFilterPanelData.buttons.reduce(
        (acc, buttonInfo) => {
          if (this.chartFilterPanelData[buttonInfo.name]) {
            buttonInfo.trendInfo = trendInfo[buttonInfo.name];
            buttonInfo.trendInfo
              ? (buttonInfo.trendInfo.color =
                  trendArrowColors[buttonInfo.name][
                    buttonInfo.trendInfo.upDown
                  ])
              : buttonInfo.trendInfo;
            const currButtonValue = this.chartFilterPanelData[buttonInfo.name];
            if (
              currButtonValue < 1000 &&
              (buttonInfo.name === "response_time_SLA" ||
                buttonInfo.name === "complaints")
            )
              buttonInfo.text = `${new Number(currButtonValue)}`;
            else {
              buttonInfo.text = `${
                currButtonValue >= 1000
                  ? util.kFormatter(currButtonValue)
                  : new Number(currButtonValue)
              }`;
              if (currButtonValue >= 1000) {
                if (buttonInfo.name === "time_saved") {
                  buttonInfo.text = `${util.kMinutesToHours(currButtonValue)}`;
                }
              }
            }
            try {
              if (buttonInfo.name==="visits") buttonInfo.isAmended = this.chartFilterPanelData.isAmended
              if (buttonInfo.name === "avg_score")
                buttonInfo.text = new Number(currButtonValue);
              else if (
                buttonInfo.name === "response_time_SLA" ||
                buttonInfo.name === "time_saved"
              ) {
                if (buttonsInfo.name === "time_saved")
                  buttonsInfo.text +=
                    currButtonValue >= 1000 ? "" : ` ${this.$t("min")}`;
                else if (buttonInfo.name === "response_time_SLA")
                  buttonInfo.text += ` ${this.$t("min")}`;
              } else if (buttonInfo.name === "complaints_vs_visits") {
                buttonInfo.text += "%";
              }
            } catch (err) {
              //getting error in buttonInfo.text += ` ${this.$t('min')}` when changing filter
            }
            acc.push(buttonInfo);
          }
          return acc;
        },
        []
      );
    },
    updateChart(filter) {
      let chartData = performanceChartService.getOverviewChartData(
        this.overviewData,
        filter
      );
      if (this.filterSelected.viewTimeBy === "month") {
        chartData.labels = chartData.labels.map((a) =>
          this.$t(`monthMap.${a}`)
        );
      }
      const {
        datasetsWithStyle,
        chartTitles,
        yAxesOptions,
      } = performanceChartService.getChartStyles(chartData, this.isShownTicks);
      this.chartTitles = chartTitles;

      //remove zero line to all charts
      yAxesOptions.forEach((item) => (item.gridLines.zeroLineWidth = 0));

      this.chartOptions.scales.yAxes = yAxesOptions;
      chartData.datasets = datasetsWithStyle;
      this.chartData = chartData;
    },
  },
  components: {
    ChartFilterPanel,
    Chart,
    Loading,
    NoData,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins";
@import "@/styles/vars";

.print-chart {
  @include chart-for-print;
}

.no-print-chart {
  @media print {
    display: none;
  }
}

.filter-info-container {
  margin-bottom: 25px;
}

.overview-analytics-container {
  box-shadow: 0px 0px 1px #bdbdbd;
}

.chart-y-titles {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  font-weight: $font-weight-regular;
  font-size: $font-size-small;
  @include rtl {
    flex-direction: row-reverse;
  }
}

.score-container h3 {
  margin-bottom: 30px;
}

.score-container {
  overflow-y: hidden;
}
</style>