<template>
  <div ref="svgDiv"></div>
</template>

<script>
export default {
  name: 'SVGIcon',
  props: {
    svg: { type: String, required: true },
  },
  data() {
    return {
      svgEl: null,
    }
  },
  async created() {
    const res = await fetch(this.svg)
    const svg = await res.text()
    const el = document.createElement('div')
    el.innerHTML = svg
    const svgEl = el.querySelector('svg')
    this.$refs.svgDiv.appendChild(svgEl)
    this.$emit('doneRender')
  },
}
</script>

<style></style>
