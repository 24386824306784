<template>
  <section class="overview-container">
    <OverviewLineChartList
      :fullWidthChart="true"
      class="custom-overview-charts"
      :singleChart="true"
      v-if="chartData"
      :chartData="chartData"
      :analyticsChartData="analyticsData.charts"
    />
    <OverviewRoomTable
      v-if="roomsData.length"
      :roomsData="surveyType === 'survey' ? realTimeData : roomsData"
      :shift="currShift"
      :inSurveyOverviewPage="surveyType === 'survey' ? true : false"
      class="custom-room-table"
    />
    <OverviewDonuts
      v-if="processedAnalyticsData"
      :analyticsChartData="processedAnalyticsData"
      :inSurveyOverviewPage="true"
    />
    <TableByHour
      class="custom-table-hour"
      v-if="
        analyticsData && analyticsData.hourData && analyticsData.hourData.length
      "
      :dataByHour="analyticsData.hourData"
      :hideRows="['maintenanceComplaint', 'maintenanceArrivals']"
      :inSurveyOverviewPage="true"
    />
  </section>
</template>

<script>
import OverviewDonuts from '../components/OverviewDonuts';
import OverviewRoomTable from '../components/OverviewRoomTable';
import OverviewLineChartList from '../components/OverviewLineChartList';
import TableByHour from "../components/TableByHour";
import { OverviewLineChartsData } from '../services/performanceChartService';
import { getOverviewData, getOverviewSums } from '../services/performanceService';
import shiftService from '../../control-panel/services/shiftService';
import realTimeService from '../../real-time/services/realTimeService';
import chartService from '../services/performanceChartService';
import util from '../../common/services/utilService';
import { overviewLineChartsColors, trendArrowsBackgroundColors } from '../consts/consts';
import { mapGetters } from 'vuex';
import scssVars from '@/styles/vars.scss';
import io from 'socket.io-client/dist/socket.io';

const datasetMatchingProperties = { borderWidth: 1, pointHitRadius: 5, pointHoverRadius: 5, hoverBorderWidth: 3, pointRadius: 2 };
const SOCKET_URL = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:3000';

export default {
  props: {
    siteType: { type: String, required: false }
  },
  data() {
    return {
      realTimeData: null,
      currShift: null,
      chartData: null,
      roomsData: [],
      surveyType: this.siteType,
      socket: null,
      trendColors: {
        avg_score: { up: scssVars.trendArrowGreen, down: scssVars.trendArrowRed },
        complaints: { up: scssVars.trendArrowRed, down: scssVars.trendArrowGreen },
      },
      yearChartData: null,
    }
  },
  async created() {
    const { company, site, rooms } = this.filterSelected;
    const filter = { company, site, rooms };
    await this.getRealTimeData(site)
    const [lastMonthFilter, lastYearFilter] = [util.getTimeByType('last_month'), util.getTimeByType('last_year')];
    const monthlyDataPrm = getOverviewData({ ...filter, time: lastMonthFilter, viewTimeBy: 'day' });
    const yearlyDataPrm = getOverviewData({ ...filter, time: lastYearFilter, viewTimeBy: 'month' });
    const [monthData, yearlyData] = await Promise.all([monthlyDataPrm, yearlyDataPrm])
    const monthSums = getOverviewSums(monthData);
    let monthOverviewData = new OverviewLineChartsData(JSON.parse(JSON.stringify(monthData)));

    const chartData = [];

    for (const property in monthOverviewData) {
      if (property !== 'complaints') continue;
      const labels = monthOverviewData.labels(property);
      const values = monthOverviewData.values(property)
      const { start, end } = chartService.trendStartEnd(values);
      const isTrendUp = start < end;
      chartData.push({
        labels,
        dataset: [{
          ...datasetMatchingProperties,
          isTrendUp,
          trendArrowBackground: this.trendColors[property][isTrendUp ? 'up' : 'down'],
          sum: util.kFormatter(monthSums[property]),
          label: this.$t('clicks'),
          data: values,
          pointBackgroundColor: overviewLineChartsColors[property],
          borderColor: overviewLineChartsColors[property],
          pointHoverBackgroundColor: 'white'
        }]
      });
    }

    const yearChartData = new OverviewLineChartsData(yearlyData);
    const yearlySums = getOverviewSums(yearlyData);
    for (const property in monthOverviewData) {
      if (property !== 'complaints') continue;
      const labels = yearChartData.labels(property, this.$t('monthMap'));
      const values = yearChartData.values(property)
      const { start, end } = chartService.trendStartEnd(values);
      const isTrendUp = start < end;
      chartData.push({
        labels,
        dataset: [{
          ...datasetMatchingProperties,
          isTrendUp,
          trendArrowBackground: trendArrowsBackgroundColors[property + '_static'],
          trendArrowColor: this.trendColors[property][isTrendUp ? 'up' : 'down'],
          sum: util.kFormatter(yearlySums[property]),
          gradientEnd: overviewLineChartsColors[property + '_static'],
          label: this.$t('clicks'),
          data: values,
          borderColor: 'white',
          pointHoverBorderColor: 'white',
          pointHoverBackgroundColor: overviewLineChartsColors[property + '_static'],
        }]
      });
    }
    this.chartData = chartData;
    this.roomsData = this.$store.getters.analyticsData.roomsData.map(a => ({ name: a.displayName, score: a.score, trend: a.trend })).sort((a, b) => a.score - b.score);
    if (this.surveyType === 'questioner') {
      this.handleIncomingRealTimeData(site)
      this.mergedRoomsDataWithRealTime(this.roomsData, this.realTimeData)
    }
  },

  methods: {
    async getRealTimeData(siteId) {
      if (!siteId) return;
      const shift = await shiftService.getCurrentShift(siteId);
      if (shift) {
        this.currShift = shift;
        const timeFrom = realTimeService.filterFromByShiftStartHour(shift.start_hour);
        const isSurveysite = (this.surveyType === 'survey') ? true : false;
        this.realTimeData = await realTimeService.getRealTimeData(siteId, timeFrom, 'clean', isSurveysite);
        return this.realTimeData
      }
      else this.realTimeData = [];
    },
    async handleIncomingRealTimeData(siteId) {
      if (this.socket) this.socket.disconnect();
      this.socket = io.connect(SOCKET_URL, {
          // withCredentials: true,
          // transports: ['websocket', 'polling']
        })
      if (this.currShift) {
        const timeFrom = await realTimeService.filterFromByShiftStartHour(this.currShift.start_hour);
        this.socket.emit('site-id', { siteId: siteId, timeFrom: timeFrom.toUTCString() });
        this.socket.on('site-data', ({ cleanRealTimeData }) => {
          const roomsData = this.$store.getters.analyticsData.roomsData.map(a => ({ name: a.displayName, score: a.score, trend: a.trend })).sort((a, b) => a.score - b.score);
          this.mergedRoomsDataWithRealTime(roomsData, cleanRealTimeData)
        })
      }
    },
    mergedRoomsDataWithRealTime(roomsData, realTimeData) {
      if (!realTimeData.length || !roomsData) return;
      let copyRoomsData = JSON.parse(JSON.stringify(roomsData))
      const copyRealTimeData = JSON.parse(JSON.stringify(realTimeData))
      const mappedRealTimeData = copyRealTimeData.reduce((acc, room) => {
        if (!acc[room.name]) acc[room.name] = room;
        return acc;
      }, {})
      copyRoomsData.map(room => {
        if (mappedRealTimeData[room.name] && mappedRealTimeData[room.name].arrival) {
          room.arrival = mappedRealTimeData[room.name].arrival;
        }
        else room.arrival = { time: "", count: "" }
        room.openTime = mappedRealTimeData[room.name].openTime;
        room.isOpen = mappedRealTimeData[room.name].isOpen;
      })
      copyRoomsData.sort((a, b) => {
        if (a.arrival.time || b.arrival.time) {
          if (b.arrival.time > a.arrival.time) return 1
          if (a.arrival.time > b.arrival.time) return -1;
        }
        else if (a.openTime || b.openTime) {
          if (b.openTime > a.openTime) return 1
          if (a.openTime > b.openTime) return -1;
        }
        return 0
      });
      this.roomsData = copyRoomsData
      return copyRoomsData
    },
  },

  computed: {
    ...mapGetters(['filterSelected', 'analyticsData']),
    processedAnalyticsData() {
      if (!this.analyticsData) return;
      const { charts } = JSON.parse(JSON.stringify(this.analyticsData))
      charts.maintenance = { data: [], labels: [] }
      return charts;
    },

  },

  destroyed() {
    if (this.socket) this.socket.disconnect();
  },
  components: { OverviewRoomTable, OverviewDonuts, OverviewLineChartList, TableByHour }
}
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
@import "@/styles/vars.scss";

.overview-container {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(9, 1fr);
  height: 75vh;
  .custom-overview-charts {
    grid-column: 1/9;
    grid-row: 1/7;
  }
}
.custom-room-table {
  grid-row: 1/7;
}
.custom-table-hour {
  grid-row: 7/10;
  height: 90%;
  grid-column: 1/17;
  @include shadow-border;
  padding-right: 5px;
  margin-top: 10px;
}
</style>