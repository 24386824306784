<template>
  <section :class="['donuts-container',{'single-donuts':inSurveyOverviewPage}]">
    <template v-for="(chartData, key) in chartListData">
      <div  class="doughnut-item" v-if="chartData.labels.length" :key="key">
        <div class="title">{{$t(key)}}</div>
        <Chart
          type="doughnut"
          :labels="chartData.labels"
          :datasets="chartData.datasets"
          :options="chartOptions"
          height="85%"
          width="100%"
        />
      </div>
    </template>
  </section>
</template>

<script>
import { analyticsChartColors } from '../consts/consts';
import { capitalize } from '../../common/services/utilService';
import Chart from '../../common/components/Chart';
import overviewChartService from '../services/overviewChartService';
import utilService from '../../common/services/utilService';
import scssVars from '@/styles/vars.scss';

export default {
  props: {
    analyticsChartData: { type: Object, required: true },
    inSurveyOverviewPage:{type:Boolean,required:false,default:false}
  },
  data() {
    return {
      chartColors: analyticsChartColors,
      labels:{
         operation :{general:'general',cleaning:'cleaning',maintenance:'maintenance'},
         survey:{general:'performance',cleaning:'clicks',maintenance:'maintenance'}
         },
      chartListData: null,
      chartOptions: {
        maintainAspectRatio: false,
        legend: { position: 'right', labels: { usePointStyle: true, padding: 5, fontSize: 10, fontFamily: 'Assistant' } },
        tooltips: {
          callbacks: {
            label: (item, tableData) => {
              let str = '';
              str += tableData.labels[item.index] + ' ';
              str += +tableData.datasets[0].data[item.index];
              return str;
            },
          },
          bodyFontSize: 12,
          titleFontSize: 12,
          backgroundColor: scssVars.lightGrey,
          titleFontColor: scssVars.blackBlue,
          bodyFontColor: scssVars.blackBlue
        },
      }
    }
  },
  created() {
    this.setChartData(JSON.parse(JSON.stringify(this.analyticsChartData)));
  },
  methods: {
    setChartData(data) {
      const dontusType = (this.inSurveyOverviewPage)?'survey':'operation';
      const Titlelabels = this.labels;
      const {general,cleaning} = Titlelabels[dontusType];
      if(dontusType === 'survey'){
        data.clicks=data.cleaning;
        delete data.cleaning;
      }
      const chartListData = { [general]: {}, [cleaning]: {}, maintenance: {} };
      let signals = { [cleaning]: 0, maintenance: 0, totalLikes: 0 };
      for (let key in data) {
        if (data[key].data) {
          const sorted = utilService.sortParallelArrays(data[key].data, data[key].labels);
          data[key].data = sorted.map(a => +(a.num).toFixed(0));
          data[key].labels = sorted.map(a => a.str);
          data[key].data.forEach(a => (signals[key] = signals[key] + a));
          data[key] = overviewChartService.addPercentToLabels(data[key], signals[key]);
          let labels;
          if (data[key].localLabels && data[key].localLabels[0]) labels = data[key].localLabels
          else if (data[key].labels[0]) labels = data[key].labels
          else labels = [] //["No Data"],
          chartListData[key] = {
            labels: labels,
            datasets: [{
              data: data[key].data[0] ? data[key].data : [1],
              backgroundColor: data[key].data[0] ? this.chartColors[key] : "#E9E9E9"
            }]
          };
        } else signals[key] = signals[key] + data[key];
      }
      //sum all signals: likes, maintenance & cleaning
      const totalSignals = Object.values(signals).reduce((acc, item) => item + acc, 0);

      const likesProblemsChart = {
        labels: Object.keys(signals).map((a, index) => {
          let labelStr = '';
          labelStr += Math.floor((Object.values(signals)[index] / totalSignals) * 100) + '% ';
          if (a === "totalLikes") labelStr += this.$t("like");
          else labelStr += capitalize(this.$t(a));
          return labelStr;
        }),
        datasets: [
          {
            data: Object.values(signals),
            backgroundColor: this.chartColors["likes"]
          }
        ]
      };
      if (!signals[cleaning] && !signals.maintenance && !signals.totalLikes) {
        likesProblemsChart.labels = [`No Data`];
        likesProblemsChart.datasets[0].data = [1];
        likesProblemsChart.datasets[0].backgroundColor = ["#E9E9E9"];
      }
      chartListData[general] = likesProblemsChart;
      this.chartListData = chartListData;

    }
  },
  components: { Chart }
}
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";

.donuts-container {
  grid-column: 1 / 13;
  grid-row: 5 / 7;
  display: flex;
  justify-content: space-between;
  @include screen-size(xs) {
    flex-direction: column;
    align-items: center;
  }
  .doughnut-item {
    @include shadow-border;
    margin-inline-end: 20px;
    padding: 5px;
    width: 40%;
    height: 95%;
    .title {
      margin-bottom: 10px;
      color: #848593;
      font-size: 1.125rem;
    }
    @include screen-size(xs) {
      width: 100%;
      margin-bottom: 5px;
      margin-inline-end: 0px;
    }
  }
}
.single-donuts {
  grid-column: 9/13;
  grid-row: 1/7;
  flex-direction: column;
  .doughnut-item{
  width: 95%;
  margin-bottom: 10px;
  height: 50%;
  .title{
    margin-bottom: 0px;
  }
  }
 }


</style>