import ajax from './httpService'
const CLOUD_NAME = 'buzzztech'
const UPLOAD_URL = `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/upload`;
const LOGO_PRESET = 'company_logo';
const API_KEY = '616472927269211'

/**
 * @param {File} file
 * @returns {Promise<String>} url string
 */
async function uploadImage(file, preset = '') {
    const uploadPreset = (preset === 'logo') ? LOGO_PRESET : preset;
    const { timestamp, signature } = await ajax.post('cloudinary/signature', { uploadPreset })
    let formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append("cloud_name", CLOUD_NAME);
    formData.append('api_key', API_KEY);
    formData.append('signature', signature);
    formData.append('timestamp', timestamp);

    const response = await fetch(UPLOAD_URL, {
        method: 'POST',
        body: formData
    })
    /**@type {{ url: String }} */
    const { url, secure_url } = await response.json();
    return secure_url || url;
}

export default { uploadImage }