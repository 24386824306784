import ajax from '../../common/services/httpService';
import { levels as allLevels } from '../consts'


export {
    getUsers,
    getUser,
    saveUser,
    removeUser,
    getPermitedLevels,
    getLanguages,
    getDefaultUser,
}

export default { getNextOptions, getFirstOptions }

async function getUsers(filter) {
    const res = await ajax.get('user', null, filter)
    return res
}

async function getUser(id) {
    const res = await ajax.get(`user/${id}`)
    return res
}

async function saveUser(user) {
    if (user.id) return await ajax.put('user', user)
    else return await ajax.post('user', user)
}

async function removeUser(id) {
    const res = await ajax.delete('user', { id })
    return res
}

function getPermitedLevels(level) {
    const levels = allLevels
    const idx = levels.findIndex(lvl => lvl === level)
    return levels.slice(idx)
}

async function getLanguages() {
    const res = await ajax.get('languages')
    return res
}

/**
 * @param {'permitted_franchise' | 'permitted_companies' | 'permitted_sites'} field
 * @param {Number[]} ids
*/
async function getNextOptions(field, ids) {
    if (!ids || ids.length === 0) return { nextField: '', options: [] }
    let fields = ['permitted_franchise_options', 'permitted_companies_options', 'permitted_sites_options'];
    const nextField = fields[fields.findIndex(a => a === `${field}_options`) + 1];
    let options = [];
    if (nextField === 'permitted_companies_options') options = await ajax.get('company', null, { franchiseIds: ids });
    else if (nextField === 'permitted_sites_options') options = await ajax.get('site', null, { companyIds: ids });
    return { nextField, options }
}

/**@param {'buzzz' | 'franchise' | 'company' | 'site'} userLevel*/
async function getFirstOptions(userLevel) {
    let routeName = '';
    let field = '';
    if (userLevel === 'buzzz') {
        routeName = 'franchise';
        field = 'permitted_franchise_options';
    } else if (userLevel === 'franchise') {
        routeName = 'company';
        field = 'permitted_companies_options';
    } else {
        routeName = 'site';
        field = 'permitted_sites_options';
    }
    const options = await ajax.get(routeName);
    return { field, options };
}

function getDefaultUser() {
    return {
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        email: '',
        phone: '',
        type: '',
        level: '',
        language: '',
        panelViews: [], // What pages allowed to see in the panel
        sites: [], // Site ids allowed to see,
        workingAreaIds: [], // working area ids that supervisor is connected to
    }
}