export const hourData = [
    {
        isSlaExpired: false,
        complaints: [{ name: 'Missing paper towel', time: new Date('2019-11-21T08:10:22.301Z') },
        { name: 'Missing paper towel', time: new Date('2019-11-21T08:15:22.301Z') }],
        openTime: new Date('2019-11-21 10:09:16'),
        closeTime: new Date('2019-11-21 10:18:25'),
        responseTime: 188529
    },
    {
        isSlaExpired: false,
        complaints: [{ name: 'Missing paper towel', time: new Date('2019-11-21T08:10:22.301Z') },
        { name: 'Missing paper towel', time: new Date('2019-11-21T08:15:22.301Z') }],
        openTime: new Date('2019-11-21 10:09:16'),
        closeTime: new Date('2019-11-21 10:20:25'),
        responseTime: 188525
    },
];