<template>
  <section class="site-view-container">
    <AdminHeader
      :texts="{ title: $t('sites'), add: $t('new_site') }"
      @add="addSite"
      @input="handleInput"
      :isSearchShown="false"
    >
      <TableSearch :tableTitles="tableTitles" @input="handleInput" />
    </AdminHeader>
    <Modal
      width="medium"
      v-if="addingSite || editingSite"
      @close="closeModal"
      @submit="submitSite"
      :isConfirmDisabled="isConfirmDisabled"
      :texts="{ headerPrimary: addingSite ? $t('new_site') : $t('edit_site') }"
    >
      <div class="form-container">
        <div class="input-container">
          <div>{{ $t('company_name') }}</div>
          <div class="company-select-container">
            <Select
              v-model="site.company_id"
              class="form-select"
              :placeholder="$t('company')"
              :value="companies.length === 1 ? companies[0].id : $store.getters.filterSelected.company"
              :disabled="companies.length === 1"
            >
              <SearchBar v-model="modalSearchBy.companyName" />
              <Option v-for="company in companiesToShow" :key="company.id" :label="company.name" :value="company.id" />
            </Select>
            <ErrorMsg :isShown="errors.includes('company')" :text="`${$t('please_select')}`" />
          </div>
        </div>
        <div class="input-container">
          <div>{{ $t('type_of_site') }}</div>
          <Select v-model="site.type" class="form-select" :placeholder="$t('type_of_site')">
            <Option v-for="type in siteType" :key="type" :label="type" :value="type" />
          </Select>
          <ErrorMsg :isShown="errors.includes('type')" :text="`${$t('please_select')}`" />
        </div>
      </div>
      <div v-if="showEmbeddedLink" class="input-container">
        <div>{{ $t('embedded_link') }}</div>
        <input type="text" :class="{ 'modal-input': true }" v-model="site.embedded_link" />
      </div>
      <div class="input-container">
        <div>{{ $t('site_name') }}</div>
        <input type="text" :class="{ 'modal-input': true }" v-model="site.name" />
        <ErrorMsg :isShown="errors.includes('name')" :text="`${$t('please_enter')}`" />
      </div>
      <div class="form-container">
        <div class="input-container">
          <div>
            <div>{{ $t('sector') }}</div>
            <div class="sector-select-container">
              <Select v-model="site.sector" class="form-select">
                <SearchBar v-model="modalSearchBy.sectorName" />
                <Option v-for="sector in sectorsToShow" :key="sector.id" :label="sector.name" :value="sector.name" />
              </Select>
              <ErrorMsg :isShown="errors.includes('sector')" :text="`${$t('please_select')}`" />
            </div>
          </div>
          <div>
            <div>{{ $t('status') }}</div>
            <div class="status-select-container">
              <Select v-model="site.status" class="form-select">
                <Option v-for="(status, idx) in siteStatuses" 
                  :key="status + idx"
                  :label="$t(status)"
                :value="status" />
              </Select>
            </div>
          </div>
          <div>
            <div>{{ $t('management_co') }}</div>
            <Select v-model="site.managementServiceProviderId" class="form-select">
              <Option
                v-for="managementCompany in serviceProviders.management"
                :key="managementCompany.id"
                :label="managementCompany.name"
                :value="managementCompany.id"
              />
            </Select>
          </div>
        </div>
        <div class="input-container">
          <div class="checboxes-container">
            <div class="site-checkbox-container">
              <span>{{ $t('is_time_save') }}</span>
              <Checkbox v-model="site.is_time_save" />
            </div>
            <div class="site-checkbox-container">
              <span>{{ $t('exposures') }}</span>
              <Checkbox v-model="site.exposures" />
            </div>
            <div class="site-checkbox-container">
              <span>{{ $t('alerts') }}</span>
              <Checkbox v-model="site.alerts" />
            </div>
            <div class="site-checkbox-container" v-if="addingSite">
              <span>{{ $t('shift') }}</span>
              <Checkbox v-model="isDefaultShifts" />
            </div>
          </div>
          <div class="service-providers">
            <div>{{ $t('cleaning_co') }}</div>
            <Select v-model="site.cleaningServiceProviderId" class="form-select">
              <Option
                v-for="cleaningCompany in serviceProviders.cleaning"
                :key="cleaningCompany.id"
                :label="cleaningCompany.name"
                :value="cleaningCompany.id"
              />
            </Select>
          </div>
        </div>
      </div>
    </Modal>
    <Table
      v-if="sitesToShow.length"
      :tableData="sitesToShow"
      idField="id"
      :editOptions="{ edit: true, remove: true }"
      :extraActions="[{ name: 'qr_code', type: 'generateSiteQRs' }]"
      :isHidingId="false"
      @action="generateSiteQRs"
      @editRow="editSite"
      @removeRow="removeSite"
      class="site-table"
      @rowClick="showSiteDetails"
    />
    <div v-else>
			<h3>{{ $t('no_site_found') }}</h3>
		</div>
  </section>
</template>

<script>
import siteService from '../services/siteService'
import util from '../../common/services/utilService'
import zipService from '../../common/services/zipService'
import Checkbox from '../../common/components/Checkbox'
import { getCompanyGeneralData } from '@/modules/common/services/filterService'
import AdminHeader from '../components/AdminHeader'
import swalService from '@/modules/common/services/swalService'
import Modal from '@/modules/common/components/Modal'
import Select from '@/modules/common/components/Select'
import Option from '@/modules/common/components/Option'
import Table from '@/modules/common/components/customTable/Table'
import TableSearch from '@/modules/common/components/customTable/TableSearch'
import ErrorMsg from '@/modules/common/components/ErrorMsg'
import SearchBar from '@/modules/common/components/SearchBar'
import { surveyTemplates } from '@/modules/surveys/consts/consts'
import { siteType } from '../consts.js'
import QRCode from 'qrcode'

export default {
  data() {
    return {
      sites: [],
      companies: [],
      sectors: [],
      siteStatuses: ['active', 'inactive', 'on hold'],
      siteType,
      addingSite: false,
      editingSite: false,
      serviceProviders: { cleaning: [], management: [] },
      site: this.getDefaultSite(),
      modalSearchBy: {
        companyName: '',
        sectorName: '',
      },
      errors: [],
      tableTitles: null,
      searchBy: '',
      searchTxt: '',
      isConfirmDisabled: false,
      isDefaultShifts: true,
    }
  },
  async created() {
    const sitesPrm = siteService.getSites()
    const companiesPrm = getCompanyGeneralData(this.$store.getters.filterSelected)
    const [sites, companies] = await Promise.all([sitesPrm, companiesPrm])
    const companiesNoDup = companies.reduce((acc, item) => {
      const company = { id: item.company_id, name: item.company_name }
      if (!acc[company.name]) acc[company.name] = company
      return acc
    }, {})
    this.companies = Object.values(companiesNoDup)
    this.sites = sites
    this.setTableTitles()
  },
  computed: {
    sitesToShow() {
      return util.filterArrayOfObjectsWithString(
        this.sites.map((s) => ({ id: s.id, ...s })),
        this.searchTxt,
        this.searchBy
      )
    },
    companiesToShow() {
      const { companyName } = this.modalSearchBy
      if (!companyName) return this.companies
      const filteredCompanies = this.companies.filter((company) =>
        company.name.toLowerCase().includes(companyName.toLowerCase())
      )
      return filteredCompanies
    },
    sectorsToShow() {
      const { sectorName } = this.modalSearchBy
      if (!sectorName) return this.sectors
      const filteredSectors = this.sectors.filter((sector) =>
        sector.name.toLowerCase().includes(sectorName.toLowerCase())
      )
      return filteredSectors
    },
    showEmbeddedLink() {
      return this.site.type === 'survey' || this.site.type === 'task' || this.site.type === 'questioner'
    },
  },
  methods: {
    async getServiceProviders() {
      const serviceProviders = await siteService.getServiceProviders()
      this.serviceProviders = serviceProviders
    },
    async getSiteServiceProvider() {
      const serviceProviders = await siteService.getServiceProvidersBySiteId(this.site.id)
      this.$set(this.site, 'cleaningServiceProviderId', serviceProviders.cleaning)
      this.$set(this.site, 'managementServiceProviderId', serviceProviders.management)
    },
    async getSectors() {
      const sectors = await siteService.getSectors()
      this.sectors = sectors
    },
    showSiteDetails(id) {
      this.$router.push(`/admin/site/${id}`)
    },
    async editSite(site) {
      this.editingSite = true
      const company = this.companies.find((a) => a.name === site.company_name)
      site = await siteService.getSiteById(site.id)
      this.site = { ...site, company_id: company.id }
      this.getServiceProviders()
      this.getSiteServiceProvider()
      this.getSectors()
    },
    addSite() {
      this.addingSite = true
      this.getServiceProviders()
      this.getSectors()
    },
    closeModal() {
      if (this.addingSite) this.addingSite = false
      if (this.editingSite) this.editingSite = false
      this.errors = []
      this.site = this.getDefaultSite()
    },
    async removeSite(id) {
      const result = await swalService.confirmMsg()

      if (!result.value) return
      try {
        await siteService.removeSite({ id })
        swalService.deletedMsg()
      } catch (err) {
        swalService.errorMsg()
      }
      this.sites = await siteService.getSites()
    },
    checkForm() {
      this.errors = []
      const { name, company_id, type, sector } = this.site
      if (!name) this.errors.push('name')
      if (!type) this.errors.push('type')
      if (!company_id) this.errors.push('company')
      if (!sector) this.errors.push('sector')
    },
    async submitSite() {
      this.checkForm()
      if (this.errors.length) return
      this.isConfirmDisabled = true
      try {
        const company = this.companies.find((c) => c.id === this.site.company_id)
        this.site.company_name = company.name
        delete this.site.country
        if (this.addingSite) {
          this.site.survey = surveyTemplates['default_survey']
          const { isDefaultShifts } = this
          await siteService.addSite({ ...this.site, isDefaultShifts })
        }
        if (this.editingSite) await siteService.updateSite(this.site)
        this.editingSite = false
        this.addingSite = false
        this.isConfirmDisabled = false
        this.isDefaultShifts = true
        this.site = this.getDefaultSite()
        swalService.savedMsg(this.$t('Your work has been saved'))
      } catch (err) {
        swalService.errorMsg()
      }
      this.sites = await siteService.getSites()
      this.$store.dispatch('loadFilterOptionsAndSelection')
    },
    getDefaultSite() {
      return siteService.getDefaultSite()
    },
    setTableTitles() {
      if (!this.sites.length) return
      this.tableTitles = Object.keys(this.sites[0]).filter((key) => key !== 'id')
    },
    handleInput(value, searchBy) {
      if (searchBy) this.searchBy = searchBy
      this.searchTxt = value
    },
    async generateSiteQRs({ idx: siteIdx }) {
      const { id, name } = this.sitesToShow[siteIdx]

      if (!id) return
      const urls = []
      const domain = window.location.origin
      const [rooms, siteQRDeviceCodes] = await Promise.all([
        siteService.getSiteRooms(id),
        siteService.getSiteQRDevices(id),
      ])
      
      if (!siteQRDeviceCodes.length) return swalService.errorMsg('No QR devices found for this site')

      await Promise.all(
        siteQRDeviceCodes.map(async (device) => {
          let { deviceCode, roomId, type } = device
          const qrDomain = type === 'qr tag' ? 'survey-app' : 'task-app'
          const room = rooms.find((r) => r.id === roomId)
          if (!room) return
          const { ref_id, zone, gender, floor, building } = room
          const qrCodeImage = new Image()
          if (deviceCode.includes('QR:')) {
            deviceCode = deviceCode.replace('QR:', '')
          }
          const url = `${domain}/${qrDomain}/?QR:${deviceCode}`
          const text = `${ref_id || ''} ${zone || ''} ${gender || ''} ${floor ? `Floor ${floor}` : ''} ${building || ''}`
          const qrCodeImageData = await QRCode.toDataURL(url, {
            width: 300,
            height: 300,
            color: {
              dark: '#000000', // QR code color
              light: '#00000000', // Background color (fully transparent)
            },
          })
          return new Promise((resolve) => {
            qrCodeImage.onload = function() {
              const canvas = document.createElement('canvas')
              canvas.width = qrCodeImage.width
              canvas.height = qrCodeImage.height + 30
              const ctx = canvas.getContext('2d')
              ctx.drawImage(qrCodeImage, 0, 0)
              ctx.font = '12px Arial' // Set the font for the text
              ctx.fillText(text, 75, qrCodeImage.height) // Draw the text below the QR code
              const a = document.createElement('a')
              a.download = `${room.display_name}-${qrDomain}-QR.png`
              a.href = canvas.toDataURL('image/png')
              urls.push({
                url: a.href,
                name: a.download,
              })
              resolve()
            }
            qrCodeImage.src = qrCodeImageData
          })
        })
      )
      zipService.downloadImagesAsZip(urls).then((content) => {
        // Create a temporary anchor element
        const anchor = document.createElement('a')
        anchor.style.display = 'none'
        anchor.href = URL.createObjectURL(content)
        anchor.download = `site-${name}-QRs.zip`

        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
      })
    },
  },
  components: {
    Table,
    AdminHeader,
    Modal,
    Select,
    Option,
    Checkbox,
    ErrorMsg,
    SearchBar,
    TableSearch,
  },
}
</script>

<style lang="scss">
.site-view-container {
  .modal {
    min-width: 40vw !important;

    span.close-btn {
      margin-inline-end: 40px !important;
    }

    .footer {
      margin-inline-start: 40px !important;
      margin-inline-end: 50px !important;
      justify-content: end !important;
    }
  }

  .md-field.form-select {
    margin: 2px 0px 4px 0px;

    .md-input {
      max-height: unset;
    }
  }

  .main-content {
    gap: 35px !important;

    & > div:nth-of-type(3) > .input-container {
      display: flex;
      flex-direction: column;
      gap: 35px;
    }
  }

  .b-input {
    position: static !important;
    height: 1.82rem !important;
    width: 1.82rem !important;

    &::after {
      left: 0.65rem !important;
      top: 0.2rem !important;
      width: 0.5rem !important;
      height: 1.15rem !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';
$input-width: 17vw;

.site-checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.checboxes-container {
  display: flex;
  width: 100%;
  place-content: space-between;
}

.service-providers {
  display: flex;
  flex-direction: column;
}

.input-container {
  display: flex;
  flex-direction: column;

  & > div {
    @include modal-input-label;
  }

  .modal-input {
    width: unset;
  }
}

.form-container {
  display: flex;
  gap: 60px;

  div {
    @include modal-input-label;
  }

  .form-select {
    @include border($grey-border-color);
    border-radius: 4px;
    padding: unset;
    height: 33px;
    width: $input-width;
  }
}

.modal-input {
  @include modal-input;

  &.disabled {
    color: $grey;
  }
}
</style>
