
import { Parser } from 'json2csv';
import { numberToHour, timePassed, ddmmyy } from '../../../filters';
import util from '../services/utilService';
import moment from 'moment';

/**@param {(string[])[]} rows*/
function rowsToCsv(rows) {
    return new Blob([rows.map(e => e.join(",")).join("\n")], { type: 'text/csv;charset=utf-8' });
}

/**@param {{
 * iconUrl: string;
 * label: string;
 * name: string;
 * text: number | string;
 * trendInfo?: { text: string; upDown: 'up' | 'down' }
 * }[]} infoBarItems*/
function getBarItemsInfoForCsv(infoBarItems) {
    /**@type {Object<string, { value: number | string; trendInfo: string; }>} */
    const barItemsInfo = infoBarItems.reduce((acc, item) => {
        acc[item.label] = {
            value: item.text,
            trendInfo: item.trendInfo ?
                `${item.trendInfo.upDown === 'down' ? '-' : ''}${item.trendInfo.text}`
                : ''
        }
        return acc;
    }, {})
    return barItemsInfo;
}


/**@typedef {import('chart.js').ChartDataSets} Datasets*/
/**
 * @param {{
 * cleaning: { labels: string[]; datasets: Datasets[] }
 * maintenance: { labels: string[]; datasets: Datasets[] }
 * general: { labels: string[]; datasets: Datasets[] }
 * }} chartListData*/
function getCsvForChartListData(chartListData) {
    const fields = [
        'General', 'general%', 'General Count', 'Cleaning', 'cleaning%',
        'Cleaning Count', 'Maintenance', 'maintenance%', 'Maintenance Count'
    ];
    let data = [];
    for (const property in chartListData) {
        chartListData[property].labels.forEach((label, index) => {
            const totalIndex = Object.keys(chartListData[property].datasets[0]._meta)[0];
            const total = Number(chartListData[property].datasets[0]._meta[totalIndex].total);
            const value = Number(chartListData[property].datasets[0].data[index]);
            const percentageNum = ((value / total) * 100).toFixed(0) + '%';
            if (property === 'general') {
                data[index] = { ...data[index], 'General': label, 'general%': percentageNum, 'General Count': value };
            } else if (property === 'cleaning') {
                data[index] = { ...data[index], 'Cleaning': label, 'cleaning%': percentageNum, 'Cleaning Count': value };
            } else if (property === 'maintenance') {
                data[index] = { ...data[index], 'Maintenance': label, 'maintenance%': percentageNum, 'Maintenance Count': value };
            }
        });
    }
    return _generateBlob(fields, data);
}

/**
 * @param {{
 * arrivals: number;
 * complaints: number;
 * likes: number;
 * maintenanceArrivals: number;
 * maintenanceComplaint: number;
 * supervisors: number;
 * time: number;
 * visits: number;
 * }[]} hourData
 */
function generateHourCsv(hourData) {
    let fields = ['issue'];
    /**@type {Array} */
    let data = [
        { issue: 'Visits' },
        { issue: 'C.complaints' },
        { issue: 'C.arrivals' },
        { issue: 'M.complaints' },
        { issue: 'M.arrivals' },
    ];
    hourData.forEach(hourItem => {
        const hour = numberToHour(hourItem.time);
        fields.push(hour);
        if (hourItem.visits) data[0] = { ...data[0], [hour]: hourItem.visits };
        if (hourItem.complaints) data[1] = { ...data[1], [hour]: hourItem.complaints };
        if (hourItem.arrivals) data[2] = { ...data[2], [hour]: hourItem.arrivals };
        if (hourItem.maintenanceComplaint) data[3] = { ...data[3], [hour]: hourItem.maintenanceComplaint };
        if (hourItem.maintenanceArrivals) data[4] = { ...data[4], [hour]: hourItem.maintenanceArrivals };
    });
    return _generateBlob(fields, data);
}

/**
 * @param {{
 * _id: number | string;
 * arrivals:number;
 * avgResponseTime: number;
 * complaints: number;
 * displayName: string;
 * missedSla: number;
 * score: number;
 * trend: 'up' | 'down';
 * visits: number;
 * }[]} roomsData
 */
function generateRoomCsv(roomsData) {
    let fields = ['Room Name', 'Visits', 'Complaints', 'Arrivals', 'Supervisors', 'Counter Calls', 'Avg. Response Time', 'Exposures', 'Score'];
    const data = roomsData.map(room => ({
        'Room Name': room.displayName,
        'Visits': room.visits,
        'Complaints': room.complaints,
        'Arrivals': room.arrivals,
        'Supervisors': room.supervisors,
        'Counter Calls': room.counterCalls,
        'Cleandex calls': room.cleandexCalls,
        'Manual calls': room.manualCalls,
        'Avg. Response Time': timePassed(room.avgResponseTime),
        'Exposures': room.exposures ? room.exposures.toFixed()+ '%' : '',
        'Score': room.score.toFixed(0),
    }));
    return _generateBlob(fields, data);
}

/** 
 * @param {Object<string, string[][]>} cleanerArrivalData 
 * @returns {Object<string, Blob>}
 */
function generateArrivalCsv(arrivalData) {
    /**@type {Object<string, Blob>} */
    let roomIdBlobMap = {};
    for (const roomId in arrivalData) {
        const length = util.lengthOfLongestArrayInMatrix(arrivalData[roomId]);
        const fields = ['Date', 'Total Arrived'];
        for (let i = 1; i <= length; i++) {
            fields.push(`${i}`);
        }
        let data = [];
        arrivalData[roomId].forEach(dayArrivals => {
            dayArrivals = dayArrivals.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
            const day = ddmmyy(dayArrivals[0]);
            const totalArrivals = dayArrivals.length;
            let dayData = { 'Date': day, 'Total Arrived': totalArrivals };
            dayArrivals.forEach((timeString, index) => dayData[index + 1] = moment(new Date(timeString)).format('HH:mm'));
            data.push(dayData);
        })
        roomIdBlobMap[roomId] = _generateBlob(fields, data);
    }
    return roomIdBlobMap;
}

/**
 * @param {string[]} fields
 * @param {Object[]} data
 */
function _generateBlob(fields, data) {
    const json2csvParser = new Parser({ fields });
    return new Blob([json2csvParser.parse(data)], { type: 'text/csv;charset=utf-8;' });
}

export default {
    rowsToCsv,
    getBarItemsInfoForCsv,
    getCsvForChartListData,
    generateHourCsv,
    generateRoomCsv,
    generateArrivalCsv
}