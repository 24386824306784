<template>
	<section class="line-charts">
		<section class="line-chart-row" v-for="rowNum in [1, 2]" :key="rowNum">
			<div class="row-title">
				{{ $t(rowNum === 1 ? 'last_month' : dynamicChartDates) }}
			</div>
			<section class="charts">
				<template v-for="(item, index) in chartsPerLine(rowNum)">
					<div
						:class="[
							'score-chart',
							{ 'small-list': !currentFilteringSite.isTimeSave },
							{ 'single-chart': fullWidthChart }
						]"
						:key="index"
						:style="{ backgroundColor: item.dataset[0].gradientEnd }"
						v-if="!(item.dataset[0].label === $t('time_saved') && !currentFilteringSite.isTimeSave)"
					>
						<div :class="['title', rowNum === 2 ? 'white' : '']">
							<div>{{ item.dataset[0].label }}</div>
							<div class="number">
								{{ item.dataset[0].sum }}
								<img
									v-if="rowNum === 1"
									:class="item.dataset[0].isTrendUp ? 'up' : 'down'"
									:style="{ backgroundColor: item.dataset[0].trendArrowBackground }"
									:src="require(`@/assets/performance/trend_white.svg`)"
								/>
								<ArrowIcon
									v-else
									:iconColor="item.dataset[0].trendArrowColor"
									:class="['arrow-bold', item.dataset[0].isTrendUp ? 'up' : 'down']"
								/>
							</div>
						</div>
						<Chart
							type="line"
							:options="getChartOptions(item.dataset[0])"
							:datasets="item.dataset"
							:labels="item.labels"
							height="50%"
							width="100%"
							style="border-radius:10px;"
						/>
					</div>
				</template>
			</section>
		</section>
	</section>
</template>

<script>
import { overviewLineChartsOptions } from '../consts/consts'
import utilService from '../../common/services/utilService'
import Chart from '../../common/components/Chart'
import ArrowIcon from '../../common/icons/ArrowIcon'
import { mapGetters } from 'vuex'

export default {
	props: {
		chartData: { type: Array, required: true },
		singleChart: { type: Boolean, required: false, default: false },
		fullWidthChart: { type: Boolean, required: false, default: false },
		dynamicChartDates: { type: String, required: false }
	},
	data() {
		return {
			chartOptions: { ...JSON.parse(JSON.stringify(overviewLineChartsOptions)) }
		}
	},
	computed: {
		...mapGetters(['currentFilteringSite']),
	},
	methods: {
		getChartOptions(dataset) {
			const options = JSON.parse(JSON.stringify(this.chartOptions))
			const { max, min } = utilService.findMaxAndMin(dataset.data)
			options.scales.yAxes[0].ticks.max = max
			options.scales.yAxes[0].ticks.min = min
			return options
		},
		chartsPerLine(rowNum) {
			if (rowNum === 1) {
				if (!this.singleChart) return this.chartData.slice(0, 4)
				else if (this.singleChart) return this.chartData.slice(0, 1)
			} else {
				if (!this.singleChart) return this.chartData.slice(4, 8)
				else if (this.singleChart) return this.chartData.slice(1, 2)
			}
		}
	},
	components: { Chart, ArrowIcon }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
@import '@/styles/vars.scss';

.line-charts {
	grid-column: 1 / 13;
	grid-row: 1 / 5;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: repeat(4, 1fr);
	display: flex;
	margin-inline-end: 20px;
	justify-content: space-between;
	flex-direction: column;
	@include screen-size(xs) {
		margin-inline-end: 0;
	}

	.line-chart-row {
		display: flex;
		flex-direction: column;
		padding-bottom: 10px;
		margin-bottom: 10px;
		height: 50%;
		@include shadow-border;
		.row-title {
			height: 20%;
			display: flex;
			align-items: center;
			margin-inline-start: 10px;
			color: #848593;
		}
		.charts {
			width: 100%;
			height: 80%;
			display: flex;
			align-items: flex-end;
			justify-content: space-evenly;
			@include screen-size(xs) {
				flex-direction: column;
				align-items: center;
			}
		}
	}
	.score-chart {
		@include border($grey-border-color);
		border-radius: 10px;
		height: 100%;
		width: 24%;
		@include screen-size(xs) {
			width: 100%;
			margin-bottom: 5px;
		}
		&.small-list {
			width: 32%;
		}
		.title {
			color: #848593;
			height: 50px;
			padding: 10px 10px 0;
			&.white {
				color: $white;
			}
			div:first-child {
				margin-bottom: 5px;
			}
			.number {
				display: flex;
				justify-content: space-between;
				font-weight: $font-weight-bold;
				.arrow-bold {
					background-color: $white;
					border-radius: 50%;
					height: 20px;
					width: 20px;
					&.down {
						transform: rotate(180deg);
					}
				}
				img {
					height: 20px;
					border-radius: 50%;
					&.up {
						transform: rotate(180deg);
					}
				}
			}
		}
	}
	.single-chart {
		width: 95%;
	}
}
</style>
