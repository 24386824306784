<template>
	<div class="manual-calls-options">
		<div class="modal-row">
			<div class="field">
				<span class="text">{{ $t('schedule_call_time') }}</span>
				<Select class="select-container" :placeholder="$t('select')" v-model="defaultCallOption.hour">
					<Option v-for="(hour, idx) in hours" :key="hour + idx" :label="hour" :value="hour"> </Option>
				</Select>
			</div>
			<div class="field">
				<span class="text">{{ $t('schedule_call_gap') }}</span>
				<Select class="select-container" :placeholder="$t('select')" v-model="defaultCallOption.gapInMinutes">
					<Option v-for="(gap, idx) in gapsInMinutes" :key="gap + idx" :label="gap" :value="gap"> </Option>
				</Select>
			</div>
			<Button skin="primary" class="add-btn" @click="addManualCallOption">{{ $t('add') }}</Button>
		</div>
		<Table
			v-if="callOptionsToDisplay.length > 0"
			class="table"
			:tableData="callOptionsToDisplay"
			headerFontSize="11px"
			bodyFontSize="15px"
			idField="num"
			@removeRow="removeCallOption"
			:editOptions="{ remove: true }"
		/>
		<div v-else>{{ $t('no_manual_calls_available') }}</div>
	</div>
</template>

<script>
import utilService from '@/modules/common/services/utilService'
import Select from '@/modules/common/components/Select'
import Option from '@/modules/common/components/Option'
import Table from '@/modules/common/components/customTable/Table'
import Button from './Button'
export default {
	name: 'ManualCallsOptions',
	components: { Select, Option, Button, Table },
	props: {
		callOptionsProp: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			hours: utilService.getDayHours(60),
			gapsInMinutes: [5, 10, 15, 20, 30, 60],
			callOptions: [],
			defaultCallOption: {
				num: 1,
				hour: '',
				gapInMinutes: ''
			}
		}
	},
	created() {
		this.callOptions = JSON.parse(JSON.stringify(this.callOptionsProp))
	},
	methods: {
		addManualCallOption() {
			this.defaultCallOption.num = this.callOptions.length + 1
			this.callOptions.push(JSON.parse(JSON.stringify(this.defaultCallOption)))
			this.$emit('saveManualCallOptions', JSON.parse(JSON.stringify(this.callOptions)))
			this.resetForm()
		},
		resetForm() {
			this.defaultCallOption = {
				num: '',
				hour: '',
				gapInMinutes: ''
			}
		},
		removeCallOption(callNum) {
			if (!callNum || this.callOptions.length === 0) return
			this.callOptions = this.callOptions.filter((callOption) => callOption.num !== callNum)
			this.$emit('saveManualCallOptions', JSON.parse(JSON.stringify(this.callOptions)))
		}
	},
	computed: {
		callOptionsToDisplay() {
			if (this.callOptions.length === 0) return []
			return this.callOptions.map((callOption) => {
				return {
					num: callOption.num,
					hour: callOption.hour,
					gapInMinutes: callOption.gapInMinutes
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';

.modal-row {
	@include flex-space-between;
	margin-bottom: 10px;
	gap: 15px;
	.field {
		display: flex;
		flex-direction: column;
		font-size: $font-size-smaller;
		height: 100%;
		> * {
			padding: 2px;
		}
		.select-container {
			width: 100%;
		}
		input {
			@include border($grey-border-color);
			height: 100%;
			padding: 0 10px;
			height: 4.25vh;
		}
	}
}

.table {
	margin-top: 10px;
	border: 1px solid $grey-border-color;
}

.add-btn {
	border-radius: 5px;
}
</style>
