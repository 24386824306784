import { render, staticRenderFns } from "./IssueNumberList.vue?vue&type=template&id=fc219868&scoped=true&"
import script from "./IssueNumberList.vue?vue&type=script&lang=js&"
export * from "./IssueNumberList.vue?vue&type=script&lang=js&"
import style0 from "./IssueNumberList.vue?vue&type=style&index=0&id=fc219868&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc219868",
  null
  
)

export default component.exports