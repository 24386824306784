<template>
  <section class="real-time-table">
    <div :class="['table-head', 'table-row', { cleandex: isCleandex }]">
      <div
        :class="[
          'table-cell',
          index === headerTitles.length - 1 ? 'last' : '',
          index === sortedTitleIdx ? 'sorted' : '',
        ]"
        v-for="(title, index) in headerTitles"
        :style="{ 'grid-column-start': index + 1 }"
        :key="title"
        @click="handleSortClick(title, index)"
      >
        {{ title !== 'sla_status' ? $t(title) : ' ' }}
      </div>
    </div>
    <div
      v-for="(room, index) in sortedRealTimeData"
      :class="['table-row', { 'open-comlaint-row': room.isOpen && isManualCloseSla, cleandex: isCleandex }]"
      :style="isCleandex ? getCleandexColor(room) : getSlaRowBgColor(room)"
      :key="index"
    >
      <RealTimeTableCell
        v-for="(header, headerIndex) in headerTitles"
        class="table-cell"
        :maxSla="room.sla || maxSla"
        :key="header"
        :style="{ 'grid-column-start': headerIndex + 1 }"
        :columnName="header"
        :slaData="room"
        :isMaintenance="isMaintenance"
      />
      <div class="buttons-container" v-if="room.isOpen && isManualCloseSla">
        <button v-if="isTask" class="start-button" @click="startTask(room)">
          <img src="@/assets/start-icon.svg" alt="start" />
        </button>
        <button class="close-button" @click="isTask ? completeTask(room) : emitCloseRoom(room)">
          <img src="@/assets/x-icon.svg" alt="X" />
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import RealTimeTableCell from '../components/RealTimeTableCell'
import { mapGetters } from 'vuex'

export default {
  props: {
    realTimeData: {
      /**
       * @type {new() {
       * name: String;
       * building: String;
       * zone: String;
       * floor: String;
       * gender: String;
       * isOpen: Boolean;
       * arrival: {
       *    count:number;
       *    time: string;
       * }
       * openTime: String | Number;
       * complaints: {
       * name: String;
       * time: String | Number;
       * count: Number
       * }[]
       * }[]}
       */
      type: Array,
      required: true,
    },
    //number of minutes
    maxSla: { type: Number, required: false },
    isMaintenance: { type: Boolean, default: false, required: false },
    isTask: { type: Boolean, default: false, required: false },
    isCleandex: { type: Boolean, default: false, required: false },
    sortBy: { type: String, default: 'time_start', required: false },
  },
  data() {
    return {
      currentSort: null,
      currentSortDir: 'original',
      sortedTitleIdx: null,
    }
  },
  created() {
    this.currentSort = this.sortBy
  },
  methods: {
    emitCloseRoom(room) {
      this.$emit('closeClick', room.id)
    },
    startTask({ slaId }) {
      this.$emit('startTask', slaId)
    },
    completeTask({ slaId }) {
      this.$emit('completeTask', slaId)
    },
    handleSortClick(value, valueIdx) {
      this.sortedTitleIdx = this.sortedTitleIdx === valueIdx ? '' : valueIdx
      if (value === this.currentSort) this.currentSortDir = this.currentSortDir === 'asc' ? 'original' : 'asc'
      if (value != this.currentSort) {
        this.currentSortDir = 'asc'
        this.currentSort = value
      }
    },
    getSlaRowBgColor(room) {
      let bgColor = ''
      const { sensorState, sensorType } = room
      if (sensorType === 'pad9' || sensorType === 'pad9+') {
        if (!sensorState || sensorState === 'offline') bgColor = '#F5F4F4'
      }
      return { backgroundColor: bgColor }
    },
    getCleandexColor({ cleandex }) {
      let bgColor = ''
      if (cleandex > 85) bgColor = '#fd4b650d'
      else if (cleandex > 50) bgColor = '#f4bc510d'
      else bgColor = '#5f9b460d'
      return { backgroundColor: bgColor }
    },
  },
  computed: {
    ...mapGetters(['loggedUser']),
    headerTitles() {
      if (this.isMaintenance)
        return ['name', 'building', 'zone', 'floor', 'gender', 'time_start', 'sla_status', 'duration', 'call']
      if (this.isTask)
        return ['name', 'building', 'zone', 'floor', 'gender', 'date', 'time_start', 'sla_status', 'duration', 'title']
      if (this.isCleandex) return ['name', 'building', 'zone', 'floor', 'gender', 'last_arrival', 'cleandex']
      if (this.isTablet) {
        return [
          'name',
          'gender',
          'arrival',
          'time_start',
          'sla_status',
          'duration',
          'call',
        ] 
      } else {
        return [
          'name',
          'building',
          'zone',
          'floor',
          'gender',
          'arrival',
          'time_start',
          'sla_status',
          'duration',
          'call',
        ]
      }
    },
    isBuzzAdmin() {
      return this.loggedUser.level === 'buzzz' && this.loggedUser.type === 'admin'
    },
    isManualCloseSla() {
      if (this.isTask) return true
      const isManager = this.loggedUser.level === 'manager' && this.loggedUser.type === 'viewer'
      return this.isBuzzAdmin || isManager
    },
    sortedRealTimeData() {
      if (this.currentSortDir === 'original') return this.realTimeData
      const value = this.currentSort
      return [...this.realTimeData].sort((a, b) => {
        if (typeof a[value] === 'string' && typeof b[value] === 'string') {
          // to put empty strings at bottom
          if (a[value] === '') return 1
          if (b[value] === '') return -1
          if (a[value] < b[value]) return -1
          if (a[value] > b[value]) return 1
          return 0
        }
        if (typeof a[value] === 'object' && !!a[value])
          return !b[value] || !a[value] ? -1 : new Date(b[value].time) - new Date(a[value].time)
        if (value === 'floor') return +a[value] - +b[value]
        if (value === 'time_start' || value === 'duration') return new Date(b.openTime) - new Date(a.openTime) // Sorting date columns
        if (value === 'date') return new Date(b.date) - new Date(a.date)
        if (value === 'call') return Object.keys(b.complaints).length - Object.keys(a.complaints).length // Sort by object length
      })
    },
    isTablet() {
      if (screen.width === 1280) {
        return true
      } else {
        return false
      }
    },
  },
  components: { RealTimeTableCell },
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars';

.real-time-table {
  // overflow-y: auto;
  // max-height: 100vh;
  .table-row {
    display: grid;
    transition: background-color 0.2s;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 4fr 1fr;
    align-items: center;
    column-gap: 10px;
    height: 50px;
    border-bottom: 1px solid $grey-border-color;
    font-size: $font-size-medium;
    @media (width: 1280px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      font-size: 1.7rem;
      height: 80px;
    }

    &.cleandex {
      padding-inline: 50px;
    }

    &.table-head {
      font-weight: $font-weight-bold;
      border-bottom: unset;
      font-size: $font-size-smaller;
      text-transform: uppercase;
      font-family: $font-family-main;
      color: #909090;
      @media (width: 1280px) {
        font-size: $font-size-large;
      }
    }

    .buttons-container {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .close-button,
      .start-button {
        max-width: 20px;
        transition: opacity 0.2s;
        opacity: 0;
      }
    }

    &.open-comlaint-row:hover {
      background-color: #f5fafe;

      .close-button,
      .start-button {
        opacity: 1;
      }
    }
  }

  .table-row.cleandex {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.table-cell {
  cursor: pointer;
}

.sorted {
  font-weight: bolder;
}

.sticky-table-head {
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: white;
}

</style>
