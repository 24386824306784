<template>
  <section :class="{ sticky }" class="overview-analytics-container" v-if="filterSelected && filterSelected.company">
    <button
      class="export-button-container"
      @click="print"
      v-if="$route.name === 'analytics'"
      :class="{ bounce: isPrinting }"
    >
      <img :src="require('@/assets/performance/pdf-export.svg')" alt="export pdf" />
    </button>
    <transition name="fade">
      <button
        class="export-analysis-report"
        v-if="$route.name === 'analytics' && isAdmin"
        @click="downloadReport"
        :class="{ bounce: isDownloadingReport }"
      >
        <img :src="require('@/assets/performance/report-export.svg')" alt="report-export" />
      </button>
    </transition>
    <transition name="fade">
      <button class="csv-export-btn-container" v-if="$route.name === 'analytics'" @click="csvExport">
        <img :src="require('@/assets/performance/csv-export.svg')" alt="export pdf" />
      </button>
      <button class="full-screen-button" v-if="$route.name === 'overview'" @click="openFullScreen" focus>
        <img :src="require('@/assets/full_screen.svg')" alt="full screen" />
      </button>
    </transition>
    <Tabs :class="siteInfo.embedded_link ? 'iframe-tab' : ''" :isRTL="isRTL" v-if="isTabsLoading" syncRoute>
      <template v-if="$route.params.roomId ? true : false">
        <Tab :label="$t('analytics_log')" :to="`/dashboard/roomAnalyticsLog/${$route.params.roomId}`">
          <Loading :height="loadingGIFHeight" v-if="isLoading" />
          <ServiceLog v-else />
        </Tab>
        <Tab :label="$t('clean_log')" :to="`/dashboard/serviceLog/cleanLog/${$route.params.roomId}`">
          <Loading :height="loadingGIFHeight" v-if="isLoading" />
          <ServiceLog v-else />
        </Tab>
        <Tab :label="$t('maintenance_log')" :to="`/dashboard/serviceLog/maintenanceLog/${$route.params.roomId}`">
          <Loading :height="loadingGIFHeight" v-if="isLoading" />
          <ServiceLog v-else />
        </Tab>
        <Tab :label="$t('performance')" :to="`/dashboard/roomAnalytics/${$route.params.roomId}`">
          <Loading :height="loadingGIFHeight" v-if="isLoading" />
          <Performance v-else />
        </Tab>
        <Tab :label="$t('analytics')" :to="`/dashboard/cleanerArrival/${$route.params.roomId}`">
          <Loading :height="loadingGIFHeight" v-if="isLoading" />
          <ServiceLog :cleanerArrivals="cleanerArrivals" v-else />
        </Tab>
        <Tab v-if="isExposureShown" :label="$t('exposures')" :to="`/dashboard/roomExposures/${$route.params.roomId}`">
          <Loading :height="loadingGIFHeight" v-if="isLoading" />
          <ServiceLog :roomExposures="roomExposures" v-else />
        </Tab>
      </template>
      <template v-else>
        <Tab :label="$t('overview')" to="/dashboard" class="overview-tab" ref="tabs" exact>
          <Loading :height="loadingGIFHeight" v-if="isLoading || !isOverviewShown" />
          <Overview v-else-if="!isSurveyOverview" />
          <section class="iframe-section" v-else-if="siteInfo.embedded_link">
            <iframe frameborder="0" :src="siteInfo.embedded_link" />
          </section>
          <SurveyOverview :siteType="siteInfo.type" v-else />
        </Tab>
        <Tab v-if="tabs.includes('suggestion')" :label="$t('suggestion')" to="/dashboard/suggestion">
          <Loading :height="loadingGIFHeight" v-if="isLoading" />
          <Suggestion :hideInfo="analyticsHideInfo" v-else />
        </Tab>
        <Tab v-if="tabs.includes('performance')" :label="$t('performance')" to="/dashboard/performance">
          <Loading :height="loadingGIFHeight" v-if="isLoading" />
          <Performance v-else />
        </Tab>
        <Tab v-if="tabs.includes('analytics')" :label="$t('analytics')" to="/dashboard/analytics">
          <Loading :height="loadingGIFHeight" v-if="isLoading" />
          <Analytics
            id="first"
            :isPrinting="isPrinting"
            :hideInfo="analyticsHideInfo"
            @donePrinting="isPrinting = false"
            v-show="$route.name === 'analytics'"
            v-else
          />
        </Tab>
      </template>
    </Tabs>
  </section>
</template>

<script>
import Tabs from '@/modules/common/components/Tabs/Tabs'
import Tab from '@/modules/common/components/Tabs/Tab'
import Loading from '../../common/components/Loading'
import performanceService, { getRoomCleanerArrivals, getRoomExposures } from '../services/performanceService'
import csvService from '../../common/services/csvService'
import zipService from '../../common/services/zipService'
import Performance from '../components/Performance'
import { mapGetters } from 'vuex'
import Analytics from '../components/Analytics'
import Overview from './Overview'
import SurveyOverview from './SurveyOverview'
import Suggestion from './Suggestion'
import ServiceLog from '../components/ServiceLog'
import siteService from '../../control-panel/services/siteService'
import { getAllDevices } from '../../devices/services/deviceService'

export default {
  data() {
    return {
      sticky: true,
      loadingGIFHeight: '62vh',
      cleanerArrivals: [],
      roomExposures: [],
      analyticsHideInfo: [],
      isOverviewShown: true,
      isExposureShown: true,
      siteInfo: null,
      tabs: ['overview', 'suggestion', 'analytics', 'performance'],
      isSurveyOverview: false,
      isTabsLoading: false,
      isDownloadingReport: false,
      isPrinting: false,
    }
  },
  created() {
    this.$store.watch(
      (state) => this.isRTL,
      (newValue) => {
        this.updateData()
      },
      { deep: true }
    )
  },
  mounted() {
    document.onfullscreenchange = () => {
      this.isOverviewShown = false
      setTimeout(() => {
        this.isOverviewShown = true
      }, 0)
    }
  },
  destroyed() {
    document.onfullscreenchange = undefined
  },
  methods: {
    openFullScreen() {
      const tabsEl = this.$refs.tabs.$el
      //browser compatibility
      if (tabsEl.requestFullscreen) tabsEl.requestFullscreen()
      else if (tabsEl.msRequestFullscreen) tabsEl.msRequestFullscreen()
      else if (tabsEl.mozRequestFullScreen) tabsEl.mozRequestFullScreen()
      else if (tabsEl.webkitRequestFullscreen) tabsEl.webkitRequestFullscreen()
    },
    async updateData() {
      this.analyticsHideInfo = []
      const filter = !this.roomId ? { ...this.filterSelected } : { ...this.filterSelected, roomId: this.roomId }
      await this.tabsToShow()
      const { exposures } = this.siteInfo
      if (!exposures) this.analyticsHideInfo.push('exposures')
      if (!filter.company) return
      this.$store.commit({ type: 'toggleLoading', value: true })
      this.isTabsLoading = true
      // Fetch room-specific data based on route
      const routeName = this.$route.name;
      const prms = []
      if (this.roomId) {
        if (routeName === 'cleanerArrival') {
          prms.push(this.getCleanerArrivalsData(this.roomId))
        } else if (routeName === 'roomExposures') {
          prms.push(this.getRoomExposuresData(this.roomId))
        }
      }
      // Fetch analytics and overview data in parallel
      await Promise.all([
        this.$store.dispatch({ type: 'getAnalyticsData', filter }),
        this.$store.dispatch({ type: 'getOverviewData', filter }),
        ...prms,
      ])
      const servikNames = this.$store.getters.servikNames
      this.$store.commit({ type: 'setFilterServikNames', servikNames })
      this.$store.commit({ type: 'toggleLoading', value: false })
    },
    async getCleanerArrivalsData() {
      const arrivals = await getRoomCleanerArrivals(this.roomId, this.filterSelected)
      this.cleanerArrivals = arrivals
    },
    async getRoomExposuresData() {
      let roomExposures = await getRoomExposures(this.roomId, this.filterSelected)

      roomExposures.forEach((shift) => {
        for (let i = shift.length - 1; i >= 0; i--) {
          if (i === 0) {
            const { complaintExposure, visitors } = shift[i].overview
            if (!complaintExposure || !visitors) shift[i].score = `100%`
            const sum = complaintExposure + visitors
            const score = parseInt((visitors / sum) * 100)
            shift[i].score = `${score}%`
          }
          if (
            i - 1 >= 0 &&
            shift[i].servik_id &&
            shift[i - 1].servik_id &&
            shift[i].servik_id === shift[i - 1].servik_id &&
            new Date(Array.isArray(shift[i].endTime) ? shift[i].endTime[0] : shift[i].endTime).getTime() -
              new Date(Array.isArray(shift[i - 1].endTime) ? shift[i - 1].endTime[0] : shift[i - 1].endTime).getTime() <
              180000
          ) {
            const newItem = { ...shift[i - 1], ...shift[i] }
            newItem.visitors = shift[i - 1].visitors + shift[i].visitors
            newItem.endTime = [shift[i - 1].endTime, shift[i].endTime]
            shift.splice(i - 1, 2, newItem)
          }
        }
      })

      this.roomExposures = roomExposures
    },
    async tabsToShow() {
      this.isSurveyOverview = false
      this.siteInfo = await siteService.getSiteById(this.filterSelected.site)
      if (this.siteInfo) {
        if (this.siteInfo.type === 'operation') this.tabs = ['overview', 'suggestion', 'analytics', 'performance']
        else if (this.siteInfo.type === 'survey' || this.siteInfo.type === 'questioner' || this.siteInfo.type === 'task') {
          this.tabs = ['overview']
          this.isSurveyOverview = true
        }
      } else this.tabs = ['overview', 'suggestion', 'analytics', 'performance']
      if (this.$route.name === 'cleanLog' || this.$route.name === 'roomExposures') {
        const devices = await getAllDevices(this.filterSelected.site)
        const counterIsExist = devices.some((device) => device.type.includes('counter') || device.type === 'pad9+')
        const { exposures } = this.siteInfo
        this.isExposureShown = counterIsExist && exposures ? true : false
      }
    },
    print() {
      this.isPrinting = true
    },
    async downloadReport() {
      const { company: companyId, site: siteId } = this.filterSelected
      const { companies } = this.filterOptions
      const { sites } = companies.find((c) => c.id === companyId)
      const currSite = sites.find((site) => site.id === siteId)
      if (this.isDownloadingReport) return
      this.isDownloadingReport = true
      const base64Report = await performanceService.getAnalysisReport(this.filterSelected)
      const link = document.createElement('a')
      link.href = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + base64Report
      link.download = `Analysis Report - ${currSite.name}.docx`
      link.click()
      this.isDownloadingReport = false
    },
    async csvExport() {
      const shouldDownload = confirm('Download Files?')
      if (!shouldDownload) return
      const siteId = this.filterSelected.site
      const cleanerArrivalData = await performanceService.getRoomCleanerArrivalsBySiteId(
        siteId,
        this.filterSelected.time
      )
      const cleanerArrivalBlobsByRoomId = csvService.generateArrivalCsv(cleanerArrivalData)
      const supervisorArrivalData = await performanceService.getRoomSupervisorArrivalsBySiteId(
        siteId,
        this.filterSelected.time
      )
      const supervisorArrivalBlobsByRoomId = csvService.generateArrivalCsv(supervisorArrivalData)
      /**@type {{ name: string; file: Blob }[]} */
      let roomsData = []
      const cleanersData = this.getBlobsByRoomId(cleanerArrivalBlobsByRoomId, 'cleaners')
      const supervisorsData = this.getBlobsByRoomId(supervisorArrivalBlobsByRoomId, 'supervisors')
      roomsData.push(...cleanersData, ...supervisorsData)
      let mainRows = [['name', 'value', 'trend']]
      for (name in this.barItemsData) {
        mainRows.push([this.$t(name), this.barItemsData[name].value, this.barItemsData[name].trendInfo])
      }
      const mainCsv = csvService.rowsToCsv(mainRows)
      const donutCsv = csvService.getCsvForChartListData(this.chartListData)
      const hourCsv = csvService.generateHourCsv(this.analyticsData.hourData)
      const roomCsv = csvService.generateRoomCsv(this.analyticsData.roomsData)
      zipService.generateFolder(
        'csvFolder',
        { name: 'main.csv', file: mainCsv },
        { name: 'donut.csv', file: donutCsv },
        { name: 'hour.csv', file: hourCsv },
        { name: 'room.csv', file: roomCsv },
        ...roomsData
      )
    },
    getBlobsByRoomId(blobs, arrivalsType = '') {
      const roomsData = []
      for (const roomId in blobs) {
        if (!this.roomIdMap[roomId]) continue
        roomsData.push({
          name: `${this.roomIdMap[roomId].name} - ${arrivalsType}.csv`,
          file: blobs[roomId],
        })
      }
      return roomsData
    },
    resetData() {
      this.cleanerArrivals = []
      this.roomExposures = []
    },
  },
  computed: {
    ...mapGetters([
      'isRTL',
      'filterSelected',
      'isLoading',
      'analyticsData',
      'barItemsData',
      'chartListData',
      'roomIdMap',
      'loggedUser',
      'filterOptions'
    ]),
    roomId() {
      return this.$route.params.roomId
    },
    isAdmin() {
      return this.loggedUser.type === 'admin'
    },
  },
  watch: {
    roomId() {
      if (!this.roomId) {
        this.$store.commit({ type: 'updateFilter', field: 'servikName', value: null })
        this.$store.commit({ type: 'updateFilter', field: 'rooms', value: null })
      }
      this.resetData()
      this.updateData()
    },
    '$route.name': function(currValue) {
      if (currValue === 'cleanerArrival' && !this.cleanerArrivals.length && this.roomId) this.getCleanerArrivalsData()
      else if (currValue === 'roomExposures' && !this.roomExposures.length && this.roomId) this.getRoomExposuresData()
    },
    filterSelected: {
      deep: true,
      immediate: true,
      handler: function(newValue) {
        if (this.roomId) {
          this.getCleanerArrivalsData()
          this.getRoomExposuresData()
        }
      },
    },
    'filterSelected.site': {
      deep: true,
      immediate: true,
      handler: function(newValue) {
        if (this.$route.params.roomId) this.$router.push('/dashboard/analytics')
        this.updateData()
      },
    },
  },
  components: {
    Tabs,
    Tab,
    Performance,
    Overview,
    Analytics,
    ServiceLog,
    Suggestion,
    Loading,
    SurveyOverview,
  },
}
</script>

<style lang="scss">
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';
@mixin top-button {
  position: absolute;
  display: flex;
  align-items: center;
  height: $tabs-height;
  z-index: 11;
}

.overview-analytics-container {
  background-color: white;
  min-height: 100%;

  .iframe-tab {
    .md-content {
      height: 75vh !important;
      .md-tabs-container {
        height: 100%;
      }
    }
    .overview-tab {
      height: 100%;
      .iframe-section {
        height: 100%;
        width: 100%;
        @include shadow-border;
        padding: 10px;
        margin-top: 10px;
        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .overview-tab:fullscreen {
    background-color: $white;
    .overview-container {
      height: 98vh;
    }
  }
  @media print {
    min-height: unset;
  }
  .full-screen-button {
    @include top-button;
    right: 75px;
    @include rtl {
      left: 75px;
      right: unset;
    }
    @media print {
      display: none;
    }
    @include screen-size(xs) {
      display: none;
    }
  }

  .export-button-container {
    @include top-button;
    right: 20px;
    @include rtl {
      left: 20px;
      right: unset;
    }

    @media print {
      display: none;
    }
  }

  .csv-export-btn-container {
    @include top-button;
    right: 75px;
    @include rtl {
      right: unset;
      left: 75px;
    }
  }

  .export-analysis-report {
    @include top-button;
    right: 135px;
    @include rtl {
      left: 135px;
      right: unset;
    }
  }

  .bounce {
    animation: bounce 2s cubic-bezier(0.5, 1.6, 0.5, 0.7) infinite;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }

  &.sticky {
    @include sticky-tabs;
  }
}
</style>
