<template>
  <section v-click-outside="toggleList" @click="toggleList(true)" class="shape-selector-container">
    <img :src="require(`@/assets/survey/icon_shapes/${value.toLowerCase()}.svg`)" alt />
    <img class="arrow-down" :src="require('@/assets/survey/icon_shapes/arrow_down.svg')" alt />
    <div v-if="isSelectingShape" class="img-list-container">
      <span @click="selectShape(shape)" v-for="(shape, idx) in shapes" :key="idx">
        <img :src="require(`@/assets/survey/icon_shapes/${shape.toLowerCase()}.svg`)" alt />
      </span>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    value: { type: String, required: true, default: "square" },
    shapes: { type: Array, required: true, default: () => [] }
  },
  data() {
    return {
      isSelectingShape: false
    };
  },
  methods: {
    toggleList(value) {
      this.isSelectingShape = value | false
    },
    selectShape(shape) {
      this.isSelectingShape = false;
      this.$emit("input", shape);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/vars";
@import "@/styles/mixins";

.shape-selector-container {
  position: relative;
  z-index: 1;
  background-color: $white;
  @include border($grey-border-color);
  @include align-center;
  justify-content: space-between;
  border-radius: 4px;
  height: 46px;
  width: 3.5vw;
  margin-inline-start: 5px;
  padding: 0 10px;
  div.invisible {
    position: relative;
    width: 109%;
    height: 100%;
    border: 1px solid red;
  }
  img:first-child {
    max-width: 20px;
  }
  .arrow-down {
    max-width: 10px;
  }
  .img-list-container {
    bottom: -11.6vh;
    left: 0;
    position: absolute;
    width: 3.5vw;
    height: 11.6vh;
    @include border($grey-border-color);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: $white;
    border-radius: 4px;
    span {
      width: 100%;
      height: 100%;
      &:hover {
        background-color: rgba(240, 240, 240, 0.692);
      }
      @include flex-center;
      img {
        max-width: 20px;
      }
    }
  }
}
</style>