<template>
  <section class="chart-container" :style="`height:${height}; width:${width};`">
    <canvas ref="chart" dir="ltr"></canvas>
  </section>
</template>

<script>
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
Chart.plugins.unregister(ChartDataLabels)

export default {
  props: {
    isClickable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      validator: (prop) => prop === 'doughnut' || prop === 'line' || prop === 'bar',
      required: true,
    },
    datasets: { type: Array, required: true },
    labels: { type: Array, required: true },
    options: { type: Object, required: false },
    height: { type: String, required: false },
    width: { type: String, required: false },
    isPlugin: { type: Boolean, default: false },
    isGradient: { type: Boolean, default: false },
    endOfGradientColor: { type: String, default: 'white' },
  },
  data() {
    return {
      chart: null,
    }
  },
  mounted() {
    if (this.isGradient) this.addGradient()
    this.drawChart()
  },
  methods: {
    addGradient() {
      this.datasets.forEach((dataset, index) => {
        const ctx = this.$refs.chart.getContext('2d')
        const { max, min } = this.options.scales.yAxes[index].ticks
        let gradientStroke = ctx.createLinearGradient(0, min, 0, max)
        gradientStroke.addColorStop(0, dataset.borderColor)
        gradientStroke.addColorStop(1, this.endOfGradientColor)
        dataset.fill = true
        dataset.backgroundColor = gradientStroke
      })
    },
    drawChart() {
      this.toFixData()
      const vueThis = this
      setTimeout(() => {
        let options = {
          ...this.options,
          animation: {
            onComplete: function(e) {
              this.options.animation.onComplete = null
              vueThis.$emit('doneRender')
            },
          },
          onClick: !this.isClickable
            ? ''
            : (evt) => {
                var activePoints = this.chart.getElementsAtEvent(evt)
                if (activePoints[0]) {
                  var chartData = activePoints[0]['_chart'].config.data
                  var idx = activePoints[0]['_index']

                  var label = chartData.labels[idx]
                  var value = chartData.datasets[0].data[idx]
                  this.$emit('click', { label, value })
                }
              },
        }
        this.chart = new Chart(this.$refs.chart, {
          type: this.type,
          data: { labels: this.labels, datasets: this.datasets },
          plugins: this.isPlugin ? ChartDataLabels : null,
          options,
        })

        if (!this.currentlyFilteringServik) return
        const meta = this.chart.getDatasetMeta(0)
        if (meta.type !== 'doughnut') return
        if (meta && meta.data) {
          const { labels } = meta.data[0]._chart.config.data
          const idx = labels.findIndex((label) => label === this.currentlyFilteringServik)
          if (idx > -1) meta.data[idx]._model.outerRadius += 10
        }
      }, 0)
    },
    toFixData() {
      this.datasets.forEach((dataset) => {
        dataset.data = dataset.data.map((dataItem) => {
          if (typeof dataItem !== 'number') return dataItem
          return dataItem.toFixed(1)
        })
      })
    },
  },
  computed: {
    currentlyFilteringServik() {
      const filter = this.$store.getters.filterSelected
      return filter.servikName
    },
  },
  watch: {
    datasets() {
      if (!this.chart) return
      this.chart.data.datasets = this.datasets
      this.chart.data.labels = this.labels
      this.chart.destroy()
      this.drawChart()
    },
  },
}
</script>
