import ajax from '../../common/services/httpService'
import utilService from '../../common/services/utilService'

const BASE_URL = 'task'
const LANGUAGES_URL = 'languages'

export default {
	getTasks,
	getTaskById,
	saveTask,
	removeTask,
	removeTasks,
	getDefaultTask,
	getLanguages,
	getTaskCategories,
	getTaskRealTimeData,
	startTask,
	completeTask
}

/**@returns {Promise<Tasks[]>} */
async function getTasks(query) {
	const tasks = await ajax.get(BASE_URL, null, { siteId: query.site })
	tasks.forEach((task) => {
		const { hour, created_at, send_date, sent_dates } = task
		hour && (task.hour = utilService.convertUtcHourToLocalHour(hour))
		created_at && (task.created_at = new Date(created_at).toLocaleString())
		send_date && (task.send_date = new Date(send_date).toLocaleString())
		sent_dates && (task.sent_dates = task.sent_dates.map((date) => new Date(date)))
	})
	return tasks
}

/**
 * @param {Number | String} siteId
 * @param {string} timeFrom
*/
async function getTaskRealTimeData(filter) {
    const realTimeData = await ajax.get(`${BASE_URL}/real-time`, null, filter);
    return realTimeData;
}

/**@param {Number} id */
async function getTaskById(id) {
	const task = await ajax.get(`${BASE_URL}/${id}`)
	const { send_date, hour } = task
	hour && (task.hour = utilService.convertUtcHourToLocalHour(task.hour))
	send_date && (task.send_date = new Date(task.send_date))
	return task
}

/**@param {Task} task */
async function saveTask(task) {
	const { send_date, hour } = task
	const [h, m] = hour.split(':')
	if (send_date) {
		task.send_date = new Date(task.send_date)
		task.send_date.setHours(h, m, 0, 0)
	}
	hour && (task.hour = utilService.convertLocalHourToUtcHour(task.hour))
	const { is_auto_start } = task
	task.is_auto_start = is_auto_start === 'true'
	if (!task.id) {
		const res = await ajax.post(BASE_URL, task)
		return res
	} else {
		const res = await ajax.put(`${BASE_URL}/${task.id}`, task)
		return res
	}
}

/**@param {Number} id */
async function removeTask(id) {
	const task = await ajax.delete(`${BASE_URL}/${id}`)
	return task
}

/**@param {Array} ids */
async function removeTasks(ids) {
	const res = await ajax.delete(BASE_URL, ids)
	return res
}

async function getLanguages() {
	const languagesInfo = await ajax.get(LANGUAGES_URL)
	return languagesInfo
}

async function getTaskCategories() {
	const categories = await ajax.get(`${BASE_URL}/categories`)
	return categories
}

async function startTask(id) {
	const res = await ajax.put(`${BASE_URL}/${id}/start`)
	return res
}

async function completeTask(id) {
	const res = await ajax.put(`${BASE_URL}/${id}/complete`)
	return res
}

function getDefaultTask() {
	const task = {
		titles: {},
		description: '',
		type: 'call_to_action',
		category: 'cleaning',
		schedule: 'once',
		send_date: new Date(),
		scheduleDates: [],
		rooms: [],
		hour: '00:00',
		sla: null,
		duration: {
			is_all_day: false,
			is_all_week: false,
			is_all_month: false,
		},
		is_auto_start: false,
		buffer_time: 15,
		selectedWorkingAreaIds: [],
	}
	return task
}
