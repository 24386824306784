<template>
  <section class="admin-view">
    <BackButton v-if="$route.params.id"/>
    <section class="admin-child">
      <router-view></router-view>
    </section>
  </section>
</template>

<script>
import BackButton from '../components/BackButton';

export default {
  components: {
    BackButton
  }
}
</script>

<style lang="scss">
@import '@/styles/vars.scss';
.admin-child {
    background-color: $white;
    box-shadow: 0px 0px 1px #BDBDBD;
    min-height: 89vh;
}
</style>