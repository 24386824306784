import ajax from '@/modules/common/services/httpService';

const BASE_URL = 'servik';

export default {
    getServikCtgMap,
    getById
}

async function getServikCtgMap() {
    const serviks = await ajax.get(`${BASE_URL}`)
    return serviks
}

/**@param {Number | String} id */
async function getById(id) {
    const servik = await ajax.get(`installation/servik/${id}`)
    return servik
}