import { servikMap } from '../../surveys/consts/consts';
import ajax from '../../common/services/httpService';
import { localeData } from '../../common/services/localeService'
import utilService from '../../common/services/utilService'

export default { getWorkingAreas, getServiks, getUsers, save, getById, remove, getServiksBySiteId, getDefaultToSelectWorkingAreaServiks, defaultSelectedWorkingAreaServiks, getServiksByRoomsIds }

const BASE_URL = 'workingArea'

async function getWorkingAreas(filter) {
    const workingAreas = await ajax.get(`${BASE_URL}`, null, { siteId: filter.site })
    return workingAreas.map(w => {
        return {
            id: w.id,
            area_name: w.area_name,
            rooms_number: w.rooms.length,
            rooms_names: w.rooms.map(r => r.room_name),
            shifts: w.shifts.map(s => s.name),
            // assign_number: w.supervisor.phone,
            sms: w.sms.isActive,
            email: w.email.isActive,
            counter: w.counter.isActive,
            cleandex: w.cleandex.isActive,
            offline_calls: w.offline_calls.isActive,
            notified: w.notified,
            api: w.api.isActive,
        }
    })
}

async function getServiksBySiteId(siteId) {
    const serviks = await ajax.get(`${BASE_URL}/serviks`, null, { siteId, language: localeData.language })
    return serviks
}

async function getServiksByRoomsIds(roomsIds) {
    const serviks = await ajax.get(`${BASE_URL}/rooms/serviks`, null, { roomsIds, language: localeData.language })
    return serviks
}

async function getUsers(siteId) {
    const users = await ajax.get('user', null, { siteId })
    return users.filter(user => user.firstName && user.lastName && user.level === 'supervisor')
}

async function getServiks() {
    return servikMap.reports
}

/**@param {Number | String} id */
async function getById(id) {
    const res = await ajax.get(`${BASE_URL}/${id}`);
    if (res.manual_calls.length > 0) {
        res.manual_calls.map(call => {
            call.hour = utilService.convertUtcHourToLocalHour(call.hour)
        })
    } 
    return res;
}


/**@param {Number | String} id */
async function remove(id) {
    const res = await ajax.delete(`${BASE_URL}/${id}`);
    return res;
}

async function save(workingArea, site_id) {
    if (workingArea.manual_calls.length > 0) {
        workingArea.manual_calls.map(call => {
            call.hour = utilService.convertLocalHourToUtcHour(call.hour)
        })
    }
    if (workingArea.id) {
        const workingAreaData = {
            workingArea: {
                id: workingArea.id,
                name: workingArea.area_name,
                shifts: workingArea.shifts,
                serviks: workingArea.active_notification_task,
                push: workingArea.notified,
                supervisors: workingArea.supervisors,
                email: workingArea.emails,
                sms: workingArea.sms,
                counter: workingArea.counter,
                cleandex: workingArea.cleandex,
                offline_calls: workingArea.offline_calls,
                api: workingArea.api,
                is_scheduled_cleaning: workingArea.is_scheduled_cleaning,
                is_suggestion: workingArea.is_suggestion,
                site_id,
                manual_calls: workingArea.manual_calls
            },
            rooms: workingArea.rooms,
        }
        const res = await ajax.put(`${BASE_URL}`, workingAreaData)
        return res
    } else {
        const workingAreaData = {
            workingArea: {
                name: workingArea.area_name,
                shifts: workingArea.shifts,
                serviks: workingArea.active_notification_task,
                push: workingArea.notified,
                supervisors: workingArea.supervisors,
                email: workingArea.emails,
                sms: workingArea.sms,
                counter: workingArea.counter,
                cleandex: workingArea.cleandex,
                offline_calls: workingArea.offline_calls,
                api: workingArea.api,
                is_scheduled_cleaning: workingArea.is_scheduled_cleaning,
                is_suggestion: workingArea.is_suggestion,
                site_id,
                manual_calls: workingArea.manual_calls
            },
            rooms: workingArea.rooms,
        }
        const res = await ajax.post(`${BASE_URL}`, workingAreaData)
        return res
    }
}

function getDefaultToSelectWorkingAreaServiks() {
    return [
        {
            id: 605,
            name: 'coffee / water machine'
        },
        {
            id: 646,
            name: 'coffee / water machine'
        },
        {
            id: 761,
            name: 'coffee / water machine'
        },
        {
            id: 604,
            name: 'missing dishes'
        },
        {
            id: 645,
            name: 'missing dishes'
        },
        {
            id: 603,
            name: 'no sugar / coffee'
        },
        {
            id: 644,
            name: 'no sugar / coffee'
        },
        {
            id: 602,
            name: 'missing milk'
        },
        {
            id: 643,
            name: 'missing milk'
        },
        {
            id: 550,
            name: 'general cleaning'
        },
        {
            id: 80,
            name: 'general cleaning'
        },
        {
            id: 165,
            name: 'general cleaning'
        },
		{
			id: 7,
			name: 'bad smell'
		},
		{
			id: 8,
			name: 'dirty floor'
		},
		{
			id: 9,
			name: 'dirty lavatory seat'
		},
		{
			id: 15,
			name: 'missing soap'
		},
		{
			id: 16,
			name: 'missing toilet paper'
		},
        {
			id: 54,
			name: 'full garbage can'
		},
		{
			id: 48,
			name: 'Cleaning performed'
		},
        {
            id: 14,
            name: 'missing paper towel'
        }
	]
}

function defaultSelectedWorkingAreaServiks() {
    return [
        {
			id: 216,
			name: 'call cleaner'
		}
    ]
}