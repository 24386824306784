<template>
  <section class="survey-btn-container" :class="isEditClass">
    <div
      :class="[btnClass, 'survey-btn']"
      :style="{ backgroundColor: option.color }"
      @click="emitClick"
    >

      <img
        v-if="!isEdit && !btnClass['transparent-circle']"
        class="mirror"
        :src="require(`@/assets/survey/icon_shapes/mirror_${option.shape}.png`)"
      />
      <img v-if="servik" :src="servik.url.white" :class="[btnClass['transparent-circle']? 'transparent-icon' : 'issue-icon']" />
      <div class="edit-btns">
        <img
          @click="switchEditMode('settings')"
          class="settings"
          :src="require(`@/${editModeIcons.settings}`)"
          alt="Select Settings"
        />
        <img
          @click="switchEditMode('service')"
          class="service"
          :src="require(`@/${editModeIcons.service}`)"
          alt="Edit Button"
        />
      </div>
      <OptionServiceModal
        :servikId="option.servik_id"
        @changeServik="changeServik"
        v-if="editMode === 'service'"
        v-click-outside="switchEditMode"
      />
      <OptionSettingsModal
        @change="changeSettings"
        :currOption="option"
        :rooms="rooms"
        :pages="pages"
        v-if="editMode === 'settings'"
        v-click-outside="switchEditMode"
      />
    </div>
    <textarea
      v-if="isEdit"
      class="survey-btn-text"
      :placeHolder="option.text || 'Enter Text Here'"
      v-model="option.text"
      :style="{'color': textColor}"
    />
    <div :style="{'color': textColor}" class="survey-btn-text" v-else>{{ option.text }}</div>
  </section>
</template>

<script>
import OptionSettingsModal from "@/modules/surveys/components/OptionSettingsModal";
import OptionServiceModal from "@/modules/surveys/components/OptionServiceModal";
import { servikMap } from "@/modules/surveys/consts/consts";
import servikService from '@/modules/surveys/services/servikService'
import roomService from '../../modules/control-panel/services/roomService'
export default {
  props: {
    currOption: { type: Object, required: true },
    isEdit: { type: Boolean, required: false, default: false },
    idx: { type: Number, required: false },
    pages: { type: Array, required: true },
    textColor: { type: String, required: false }
  },
  data() {
    return {
      option: this.currOption,
      editMode: "",
      isModalOpen: false,
      servik: null,
      rooms: []
    };
  },
  async created() {
    this.servik = await servikService.getById(this.option.servik_id)
    if (this.isEdit) {
      this.rooms = await roomService.getRooms(this.$store.getters.filterSelected)
      if (this.rooms.length) this.rooms.unshift({ display_name: "None", id: "" })
    }
  },
  destroyed() {
  this.servik=null;
  },
  methods: {
    emitClick() {
      if (this.isEdit) return;
      const { servik_id, route_to, connectedRoomId,isLinkServik } = this.option;
      this.$emit("click", servik_id, route_to, connectedRoomId,isLinkServik);
    },
    switchEditMode(newMode) {
      if (typeof newMode !== 'string' || newMode === this.editMode) {
        this.editMode = "";
        return;
      }
      setTimeout(() => this.editMode = newMode, 0)
    },
    changeSettings(newOption) {
      if (newOption.hasOwnProperty('connectedRoomId')){
        if(newOption.connectedRoomId.length){
          const { connectedRoomId } = newOption;
          if(connectedRoomId.includes('') || !connectedRoomId.length) delete newOption.connectedRoomId;
        } else if(newOption.connectedRoomId === '') {
          delete newOption.connectedRoomId;
        }
      }
      this.option = newOption;
      this.$emit("updatedOption", { idx: this.idx, option: this.option });
    },
    changeServik({ id, name, category }) {
      this.option.servik_id = id;
      this.option.icon = name;
      if (category === 'link') this.option.isLinkServik = true;
      else if (this.option.hasOwnProperty('isLinkServik') && this.category !== 'link') delete this.option.isLinkServik;
      this.$emit("updatedOption", { idx: this.idx, option: this.option });
    },
  },
  watch: {
    option: {
      deep: true,
      async handler(to, from) {
        this.servik=null;
        this.servik = await servikService.getById(to.servik_id)
        this.$emit("updatedOption", { idx: this.idx, option: to });
      }
    },
    currOption(to, from) {
      this.option = to;
    }
  },
  computed: {
    editModeIcons() {
      if (!this.editMode)
        return {
          settings: "assets/btn_settings.svg",
          service: "assets/btn_servik.svg"
        };
      const settings = this.editMode === "settings" ? "assets/btn_settings_active.svg" : "assets/btn_settings.svg";
      const service = this.editMode === "service" ? "assets/btn_servik_active.svg" : "assets/btn_servik.svg";
      return { settings, service };
    },
    isEditClass() {
      return { edit: this.isEdit };
    },
    issueIcon() {
      let icon;
      const servikCategories = Object.values(servikMap);
      let currServik;
      for (var i = 0; i < servikCategories.length; i++) {
        const currCategory = servikCategories[i]
        currServik = currCategory.find(servik => servik.id === this.option.servik_id)
        if (currServik) {
          currServik.category = Object.keys(servikMap)[i]
          break;
        }
      }
      try {
        icon = require(`@/assets/survey/servik_icons_white/${currServik.category}/${currServik.id}.${currServik.name}.svg`);
      } catch (err) {
        icon = require("@/assets/servik_survey_icons/missing_toilet_paper.svg");
      }
      return icon
    },
    btnClass() {
      return {
        circle: this.option.shape === "circle",
        square: this.option.shape === "square",
        rounded: this.option.shape === "rounded",
        'transparent-circle':this.option.shape === "transparent-circle"
      };
    }
  },
  components: { OptionSettingsModal, OptionServiceModal }
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars";
@import "@/styles/mixins";

.edit {
  @include flex-center;
  flex-direction: column;

  .survey-btn {
    position: relative;
    width: 8.5vw;
    height: 8.5vw;
    .issue-icon {
      margin: 30px;
      width: 5vw;
      height: 5vw;
      position: static;
    }
  }

  .survey-btn-text {
    width: 12vw;
    height: 6vh;
    @include border($grey-border-color);
    border-radius: 4px;
    @include flex-center;
    margin: 0 auto;
    text-transform: inherit;
  }

  .edit-btns {
    display: block;
    position: absolute;
    bottom: 10px;
    width: 100%;
    & > * {
      position: absolute;
      bottom: 0;
      width: 1vw;
    }
    .service {
      right: 10px;
    }
    .settings {
      left: 10px;
    }
  }
}

.survey-btn-container {
  width: 16vw;
  height: 35vh;
   @include screen-size(xxs) {
    width: 35vw;
    height: 20vh;
  }
}

.survey-btn {
  width: 13.6vw;
  height: 13.6vw;
  margin: 0 auto;
  margin-bottom: 2vh;
  cursor: pointer;
  position: relative;
   @include screen-size(xxs) {
    width: 27.6vw;
    height: 27.6vw;
  }
  .issue-icon {
    width: 6vw;
    height: 6vw;
    position: absolute;
    left: 27%;
    top: 26%;
     @include screen-size(xxs) {
    width: 13.8vw;
    height: 13.8vw;
  }
  }
   .transparent-icon {
    width: 100%;
    height: 100%;
    position: absolute;

  }
}

.edit-btns {
  display: none;
}

.square {
  border-radius: 0px;
}

.rounded {
  border-radius: 35px;
}


.circle {
  border-radius: 50%;
}

.mirror {
  position: absolute;
  width: 100%;
}

.survey-btn-text {
  font-weight: $font-weight-bold;
  font-size: $font-size-large;
  text-align: center;
  text-transform: capitalize;
  line-height: 25px;
  white-space: pre;
  font-family: inherit;
}
</style>