<template>
  <section class="setup-form">
    <div class="form-header">{{`please select the ${currentKey}`}}</div>
    <div class="form">
      <div class="label">
        <img :src="require(`@/assets/${iconName}.svg`)" alt="site icon" />
        <span>{{currentKey}}</span>
      </div>
      <select v-model="selected" placeholder="select" class="form-select">
        <option v-for="option in options" :key="option.name" :value="option.value">{{option.name}}</option>
      </select>
    </div>
    <div class="switch-container" v-if="room.site && !room.building && !room.id">
      <span>Room steps</span>
      <SwitchButton @input="emitSelectMode" :isChecked="selectMode === 'list' ? true : false"/>
      <span>Room list</span>
    </div>
  </section>
</template>

<script>
import installService from '../services/installationService';
import SwitchButton from './SwitchButton';

export default {
  props: {
    room: { type: Object, default: () => ({}) },
    options: { type: Array, required: false },
    selectMode: { 
      type: String,
      validator: (prop) => ['list', 'steps'].includes(prop),
      required: true
    }
  },
  data() {
    return {
      selected: '',
    }
  },
  computed: {
    currentKey() {
      if (this.selectMode === 'list' && !this.room.id) return 'room';
      if (this.selectMode === 'list' && this.room.id) return 'survey';
      return installService.getCurrSelectingKey(this.room);
    },
    iconName() {
      const { currentKey } = this;
      if (currentKey === 'site') return 'site_icon';
      else if (currentKey === 'survey name') return 'finger_clicking';
      else return 'icon_home';
    },
  },
  methods: {
    emitSelectMode(value) {
      const selectMode = value ? 'list' : 'steps';
      this.$emit('selectModeChange', selectMode);
    }
  },
  watch: {
    selected(value, old) {
      if (value) {
        this.$emit('input', this.currentKey, value);
        this.$emit('restartTimeout')
        setTimeout(() => {
          this.selected = '';
        }, 0);
      }
    },
  },
  components: { SwitchButton }
}
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";
@import "@/styles/vars.scss";

.setup-form {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .form {
    margin-bottom: 30px;
  }
  .switch-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    color: #a5a4bf;
  }
}

.form-select {
  border: 1px solid $blue;
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 35vw;
  height: 40px;
}

.label {
  text-transform: capitalize;
  @include survey-label;
}

.md-field.md-theme-default {
  &:after,
  &:before {
    display: none;
  }
}

.form-header {
  @include survey-form-header;
  margin-bottom: 80px;
}
</style>