<template>
	<Modal width="small" @close="emitCloseModal" @submit="saveUser" :texts="{ headerPrimary: modalTitle }">
		<section class="form-container">
			<div class="task-main-info">
				<template v-for="(input, idx) in modalInputs">
					<div class="input-container" :key="input.keyInUser + idx">
						<div class="input-img-wrapper">
							{{ $t(input.label) }}
						</div>
						<input
							class="modal-input"
							dir="auto"
							@click.right.prevent
							:class="input.keyInUser + '-input'"
							:placeholder="$t(input.label).toLowerCase()"
							:type="input.keyInUser === 'password' ? 'password' : 'text'"
							v-model.trim="user[input.keyInUser]"
						/>
						<ErrorMsg :isShown="isErrorShown(input.label)" :text="`${$t('please_enter')} ${$t(input.label)}`" />
					</div>
				</template>
			</div>
			<div class="task-secondary-info">
				<div class="input-container">
					<div>{{ $t('type') }}</div>
					<Select class="select-container" v-model="user.type">
						<Option v-for="(type, idx) in userTypes" :key="type + idx" :label="$t(type)" :value="type" />
					</Select>
					<ErrorMsg :isShown="isErrorShown('type')" :text="`${$t('please_select')} ${$t('type')}`" />
				</div>
				<div class="input-container">
					<div>{{ $t('level') }}</div>
					<Select class="select-container" v-model="user.level">
						<Option v-for="(level, idx) in userLevels" :key="level + idx" :label="$t(level)" :value="level" />
					</Select>
					<ErrorMsg :isShown="isErrorShown('level')" :text="`${$t('please_select')} ${$t('level')}`" />
				</div>
				<div class="input-container">
					<div>{{ $t('language') }}</div>
					<Select class="select-container" v-model="user.language">
						<Option
							v-for="(lang, idx) in languages"
							:key="lang.label + idx"
							:label="$t(lang.label)"
							:value="lang.value"
							:src="lang.logo_url"
						/>
					</Select>
					<ErrorMsg :isShown="isErrorShown('invalid_language')" :text="`${$t('invalid_language')}`" />
				</div>
				<div class="input-container">
					<div>{{ $t('panel_permitted') }}</div>
					<Select class="select-container" v-model="user.panelViews" :multiple="true">
						<Option
							v-for="(option, idx) in panelPermittedOptions"
							:key="option + idx"
							:label="$t(option)"
							:value="option"
						/>
					</Select>
					<ErrorMsg
						:isShown="isErrorShown('panel_permitted')"
						:text="`${$t('please_select')} ${$t('panel_permitted')}`"
					/>
				</div>
				<div v-if="isSuperVisor">
					<div>{{ $t('working_areas') }}</div>
					<Select class="select-container" v-model="user.workingAreaIds" :multiple="true">
						<Option
							v-for="(workingArea, idx) in workingAreas"
							:key="workingArea.id + idx"
							:label="workingArea.area_name"
							:value="workingArea.id"
						/>
					</Select>
				</div>
			</div>
		</section>
	</Modal>
</template>

<script>
import Modal from '@/modules/common/components/Modal'
import Option from '@/modules/common/components/Option'
import Select from '@/modules/common/components/Select'
import ErrorMsg from '@/modules/common/components/ErrorMsg'

export default {
	name: 'UserModal',
	components: { Modal, ErrorMsg, Option, Select },
	props: {
		userProp: { type: Object, required: true },
		languages: { type: Array, required: true },
		workingAreas: { type: Array, required: true }
	},
	data() {
		return {
			errors: [],
			user: JSON.parse(JSON.stringify(this.userProp)),
			modalInputs: [
				{
					label: 'first_name',
					keyInUser: 'firstName'
				},
				{
					label: 'last_name',
					keyInUser: 'lastName'
				},
				{
					label: 'username',
					keyInUser: 'username'
				},
				{
					label: 'password',
					keyInUser: 'password'
				},
				{
					label: 'email',
					keyInUser: 'email'
				},
				{
					label: 'phone',
					keyInUser: 'phone'
				}
			],
			userTypes: ['viewer', 'installer'],
			userLevels: ['viewer', 'supervisor'],
			panelPermittedOptions: ['dashboard', 'real-time', 'survey', 'room', 'task', 'users', 'devices', 'working_area']
		}
	},
	methods: {
		emitCloseModal() {
			this.$emit('closeModal')
		},
		checkForm() {
			this.errors = []
			const {
				id,
				firstName,
				lastName,
				username,
				password,
				email,
				phone,
				type,
				level,
				language,
				panelViews
			} = this.user
			if (!this.isSuperVisor && this.user.workingAreaIds.length > 0) this.user.workingAreaIds = []
			if (!firstName) this.addError('first_name')
			if (!lastName) this.addError('last_name')
			if (!username) this.addError('username')
			if (!password && !id) this.errors.push('password')
			if (!email) this.addError('email')
			if ((phone && !/^\d+$/.test(phone)) || phone.length < 10 || !phone) this.addError('phone')
			if (!type) this.addError('type')
			if (!level) this.addError('level')
			if (!language) this.addError('invalid_language')
			if (!panelViews || panelViews.length === 0) this.addError('panel_permitted')
		},
		addError(error) {
			this.errors.push(error)
		},
		isErrorShown(error) {
			return this.errors.includes(error)
		},
		saveUser() {
			this.checkForm()
			if (this.errors.length > 0) return
			this.$emit('save', this.user)
		}
	},
	computed: {
		modalTitle() {
			return this.user.id ? this.$t('edit_user') : this.$t('new_user')
		},
		langFlag() {
			const { language: lang } = this.user
			return this.languages.find((language) => language.value === lang)?.logo_url
		},
		isSuperVisor() {
			return this.user.level === 'supervisor'
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins';

.form-container {
	.task-main-info,
	.task-secondary-info {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}
}

.input-container {
	margin-bottom: 15px;
	input {
		@include modal-input;
		padding: 0 10px;
		width: 10vw;
	}
}

.md-field.select-container {
	width: 100% !important;
}

.select-container {
	border-bottom: 1px solid $grey-border-color;
}
</style>
