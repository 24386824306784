<template>
  <section class="cleaner-arrival">
    <div class="header-row">
      <div
        :key="title"
        v-for="title in headerTitles"
        class="row-title"
        :class="{ click: ['date', 'shift_name', 'expose_score'].includes(title), sorted: title === currSort }"
        @click=";['date', 'shift_name', 'expose_score'].includes(title) ? sortExposures(title) : ''"
      >
        {{ $t(`${title}`) }}
      </div>
    </div>
    <template v-for="(dayArrivals, dayIndex) in sortedExposures">
      {{ resetPreviousArrival() }}
      <div class="date-row" :key="dayArrivals[0].startTime + (''+dayIndex)">
        <div class="date-container">
          <div class="row-title">
            {{ dayArrivals[0].startTime | ddmmyy }}
          </div>
          <div class="row-title">
            {{ dayArrivals[0].shift_name }}
          </div>
          <div class="overview-results">
            <div class="overview-circle red-border">
              {{ dayArrivals[0].overview.complaintExposure }}
            </div>
            <div class="overview-circle green-border">
              {{ dayArrivals[0].overview.visitors }}
            </div>
          </div>
          <div class="row-title score">
            {{ dayArrivals[0].score }}
          </div>
        </div>
        <template>
          <div class="line">
            <template v-for="(arrival, arrivalIndex) in dayArrivals">
              <div v-if="showline(arrival)" class="line-container" :key="'' + arrivalIndex">
                <Tooltip
                  v-if="arrival.servik_id"
                  style="
                    position: absolute;
                    width: 100%;
                    height: 20px;
                    z-index: 30;
                  "
                  :boldText="getToolTipText(arrival).servik_text"
                  :text="getToolTipText(arrival).time"
                >
                </Tooltip>

                <span class="visits-number">{{ parseInt(arrival.visitors) }}</span>
                <div
                  class="inner-line"
                  :style="getLineColor(arrival, arrivalIndex, dayIndex, arrival.is_offline)"
                ></div>
                <img
                  v-if="arrival.servik_id"
                  :src="getServikIcon(arrival.servik_info)"
                  :style="
                    getComplaintStyle(arrival.is_suggestion, arrival.servik_info, arrival.is_offline, arrival.endTime)
                  "
                  alt="supervisor"
                  class="img-icon"
                  :class="{ bigger: Array.isArray(arrival.endTime) }"
                />
              </div>
            </template>
            <div class="dashed-line"></div>
          </div>
        </template>
      </div>
    </template>
  </section>
</template>

<script>
import Tooltip from '../../common/components/Tooltip.vue'
import { overviewLineChartsOptions } from '../consts/consts'
import utilService from '@/modules/common/services/utilService.js'
export default {
  props: {
    roomExposures: { type: Array, required: true },
  },
  data() {
    return {
      headerTitles: ['date', 'shift_name', 'expose_results', 'expose_score', 'events_expose'],
      currSort: 'date',
      isSortAsc: false,
    }
  },
  methods: {
    sortExposures(title) {
      const currSort = this.currSort
      this.currSort = title
      if (currSort !== title) this.isSortAsc = false
      else this.isSortAsc = !this.isSortAsc
    },
    resetPreviousArrival() {
      this.lastArrival = null
    },
    showline(arrival) {
      if (arrival.visitors === 0 && (arrival.servik_id === 42 || arrival.servik_id === 46)) return false
      return true
    },
    getToolTipText(arrival) {
      const firstEndTime = Array.isArray(arrival.endTime) ? arrival.endTime[0] : arrival.endTime
      let time = ' '
      let min = firstEndTime.getMinutes()
      let hour = firstEndTime.getHours()
      let minStr = min >= 10 ? `${min}` : `0${min}`

      time += `${hour}:${minStr}`

      if (Array.isArray(arrival.endTime)) {
        utilService.flattenArray(arrival.endTime).forEach((str, idx) => {
          if (idx === 0) return
          min = str.getMinutes()
          hour = str.getHours()
          minStr = min >= 10 ? `${min}` : `0${min}`
          time += ` | ${hour}:${minStr}`
        })
      }

      const servik_text = arrival.servik_info.name ? arrival.servik_info.name.split(' ').join('_') : ''
      return { time, servik_text }
    },
    getComplaintStyle(is_suggestion, servik_info, is_offline, endTime) {
      let { backgroundColor } = servik_info
      if (is_suggestion) return { backgroundColor: '#DD7ADD', borderColor: '#DD7ADD' }
      else if (is_offline) return { backgroundColor: '#808080', borderColor: '#808080' }
      else if (backgroundColor)
        return {
          backgroundColor: Array.isArray(backgroundColor) ? backgroundColor : backgroundColor,
          borderColor: backgroundColor,
        }
    },
    getLineColor(arrival, arrivalIndex, dayIndex, is_offline) {
      let backgroundColor = ''
      if (is_offline) return { backgroundColor: '#808080' }
      const previousArrivalIdx = arrivalIndex === 0 ? 0 : arrivalIndex - 1
      const previousArrival = this.sortedExposures[dayIndex][previousArrivalIdx]
      if ((arrival.servik_type === 'problem' || !arrival.servik_type) && arrivalIndex === 0) backgroundColor = '#5f9b46'
      else if (this.lastArrival && this.lastArrival === 'problem') backgroundColor = '#ca3543'
      else if (arrival.servik_type === 'problem' && previousArrival.servik_type === 'problem')
        backgroundColor = '#ca3543'
      else if (previousArrival.servik_type === 'problem') backgroundColor = '#ca3543'
      else backgroundColor = '#5f9b46'
      if (arrival.servik_type === 'problem' || arrival.servik_type === 'cleaner') this.lastArrival = arrival.servik_type
      return { backgroundColor }
    },
    getServikIcon({ name, ctg, id, url }) {
      let icon
      try {
        if (url) icon = url
        else icon = require(`@/assets/survey/servik_icons_white/${ctg}/${id}.${name}.svg`)
      } catch (err) {
        icon = null
      }
      return icon
    },
  },
  computed: {
    sortedExposures() {
      return this.roomExposures.sort((shiftA, shiftB) => {
        if (this.currSort === 'date') {
          return (
            new Date(this.isSortAsc ? shiftA[0].startTime : shiftB[0].startTime) -
            new Date(this.isSortAsc ? shiftB[0].startTime : shiftA[0].startTime)
          )
        } else if (this.currSort === 'shift_name') {
          const op = this.isSortAsc ? -1 : 1
          return shiftA[0].shift_name > shiftB[0].shift_name ? op : -op
        } else if (this.currSort === 'expose_score') {
          return (
            parseInt((this.isSortAsc ? shiftA[0].score : shiftB[0].score).split('%')[0]) -
            parseInt((this.isSortAsc ? shiftB[0].score : shiftA[0].score).split('%')[0])
          )
        }
      })
    },
  },
  components: { Tooltip },
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars';
@import '@/styles/mixins';

$arrival-green: #5f9b46;
$supervisor-green: #ffdca8;
$supervisor-orange: #ffdca8;
$supervisor-orange-border: #ffbb56;
$complaint-red: #e19098;
$complaint-red-border: #ca3543;
$dashed-border-color: #f4efef;

.header-row {
  display: flex;
  align-items: center;
  height: 40px;
  max-width: 100vw;
  .row-title {
    font-weight: $font-weight-bold;
    color: $spun-pearl;
    width: 7vw;
    // text-transform: capitalize;

    &.click {
      cursor: pointer;
    }

    &.sorted {
      font-weight: bolder;
      color: #000000b3;
    }

    &:nth-child(5) {
      width: 85vw;
      padding-left: 15px;
    }
  }
}

.date-row {
  max-width: 100vw;
  .date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }
  .row-title {
    margin-inline-end: 10px;
  }
  .score {
    font-weight: $font-weight-bold;
    color: $spun-pearl;
  }
  &:nth-child(2) {
    margin-top: 15px;
  }

  .overview-results {
    display: flex;
    font-size: 1.125rem;
    font-weight: bolder;
    width: 5vw;
    .overview-circle {
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      font-size: 1rem;
      border-radius: 50%;
    }
    .red-border {
      border: 3px solid $complaint-red-border;
    }
    .green-border {
      border: 3px solid $arrival-green;
    }
  }
  .line {
    width: 85vw;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    .line-container {
      width: 5%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      position: relative;

      .visits-number {
        position: absolute;
        top: 15%;
      }
      .img-icon {
        @include rtl {
          left: 0;
          right: unset;
        }
        border-radius: 50%;
        height: 20px;
        padding: 2px;
        background-color: $arrival-green;
        position: absolute;
        right: 0;
        z-index: 15;

        &.bigger {
          height: 30px;
        }
      }
      .inner-line {
        height: 4px;
        width: 100%;
        z-index: 10;
        position: relative;
      }
    }

    .dashed-line {
      outline: 0.1px dashed $dashed-border-color;
      width: 100%;
      position: absolute;
    }
  }
  height: 60px;
  display: flex;
  align-items: flex-end;
  .row-title {
    color: $boulder;
    font-size: 1.125rem;
    width: 5vw;
  }
}
</style>
