<template>
  <div class="main-container">
    <div class="modal-header">{{ $t('login') }}</div>
    <div class="border-bottom"></div>
    <form @submit="login" class="modal-body">
      <Input class="input user-input" :placeholder="`${$t('username')} ${$t('or')} ${$t('email')}`" skin="roundedGrey" v-model="loginInput" />
      <Input class="input password-input" :placeholder="$t('password')" v-model="password" type="password" />
      <button style="display:none"></button>
    </form>
    <div class="terms-and-conditions">
      <CheckBox ref="checkbox" class="custom-checkbox" v-model="isAgreedToTerms" />
      <div>
        {{ $t("By logging in, you agree to Buzzztech's") }}
        <a class="link" href="https://buzzztech.com/en/terms-and-conditions/" target="_blank">{{ $t('Terms and Conditions') }}</a>
        {{ $t('and') }}
        <a class="link" href="https://buzzztech.com/en/privacy-policy/" target="_blank">{{ $t('Privacy Policy') }}.</a>
        {{ $t('All actions within this panel are recorded for quality control and improvement') }}.
      </div>
    </div>
    <div class="buttons-container">
      <Button skin="primary" @click="login">{{ $t('login') }}</Button>
      <span class="error-msg" v-if="errorMsg">{{ errorMsg }}</span>
    </div>
  </div>
</template>

<script>
import Input from '@/modules/common/components/Input'
import Button from '@/modules/common/components/Button'
import swalService from '@/modules/common/services/swalService'
import authService from '@/modules/auth/services/authService'
import CheckBox from '@/modules/surveys/components/CheckBox'

export default {
  data() {
    return {
      loginInput: '', // Holds the input value (can represent either username or email)
      password: '',
      isAgreedToTerms: false,
    }
  },
  computed: {
    errorMsg() {
      if (!this.isAgreedToTerms) {
        return `${this.$t('You must agree to the terms and conditions')}`
      } else {
        return this.$store.getters.authErrorMsg
      }
    },
  },
  components: { Input, Button, CheckBox },
  methods: {
    async login(ev) {
      if (!this.isAgreedToTerms) {
        this.$refs.checkbox.$el.classList.add('not-selected')
        setTimeout(() => {
          this.$refs.checkbox.$el.classList.remove('not-selected')
        }, 1000)
        return
      }
      ev.preventDefault()
      const credentials = this.isEmail(this.loginInput) ? { email: this.loginInput, password: this.password } : { username: this.loginInput, password: this.password }
      const user = await this.$store.dispatch({ type: 'login', credentials })
      await this.$store.dispatch({ type: 'loadFilterOptionsAndSelection' })
      const { siteIdsToShow, roles } = user
      if (user) {
        if (user.level === 'installer') {
          swalService.errorMsg()
          authService.logout()
        } else if (user && siteIdsToShow.length > 0) {
            if (roles.includes('dashboard')) return this.$router.push('/dashboard')
            else if (roles.includes('real-time')) return this.$router.push('/real-time')
            else if (roles.includes('survey')) return this.$router.push('/survey')
            else if (roles.includes('room')) return this.$router.push('/admin/room/list')
            else if (roles.includes('task')) return this.$router.push('/task')
            else if (roles.includes('users')) return this.$router.push('/users')
            else if (roles.includes('shift')) return this.$router.push('/shift')
        } else if (user.type === 'admin') {
            if (roles.includes('company')) return this.$router.push('/admin/company')
            if (roles.includes('site')) return this.$router.push('/admin/site')
            if (roles.includes('user')) return this.$router.push('/admin/user')
        }
      }
    },
    isEmail(value) {
      // Regular expression to check if the value matches an email pattern
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';
.main-container {
  margin: 2vh auto 0;
  background-color: #fff;
  max-width: 33vw;
  height: 50vh;
  padding: 42.5px 42px 50px 41px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include border($grey-border-color);
  border-radius: 3px;
  box-shadow: 0px 0px 5px -1px rgba(186, 201, 212, 0.3);
  @include screen-size(xs) {
    max-width: 100vw;
    justify-content: center;
  }
  .border-bottom {
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 20px;
  }
  .modal-header {
    font: Bold 24px/28px Assistant;
    letter-spacing: -0.27px;
    color: $black-blue;
    height: 57.5px;
    margin-bottom: 20px;
  }
  .modal-body {
    & > * {
      border: 1px solid #e7e7e7;
      padding-left: 6px;
    }
    .input {
      padding: 10px;
      border-radius: 3px;
    }
  }

  .error-msg {
    color: red;
  }
  .buttons-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .terms-and-conditions {
    display: flex;
    font-family: $font-family-main;
    .custom-checkbox {
      height: 2vw;
      width: 5vw;
      &.not-selected {
        animation-name: checkbox-blink;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
      @include screen-size(xs) {
        height: 6vw;
        width: 20vw;
      }
    }
  }
}
@keyframes checkbox-blink {
  0% {
    opacity: 1;
    background-color: #ff6961;
    border-color: #ff6961;
  }
  50% {
    opacity: 0.5;
    background-color: #ffd369;
    border-color: #ffd369;
  }
  100% {
    opacity: 1;
    background-color: rgba(13, 143, 255, 1);
    border-color: rgba(13, 143, 255, 1);
  }
}
</style>
