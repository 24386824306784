<template>
	<section class="email-modal-container">
		<Modal
			v-if="selectedSurvey"
			@submit="saveEmailSettings"
			@close="closeEmailModal"
			:texts="modalTexts"
			width="small"
		>
			<section class="modal-container">
				<h4>{{ $t('please_edit_the_email_information') }}</h4>
				<div class="inputs-container">
					<div class="titles">
						<h4>{{ $t('email_sender_info') }}</h4>
						<h4>{{ $t('complaint_language') }}</h4>
					</div>
					<div class="inputs">
						<input v-model.trim="emailSettings.sender" />
						<Select class="select-container" v-model="emailSettings.language" :placeholder="$t('select')">
							<Option
								v-for="(lang, idx) in languages"
								:key="lang.label + idx"
								:label="$t(lang.label)"
								:value="lang.value"
								:src="lang.logo_url"
							/>
						</Select>
					</div>
				</div>
				<div class="email-body-info">
					<div class="email-info">
						<div class="titles">
							<h4>{{ $t('email_subject_info') }}</h4>
							<h4>{{ $t('email_message_info') }}</h4>
						</div>
						<div class="selectors">
							<Select
								class="select-container"
								@input="handleEmailSubjectSettings"
								:placeholder="$t('select')"
								:multiple="true"
								:value="selectedEmailSubjectSettings"
							>
								<Option
									v-for="(val, infoName, idx) in emailSettings.subjectSettings"
									:key="infoName + idx"
									:label="$t(boolNameToText(infoName))"
									:value="infoName"
								/>
							</Select>

							<Select
								class="select-container"
								@input="handleEmailBodySettings"
								:placeholder="$t('select')"
								:multiple="true"
								:value="selectedEmailBodySettings"
							>
								<Option
									v-for="(val, infoName, idx) in emailSettings.bodySettings"
									:key="infoName + idx"
									:label="$t(boolNameToText(infoName))"
									:value="infoName"
								/>
							</Select>
						</div>
					</div>
					<div class="switch-btns-containers">
						<h4>{{ $t('email_message_format') }}</h4>
						<div class="switch-btn-container">
							<span>{{ $t('line') }}</span>
							<md-switch class="switch-btn" v-model="emailSettings.isBodyAsList"></md-switch>
							<span>{{ $t('list') }}</span>
						</div>
					</div>
				</div>
			</section>
		</Modal>
	</section>
</template>

<script>
import Modal from '@/modules/common/components/Modal'
import Select from '@/modules/common/components/Select'
import Option from '@/modules/common/components/Option'
export default {
	name: 'EmailModal',
	props: {
		selectedSurvey: { type: Object, default: null },
		languages: { type: Array, required: true }
	},
	components: {
		Modal,
		Select,
		Option
	},
	data() {
		return {
			survey: this.selectedSurvey,
			modalTexts: {
				headerPrimary: 'email_configuration'
			},
			emailSettings: {
				language: '',
				sender: '',
				isBodyAsList: false,
				subjectSettings: {
					isCompanyName: false,
					isSiteName: false,
					isRoomName: false,
					isServikName: false,
					isZone: false
				},
				bodySettings: {
					isCompanyName: false,
					isSiteName: false,
					isRoomName: false,
					isServikName: false,
					isRoomType: false,
					isBuilding: false,
					isFloor: false,
					isZone: false
				}
			},
			selectedEmailSubjectSettings: [],
			selectedEmailBodySettings: []
		}
	},
	created() {
		const { email_settings } = this.selectedSurvey
		if (email_settings) this.emailSettings = email_settings
		this.updateSurveySelectedSettings()
	},
	methods: {
		boolNameToText(boolName) {
			return boolName.replace('is', '').replace(/^\w/, (c) => c.toLowerCase())
		},
		saveEmailSettings() {
			this.$emit('close', this.emailSettings)
		},
		closeEmailModal() {
			this.$emit('close')
		},
		updateSurveySelectedSettings() {
			this.selectedEmailBodySettings = Object.keys(this.emailSettings.bodySettings).filter(
				(key) => this.emailSettings.bodySettings[key]
			)
			this.selectedEmailSubjectSettings = Object.keys(this.emailSettings.subjectSettings).filter(
				(key) => this.emailSettings.subjectSettings[key]
			)
		},
		handleEmailSubjectSettings(selectedSettings) {
			this.handleSettings('subjectSettings', selectedSettings)
		},
		handleEmailBodySettings(selectedSettings) {
			this.handleSettings('bodySettings', selectedSettings)
		},
		handleSettings(type, newSettings) {
			const { emailSettings } = this
			Object.keys(emailSettings[type]).forEach((key) => {
				emailSettings[type][key] = newSettings.includes(key)
			})
		}
	},
	computed: {}
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins';

.modal-container {
	& > *:not(:last-child) {
		margin-bottom: 20px;
	}
	.inputs-container {
		.inputs {
			@include flex-space-between;
			input {
				background-color: $white;
				@include border($grey-border-color);
				border-radius: 4px;
				width: 75%;
				height: 4vh;
				padding: 0 14px;
				font-size: 14px;
				margin-inline-start: 10px;
			}
		}
	}
}

.titles {
	@include flex-space-around;
}

.email-info {
	width: 100%;
	margin-bottom: 10px;
	.selectors {
		@include flex-space-between;
	}
}

.switch-btns-containers {
	.switch-btn-container {
		.switch-btn {
			margin-inline-start: 10px;
		}
	}
}

.select-container {
	border-bottom: 1px solid $grey-border-color;
	border-radius: 4px;
	height: 7vh;
	margin-inline-start: 10px;
}
</style>
