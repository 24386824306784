<template>
  <section class="suggestion-table-container">
    <SuggestionTableItem
      v-for="(item, name) of topIssues.weekIssues"
      :key="name"
      :title="`top ${name.substring(0, name.length - 1)} of the week`"
      :issue="item"
    />
    <SuggestionTableItem
      v-for="(item, name) of topIssues.monthIssues"
      :key="name + 1"
      :title="`top ${name.substring(0, name.length - 1)} of the month`"
      :issue="item"
    />
  </section>
</template>

<script>
import SuggestionTableItem from './SuggestionTableItem';

export default {
  props: {
    topIssues: { type: Object, required: true },
  },
  components: {
    SuggestionTableItem
  }
}
</script>

<style lang="scss" scoped>
.suggestion-table-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
</style>