<template>
  <header>
    <div class="title">{{texts.title}}</div>
    <div class="button-container">
      <slot></slot>
      <div v-show="isSearchShown">
        <span>{{$t('search')}}:</span>
        <input type="text" v-model="searchText" />
      </div>
      <Button
        @click="remove"
        v-if="isDeleteShown"
        skin="accent"
        :disabled="isDeleteDisabled"
      >{{$t('delete')}}</Button>
      <Button @click="add" skin="primary" v-if="isAddShown">{{texts.add}}</Button>
    </div>
  </header>
</template>

<script>
import Button from "@/modules/common/components/Button";
export default {
  name: 'AdminHeader',
  props: {
    isDeleteShown: { default: false, type: Boolean },
    isDeleteDisabled: { default: true, type: Boolean },
    texts: {
      type: Object,
      default: () => ({ title: 'title', add: 'add' })
    },
    isAddShown: { type: Boolean, default: true },
    isSearchShown: { type: Boolean, default: true }
  },
  data() {
    return {
      searchText: '',
    }
  },
  watch: {
    searchText(currValue) {
      this.$emit('input', currValue);
    }
  },
  methods: {
    add() {
      this.$emit('add')
    },
    remove() {
      this.$emit('remove')
    }
  },
  components: { Button }
};
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";
@import "@/styles/mixins.scss";

header {
  height: 9.5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
  .title {
    font-weight: $font-weight-bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase !important;
    max-width: 21vw;
    min-width: 10vw;
    height: 9.5vh;
    font-size: 13px;
    border-bottom: 9px solid #448aff;
    text-align: center;
    padding-inline: 5px;
  }
  .button-container {
    display: flex;
    align-items: center;
    margin-inline-end: 50px;
    text-transform: uppercase;
    span {
      @include modal-input-label;
      margin-bottom: 0px;
      font-size: 13px;
    }
    input {
      @include modal-input;
      margin-inline-start: 5px;
      margin-bottom: 0px;
      padding: 0 5px;
      width: 150px;
    }
    button {
      font-size: 12px;
      font-weight: bold;
    }
  }
}
</style>