<template>
	<v-app>
		<section class="map-view">
			<TaskModal
				v-if="isOpenTaskModal && languages.length"
				:taskProp="task"
				:rooms="siteRooms"
				:languages="languages"
				:taskCategories="taskCategories"
				:workingAreas="workingAreas"
				@closeModal="closeTaskModal"
				@save="saveTask"
			/>
			<transition name="fade" v-if="routeName === 'task'">
				<div class="custom-button-container">
					<TableSearch :tableTitles="tableTitles" @input="handleInput" />
					<template v-if="isAdmin">
						<Button @click="removeSelectedTasks" :disabled="!selectedTasks.length" skin="accent">{{
							$t('delete')
						}}</Button>
						<Button @click="openTaskModal" skin="primary">{{ $t('new_task') }}</Button>
					</template>
				</div>
			</transition>
			<Tabs :isRTL="isRTL" syncRoute>
				<Tab :label="$t('task')" :to="'/task'" exact>
					<section class="map-details">
						<Loading v-if="isLoading" class="loader" style="height: 75vh" />
						<Table
							v-if="tasksToShow.length"
							idField="id"
							:tableData="tasksToShow"
							:editOptions="{ remove: isAdmin, edit: isAdmin }"
							:extraActions="extraTableActions"
							:isSelect="isAdmin"
							@action="handleAction"
							@editRow="editTask"
							@removeRow="removeTask"
							@select="handleSelectedTasks"
						></Table>
						<div v-else>
							<h3>{{ $t('no_tasks_found') }}</h3>
						</div>
					</section>
				</Tab>
				<Tab :label="$t('calendar')" :to="'/task/calendar'" exact>
					<Loading v-if="isLoading" class="loader" style="height: 75vh" />
					<section class="map-details">
						<Calendar
							:data="tasksCalendarInfo"
							@editRow="editTask"
							@addEvent="addEvent"
							@errorMsg="errorMsg"
						></Calendar>
					</section>
				</Tab>
			</Tabs>
		</section>
	</v-app>
</template>

<script>
import Tabs from '@/modules/common/components/Tabs/Tabs'
import Tab from '@/modules/common/components/Tabs/Tab'
import Loading from '@/modules/common/components/Loading'
import Table from '@/modules/common/components/customTable/Table'
import Button from '@/modules/common/components/Button'
import TaskModal from '@/modules/task/components/TaskModal'
import Calendar from '@/modules/task/views/Calendar.vue'
import TableSearch from '@/modules/common/components/customTable/TableSearch'
import taskService from '@/modules/task/services/taskService.js'
import roomService from '../../control-panel/services/roomService'
import utilService from '@/modules/common/services/utilService.js'
import swalService from '@/modules/common/services/swalService'
import workingAreaService from '@/modules/working-area/services/workingAreaService'
import { mapGetters } from 'vuex'

export default {
	name: 'Task',
	components: { TaskModal, Calendar, Tabs, Tab, Loading, Table, Button, TableSearch },
	data() {
		return {
			tasks: [],
			languages: [],
			isLoading: false,
			isOpenTaskModal: false,
			selectedTasks: [],
			tableTitles: [],
			searchTxt: '',
			searchBy: '',
			task: taskService.getDefaultTask(),
			taskCategories: [],
			siteRooms: [],
			workingAreas: []
		}
	},
	async created() {
		const [languages, taskCategories, workingAreas] = await Promise.all([
			taskService.getLanguages(),
			taskService.getTaskCategories(),
			workingAreaService.getWorkingAreas(this.filterSelected)
		])
		this.languages = languages
		this.taskCategories = taskCategories
		this.workingAreas = workingAreas
	},
	methods: {
		async updatePageData() {
			const { filterSelected } = this
			this.startLoading()
			const tasks = await taskService.getTasks(filterSelected)
			this.stopLoading()
			const siteRooms = await roomService.getSiteRoomsInfo(filterSelected.site)
			if (siteRooms) this.siteRooms = siteRooms
			if (!tasks) return
			this.tasks = tasks
			this.setTableTitles()
		},
		setTableTitles() {
			if (!this.tasksToShow.length) return
			this.tableTitles = Object.keys(this.tasksToShow[0]).filter((key) => key !== 'id')
		},
		handleInput(value, searchBy) {
			if (searchBy) this.searchBy = searchBy
			this.searchTxt = value
		},
		openTaskModal() {
			this.isOpenTaskModal = true
		},
		closeTaskModal() {
			this.isOpenTaskModal = false
			this.task = taskService.getDefaultTask()
		},
		async saveTask(task) {
			try {
				const { site: site_id, company: company_id } = this.filterSelected
				const newTask = { ...task, site_id, company_id }
				await taskService.saveTask(newTask)
				swalService.savedMsg(this.$t('Your work has been saved'))
				this.closeTaskModal()
				this.updatePageData()
			} catch (err) {
				this.errorMsg()
			}
		},
		async editTask({ id }) {
			try {
				const taskToEdit = await taskService.getTaskById(id)
				this.task = taskToEdit
				this.openTaskModal()
			} catch (err) {
				this.errorMsg()
			}
		},
		async removeTask(taskId) {
			const isRemoveConfirm = await swalService.confirmMsg()
			if (!isRemoveConfirm.value) return
			try {
				await taskService.removeTask(taskId)
				swalService.deletedMsg()
				this.updatePageData()
			} catch (err) {
				this.errorMsg()
			}
		},
		handleSelectedTasks(tasks) {
			this.selectedTasks = tasks
		},
		async removeSelectedTasks() {
			const isRemoveConfirm = await swalService.confirmMsg()
			if (!isRemoveConfirm.value) return
			try {
				const tasksToRemoveIds = this.selectedTasks.map((task) => task.id)
				await taskService.removeTasks(tasksToRemoveIds)
				this.selectedTasks = this.selectedTasks.filter((task) => task)
				swalService.deletedMsg()
				this.updatePageData()
			} catch (err) {
				this.errorMsg()
			}
		},
		startLoading() {
			this.isLoading = true
		},
		stopLoading() {
			this.isLoading = false
		},
		getWeekDaysNamesFromNums(days) {
			const weekDays = days.split(',').map((day) => {
				const dayName = utilService.getWeekDayName(day)
				if (dayName) {
					return this.$t(`${dayName}`)
				}
			})
			return weekDays
		},
		getDailyCalendarDates({ week_days, hour }) {
			const weekDaysNums = this.convertToArray(week_days)

			const dates = []
			const [h, m] = hour.split(':')
			const lastDayOfYear = this.getLastDateOfCurrYear()
			const today = this.getRestedDate(new Date())

			for (let i = today; i <= lastDayOfYear; i.setDate(i.getDate() + 1)) {
				const day = i.getDay() + 1
				const isDayValid = weekDaysNums.includes(day.toString())
				if (isDayValid) {
					const date = new Date(i)
					date.setHours(h, m, 0, 0)
					dates.push(date)
				}
			}
			return dates
		},
		getWeeklyCalendarDates({ week_days, month_weeks, hour }) {
			const weekDaysNums = this.convertToArray(week_days)
			const monthWeeksNums = this.convertToArray(month_weeks)

			const dates = []
			const [h, m] = hour.split(':')
			const lastDayOfYear = this.getLastDateOfCurrYear()
			const today = this.getRestedDate(new Date())

			for (let i = today; i <= lastDayOfYear; i.setDate(i.getDate() + 1)) {
				const day = i.getDay() + 1
				const weekNum = Math.ceil((i.getDate() + 1) / 7)
				const isDayValid = weekDaysNums.includes(day.toString()) && monthWeeksNums.includes(weekNum.toString())
				if (isDayValid) {
					const date = new Date(i)
					date.setHours(h, m, 0, 0)
					dates.push(date)
				}
			}
			return dates
		},
		getMonthlyCalendarDates({ month_days, hour }) {
			const monthDaysNums = this.convertToArray(month_days)

			const dates = []
			const [h, m] = hour.split(':')
			const lastDayOfYear = this.getLastDateOfCurrYear()
			const today = this.getRestedDate(new Date())

			for (let i = today; i <= lastDayOfYear; i.setDate(i.getDate() + 1)) {
				const date = i.getDate()
				const isDayValid = monthDaysNums.includes(date.toString())
				if (isDayValid) {
					const date = new Date(i)
					date.setHours(h, m, 0, 0)
					dates.push(date)
				}
			}
			return dates
		},
		getOnceCalendarDate({ send_date, hour }) {
			const [h, m] = hour.split(':')
			const date = new Date(send_date)
			date.setHours(h, m, 0, 0)
			return [date]
		},
		getLastDateOfCurrYear() {
			const currYear = new Date().getFullYear()
			const lastDayOfYear = new Date(currYear, 11, 31)
			return lastDayOfYear
		},
		getRestedDate(date) {
			const dateCopy = new Date(date)
			dateCopy.setHours(0, 0, 0, 0)
			return dateCopy
		},
		convertToArray(arr) {
			return Array.isArray(arr) ? arr : arr.split(',')
		},
		addEvent(event) {
			const { year, month, day } = event
			this.task.type = 'call_to_action'
			this.task.category = 'cleaning'
			this.task.schedule = 'once'
			this.task.send_date = `${year}-${month}-${day}`
			this.openTaskModal()
		},
		async handleAction({ type, idx }) {
			const task = this.tasks[idx]
			if (type === 'duplicate') {
				this.duplicateTask(task)
			}
		},
		async duplicateTask({ id }) {
			const taskDup = await taskService.getTaskById(id)
			const { titles, description, category, type, schedule, send_date, scheduleDates, hour, sla, rooms, duration, is_auto_start, selectedWorkingAreaIds, buffer_time } = taskDup
			const newTask = {
				...newTask,
				titles,
				description,
				category,
				type,
				send_date,
				schedule,
				scheduleDates,
				hour,
				rooms,
				sla,
				duration,
				is_auto_start,
				selectedWorkingAreaIds,
				buffer_time
			}
			this.saveTask(newTask)
		},
		errorMsg(msg) {
			swalService.errorMsg(msg)
		}
	},
	computed: {
		...mapGetters(['isRTL', 'loggedUser', 'filterSelected']),
		tasksToShow() {
			const { searchTxt, searchBy } = this
			const filteredTasks = utilService.filterArrayOfObjectsWithString(this.tasks, searchTxt, searchBy)

			return filteredTasks.map((task) => {
				const { servik_id, site_id, is_active, updated_at, sent_dates, duration, is_auto_start, buffer_time, ...taskToShow } = task
				const { created_at, send_date, week_days, month_weeks, month_days } = taskToShow
				const separatorToShow = ' | '

				created_at && (taskToShow.created_at = this.$options.filters.ddmmyy(created_at))
				send_date && (taskToShow.send_date = this.$options.filters.ddmmyy(send_date))

				if (week_days) {
					taskToShow.week_days = this.getWeekDaysNamesFromNums(week_days).join(separatorToShow)
				}

				if (month_weeks) {
					taskToShow.month_weeks = month_weeks.replace(/,/g, separatorToShow)
				}

				if (month_days) {
					taskToShow.month_days = month_days.replace(/,/g, separatorToShow)
				}

				return taskToShow
			})
		},
		isAdmin() {
			return this.loggedUser.type === 'admin'
		},
		routeName() {
			return this.$route.name
		},
		tasksCalendarInfo() {
			const calendarInfo = []
			const { tasks, taskCategories } = this
			if (tasks.length && taskCategories.length) {
				const calendarDates = {
					daily: this.getDailyCalendarDates,
					weekly: this.getWeeklyCalendarDates,
					monthly: this.getMonthlyCalendarDates,
					once: this.getOnceCalendarDate
				}
				const today = new Date()
				const currHour = today.getHours()
				const currMin = today.getMinutes()

				tasks.forEach((task) => {
					const { description, schedule, category, sent_dates, is_active } = task
					const color = taskCategories.find((cat) => cat.name === category).color
					const dates = [...sent_dates]

					if (is_active) {
						const futureSendDates = calendarDates[schedule](task)
						const filteredFutureDates = futureSendDates.filter((date) => {
							return date > today || (date.getHours() === currHour && date.getMinutes() < currMin)
						})

						dates.push(...filteredFutureDates)
					}

					const info = {
						color,
						description,
						dates,
						id: task.id
					}

					calendarInfo.push(info)
				})
			}
			return calendarInfo
		},
		extraTableActions() {
			return [{ name: 'duplicate_icon', type: 'duplicate' }]
		}
	},
	watch: {
		'$store.getters.filterSelected.site': {
			handler() {
				this.updatePageData()
			},
			immediate: true
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/views/map.scss';
@import '@/styles/mixins.scss';

.map-view {
	position: relative;
	background-color: white;
	min-height: 86vh;
}

.custom-button-container {
	height: 80px;
	display: flex;
	align-items: center;
	margin-inline-end: 20px;
	text-transform: uppercase;
	position: absolute;
	z-index: 3;
	right: 0;
	@include rtl {
		left: 0;
		right: unset;
	}
	span {
		@include modal-input-label;
		margin-bottom: 0px;
		font-size: 13px;
	}
	input {
		@include modal-input;
		margin-inline-start: 5px;
		margin-bottom: 0px;
		padding: 0 5px;
		width: 150px;
	}
	button {
		font-size: 12px;
		font-weight: bold;
	}
}
</style>
