<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29.927"
    height="29.927"
    viewBox="0 0 29.927 29.927"
  >
    <defs>
    </defs>
    <g transform="translate(29.927 29.927) rotate(180)">
      <path
        class="b"
        :fill="iconColor"
        :stroke="iconColor"
        stroke-width="2px"
        d="M12.976,6.488,11.833,5.345,7.3,9.87V0H5.677V9.87L1.152,5.336,0,6.488l6.488,6.488Z"
        transform="translate(8.304 8.304)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>