<template>
  <section class="suggestion-container">
    <section class="info-bar">
      <BarItem
        v-for="(item, index) in infoBarItems"
        :key="item.label"
        :trendInfo="item.trendInfo"
        componentType="info"
        :text="item.text"
        :label="$t(item.label)"
        :iconUrl="item.iconUrl"
        :isAmended="item.isAmended"
        :class="{ last: index === infoBarItems.length - 1, first: index === 0}"
      />
    </section>
    <section class="suggestion-table-container">
      <Loading v-if="loading" height="37vh" />
      <SuggestionTable v-else :topIssues="tableData" />
    </section>
  </section>
</template>

<script>
import SuggestionTable from "../components/SuggestionTable";
import BarItem from "../components/BarItem";
import Loading from "@/modules/common/components/Loading";
import { mapGetters } from "vuex";
import {
  getTopIssues,
  getOverviewSums,
  getAnalyticsSums,
} from "../services/performanceService";
import performanceService from "../services/performanceService";
import utilService from "../../common/services/utilService";
import { infoBarItems } from "../consts/consts";
import { trendArrowColors } from "../consts/consts";

export default {
  props:{
    hideInfo: { type: Array, required: false}
  },
  data() {
    return {
      tableData: { weekIssues: {}, monthIssues: {} },
      loading: false,
      infoBarItems: JSON.parse(JSON.stringify(infoBarItems)),
    };
  },
  async created() {
    this.assignTopBarItems();
    this.updatePageData();
    this.$store.watch(
      (state) => this.isRTL,
      (newValue) => {
        this.updatePageData();
      },
      { deep: true }
    );
    this.$store.watch(
      (state) => this.overviewData,
      (newValue) => {
        this.assignTopBarItems();
      },
      { deep: true }
    );
    this.$store.watch(
      (state) => this.analyticsData,
      (newValue) => {
        this.assignTopBarItems();
      },
      { deep: true }
    );
  },
  methods: {
    async updatePageData() {
      this.loading = true;
      let monthTime = { timeFrom: new Date(), timeTo: new Date() };
      monthTime.timeFrom.setMonth(monthTime.timeFrom.getMonth() - 1);
      monthTime = this.yyyymmdd(monthTime);
      const monthTopIssuesPrm = getTopIssues({
        ...this.filterSelected,
        time: { ...monthTime },
      });
      let weekTime = { timeFrom: new Date(), timeTo: new Date() };
      weekTime.timeFrom.setDate(weekTime.timeFrom.getDate() - 7);
      weekTime = this.yyyymmdd(weekTime);
      const weekTopIssuesPrm = getTopIssues({
        ...this.filterSelected,
        time: { ...weekTime },
      });
      const [weekTopIssues, monthTopIssues] = await Promise.all([
        weekTopIssuesPrm,
        monthTopIssuesPrm,
      ]);
      this.tableData.weekIssues = weekTopIssues;
      this.tableData.monthIssues = monthTopIssues;
      this.loading = false;
    },
    yyyymmdd(time) {
      time.timeFrom = time.timeFrom.toISOString().split("T")[0];
      time.timeTo = time.timeTo.toISOString().split("T")[0];
      return time;
    },
    assignTopBarItems() {
      if (!this.overviewData || !this.analyticsData) return;
      const overviewSums = getOverviewSums(this.overviewData);
      const analyticsSums = getAnalyticsSums(this.analyticsData.hourData);
      const trendInfo = performanceService.trendInfoForBarItems(
        this.overviewData
      );
      this.infoBarItems = this.infoBarItems.reduce((acc, item) => {
        if (analyticsSums[item.name] || overviewSums[item.name]) {
          item.trendInfo = trendInfo[item.name];
          item.trendInfo
            ? (item.trendInfo.color =
                trendArrowColors[item.name][item.trendInfo.upDown])
            : item.trendInfo;
          if (item.name === "visits") item.isAmended = analyticsSums.isAmended || overviewSums.isAmended
          if (item.name === "avg_score" || item.name === "response_time_SLA") {
            if (item.name === "avg_score") {
              item.text = utilService.kFormatter(overviewSums[item.name]);
            } else {
              item.text = utilService.kMinutesToHours(overviewSums[item.name]);
            }
          } else
            item.text = utilService.kFormatter(
              analyticsSums[item.name] || overviewSums[item.name]
            );
          try {
            if (item.name === "response_time_SLA")
              item.text += ` ${this.$t("min")}`;
          } catch (err) {}
          acc.push(item);
        }
        return acc;
      }, []);
      if(this.hideInfo.length > 0) this.infoBarItems = this.infoBarItems.filter(col => this.hideInfo.indexOf(col.name) === -1);
    },
  },
  computed: mapGetters([
    "analyticsData",
    "overviewData",
    "isRTL",
    "filterSelected",
  ]),
  components: { SuggestionTable, Loading, BarItem },
};
</script>

<style lang="scss" scoped>
.info-bar {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3.1vh;
}
</style>