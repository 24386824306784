<template>
  <transition name="slide-fade">
    <button class="top-of-view-button" @click="handleClick">
      <TriangleIcon class="triangle-icon" :direction="isRTL? 'right' : 'left'" />
      {{$t(text)}}
    </button>
  </transition>
</template>

<script>
import TriangleIcon from "@/modules/common/components/TriangleIcon";
export default {
  methods: {
    handleClick() {
      this.$router.go(-1);
    }
  },
  computed: {
    text() {
      if (this.$route.name === "siteDetails") return "sites";
      else if (this.$route.name === "roomDetails") return "room";
    },
    isRTL() {
      return this.$store.getters.isRTL;
    }
  },
  components: {
    TriangleIcon
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";

.top-of-view-button {
  height: 5vh;

  @include flex-center;

  padding-bottom: 16px;

  .triangle-icon {
    margin-inline-end: 14px;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
