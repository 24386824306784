<template>
  <section v-if="survey && currPage && !isSavingSurvey" class="survey-container">
    <SurveyHeader
      v-if="survey && currPage"
      @updatePage="updatePage"
      @updateOptions="updateOptions"
      @removePage="removePage"
      @addPage="addPage"
      @saveSurvey="saveSurvey"
      @changeEditMode="changeEditMode"
      @changePage="setPage"
      @triggerRerender="triggerRerender"
      :isEdit="isEdit"
      :currPageNum="currPageNum"
      :currPage="currPage"
      :currSurvey="survey"
      :key="rerenderFlag + currPageNum"
    />
    <Survey
      v-if="isShown"
      @updatePage="updatePage"
      @setPage="setPage"
      @setLogoRoute="setLogoRoute"
      @logoChange="saveFileToData"
      @leftLogoChange="saveLeftLogoFileToData"
      @updatePagesPreviewImagesInfo="updatePagesPreviewImagesInfo"
      @deletePagesPreviewImagesInfo="deletePagesPreviewImagesInfo"
      @deletePageImage="deletePageImage"
      @changePageImgSize="changePageImgSize"
      @handlePageBackgroundImage="handlePageBackgroundImage"
      :isEdit="isEdit"
      :currPageNum="currPageNum"
      :survey="survey"
      :pagesPreviewImagesInfo="pagesPreviewImagesInfo"
      :isTestSurvey="false"
      :key="rerenderFlag"
    />
    <Modal
      v-if="isRename"
      @submit="setSurveyName"
      @close="closeModal"
      :texts="modalTexts"
      width="medium"
    >
      <div class="modal-body">
        <div class="field">
          <h4>{{ $t('name') }}</h4>
          <input v-model="survey.name" />
        </div>
        <div class="field">
          <h4>{{ $t('template') }}</h4>
          <Select
            v-model="surveyTemplate"
            :placeholder="$t('select_template')"
            class="filter-select"
          >
            <Option
              v-for="template in templateOptions"
              :key="template"
              :label="$t(template)"
              :value="template"
            />
          </Select>
        </div>
         <div class="field checkbox">
           <h4>{{$t('add_stage_number_to_signal')}}</h4>
        <CheckBox :value="survey.attach_stage_number" v-model="survey.attach_stage_number"  />
        </div>
      </div>
    </Modal>
  </section>
  <Loading v-else-if="isSavingSurvey" class="loader" style="height:75vh;" />
</template>

<script>
import SurveyHeader from '../components/SurveyHeader';
import Survey from '@/survey/views/Survey';
import surveyService from '../services/surveyService';
import { emptySurvey, emptyPage, surveyTemplates } from '../consts/consts';
import Modal from '@/modules/common/components/Modal';
import swalService from '@/modules/common/services/swalService';
import Select from '@/modules/common/components/Select';
import Option from '@/modules/common/components/Option';
import cloudinaryService from '../../common/services/cloudinaryService';
import { mapGetters } from 'vuex';
import CheckBox from "../components/CheckBox";
import Loading from '../../common/components/Loading.vue';

export default {
  data() {
    return {
      survey: null,
      surveyName: '',
      isEdit: true,
      pageDiff: 0,
      isShown:true,
      currPageNum: 0,
      modalTexts: {
        headerPrimary: 'survey_name',
      },
      isRename: false,
      surveyTemplate: '',
      logoFile: null,
      leftLogoFile: null,
      surveyTemplateList: null,
      pagesPreviewImagesInfo: {},
      rerenderFlag: false,
      isSavingSurvey: false,
    };
  },
  computed: {
    ...mapGetters(['filterSelected']),
    currPage() {
      if (!this.survey.pages) return null;
      return this.survey.pages[this.currPageNum]
    },
    templateOptions() {
      return Object.keys(this.surveyTemplateList);
    },
  },
  async created() {
    const siteTemplates = await surveyService.getCompanyTemplates(
      this.filterSelected.company
    );
    this.surveyTemplateList = { ...surveyTemplates, ...siteTemplates };
    if (!this.$store.getters.filterSelected.site) this.$router.push('/survey');
    let newSurvey = JSON.parse(JSON.stringify(emptySurvey));
    const id = this.$route.params.surveyId;
    if (id) {
      newSurvey = await surveyService.getSurveyById(id);
      // TODO: remove this after all surveys will have the new structure
      // We need to do this because we changed the structure of the question in the survey
      const { pages } = newSurvey
      pages.forEach((page, index) => {
        const { question } = page
        if (typeof question === 'string') {
          pages[index].question = {
            text: question,
            align: '',
            fontSize: 45
          }
        }
      })
      this.isEdit = this.$route.params.isEdit;
    }
    if (this.$route.params.isRename) {
      this.isRename = this.$route.params.isRename;
    }
    this.survey = newSurvey;
  },
  methods: {
    saveFileToData(file) {
      this.logoFile = file;
    },
    saveLeftLogoFileToData(file) {
      this.leftLogoFile = file
    },
    setPage(diff) {
      this.isShown=false;
      setTimeout(() => {
          this.currPageNum += diff;
          this.isShown=true;
          this.triggerRerender()
      }, 0);
    },
    setLogoRoute(val) {
      this.survey.logo_route = val;
    },
    changePage(diff) {
      this.pageDiff += diff;
    },
    changeEditMode(mode) {
      this.isEdit = mode;
    },
    addPage() {
      this.survey.pages.push(JSON.parse(JSON.stringify(emptyPage)));
      this.currPageNum = this.survey.pages.length - 1;
      this.triggerRerender()
    },
    async removePage(pageNumToRemove) {
      if (this.survey.pages.length === 1) return;
      try {
        const result = await swalService.confirmMsg();
        if (!result.value) return;
          this.survey.pages.splice(pageNumToRemove, 1);
          if (pageNumToRemove === this.survey.pages.length) {
            this.currPageNum--;
          }
        this.updatePagesPreviewIds()
        swalService.deletedMsg();
        this.triggerRerender()
      } catch (err) {
        swalService.errorMsg();
      }
    },
    updatePage(updatedPage) {
      this.survey.pages[this.currPageNum] = updatedPage;
    },
    async saveSurvey() {
      if (!this.survey.name) return;
      this.isSavingSurvey = true;
      try {
        if (this.logoFile) {
          const logoUrl = await cloudinaryService.uploadImage(this.logoFile, 'logo');
          this.survey.logo_url = logoUrl;
        }
        if (this.leftLogoFile) {
          const leftLogoUrl = await cloudinaryService.uploadImage(this.leftLogoFile, 'logo');
          this.survey.left_logo_url = leftLogoUrl;
        }
        if (Object.keys(this.pagesPreviewImagesInfo).length > 0) {
          for (const pageId in this.pagesPreviewImagesInfo) {
            const { file, width, height, isBackground } = this.pagesPreviewImagesInfo[pageId]
            const imgUrl = await cloudinaryService.uploadImage(file, 'survey_images');
            this.survey.pages[pageId].imgInfo = {
              url: imgUrl,
              width,
              height,
              isBackground,
            }
          }
        }
      } catch (err) {
        console.warn('error while uploading image');
      }
      try {
        const addedSurvey = await surveyService.saveSurvey(
          this.survey,
          this.$store.getters.filterSelected.site
        );
        this.isSavingSurvey = false;
        swalService.savedMsg(this.$t('Your work has been saved'))
        this.survey.name = '';
        this.survey = null;
        this.$router.push({ name: 'survey' });
      } catch (err) {
        swalService.errorMsg();
      }
    },
    setSurveyName() {
      this.surveyName = '';
      this.isRename = false;
      swalService.savedMsg('Renamed Survey!', 'Press save to make changes');
    },
    closeModal() {
      if (!this.survey.name) {
        this.survey = null;
        this.$router.push('/survey');
      }
      this.isRename = false;
    },
    updateOptions(updatedOptions) {
      this.survey.pages[this.currPageNum].options = [...updatedOptions];
    },
    updatePagesPreviewImagesInfo({ file, blobURL, pageId, width, height, isBackground }) {
      this.pagesPreviewImagesInfo[pageId] = { file, blobURL, pageId, width, height, isBackground }
    },
    deletePagesPreviewImagesInfo(pageId) {
      delete this.pagesPreviewImagesInfo[pageId]
    },
    deletePageImage(pageId) {
      this.survey.pages[pageId].imgInfo = this.getDefaultImgInfo()
    },
    triggerRerender() {
      this.rerenderFlag = !this.rerenderFlag
    },
    changePageImgSize({ width, height }) {
      this.survey.pages[this.currPageNum].imgInfo.width = width
      this.survey.pages[this.currPageNum].imgInfo.height = height
    },
    handlePageBackgroundImage(pageId) {
      this.survey.pages[pageId].imgInfo.isBackground = !this.survey.pages[pageId].imgInfo.isBackground
    },
    updatePagesPreviewIds() {
      this.pagesPreviewImagesInfo = Object.keys(this.pagesPreviewImagesInfo).reduce((acc, curr) => {
        const newKey = curr - 1
        acc[newKey] = this.pagesPreviewImagesInfo[curr]
        return acc
      }, {})
    },
    getDefaultImgInfo() {
      return {
        url: '',
        width: 0,
        height: 0
      }
    },
  },
  watch: {
    '$store.getters.filterSelected.site': {
      deep: true,
      handler: function(to, from) {
        this.$router.push('/survey');
      },
    },
    surveyTemplate(value) {
      if (value === '') return;
      this.survey.pages = JSON.parse(
        JSON.stringify(this.surveyTemplateList[value].pages)
      );
      this.survey.logo_route = this.surveyTemplateList[value].logo_route;
      this.survey.name = this.surveyTemplateList[value].name;
    },
  },
  components: {
    SurveyHeader,
    Survey,
    Modal,
    Select,
    Option,
    CheckBox,
    Loading
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';

.survey-container {
  background-color: white;
  min-height: 86vh;
  @include border($grey-border-color);
  .franchise-edit {
    width: 240px;
    height: 120px;
    @include border($grey-border-color);
    img.franchise {
      width: 100%;
      height: 100%;
    }
  }
  .modal {
    margin-inline-start: -10vw;
  }
}

.modal-body {
  width: 100%;
  height: 100%;
  & > * {
    margin: 15px;
  }
  @include flex-center;
  flex-direction: column;

  .field {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    input {
      width: 80%;
      background-color: $white;
      @include border($grey-border-color);
      border-radius: 4px;
      padding: 10px 15px;
      font-size: $font-size-regular;
    }
  }
.checkbox{
     justify-content: flex-start;
  }

}
</style>
