<template>
  <section>
    <section class="pdf-print-container" ref="pages">
      <PdfHeader
        @mounted="pdfHeader = true"
        :currCompany="currCompany"
        :currSite="currSite"
        :currRoom="currRoom"
        :time="filterSelected.time"
      />
      <section class="info-bar" ref="barItem">
        <BarItem
          v-for="(item, index) in infoBarItems"
          :key="item.label"
          :isAmended="item.isAmended"
          :trendInfo="item.trendInfo"
          componentType="info"
          :text="item.text"
          :label="$t(item.label)"
          :iconUrl="item.iconUrl"
          :class="{ last: index === infoBarItems.length - 1, first: index === 0 }"
          @mounted="barItem = true"
        />
      </section>
      <AnalyticsChartTable
        :data="analyticsData"
        class="analytics-data"
        @mounted="analyticsChart = true"
        ref="analytics"
      />
      <TableByHour :dataByHour="hourData" class="hour-data" @mounted="tableByHour = true" ref="hourTable" />
    </section>
    <RoomTable :isExporting="true" :hideCols="hideInfo" :rooms="roomsData" class="room-data" @mounted="roomTable = true" ref="roomTable" />
  </section>
</template>

<script>
import BarItem from './BarItem'
import AnalyticsChartTable from './AnalyticsChartTable'
import RoomTable from './RoomTable'
import TableByHour from './TableByHour'
import PdfHeader from '../../../PDF/PdfHeader'
import html2pdf from 'html2pdf.js'
import { mapGetters } from 'vuex'

export default {
  name: 'analytics-print',
  props: {
    infoBarItems: { type: Array, required: true },
    analyticsData: { type: Object, required: true },
    hourData: { type: Array, required: true },
    hideInfo: { type: Array, required: false },
    roomsData: { type: Array, required: false },
  },
  data() {
    return {
      pdfHeader: false,
      barItem: false,
      analyticsChart: false,
      tableByHour: false,
      roomTable: false,
    }
  },
  computed: {
    ...mapGetters(['isRTL', 'filterSelected', 'filterOptions']),
    loadedComps() {
      const { pdfHeader, barItem, analyticsChart, tableByHour, roomTable } = this
      return pdfHeader && barItem && analyticsChart && tableByHour && roomTable
    },
    currCompany() {
      const { companies } = this.filterOptions
      return companies.find((c) => c.id === this.filterSelected.company)
    },
    currSite() {
      const { site: siteId } = this.filterSelected
      const { sites } = this.currCompany
      const foundSite = sites.find((site) => site.id === siteId)
      if (foundSite) return foundSite
      else return null
    },
    currRoom() {
      const { rooms } = this.filterOptions
      const { roomId } = this.$route.params
      for (const key in rooms) {
        if (rooms[key].id === +roomId) return rooms[key]
      }
      return null
    },
  },
  watch: {
    loadedComps: {
      handler(newVal) {
        if (newVal) {
          if (this.isRTL) {
            for (const cmp in this.$refs) {
              if (this.$refs[cmp].$el === undefined) this.$refs[cmp].classList.add('rtl')
              else this.$refs[cmp].$el.classList.add('rtl')
            }
          }
          const pages = this.$refs.pages
          const roomTable = this.$refs.roomTable.$el
          const opts = {
            margin: [0, 0.1],
            filename: `${this.currCompany.name}-${this.currSite.name}-${this.filterSelected.time.timeFrom}-${
              this.filterSelected.time.timeTo
            }`,
            image: { type: 'jpeg', quality: 0.98 },
            jsPDF: { unit: 'in', format: 'a3', orientation: 'landscape' },
            html2canvas: {
              useCORS: true,
              scale: 1,
            },
          }
          const vueThis = this
          document.body.classList.add('print-mode')

          html2pdf()
            .set(opts)
            .from(pages)
            .toPdf()
            .get('pdf')
            .then(function(pdf) {
              pdf.addPage()
            })
            .set({
              pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
            })
            .from(roomTable)
            .toContainer()
            .toCanvas()
            .toPdf()
            .then(function(pdf) {
              vueThis.$emit('donePrinting')
            })
            .save()
            .finally(() => {
              document.body.classList.remove('print-mode')
            })
        }
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    BarItem,
    AnalyticsChartTable,
    RoomTable,
    TableByHour,
    PdfHeader,
  },
}
</script>

<style lang="scss">
.pdf-print-container {
  display: flex;
  flex-direction: column;
  height: max-content;
  gap: 60px;

  .info-bar {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-self: center;

    .card {
      .label-container {
        font-size: 11px;
      }
    }
  }

  .analytics-data {
    display: flex;
    justify-content: center;
  }

  .hour-data {
    .hour-table {
      min-height: 250px;
      height: 250px;

      tr {
        min-height: 40px;
        height: 40px;

        td:first-child {
          display: flex;
          justify-content: center;
          font-size: 11px;
          width: 130px;
        }
      }
    }
  }

  .rtl {
    direction: rtl;
  }
}
</style>
