<template>
  <section class="survey-header-container">
    <div class="controls">
      <div class="stage-selector">
        <button @click="changePage(-1)">
          <img src="@/assets/survey/arrow_left.svg" alt />
        </button>
        <h4>{{$t('stage')}} #</h4>
        <span class="stage-number">{{' ' + (currPageNum + 1)}}</span>
        <button @click="changePage(1)">
          <img src="@/assets/survey/arrow_right.svg" alt />
        </button>
      </div>
      <div class="btn-editor">
        <h4>{{$t('type')}}</h4>
        <Select :value="page.type" @input="changePageType" class="filter-select">
          <Option
            v-for="type in pageTypes"
            :key="type"
            :label="type"
            :value="type"
          />
        </Select>
      </div>
      <template v-if="isRowsSettings">
        <div class="btn-editor">
          <h4>{{$t('number_of_rows')}}</h4>
          <Select :value="rowCount" @input="changeRowNums" class="filter-select">
            <Option
              v-for="rowNum in [0,1,2]"
              :key="rowNum"
              :label="rowNum"
              :value="`${rowNum}`"
            />
          </Select>
        </div>
      </template>
      <template v-if="isBtnSettings">
        <div class="btn-editor">
          <h4>{{$t('number_of_buttons')}}</h4>
          <Select :value="optionCount" @input="changeOptionCount" class="filter-select">
            <Option
              v-for="iconNum in iconNums"
              :key="iconNum"
              :label="iconNum"
              :value="`${iconNum}`"
            />
          </Select>
        </div>
        <div class="btn-editor">
          <h4>{{$t('button_shape')}}</h4>
          <ShapeSelector v-model="btnShape" :shapes="btnShapes" />
        </div>
      </template>
      <div v-if="pageType === 'rating'" class="btn-editor">
        <h4>{{ $t("rating_shape") }}</h4>
        <ShapeSelector
          v-model="page.rating_shape"
          @input="rerender"
          :shapes="ratingShapes" />
      </div>
      <div class="btn-editor">
        <h4>{{$t('delay_time_stage')}}</h4>
        <Select :value="delayTime" @input="changeDelayTime" class="filter-select">
          <Option
            v-for="delayTime in delayTimes"
            :key="delayTime"
            :label="delayTime | formatSeconds"
            :value="delayTime"
          />
        </Select>
      </div>
       <div class="btn-editor">
        <h4>{{$t('redirect_to_url')}}</h4>
        <input :value="urlRedirect"  v-on:change="changeUrl" type="text"/>
      </div>
    </div>
    <div class="action-btns">
      <img @click="removePage" src="@/assets/delete-icon.svg" />
      <button class="add-stage" @click="addPage">{{$t('add_stage')}}</button>
      <button class="save-survey" @click="saveSurvey">{{$t('save')}}</button>
    </div>
  </section>
</template>

<script>
import Select from "@/modules/common/components/Select";
import Option from "@/modules/common/components/Option";
import ShapeSelector from "../components/ShapeSelector";

export default {
  props: {
    currSurvey: { type: Object, required: false },
    currPage: { type: Object, required: true },
    currPageNum: { type: Number, required: false },
    isEdit: { type: Boolean, required: false }
  },
  data() {
    return {
      iconNums: [0, 1, 2, 3, 4, 5, 6, 8, 9, 10, 12],
      delayTimes: [0, 1, 4, 8, 10, 12, 30, 60, 300, 900, 1800, 3600, 18000, 86400],
      survey: this.currSurvey,
      page: this.currPage,
      shapes: ["square", "circle", "rounded","transparent-circle"],
      pageTypes: ['button', 'content', 'rating', 'image', 'open_text'],
      btnShape: this.currPage.shape.toLowerCase(),
      apiEditMode: false,
      smsList: this.currPage.phone_numbers.join(","),
      btnShapes: ["circle", "rounded", "square", "transparent-circle"],
      ratingShapes: ["star", "heart", "thumbs_up"],
      pageType: null,
    };
  },
  created() {
    if (!this.page.type) {
      const { options, content, ratings, imgInfo, rating_shape, open_text } = this.page
      // set default rating shape
      if (!rating_shape) {
        this.page.rating_shape = this.ratingShapes[0]
      }
      switch (true) {
        case options.length > 0:
          this.page.type = 'button'
          break
        case content.length > 0:
          this.page.type = 'content'
          break
        case ratings.length > 0:
          this.page.type = 'rating'
          break
        case imgInfo.url.length > 0:
          this.page.type = 'image'
          break
        case open_text.text.length > 0:
          this.page.type = 'open_text'
          break
        default:
          this.page.type = 'button'
      }
    }
    this.pageType = this.page.type
  },
  computed: {
    editLogoUrl() {
      return this.isEdit
        ? "assets/survey/edit-selected.svg"
        : "assets/survey/edit.svg";
    },
    eyeLogoUrl() {
      return !this.isEdit
        ? "assets/survey/eye-selected.svg"
        : "assets/survey/eye.svg";
    },
    phoneNums() {
      let phones = this.phoneNumsStr.split(",");
      return phones.filter(phone => phone.length > 0);
    },
    optionCount() {
      return this.currPage.options.length;
    },
    rowCount() {
      const { content, ratings, type } = this.page
      if (type === 'content') return content?.length || 0
      if (type === 'rating') return ratings?.length || 0
      return 0
    },
    delayTime() {
      return this.currPage.delay_time;
    },
    currPageApi() {
      return this.survey.pages[this.currPageNum].api ? true : false;
    },
    urlRedirect(){
      return this.currPage.redirect_to;
    },
    isRowsSettings() {
      const { pageType } = this
      return pageType === 'content' || pageType === 'rating'
    },
    isBtnSettings() {
      const { pageType } = this
      return pageType === 'button' || pageType === 'image' || pageType === 'open_text'
    },
  },
  methods: {
    openApiModal() {
      this.apiEditMode = true;
    },
    closeApiModal(newApi) {
      if (newApi) {
        this.survey.pages[this.currPageNum].api = newApi;
      }
      this.apiEditMode = false;
    },
    changePage(diff) {
      if (
        (this.currPageNum === 0 && diff === -1) ||
        (this.currPageNum === this.survey.pages.length - 1 && diff === 1)
      )
        return;
      this.$emit("changePage", diff);
    },
    changeEditMode(mode) {
      this.$emit("changeEditMode", mode);
    },
    addPage() {
      this.$emit("addPage");
    },
    removePage() {
      this.$emit("removePage", this.currPageNum);
    },
    saveSurvey() {
      this.$emit("saveSurvey");
    },
    changeOptionCount(to) {
      let from = this.optionCount;
      let newOptions;
      let currPage = this.survey.pages[this.currPageNum];
      if (to < from) {
        newOptions = currPage.options.slice(0, +to);
      } else {
        const diff = to - from;
        newOptions = currPage.options.slice();
        for (let i = 0; i < diff; i++) {
          newOptions.push(this.getDefaultOption());
        }
      }
      this.page.options = newOptions;
      this.page.content = []
      this.$emit("updateOptions", newOptions);
    },
    changeDelayTime(newDelayTime) {
      this.page.delay_time = newDelayTime;
    },
    changeUrl(url){
      this.page.redirect_to=url.target.value.trim() || "";
    },
    changePageType(type){
      this.page.type = type
      this.pageType = type
      switch (type) {
        case 'button':
          this.page.options = this.page.options || []
          this.page.content = []
          this.page.ratings = []
          this.page.imgInfo = this.getDefaultImgInfo()
          break
        case 'content':
          this.page.content = this.page.content || []
          this.page.options = []
          this.page.ratings = []
          this.page.imgInfo = this.getDefaultImgInfo()
          break
        case 'rating':
          this.page.ratings = this.page.ratings || []
          this.page.options = []
          this.page.content = []
          this.page.imgInfo = this.getDefaultImgInfo()
          break
        case 'image':
          this.page.imgInfo = this.page.imgInfo || this.getDefaultImgInfo()
          this.page.content = []
          this.page.ratings = []
          break
        default:
          this.page.options = []
          this.page.content = []
          this.page.ratings = []
          this.page.imgInfo = this.getDefaultImgInfo()
      }
      this.rerender()
    },
    changeRowNums(rowNum) {
      if (this.page.type === 'content') {
        if (!this.page.content.length) {
          for (let i = 0; i < rowNum; i++) {
            this.page.content.push(this.getDefaultContentRow())
          }
        } else {
          if (rowNum < this.page.content.length) {
            this.page.content = this.page.content.slice(0, rowNum)
          } else {
            const diff = rowNum - this.page.content.length
            for (let i = 0; i < diff; i++) {
              this.page.content.push(this.getDefaultContentRow())
            }
          }
        }
      }
      if (this.page.type === 'rating') {
        if (!this.page.ratings.length) {
          for (let i = 0; i < rowNum; i++) {
            this.page.ratings.push(this.getDefaultRatingRow())
          }
        } else {
          if (rowNum < this.page.ratings.length) {
            this.page.ratings = this.page.ratings.slice(0, rowNum)
          } else {
            const diff = rowNum - this.page.ratings.length
            for (let i = 0; i < diff; i++) {
              this.page.ratings.push(this.getDefaultRatingRow())
            }
          }
        }  
      }
      // rerender because old surveys doesn't have the new fields like content, ratings, imgInfo
      this.rerender()
    },
    getDefaultOption() {
      return {
        phone_numbers: [],
        apis: [],
        servik_id: 9,
        text: '',
        icon: 'light',
        color: '#E6E6E6',
        shape: 'square',
        route_to: '',
        connectedRoomId:'',
        isLinkServik: false,
      }
    },
    getDefaultContentRow() {
      return {
          text: '',
          response: '',
          placeholder: '',
          type: 'text',
        }
    },
    getDefaultRatingRow() {
      return {
        main_text: '',
        value: 0,
        left_text: '',
        right_text: ''
      }
    },
    getDefaultImgInfo() {
      return {
        url: '',
        width: 0,
        height: 0
      }
    },
    rerender() {
      this.$emit('triggerRerender')
    },
  },
  watch: {
    smsList(newPhoneNumbers, oldPhoneNumbers) {
      let newSmsList = [];
      if (newPhoneNumbers.length) {
        newSmsList = newPhoneNumbers.split(",");
      }
      this.page.phone_numbers = newSmsList;
      this.$emit("updatePage", this.page);
    },
    btnShape(to, from) {
      this.btnShape = to.toLowerCase();
      let updatedPage = this.survey.pages[this.currPageNum];
      const newShape = to.toLowerCase();
      updatedPage.shape = newShape;
      updatedPage.options = updatedPage.options.map(option => {
        option.shape = newShape
        return option
      });
      this.$emit("updatePage", updatedPage);
    },
    isApi(to) {
      this.isEditingApi = to;
    },
    currPage: {
      deep: true,
      handler(to, from) {
        this.page = to;
        this.smsList = this.currPage.phone_numbers.join(",");
      }
    },
  },
  components: {
    Select,
    Option,
    ShapeSelector
  }
};
</script>


<style lang="scss" scoped>
@import "@/styles/vars.scss";
@import "@/styles/mixins.scss";

.survey-header-container {
  height: 9.1vh;
  width: 100%;
  // border-bottom: 1px solid $grey-border-color;
  @include border($grey-border-color);
  background-color: $light-blue;
  padding-inline-start: 30px;
  padding-inline-end: 30px;
  @include align-center;
  justify-content: space-between;
  .controls {
    @include align-center;
  }
  h4 {
    font-size: $font-size-regular;
    // font-size:0.8rem;
    color: $dark-grey;
    text-transform: capitalize;
  }

  .btn-editor {
    @include align-center;
    margin-inline-start: 10px;
    .filter-select {
      background-color: $white;
      @include border($grey-border-color);
      height: 46px;
      width: 80px !important;
      max-width: 100px !important;
      border-radius: 4px;
      padding-top: 0;
      margin: 0 7px;
    }
    input {
      background-color: $white;
      @include border($grey-border-color);
      border-radius: 4px;
      width: 9.1vw;
      height: 4.2vh;
      padding: 0 14px;
      font-size: 14px;
      margin-inline-start: 10px;
    }
  }

  .stage-selector {
    height: 100%;
    @include align-center;
    justify-content: space-between;
    & > *:not(:first-child, :last-child) {
      margin-inline-end: 1vw;
    }

    button {
      width: 2.3vw;
      height: 2.3vw;
      background-color: $dark-blue;
      @include flex-center;
      border-radius: 4px;
      line-height: 100%;
      img {
        width: 0.6vw;
        height: 1.6vh;
      }
      &:first-child {
        margin-inline-end: 10px;
      }
      &:last-child {
        margin-inline-start: 10px;
      }
    }
    span {
      color: $blue;
      font-weight: $font-weight-bold;
    }
  }
  .action-btns {
    width: 15vw;
    height: 4.2vh;
    @include align-center;
    justify-content: space-between;
    img {
      width: 3vw;
      max-height: 60%;
      margin: 0 5px;
    }
    button {
      width: 4.8vw;
      height: 100%;
      @include border($grey-border-color);
      @include flex-center;
      border-radius: 4px;
      color: $white;
      // &:first-child {
        background-color: $dark-blue;
      // }
      &:last-child {
        background-color: $blue;
      }
    }
  }
}
</style>