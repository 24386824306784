<template>
  <section class="option-settings-container">
    <div class="field">
      <h1>{{$t('go_to_stage')}}</h1>
      <Select v-model="option.route_to" class="select-container">
        <Option
          v-for="(page) in ['', ...pages]"
          :value="page"
          :key="page"
          :label="(!page ? 'None' : ++page)"
        />
      </Select>
    </div>
    <div  v-if="rooms &&rooms.length" class="field">
      <h1>{{$t('connect to room')}}</h1>
      <Select v-model="option.connectedRoomId" class="select-container" :multiple="true">
        <Option
          v-for="room in rooms"
          :value="room.id"
          :key="room.id"
          :label="(!room ? 'None' : room.display_name)"
        />
      </Select>
    </div>
    <div class="field">
      <h1>{{$t('color')}}</h1>
      <input v-model="option.color" class="color-picker" type="color" name="btnColor" />
    </div>
    <div class="field">
      <h1>SMS</h1>
      <input class="input-container" v-model="smsList" />
    </div>
  </section>
</template>

<script>
import Select from "@/modules/common/components/Select";
import Option from "@/modules/common/components/Option";

export default {
  name:"optionSettingsModal",
  props: {
    currOption: { type: Object, required: false },
    pages: { type: Array, required: true },
    rooms:{type: Array, required: false}
  },
  data() {
    return {
      option: this.currOption,
      smsList: this.currOption.phone_numbers.join(","),
      apiEditMode: false
    };
  },
  methods: {
    closeApiModal(newApi) {
      if (newApi) {
        this.option.api = newApi;
      }
      this.apiEditMode = false;
    },
    openApiModal() {
      this.apiEditMode = true;
    }
  },
  computed: {
    currOptionApi() {
      return this.option.api ? true : false;
    }
  },
  watch: {
    option: {
      deep: true,
      handler(to, from) {
        this.$emit("change", to);
      }
    },
    smsList(newPhoneNumbes, oldPhoneNumbes) {
      this.option.phone_numbers = newPhoneNumbes.split(",");
    }
  },
  components: {
    Select,
    Option,
  }
};
</script>

<style lang="scss">
@import "@/styles/vars";
@import "@/styles/mixins";

.option-settings-container {
  position: absolute;
  width: 8.5vw;
  top: -3.5vh;
  box-shadow: 0px 2px 6px #bdbdbd;
  background-color: #fff;
  .top-bar {
    width: 8.5vw;
    height: 25px;
    position: absolute;
    top: 0;
    border-radius: 16px 16px 0 0;
  }
  .field {
    padding: 10px;
    @include flex-center;
    justify-content: space-between;
    &:not(:last-child) {
      border-bottom: 1px solid $grey-border-color;
    }
    .select-container {
      @include border($grey-border-color);
      background-color: $white;
      height: 46px;
      width: 2.7vw !important;
      max-width: 100px !important;
      border-radius: 4px;
      padding-top: 0;
      margin: 0;
    }
    .input-container {
      background-color: $white;
      @include border($grey-border-color);
      width: 4.2vw;
      height: 3.3vh;
      border-radius: 4px;
    }
  }
}
</style>