import Vue from 'vue';
import App from './App.vue';
import VueMaterial from 'vue-material';

Vue.config.productionTip = false;

Vue.use(VueMaterial);

new Vue({
  // i18n,
  render: h => h(App)
}).$mount('#survey-app');
