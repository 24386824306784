<template>
  <section class="working-area-list-container">
    <h1 v-if="!workingAreas.length">No Working Areas Available Yet</h1>
    <template v-else>
      <div class="header">
        <h4
          v-for="(fieldName, idx) in fieldNames"
          :key="idx"
          :class="{last:idx>=4}"
        >{{$t(fieldName)}}</h4>
      </div>
      <WorkingAreaItem
        v-for="(workingArea, idx) in workingAreas"
        :key="idx"
        :workingArea="workingArea"
        @editWorkingArea="emitEdit"
        @removeWorkingArea="emitRemove"
      />
    </template>
  </section>
</template>

<script>
import WorkingAreaItem from './WorkingAreaItem'


export default {
  props: {
    workingAreas: {
      type: Array,
      required: true
    },
  },
  methods: {
    emitEdit(workingAreaId) {
      this.$emit('editWorkingArea', workingAreaId)
    },
    emitRemove(workingAreaId) {
      this.$emit('removeWorkingArea', workingAreaId)
    },
  },
  computed: {
    fieldNames() {
      if (!this.workingAreas.length) return []
      const keys = Object.keys(this.workingAreas[0])
      return keys.filter(key => key !== 'id' && key !== 'cleandex')
    },
  },
  components: { WorkingAreaItem }
}
</script>

<style lang="scss">
@import "@/styles/mixins.scss";
@import "@/styles/vars.scss";

.working-area-list-container {
  position: relative;
  .header {
    width: 80vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2.2fr 1fr 6fr 3.8fr 1.6fr repeat(7, 1fr);
    padding: 0 30px;
    text-transform: uppercase;
    color: #909090;
    text-align: left;
    @include rtl {
      text-align: right;
    }
    font-size: $font-size-smaller;
    .last {
      text-align: center;
    }
  }
}
</style>